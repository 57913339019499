import './EditDiary.scss';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import useButtonChange from '../../../../hooks/useButtonChange';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { spinnerRequest } from '../../../../shared/spinnerRequest';
import { uuid } from '../../../../utils';
import { getUpload } from '../../../../utils/uploadUtils';
import ButtonFieldWithErrors from './../../../Forms/ButtonFieldWithErrors';
import { dataURLtoFile } from '../../../Cam/Cam.functions';
import Input from '../../../Forms/Input/Input';
import Uploader from '../../../Uploader/DiaryUploader';
import EditDiaryButtons from './EditDiaryButtons';

function EditDiary({
  activeDiary,
  updateActiveGallery,
  spinner,
  isMobile,
  isLarge,
  isMediumLarge,
  uploads = [],
  uploadFile,
  published,
  openOverlay,
  spinneractive,
}) {
  const intl = useIntl();
  const history = useHistory();
  const id = parseInt(useLocation().pathname.split('/').pop(), 10);
  const indicator = 'diarypicture';
  const [diaryError, setDiaryError] = useState();
  const [imageError, setImageError] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [dId, setDId] = useState();
  const [item, setItem] = useState({});
  const [savedItem, setSavedItem] = useState({});
  const [saveImage, setSaveImage] = useState(false);
  const [publishBtnLabel, setPublishBtnLabel] = useState(
    'DIARY_BUTTON_PUBLISH'
  );
  const [iSource, setISource] = useState();
  const [filename, setFilename] = useState();
  const { values, isValid } = useFormikContext();
  const minDimensions = { width: 800, height: 600 };
  const isMobileScreen = useMediaQuery('(min-width: 767px)');
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const { uploadIndicator, uploadError, uploadPercent, uploadResult } =
    getUpload(uploads, indicator);

  const [, updateDiary] = useAxios(
    {
      url: `/user/diary/${id}`,
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const [, publishDiaryData] = useAxios(
    {
      url: `/user/diary/${id}/publish`,
      method: 'POST',
    },
    { manual: true }
  );

  const [, unpublishDiaryData] = useAxios(
    {
      url: `/user/diary/${id}/publish`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const [, createDiaryRequest] = useAxios(
    {
      url: '/user/diary',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  const [, deleteDiaryImage] = useAxios(
    {
      url: `/user/diary/${id}/image`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const [
    { data: diaryEntryRequestData, loading: diaryEntryRequestLoading },
    diaryEntryRequest,
  ] = useAxios(
    {
      url: `/user/diary/${id}`,
    },
    { manual: true }
  );

  const getDiaryEntry = useCallback(() => {
    if (id) {
      spinnerRequest({
        request: diaryEntryRequest,
        spinner,
      })
        .then((response) => {
          const item = response?.data || {};
          updateActiveGallery(item);
          setItem(item);
          setSavedItem(item);
          setISource();
        })
        .catch((error) => {
          if (
            error?.response?.data?.errors &&
            error?.response?.data?.errors[0] === 'validation.diary_invalid_user'
          ) {
          } else if (error?.response) {
            setDiaryError(intl.formatMessage({ id: 'DIARY_ERROR_LOADING' }));
          }
        });
    } else {
      if (activeDiary?.type !== 'weblog' || activeDiary?.id !== 'unsaved') {
        updateActiveGallery({
          date: null,
          description: '',
          id: null,
          image: null,
          published: null,
          title: '',
          type: null,
        });
        return setItem({});
      }
      setItem({
        date: activeDiary?.date,
        discription: activeDiary?.description,
        id: activeDiary?.id,
        image: activeDiary?.image,
        published: activeDiary?.published,
        title: activeDiary?.title,
        type: activeDiary?.type,
      });
    }
  }, [
    diaryEntryRequest,
    id,
    intl,
    spinner,
    updateActiveGallery,
    activeDiary?.type,
    activeDiary?.date,
    activeDiary?.description,
    activeDiary?.id,
    activeDiary?.image,
    activeDiary?.published,
    activeDiary?.title,
  ]);

  useEffect(() => {
    setPublishBtnLabel(
      published ? 'DIARY_BUTTON_UNPUBLISH' : 'DIARY_BUTTON_PUBLISH'
    );
  }, [published]);

  useEffect(() => {
    if (!diaryEntryRequestLoading && !diaryEntryRequestData && !diaryError) {
      getDiaryEntry();
    }
  }, [
    diaryError,
    diaryEntryRequestData,
    diaryEntryRequestLoading,
    getDiaryEntry,
  ]);

  useEffect(() => {
    if (uploadIndicator === indicator) {
      if (uploadError) {
        const imageError =
          uploadError?.response?.data?.file?.shift() === 'dimensions'
            ? intl.formatMessage(
                { id: 'UPLOADER_INVALID_DIMENSIONS' },
                {
                  minWidth: minDimensions.width,
                  minHeight: minDimensions.height,
                }
              )
            : intl.formatMessage({ id: 'ERROR_NETWORK_ERROR' });

        getDiaryEntry();
        setImageError(imageError);
        uploadFile('', false, indicator);
      } else if (uploadResult && uploadResult?.data) {
        getDiaryEntry();
        setImageError('');
        uploadFile('', false, indicator);
        setISource(-1);
      }
    }
  }, [
    activeDiary?.status,
    getDiaryEntry,
    intl,
    uploadError,
    uploadFile,
    uploadIndicator,
    uploadResult,
    minDimensions.width,
    minDimensions.height,
  ]);

  const uploadFormData = useMemo(() => {
    new FormData();
  }, []);

  const publishEntry = useCallback(
    (responseId) =>
      new Promise((resolve, reject) => {
        if (!item) return reject();
        const diaryId = responseId || id;
        const request = item.published ? unpublishDiaryData : publishDiaryData;
        spinnerRequest({
          request,
          spinner,
          payload: { url: `/user/diary/${diaryId}/publish` },
        })
          .then((result) => {
            if (result.status === 200 || result.status === 204) {
              const changedItem = {
                ...item,
                id: diaryId,
                published: !item.published,
              };
              updateActiveGallery(changedItem);
              setItem(changedItem);
              setSaveImage(changedItem);
            }
            resolve(diaryId);
          })
          .catch((error) => reject(error));
      }),
    [
      item,
      publishDiaryData,
      unpublishDiaryData,
      spinner,
      updateActiveGallery,
      id,
    ]
  );

  const publishDiary = async () => {
    try {
      const diaryId = await saveDiary(false);
      await publishEntry(diaryId);
      setRedirect(true);
      setDId(diaryId);
      iSource && iSource !== -1 && setSaveImage(true);
    } catch (error) {
      console.log(error);
    }
  };

  const saveDiary = async (direct = true) => {
    try {
      const diaryId = id || (await createDiaryRequest())?.data?.id;
      await updateDiary({
        url: `/user/diary/${diaryId}`,
        data: {
          title: values.title,
          description: values.description,
        },
      });

      const savedItem = {
        ...item,
        id: diaryId,
        title: values.title,
        description: values.description,
      };

      setItem(savedItem);
      setSavedItem(savedItem);

      if (direct) {
        setRedirect(true);
        setDId(diaryId);
        iSource && iSource !== -1 && setSaveImage(true);
      }

      return diaryId;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      iSource &&
      iSource !== -1 &&
      filename &&
      !spinneractive &&
      dId &&
      saveImage
    ) {
      const data = new FormData();
      const file = dataURLtoFile(iSource, filename);
      data.append('file', file, filename);
      uploadFile(`/user/diary/${dId}/image`, data, indicator, false);
      setFilename(null);
      setSaveImage(false);
    }
  }, [iSource, filename, dId, indicator, uploadFile, spinneractive, saveImage]);

  useEffect(() => {
    const redirectWithImage =
      redirect &&
      uploadPercent === null &&
      uploadIndicator === indicator &&
      uploadResult?.status === 200 &&
      !id &&
      iSource &&
      iSource !== -1 &&
      dId;
    const redirectWithoutImage =
      redirect && !uploadPercent && !id && !iSource && dId;

    if (redirectWithImage || redirectWithoutImage) {
      if (!(isLarge && !isMediumLarge)) {
        updateActiveGallery({
          date: null,
          description: '',
          id: null,
          image: null,
          published: null,
          title: '',
          type: null,
        });
        history.push(`/my-content/diary/my-diary`);
      } else {
        history.push(`/my-content/diary/edit/${dId}`);
      }
    }
  }, [
    redirect,
    iSource,
    id,
    dId,
    history,
    uploadPercent,
    isLarge,
    isMediumLarge,
    updateActiveGallery,
    uploadIndicator,
    uploadResult?.status,
  ]);

  const openCam = () => {
    const stamp = uuid();

    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        camstamp: stamp,
        indicator,
        uploadPath: `/user/diary/${id}/image`,
        uploadFormData,
        title: intl.formatMessage({ id: 'SNAPSHOT_PROFILE_PICTURE_TITLE' }),
        minScreenshotWidth: 1600,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        outsideFormik: true,
        singleFile: true,
        setSourceOnly: (source, name) => {
          setISource(source);
          setImageError('');
          setFilename(name);
        },
      },
    });
  };

  const publishDisabled = !(
    isValid &&
    values.title !== '' &&
    values.description !== ''
  );

  const deleteImage = () => {
    deleteDiaryImage()
      .then((result) => {
        if (result.status === 204) {
          updateActiveGallery({ ...item, image: null });
          const savedItem = {
            ...item,
            image: null,
          };
          setItem(savedItem);
          setSavedItem(savedItem);
        }
      })
      .catch((error) => setImageError(error?.message));
  };

  const action = (name) => (data) => {
    if (typeof data !== 'object' || !data) {
      return;
    }

    const active = activeDiary || {};

    updateActiveGallery({
      ...active,
      [name]: data[name],
      id: active?.id || 'unsaved',
      type: 'weblog',
    });
  };

  const updateOptions = {
    title: useButtonChange({
      spinner,
      name: 'title',
      action: action('title'),
    }),
    description: useButtonChange({
      spinner,
      name: 'description',
      action: action('description'),
    }),
  };

  const cancel = () => {
    setItem(savedItem);
    setISource(null);
    setDId();
    setISource(-1); // has to be -1 not null or undefined so the unsaved image gets reset
    updateActiveGallery(savedItem);
  };

  return diaryError ? (
    <div className="error-message">{diaryError}</div>
  ) : (
    <div className="data-columns diary-edit">
      {!isMobileScreen && (
        <div className="data-right diary-uploads">
          <Uploader
            minDimensions={minDimensions}
            minGalleryDimensions={minDimensions}
            label="DIARY_FILE"
            acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png']}
            title={activeDiary?.title?.content}
            handleError={(error) => setImageError(error)}
            imageError={imageError}
            dropzoneProps={{ multiple: false }}
            isMobile={isMobile}
            className="diary-photo"
            type="diary-photo"
            activeGallery={activeDiary}
            singleFile={true}
            openCam={openCam}
            setISource={(source) => {
              setISource(source);
              setImageError('');
            }}
            deleteDiaryImage={deleteImage}
            setFilename={setFilename}
            outsideISource={iSource}
          />
        </div>
      )}

      <div className="data-left diary-inputs">
        {isLargeScreen && (
          <h2 className="headline">
            {intl.formatMessage({ id: 'DIARY_EDIT_HEADLINE' })}
          </h2>
        )}
        <ButtonFieldWithErrors
          as={Input}
          name="title"
          label="TITLE_LABEL"
          updateOptions={updateOptions['title']}
          blurTimeout={200}
        />
        <ButtonFieldWithErrors
          as="textarea"
          name="description"
          label="DESCRIPTION_LABEL"
          updateOptions={updateOptions['description']}
          placeholder={intl.formatMessage({ id: 'MIN_32_CHARS' })}
          blurTimeout={200}
        />
        <div className="form-group">
          <p className="textarea-length-indicator">
            {intl.formatMessage({ id: 'REMAINING_CHARS' })}{' '}
            {3000 - values.description.length}
          </p>
        </div>
      </div>

      {isMobileScreen && (
        <div className="data-right diary-uploads">
          <Uploader
            minDimensions={minDimensions}
            minGalleryDimensions={minDimensions}
            label="DIARY_FILE"
            acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png']}
            title={activeDiary?.title?.content}
            handleError={(error) => setImageError(error)}
            imageError={imageError}
            dropzoneProps={{ multiple: false }}
            isMobile={isMobile}
            className="diary-photo"
            type="diary-photo"
            activeGallery={activeDiary}
            singleFile={true}
            openCam={openCam}
            setISource={(source) => {
              setISource(source);
              setImageError('');
            }}
            deleteDiaryImage={deleteImage}
            setFilename={setFilename}
            outsideISource={iSource}
          />
        </div>
      )}

      {isMobileScreen ? (
        <div className="data-right edit-button-wrapper">
          <EditDiaryButtons
            saveDiary={saveDiary}
            publishDisabled={publishDisabled}
            publishDiary={publishDiary}
            publishBtnLabel={publishBtnLabel}
            cancel={cancel}
            cancelDisabled={!id}
          />
        </div>
      ) : (
        <EditDiaryButtons
          saveDiary={saveDiary}
          publishDisabled={publishDisabled}
          publishDiary={publishDiary}
          publishBtnLabel={publishBtnLabel}
          cancel={cancel}
          cancelDisabled={!id}
        />
      )}
    </div>
  );
}

export default EditDiary;
