export default {
  USER_ADVERTISING_TITLE: `Jetzt User werben und 25% Provision zusätzlich erhalten`,
  USER_ADVERTISING_CONTENT_1: `Benutze Deinen persönlichen Werbe-Link, um User zu werben, und erhalte bis zu 25% Provision on top auf alle generierten Umsätze.`,
  USER_ADVERTISING_CONTENT_2: `<p>Platziere Deinen persönlichen Werbe-Link in sozialen Medien, wie Facebook oder Instagram, oder poste ihn in Deinem persönlichen Blog oder Foren und führe so neue User auf Dein Profil Deiner bevorzugten Community.</p>
  <p><span class="bold">Für jeden Coin-Kauf</span>, den diese User tätigen, erhältst Du <span class="bold green">25% Provision - sofort</span>! Diese wird dann automatisch Deinem Umsatz in der laufenden Abrechnungsperiode gutgeschrieben.</p>
  <p>Zusätzlich bekommst Du jedes Mal bis zu <span class="bold green">32% Provision</span>, wenn User Deine Inhalte kaufen oder Deine Livecam bzw. Deinen Chat besuchen.</p>
  <p>Somit hast Du also die Möglichkeit Deinen persönlichen Umsatz um bis zu 57% zu steigern – <span class="bold">nutze Deine Chance und fang noch heute an, kräftig abzukassieren</span>!</p>`,
  USER_ADVERTISING_LINK_AMX: `Dein persönlicher Link für AmateurX`,
  USER_ADVERTISING_LINK_FUN: `Dein persönlicher Link für FunCommunity`,
  USER_ADVERTISING_LINK_AMF: `Dein persönlicher Link für AmateurFans`,
};
