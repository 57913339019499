import {
  MANAGEMENT_EVENTS_STREAM_STOP,
  MANAGEMENT_EVENTS_USER_ADD,
  MANAGEMENT_EVENTS_USER_REMOVE,
} from '../actions/actions';

const initialState = [
  { className: 'usercolor-blue', count: 0 },
  { className: 'usercolor-light-blue', count: 0 },
  { className: 'usercolor-green', count: 0 },
  { className: 'usercolor-orange', count: 0 },
  { className: 'usercolor-purple', count: 0 },
  { className: 'usercolor-red', count: 0 },
  { className: 'usercolor-brown', count: 0 },

  { className: 'usercolor-darkgreen', count: 0 },
  { className: 'usercolor-gray', count: 0 },
  { className: 'usercolor-lightpurple', count: 0 },
  { className: 'usercolor-darkblue', count: 0 },
  { className: 'usercolor-yellowgreen', count: 0 },
];

const usercolor = (state = initialState, action) => {
  switch (action.type) {
    case MANAGEMENT_EVENTS_USER_ADD:
      const { camUsers = [] } = action;
      const userExists = camUsers.some(
        (camUser) => camUser.userId === action.payload.userId
      );
      return state.map((obj) => {
        if (obj.className === action.payload.color && userExists) {
          return Object.assign({}, obj, { count: ++obj.count });
        }
        return obj;
      });
    case MANAGEMENT_EVENTS_USER_REMOVE:
    case MANAGEMENT_EVENTS_STREAM_STOP:
      if (!action.payload.color) {
        return state;
      }

      return state.map((obj) => {
        if (obj.className === action.payload.color && obj.count > 0) {
          return Object.assign({}, obj, { count: --obj.count });
        }
        return obj;
      });
    default:
      return state;
  }
};

export default usercolor;
