export default {
  RELATIONSHIPS_HEADLINE: `Beziehungen – Freunde und Favoriten`,
  RELATIONSHIPS_CONTENT: `Unter dem Punkt {link} im Hauptmenü kannst Du Deine gespeicherten Kontakte einsehen und bearbeiten, Freundschaftsanfragen annehmen oder ablehnen und direkt mit „Freunden“ und „Favoriten“ Kontakt aufnehmen. Die strategische Nutzung dieser beiden Kategorien ist entscheidend für eine stressfreie Interaktion. Indem Du Deine Freunde und Favoriten organisierst und verwaltest, stellst Du sicher, dass Du keine Nachricht verpasst. So kannst Du stets mit Deinen wichtigsten Kunden in Verbindung bleiben und sie aktiv anschreiben, ohne großes Suchen.`,
  RELATIONSHIPS_CONTENT_LINK: `Beziehungen`,
  RELATIONSHIPS_CONTENT_MESSENGER_LINK: `Multi Messenger`,
  RELATIONSHIPS_CONTENT_1: `Freunde`,
  RELATIONSHIPS_CONTENT_1_1: `Ein Freund in Deinem Account ist ein Kunde, mit dem Du regelmäßig kommuniziert. Diese Freundschaft kann darauf basieren, dass Ihr ähnliche Interessen teilt oder Ihr euch intensiver über Eure Aktivitäten unterhaltet.`,
  RELATIONSHIPS_CONTENT_1_2: `Sobald Du einige Male online bist, wirst Du feststellen, dass Du von vielen Usern Freundschaftsanfragen erhältst. Doch bevor Du diese einfach annimmst, solltest Du Dir Zeit nehmen, um den jeweiligen User anzuschreiben und eine aktive Kommunikation und Freundschaft aufzubauen. Es bringt Dir nichts, Freunde zu haben, mit denen Du nie sprichst.`,
  RELATIONSHIPS_CONTENT_1_3: `<span class="bold">Hinweis:</span> Jeder User kann auf Deinem Profil Deine Freunde sehen, daher sei mit der Annahme neuer Freundschaften ohne vorherigen Kontakt immer etwas vorsichtig.`,

  RELATIONSHIPS_CONTENT_2: `Wie kann ich selbst eine Freundschaftsanfrage stellen?`,
  RELATIONSHIPS_CONTENT_2_1: `Klicke im Chatfenster des Kunden oben auf das Herz und Du sendest ihm eine Freundschaftsanfrage.`,
  RELATIONSHIPS_CONTENT_2_2: `Wo finde ich neue Freundschaftsanfragen, während ich online bin?`,
  RELATIONSHIPS_CONTENT_2_3: `Wenn Du online bist, werden neue Freundschaftsanfragen direkt in Deinem {link} unter Aktivitäten angezeigt. Dort kannst Du schnell reagieren und den Kunden anschreiben.`,
  RELATIONSHIPS_CONTENT_2_4: `Wo finde ich neue Freundschaftsanfragen, wenn ich offline war?`,
  RELATIONSHIPS_CONTENT_2_5: `Deine offenen Anfragen von Usern findest Du unter dem Menüpunkt {link}.`,
  RELATIONSHIPS_CONTENT_2_LINK: `Beziehungen – Freunde – Anfragen`,
  RELATIONSHIPS_CONTENT_2_NO_FRIEND: `Der Kunde ist noch kein Freund. Klicke auf das Herz, um ihm eine Anfrage zu senden.`,
  RELATIONSHIPS_CONTENT_2_IS_FRIEND: `Der Kunde ist ein bestätigter Freund. Durch Klick auf das gefüllte Herz entfernst Du den Kunden als Freund.`,

  RELATIONSHIPS_CONTENT_3: `Wo finde ich meine Freunde?`,
  RELATIONSHIPS_CONTENT_3_1: `Aktive Chats mit Deinen Freunden sind im {link} unter dem Filter „Freunde“ zu finden.`,
  RELATIONSHIPS_CONTENT_3_2: `Freunde, die gerade Online kommen, findest Du im {link} unter Aktivitäten – Freunde Logins.`,
  RELATIONSHIPS_CONTENT_3_3: `Eine Übersicht aller Deiner Freunde findest Du unter dem Menüpunkt {link}.`,
  RELATIONSHIPS_CONTENT_3_LINK: `Beziehungen – Freunde – Bestätigte Freunde`,

  RELATIONSHIPS_CONTENT_4: `Favoriten`,
  RELATIONSHIPS_CONTENT_4_1: `Merke Dir User, die Dir viele Aufmerksamkeiten senden oder auch sonst viele Coins bei Dir ausgeben. Damit hast Du Deine Top-Kunden immer im Blick und vergisst nicht, Dich regelmäßig bei ihnen zu melden.`,
  RELATIONSHIPS_CONTENT_4_2: `Ein Favorit kann auch ein Kunde sein, mit dem Du Dich gut unterhalten kannst und bei dem Du das Gefühl hast, dass ihr Euch auch in Zukunft noch lange austauschen wollt. Das kann ein entscheidendes Kriterium dafür sein, dass er zu Deinem Favoriten und damit zu Deinem Stammkunden werden kann.`,
  RELATIONSHIPS_CONTENT_4_3: `<span class="bold">Tipp:</span> Kunden sehen nicht, wenn Du sie als Favorit markierst.`,
  RELATIONSHIPS_CONTENT_4_4: `Wie mache ich einen Kunden zu meinem Favoriten?`,
  RELATIONSHIPS_CONTENT_4_5: `Klicke im Chatfenster des Kunden oben auf den Stern und markiere ihn damit als Favorit.`,
  RELATIONSHIPS_CONTENT_4_6: `Wo finde ich meine Favoriten?`,
  RELATIONSHIPS_CONTENT_4_7: `Aktive Chats mit Deinen Favoriten sind im {link} unter dem Filter „Favoriten“ zu finden.`,
  RELATIONSHIPS_CONTENT_4_8: `Eine Übersicht aller Deiner Favoriten findest Du unter dem Menüpunkt {link}.`,
  RELATIONSHIPS_CONTENT_4_LINK: `Beziehungen – Favoriten`,
  RELATIONSHIPS_CONTENT_4_NO_FAVORITE: `Der Kunde ist noch kein Favorit. Klicke auf den Stern, um ihn als Favorit zu markieren.`,
  RELATIONSHIPS_CONTENT_4_IS_FAVORITE: `Der Kunde ist als Favorit markiert. Durch Klick auf den gefüllten Stern entfernst Du den Kunden als Favorit.`,

  RELATIONSHIPS_CONTENT_5: `Achtung`,
  RELATIONSHIPS_CONTENT_5_1: `Ältere Chats werden automatisch aus Deinem Multi Messenger entfernt, damit Du nur die aktuellen Chats mit Deinen Kontakten siehst. Möchtest Du einen älteren Chat mit einem Deiner Freunde oder Favoriten wieder aufleben lassen, kannst Du ihn auf der jeweiligen Übersichtsseite durch einen Klick auf das Profilbild Menü (drei Punkte) jederzeit anschreiben und dadurch wieder aktiv in Deinen Multi Messenger holen.`,

  RELATIONSHIPS_CONTENT_6: `Fazit`,
  RELATIONSHIPS_CONTENT_6_1: `Nutze die Vorteile der „Favoriten“ und „Freunde“-Funktion, um Deine Chats und Kontakte effizient zu verwalten. Auf diese Weise behältst Du immer den Überblick und kannst Deine Freunde und Favoriten zu jeder Zeit schneller finden und anschreiben.`,
};

