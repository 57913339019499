import attachments from './attachments';
import authenticity from './authenticity';
import creatorReferral from './creatorReferral';
import creatorTags from './creatorTags';
import diary from './diary';
import firstSteps from './firstSteps';
import fourScore from './fourScore';
import galleries from './galleries';
import icebreaker from './icebreaker';
import livecamFaq from './livecamFaq';
import livecamSetting from './livecamSetting';
import lovenseInstallation from './lovenseInstallation';
import multiMessenger from './multiMessenger';
import profile from './profile';
import profileImage from './profileImage';
import rankings from './rankings';
import relationships from './relationships';
import settings from './settings';
import timeManagement from './timeManagement';
import userAdvertising from './userAdvertising';
import userInfocard from './userInfocard';
import visibility from './visibility';
import voiceMessage from './voiceMessage';
import xmasCalendar from './xmasCalendar';

export default {
  ...creatorReferral,
  ...userAdvertising,
  ...profileImage,
  ...livecamSetting,
  ...livecamFaq,
  ...fourScore,
  ...xmasCalendar,
  ...lovenseInstallation,
  ...firstSteps,
  ...multiMessenger,
  ...attachments,
  ...creatorTags,
  ...rankings,
  ...profile,
  ...settings,
  ...icebreaker,
  ...diary,
  ...galleries,
  ...voiceMessage,
  ...visibility,
  ...relationships,
  ...timeManagement,
  ...authenticity,
  ...userInfocard,

  WIKI_HOW_TO_HEADER: `Choose your topic from the list`,
  WIKI_HOW_TO_MENU_CREATOR_REFERRAL: `Creator referral program`,
  WIKI_HOW_TO_MENU_USER_ADVERTISING: `Advertising links for users`,
  WIKI_HOW_TO_MENU_PROFILE_IMAGE: `Your perfect profile picture`,
  WIKI_HOW_TO_MENU_LIVECAM_SETTING: `Livecam settings`,
  WIKI_HOW_TO_MENU_LIVECAM_FAQ: `FAQ about livecam`,
  WIKI_HOW_TO_MENU_4SCORE: `Community Game 4Score`,
  WIKI_HOW_TO_MENU_LOVENSE_INSTALLATION: `Setting up the Lovense Toy`,
  WIKI_HOW_TO_MENU_FIRST_STEPS: `The perfect start in LiveCreator`,
  WIKI_HOW_TO_MENU_MULTI_MESSENGER: `Multi Messenger`,
  WIKI_HOW_TO_MENU_ATTACHMENTS: `File attachments`,
  WIKI_HOW_TO_MENU_CREATOR_TAGS: `Creator tags`,
  WIKI_HOW_TO_MENU_RANKINGS: `Rankings / My performance`,
  WIKI_HOW_TO_MENU_PROFILE: `Your profile`,
  WIKI_HOW_TO_MENU_SETTINGS: `Settings`,
  WIKI_HOW_TO_MENU_ICEBREAKER: `Icebreaker`,
  WIKI_HOW_TO_MENU_DIARY: `Diary`,
  WIKI_HOW_TO_MENU_GALLERIES: `Photo and Video Galleries`,
  WIKI_HOW_TO_MENU_VOICE_MESSAGE: `Voice messages`,
  WIKI_HOW_TO_MENU_VISIBILITY: `Visibility and presence`,
  WIKI_HOW_TO_MENU_RELATIONSHIPS: `Relationships - Friends and Favorites`,
  WIKI_HOW_TO_MENU_TIME_MANAGEMENT: `Time Management`,
  WIKI_HOW_TO_MENU_AUTHENTICITY: `Authenticity`,
  WIKI_HOW_TO_MENU_INFOCARD: `User Infocard`,

  WIKI_HEADLINE_VIDEO: `Video tutorial`,
};
