import './RunToy.scss';

import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import pendingSVG from '../../../assets/img/svg/pending.svg';

function RunToy({ runToy, startToyTimer }) {
  const timerRef = useRef();
  const intl = useIntl();
  useEffect(() => {
    if (runToy?.countDown && runToy?.timeoutMS && runToy.start) {
      timerRef.current = setTimeout(() => {
        startToyTimer();
      }, 1000);

      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [runToy?.countDown, runToy?.timeoutMS, runToy.start, startToyTimer]);

  if (!runToy?.timeoutMS || !runToy?.countDown || !runToy?.username) {
    return null;
  }

  const usersTranslation =
    runToy?.numberUsersWaiting > 1
      ? 'TOYCONTROL_USERS_PL'
      : 'TOYCONTROL_USERS_SG';

  const usersWaitingTranslation =
    runToy?.numberUsersWaiting > 1
      ? 'TOYCONTROL_USERS_WAITING_PL'
      : 'TOYCONTROL_USERS_WAITING_SG';

  return (
    <div className="toy-control">
      <span className="countdown">
        <span>{runToy.countDown}</span>
        <span className="additional">
          {intl.formatMessage({ id: 'TOYCONTROL_SECONDS' })}
        </span>
      </span>
      <span className="user-active">
        {runToy.username}
        <span className="additional">
          {intl.formatMessage({ id: 'TOYCONTROL_HAS_ACTIVATED_TOY' })}
        </span>
      </span>
      {runToy?.numberUsersWaiting > 0 ? (
        <span className="users-waiting">
          <span className="text-block">
            <span>
              {runToy?.numberUsersWaiting}{' '}
              {intl.formatMessage({ id: usersTranslation })}
            </span>
            <span className="additional">
              {intl.formatMessage({ id: usersWaitingTranslation })}
            </span>
          </span>
          <ReactSVG src={pendingSVG} wrapper="span" className="icon" />
        </span>
      ) : null}
    </div>
  );
}

export default RunToy;
