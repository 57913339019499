export default {
  AUTHENTICITY_HEADLINE: `Authentizität: Der Schlüssel zu einer stärkeren Bindung`,
  AUTHENTICITY_INTRO: `Heute dreht sich alles um das Thema Authentizität. Doch was ist Authentizität genau und was bedeutet sie für Dich als Creator? Warum ist sie so wichtig und wie kannst Du sie effektiv umsetzen? Diese Fragen und mehr möchten wir hier beantworten.`,
  AUTHENTICITY_INTRO_1: `In der heutigen digitalen Welt, in der beinahe alles inszeniert und bearbeitet wird, suchen Kunden zunehmend nach Authentizität und Einblicken in das Leben anderer Menschen, als nur nach Modelmaßen und Erotik. Also kann genau diese Authentizität der Schlüssel sein, um eine engere und damit längere Bindung zu Deinen Kunden aufzubauen.`,
  AUTHENTICITY_HEADLINE_2: `Was bedeutet authentisch sein?`,
  AUTHENTICITY_TEXT_1: `Authentisch sein bedeutet sich selbst treu zu bleiben. Es geht darum, nicht alle Makel zu retuschieren und mehrere Filter zu verwenden, um ein perfektes Bild zu erzeugen. Kunden schätzen es mehr, wenn sie Dich als echte Person erkennen können. Jeder hat Makel oder Zonen, die man an sich selbst nicht so sehr mag. Doch eben diese können, wenn Du sie nicht zeigen oder verstecken möchtest, geschickt mit Posen oder Outfits kaschiert werden, anstatt alles mit Filtern zu bearbeiten.`,
  AUTHENTICITY_TEXT_2: `Authentizität bedeutet auch, dass es nicht immer um Erotik gehen muss. Keine Frau ist ständig in erotischer Stimmung, und es kommt gut an auch über Hobbys oder Reiseziele zu sprechen. Kunden, die eine langfristige Bindung eingehen, wünschen sich oft den Alltag mit Dir zu teilen und nicht nur erotischen Inhalt zu konsumieren. Diese echten und persönlichen Interaktionen machen Dich authentisch.`,
  AUTHENTICITY_HEADLINE_3: `Warum ist Authentizität wichtig?`,
  AUTHENTICITY_STEP_1_HEADER: `Vertrauen aufbauen`,
  AUTHENTICITY_STEP_1_CONTENT: `Kunden möchten das Gefühl haben Dich wirklich zu kennen. Indem Du persönliche Momente und Einblicke aus Deinem Alltag teilst, zeigst Du, dass Du echt und zugänglich bist. Dies schafft Vertrauen und Loyalität.`,
  AUTHENTICITY_STEP_2_HEADER: `Verbindung auf einer tieferen Ebene`,
  AUTHENTICITY_STEP_2_CONTENT: `Authentische Einblicke lassen Deine Kunden fühlen, dass sie Teil Deines Lebens sind. Sie schätzen nicht nur Deine Inhalte, sondern auch Dich als Person.`,
  AUTHENTICITY_STEP_3_HEADER: `Individualität`,
  AUTHENTICITY_STEP_3_CONTENT: `In einer Branche, in der viele Inhalte ähnlich wirken, kann Authentizität Dich hervorheben. Es ist Dein individueller Alltag und Deine Persönlichkeit, die Dich einzigartig machen.`,
  AUTHENTICITY_HEADLINE_4: `Wie setze ich Authentizität um?`,
  AUTHENTICITY_STEP_4_HEADER: `Tagebuch führen`,
  AUTHENTICITY_STEP_4_CONTENT: `Ein Tagebucheintrag, in dem Du persönliches teilst, kann eine wunderbare Möglichkeit sein, Deinen Kunden einen echten Einblick zu geben. Dabei kann es sich um ein Selfie mit Deiner Kaffeetasse am Morgen handeln oder auch um ein Bild vom Ausflug an den See.`,
  AUTHENTICITY_STEP_5_HEADER: `Galerie erstellen`,
  AUTHENTICITY_STEP_5_CONTENT: `Neben professionellen, inszenierten Fotos, könntest Du eine Galerie mit Deinen liebsten Sommerkleidern, Deiner letzten Shoppingausbeute oder anderen Dingen aus Deinem Alltag erstellen. Bist Du z.B. in Deiner Küche zum Backen, warum dann nicht ein paar Schnappschüsse von Dir dabei erstellen und so Deine Kunden daran teilhaben lassen? Das heißt nicht, dass Du nicht auf die Qualität Deiner Aufnahmen achten sollst.`,
  AUTHENTICITY_OUTRO: `Zusammenfassend lässt sich sagen, dass Authentizität eine tolle Möglichkeit ist, die Beziehung zu Deinen Kunden zu stärken und Dich von der Masse abzuheben. Deine Kunden werden nicht nur begeistert sein von dem was Du tust, sondern sich auch mit Dir identifizieren und eine emotionale Bindung aufbauen. Also zeige Dich wie Du bist, denn es macht Dich unverwechselbar und einzigartig.`,
};
