import './TwoFactorLogin.scss';

import useAxios from 'axios-hooks';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import closeIcon from '../../../assets/img/svg/close.svg';
import { lStorage } from '../../../services/storage';
import useSendTwoFactorMail from '../../../utils/twoFactorUtils';
import TwoFactorInput from '../TwoFactorInput/index';

function TwoFactor({
  closeOverlay = () => {},
  stamp,
  userId,
  token,
  rememberMe,
  email,
  addAuthToken = () => {},
  spinner = () => {},
}) {
  const intl = useIntl();
  const { sendTwoFactorMail } = useSendTwoFactorMail();
  const [error, setError] = useState();
  const [useBackupCode, setUseBackupCode] = useState(false);
  const [mailSend, setMailSend] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      spinner(false);
      isMounted.current = false;
    };
  }, [spinner]);

  const showMessage = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    let timer;
    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [isVisible]);

  if (email && !mailSend) {
    setMailSend(true);
    showMessage();
  }
  const [{ loading }, twoFactorRequest] = useAxios(
    {
      url: '/oauth/token',
      method: 'POST',
    },
    { manual: true }
  );

  function handleFormSubmission(code) {
    spinner(true);
    const payload = {
      grant_type: 'two_factor_user',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '*',
      token: token,
      user_id: userId,
      code: code,
    };

    twoFactorRequest({
      data: payload,
    })
      .then((response) => {
        if (isMounted.current) {
          const responseData = response.data;
          if (!lStorage.getItem('hasUsedDevice')) {
            lStorage.setItem('hasUsedDevice', true);
          }
          lStorage.setItem('rememberMe', rememberMe);
          addAuthToken(responseData, rememberMe);
          lStorage.setItem('navigationTooltip', 1);
          lStorage.setItem('finaliseTooltip', 1);
          closeOverlay(stamp);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setError(error.response.data?.hint);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          spinner(false);
        }
      });
  }

  function sendMail() {
    sendTwoFactorMail({ userId, spinner });
    showMessage();
  }
  return (
    <>
      <div className="overlay-wrapper small-overlay two-factor-login">
        <div
          className="close-btn"
          onClick={() => {
            closeOverlay(stamp);
          }}
        >
          <ReactSVG src={closeIcon} wrapper="span" />
        </div>
        <div className="overlay-header">
          <h1 className="headline">
            {intl.formatMessage({
              id: useBackupCode ? 'TWO_FACTOR_RESET' : 'TWO_FACTOR_LOGIN',
            })}
          </h1>
        </div>
        <div className="overlay-content">
          {useBackupCode ? (
            <p className="text">
              {intl.formatMessage({ id: 'TWO_FACTOR_BACKUP_CODE_TEXT' })}
            </p>
          ) : (
            <p className="text">
              {intl.formatMessage({ id: 'TWO_FACTOR_LOGIN_TEXT' })}
              {intl.formatMessage({
                id: email ? 'TWO_FACTOR_LOGIN_EMAIL' : 'TWO_FACTOR_LOGIN_TOTP',
              })}
            </p>
          )}
          <TwoFactorInput
            buttonClick={handleFormSubmission}
            error={error}
            setError={setError}
            useBackupCode={useBackupCode}
            setUseBackupCode={setUseBackupCode}
            noTitle={true}
            loading={loading}
            email={email}
            label={
              useBackupCode ? 'TWO_FACTOR_RESET_BUTTON' : 'TWO_FACTOR_VERIFY'
            }
          />
          {email && (
            <>
              {isVisible && (
                <div className="success-wrapper small has-margin-bottom">
                  <FormattedMessage id="TWO_FACTOR_EMAIL_SEND" />
                </div>
              )}
              <p className="text-small">
                <FormattedMessage id="TWO_FACTOR_RESEND" />
                <a onClick={() => sendMail()}>
                  <FormattedMessage id="TWO_FACTOR_RESEND_CLICK" />
                </a>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="background" />
    </>
  );
}

export default TwoFactor;

