import './App.scss';

import axios from 'axios';
import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import MobileHeader from '../../components/Header/MobileHeader/MobileHeader';
import Menu from '../../components/Menu/Menu';
import {
  getConfirmedMenuItems,
  getDoiMenuItems,
  getOnboardingMenuItems,
  getReviewMenuItems,
  getUnauthMenuItems,
  getUnconfirmedMenuItems,
} from '../../components/Menu/Menu.functions';
import useResize from '../../hooks/useResize';
import { default as locales } from '../../locale';
import { getNestedRoutes, landingPageRoute, routes } from '../../routes';
import senderService from '../../services/sender';
import { lStorage } from '../../services/storage';
import {
  getCookieHintData,
  isLivecamCreator,
  removeUrlParam,
  user as userUtils,
  uuid,
} from '../../utils';
import { renderRoutes } from '../../utils/routing';
import Alert from '../Alerts/Alert';
import LivecamNotification from '../Alerts/Notifications';
import Page404 from '../Content/Errors/Page404/';
import Overlay from '../Overlay';
import Spinner from '../Spinner';
import SpinnerPage from '../Spinner/SpinnerPage';
import Redirect from '../Utils/Redirect';
import VeriffComponent from '../Veriff';
import VeriffBar from '../VeriffBar';
import ErrorBoundary from './ErrorContainer';

function App({
  isSubmenuShown = true,
  logout = () => {},
  toggle = () => {},
  user = {},
  sender = {},
  browser = {},
  alerts = [],
  notifications = [],
  keyDown = () => {},
  locale = 'de',
  errorLogin = () => {},
  cookies = true,
  cookie = () => {},
  thirdPartyCookiesTested = false,
  resize,
  toggleUserlistView,
  userinfo,
  usernote,
  userlist,
  isMobile,
  friendRequests,
  newMessages,
  gameTurn,
  visibilityChange,
  userShowLogoutAlert,
  userLogin = () => {},
  updateIntl,
  versionTimerStart,
  updateVersion,
  openOverlay,
  openVeriff,
  showVeriffReminder,
  thirdPartyCookiesAllowed,
}) {
  const intl = useIntl();
  const [title, setTitle] = useState('');
  const [headline, setHeadline] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [versionCheckStarted, setVersionCheckStarted] = useState(false);

  const [areKeyEventsSet, setAreKeyEventsSet] = useState(false);
  const [hasStorage, setHasStorage] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [showLanguageSelect, setshowLanguageSelect] = useState(false);
  const [showVeriff, setShowVeriff] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const openedUserlist = useRef(false);

  const search = useLocation().search;
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const studio = urlSearchParams.get('studio');
  const aid = urlSearchParams.get('AID');
  const userId = urlSearchParams.get('userid');
  const trk = urlSearchParams.get('trk');

  const isMultiMessengerRoute =
    history.location.pathname === '/multi-messenger';

  useResize({ resize });
  if (studio) {
    lStorage.setItem('studio', studio);
  }

  if (aid) {
    lStorage.setItem('aid', aid);
  }

  const isLivecam = isLivecamCreator(user);

  const [, checkToken] = useAxios(
    {
      url: '/oauth/login',
    },
    { manual: true }
  );

  const [, mailClick] = useAxios(
    {
      url: '/mail/click',
      method: 'POST',
      data: {
        trk,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (
      user?.account?.nickname.startsWith('sender-') &&
      (user?.account?.state === 'confirmed' ||
        user?.account?.state === 'unconfirmed')
    ) {
      openOverlay({
        Component: 'NicknameChanger',
        props: {
          intl,
        },
      });
    }
  }, [user?.account?.nickname, user?.account?.state, openOverlay, intl]);

  useEffect(() => {
    if (trk) {
      const handleClick = async () => {
        try {
          await mailClick();
          urlSearchParams.delete('trk');
          history.replace({ search: urlSearchParams.toString() });
        } catch (err) {
          console.log({ trkError: err });
        }
      };

      handleClick();
    }
  }, [trk, history, mailClick, urlSearchParams]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      setIsAuth(false);
    }

    const cookieHintData = getCookieHintData(locale, user.isLoggedIn);

    if (!hasStorage && !thirdPartyCookiesTested) {
      cookie(false, cookieHintData);
    } else if (!cookies) {
      setHasStorage(false);
    } else if (!lStorage.test()) {
      setHasStorage(false);
    } else if (user.isLoggedIn && user.account.livecam !== 'true') {
      cookie(true);
    } else if (
      sender.isAuthenticated &&
      hasStorage &&
      !isAuth &&
      !thirdPartyCookiesTested
    ) {
      senderService
        .auth()
        .then((authdata) => {
          if (authdata && authdata.status === 400) {
            localStorage.removeItem('thirdPartyCookie');
            cookie(false, cookieHintData);
          } else {
            localStorage.setItem('thirdPartyCookie', 'true');
            cookie(true);
            setIsAuth(true);
          }
        })
        .catch((err) => {
          setHasStorage(false);
        });
    }
  }, [
    hasStorage,
    sender,
    errorLogin,
    locale,
    cookies,
    cookie,
    user,
    isAuth,
    thirdPartyCookiesTested,
  ]);

  useEffect(() => {
    if (!user?.isLoggedIn) {
      if (browser.isBot) {
        const defaultLanguage = 'de';
        updateIntl({
          locale: defaultLanguage,
          messages: locales[defaultLanguage],
        });
      } else {
        let lang = new URLSearchParams(location.search).get('lang');
        if (lang) {
          lang = lang.toLowerCase();
          if (['de', 'en'].includes(lang)) {
            lStorage.setItem('lang', lang);
            updateIntl({
              locale: lang,
              messages: locales[lang],
            });
          }
        }
      }
    }
  }, [location.search, user.isLoggedIn, updateIntl, browser.isBot]);

  useEffect(() => {
    if (!lStorage.getItem('parametersDataSet')) {
      lStorage.setItem('referer', document.referrer);
      lStorage.setItem('userAgent', navigator.userAgent);
      lStorage.setItem('locale', navigator.language);
      lStorage.setItem('getParams', search);
      lStorage.setItem('parametersDataSet', true);
    }
  }, [search]);

  useEffect(() => {
    if (
      user.isLoggedIn &&
      user?.account?.state === 'confirmed' &&
      showVeriff === null
    ) {
      const showVeriff = userUtils.getShowVeriff(user);
      setShowVeriff();
      const stamp = uuid;
      showVeriff &&
        openOverlay({
          stamp,
          Component: 'VeriffOverlay',
          props: {
            stamp,
          },
        });
    }
  }, [user, user?.isLoggedIn, user?.account?.state, showVeriff, openOverlay]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      setShowVeriff(null);
    }
  }, [user.isLoggedIn, setShowVeriff]);

  useEffect(() => {
    if (
      user.isLoggedIn &&
      user?.account?.state === 'confirmed' &&
      !areKeyEventsSet
    ) {
      setAreKeyEventsSet(true);
      console.log('windows key set');
      window.document.addEventListener('keydown', keyDown);
      if (browser.isMobile) {
        window.document.addEventListener('visibilitychange', visibilityChange);
      }
    } else if (areKeyEventsSet && !user.isLoggedIn) {
      console.log('windows key cleanup');
      window.document.removeEventListener('keydown', keyDown);
      window.document.removeEventListener('visibilitychange', visibilityChange);
      setAreKeyEventsSet(false);
    }
  }, [
    user.isLoggedIn,
    user?.account?.state,
    keyDown,
    areKeyEventsSet,
    setAreKeyEventsSet,
    user,
    browser.isMobile,
    visibilityChange,
  ]);

  useEffect(() => {
    if (
      !user.isLoggedIn &&
      user.access_token &&
      !location.pathname.includes('client-login') &&
      !location.pathname.includes('management-login') &&
      !location.pathname.includes('email-verification')
    ) {
      checkToken()
        .then(() => {
          removeUrlParam('userid');
          userLogin(isMultiMessengerRoute && userId);
        })

        .catch((err) => {
          console.log({ err });
          if (!axios.isCancel(err)) {
            logout(true);
          }
        });
    }
  }, [
    user.access_token,
    user.isLoggedIn,
    location.pathname,
    isMultiMessengerRoute,
    userId,
    userLogin,
    logout,
    checkToken,
    updateVersion,
  ]);

  useEffect(() => {
    let currentRoute;

    if (['/', '/model'].includes(location.pathname)) {
      setshowLanguageSelect(true);
    } else {
      setshowLanguageSelect(false);
    }
    if (location.pathname === '/') {
      currentRoute = landingPageRoute;
    } else {
      currentRoute = routes.find(
        (r) => r.path !== '/' && location.pathname.includes(r.path)
      );
    }

    const childRoute = getNestedRoutes().find(
      (r) => r.path !== '/' && location.pathname.includes(r.path)
    );
    if (!currentRoute) {
      document.title = 'LiveCreator';
    } else {
      const translatedTitle =
        currentRoute.showCreatorName && user?.account?.nickname
          ? user?.account?.nickname
          : intl.formatMessage({ id: currentRoute.title });
      const translatedHeadline =
        currentRoute.showCreatorName && user?.account?.nickname
          ? user?.account?.nickname
          : intl.formatMessage({
              id: currentRoute.headline,
            });
      const translatedSubtitle =
        childRoute?.title || currentRoute.subtitle
          ? intl.formatMessage({
              id: childRoute?.title || currentRoute.subtitle,
            })
          : '';
      setTitle(translatedTitle);
      setHeadline(translatedHeadline);
      setSubtitle(translatedSubtitle);

      document.title = `${translatedTitle}${
        translatedSubtitle ? ` - ${translatedSubtitle}` : ''
      } | LiveCreator`;
    }
  }, [location, intl, setshowLanguageSelect, user?.account?.nickname]);

  useEffect(() => {
    if (!versionCheckStarted) {
      versionTimerStart();
      setVersionCheckStarted(true);
    }
  }, [versionCheckStarted, setVersionCheckStarted, versionTimerStart]);

  const shortenFriendRequests = friendRequests > 9 ? '9+' : friendRequests;

  const menuItems = useMemo(() => {
    if (!user.isLoggedIn) {
      return getUnauthMenuItems(intl, history.location.pathname);
    }

    function handleLogoutBtnClick() {
      logout(false);
    }

    function handleOnboardingLogoutClick() {
      if (user.account.finaliseBtnShown) {
        userShowLogoutAlert();
      } else {
        logout(false);
      }
    }

    // TODO: Implement routing for all states
    switch (user.account.state) {
      case 'onboarding':
      case 'pending':
        return getOnboardingMenuItems(
          intl,
          handleOnboardingLogoutClick,
          history.location.pathname
        );
      case 'double_optin_needed':
        return getDoiMenuItems(intl, handleLogoutBtnClick);
      case 'review_needed':
        return getReviewMenuItems(intl, handleOnboardingLogoutClick);
      case 'confirmed':
        return getConfirmedMenuItems(
          intl,
          handleLogoutBtnClick,
          user,
          shortenFriendRequests,
          newMessages > 9 ? '9+' : newMessages <= 0 ? null : newMessages,
          gameTurn
        );
      case 'unconfirmed':
        return getUnconfirmedMenuItems(intl, handleLogoutBtnClick, user);

      default:
        return [];
    }
  }, [
    user,
    intl,
    history.location.pathname,
    logout,
    shortenFriendRequests,
    newMessages,
    gameTurn,
    userShowLogoutAlert,
  ]);

  function handleMenuToggle() {
    toggle();
  }

  // alert popup shown for leaving the page at any moment of onboarding
  useEffect(() => {
    if (user?.account?.finaliseBtnShown) {
      window.addEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [user?.account?.finaliseBtnShown, user?.account?.state]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const classes = classNames('app', {
    'menu-expanded': isSubmenuShown,
    'userinfo-open': userinfo,
    'usernote-open': usernote,
    'is-mobile': isMobile,
  });
  return (
    <ErrorBoundary>
      <div className={classes}>
        <div className="content-wrapper">
          {isMobile && (
            <MobileHeader
              title={title}
              subtitle={subtitle}
              onMenuToggle={handleMenuToggle}
              showLanguageSelect={showLanguageSelect}
              toggleUserlistView={toggleUserlistView}
              userlist={userlist}
              openedUserlist={openedUserlist}
              sidebar={isSubmenuShown}
            />
          )}

          <Menu
            title={headline}
            items={menuItems}
            isSubmenuShown={isSubmenuShown}
            onMenuToggle={handleMenuToggle}
            toggleUserlistView={toggleUserlistView}
            isMobile={isMobile}
            userlist={userlist}
            additionalContent={shortenFriendRequests}
            openedUserlist={openedUserlist}
          />

          <div className="app-content">
            <div className="app-alerts height-auto">
              {alerts.length > 0 && <Alert />}
            </div>
            <Overlay />
            <Spinner />
            {openVeriff ? <VeriffComponent /> : null}
            <div className="app-inner-content">
              {(!user?.isLoggedIn &&
                user?.access_token &&
                !(
                  location.pathname.includes('client-login') ||
                  location.pathname.includes('management-login') ||
                  location.pathname.includes('email-verification')
                )) ||
              (isLivecam &&
                !thirdPartyCookiesTested &&
                !sender.tokenLoginError) ? (
                <SpinnerPage />
              ) : (
                <>
                  {isLivecam &&
                    thirdPartyCookiesTested &&
                    thirdPartyCookiesAllowed && (
                      <>
                        {isMultiMessengerRoute && notifications.length > 0 && (
                          <LivecamNotification />
                        )}
                        {isMultiMessengerRoute && alerts.length > 0 && (
                          <Alert scope="livecam" />
                        )}
                        <Redirect />
                      </>
                    )}
                  <Switch>
                    {renderRoutes(routes, user)}
                    <Route path="*" component={Page404} />
                  </Switch>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showVeriffReminder || user?.account?.veriff?.status === 'pending' ? (
        <VeriffBar />
      ) : null}
    </ErrorBoundary>
  );
}

export default App;
