import './TwoFactorInput.scss';

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../Button/Button';
import Input from '../../Forms/Input/Input';

function TwoFactorInput({
  buttonClick,
  label = 'TWO_FACTOR_VERIFY',
  method,
  error,
  setError,
  noBackupCodeBelow,
  useBackupCode,
  setUseBackupCode,
  noTitle = false,
  verified = false,
  stateMethod,
  email = false,
  loading = false,
}) {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [backupCode, setBackupCode] = useState('');

  const inputRefs = useRef([]);
  const [errorMessage, setErrorMessage] = useState();

  const handleChange = (element, index) => {
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      updatedOtp[index] = element.value;
      return updatedOtp;
    });

    if (element.value !== '' && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const checkEmptyInputs = () => {
    if (useBackupCode) {
      return backupCode.length !== 12;
    } else {
      return otp.some((input) => input === '');
    }
  };
  const handleBackupCodeToggle = () => {
    setError(null);
    setUseBackupCode(!useBackupCode);
    setBackupCode('');
    setOtp(new Array(6).fill(''));
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('Text').slice(0, 6);
    const otpArray = pastedData.split('');

    setTimeout(() => {
      setOtp([...otpArray, ...new Array(6 - otpArray.length).fill('')]);

      otpArray.forEach((value, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].value = value;
        }
      });

      if (inputRefs.current[otpArray.length]) {
        inputRefs.current[otpArray.length].focus();
      }
    }, 0);
  };

  const handleKeyDown = (e, index, backupCodes = false) => {
    if (
      e.key === 'Backspace' &&
      index > 0 &&
      otp[index] === '' &&
      !backupCodes
    ) {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === 'Enter') {
      if (!checkEmptyInputs()) {
        handleClick();
      }
    }
  };

  const handleClick = () => {
    const otpCode = otp.join('');
    buttonClick(
      method || (useBackupCode ? backupCode : otpCode),
      method ? (useBackupCode ? backupCode : otpCode) : undefined
    );
    setBackupCode('');
    setOtp(new Array(6).fill(''));
  };

  useEffect(() => {
    const errorMessages = {
      invalid_code: 'TWO_FACTOR_ERROR_CODE',
      expired_code: 'TWO_FACTOR_ERROR_EXPIRED',
    };
    setErrorMessage(error ? errorMessages[error] || 'TWO_FACTOR_ERROR' : null);
  }, [error]);

  return (
    <div className="input-wrapper">
      {!noTitle && (
        <h2 className="headline">
          <FormattedMessage
            id={verified ? 'TWO_FACTOR_RESET' : 'TWO_FACTOR_LOGIN'}
          />
        </h2>
      )}
      {verified && (
        <p className="has-margin-bottom">
          {!useBackupCode ? (
            <FormattedMessage
              id="TWO_FACTOR_USE_BACKUP"
              values={{
                link: (
                  <a onClick={() => handleBackupCodeToggle()}>
                    <FormattedMessage id="TWO_FACTOR_USE_BACKUP_LINK" />
                  </a>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="TWO_FACTOR_USE_CODE"
              values={{
                link: (
                  <a onClick={() => handleBackupCodeToggle()}>
                    <FormattedMessage id="TWO_FACTOR_USE_CODE_LINK" />
                  </a>
                ),
                method: (
                  <FormattedMessage
                    id={
                      stateMethod === 'email' || email
                        ? 'TWO_FACTOR_USE_EMAIL'
                        : 'TWO_FACTOR_USE_APP'
                    }
                  />
                ),
              }}
            />
          )}
        </p>
      )}
      <div className="form-group">
        <label>
          <FormattedMessage id="TWO_FACTOR_LABEL" />
        </label>
        {useBackupCode ? (
          <Input
            type="text"
            name="otp"
            maxLength="12"
            value={backupCode}
            onChange={(e) => setBackupCode(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, 0, true)}
            className="backup-input"
          />
        ) : (
          <div className="column2 input-container">
            {otp.map((data, index) => (
              <input
                key={index}
                ref={(ref) => (inputRefs.current[index] = ref)}
                type="text"
                name="otp"
                maxLength="1"
                value={data}
                onPaste={handlePaste}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="form-group">
          <span className="column"></span>
          <div className="column2 error-wrapper small">
            <FormattedMessage id={errorMessage} />
          </div>
        </div>
      )}
      <div className="form-group">
        <span className="column"></span>
        <Button
          type="button"
          label={!loading ? label : 'TWO_FACTOR_LOADING'}
          id="submit-btn"
          className="btn-form"
          disabled={checkEmptyInputs()}
          onClick={() => handleClick()}
        />
      </div>
      {!noBackupCodeBelow && (
        <p className="has-margin-bottom">
          {!useBackupCode ? (
            <FormattedMessage
              id="TWO_FACTOR_USE_BACKUP"
              values={{
                link: (
                  <a onClick={() => handleBackupCodeToggle()}>
                    <FormattedMessage id="TWO_FACTOR_USE_BACKUP_LINK" />
                  </a>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="TWO_FACTOR_USE_CODE"
              values={{
                link: (
                  <a onClick={() => handleBackupCodeToggle()}>
                    <FormattedMessage id="TWO_FACTOR_USE_CODE_LINK" />
                  </a>
                ),
                method: (
                  <FormattedMessage
                    id={
                      stateMethod === 'email' || email
                        ? 'TWO_FACTOR_USE_EMAIL'
                        : 'TWO_FACTOR_USE_APP'
                    }
                  />
                ),
              }}
            />
          )}
        </p>
      )}
    </div>
  );
}

export default TwoFactorInput;

