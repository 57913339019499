export default {
  ROUTE_SIGNUP_HEADLINE: `Willkommen`,
  ROUTE_SIGNUP_TITLE: `Registrierung`,
  ROUTE_SIGNUP_SUBTITLE: `Werde jetzt Creator`,
  ROUTE_LOGIN_HEADLINE: `Willkommen`,
  ROUTE_LOGIN_TITLE: `Login`,
  ROUTE_LOGIN_SUBTITLE: `LiveCreator Login`,
  ROUTE_HOMEPAGE_HEADLINE: `Willkommen`,
  ROUTE_HOMEPAGE_TITLE: `Home`,
  ROUTE_HOMEPAGE_SUBTITLE: `LiveCreator`,
  ROUTE_PASSWORD_RESET_HEADLINE: `Passwort vergessen`,
  ROUTE_PASSWORD_RESET_TITLE: `Login`,
  ROUTE_PASSWORD_RESET_SUBTITLE: `Neues Passwort anfordern`,
  ROUTE_SET_PASSWORD_HEADLINE: `Passwort festlegen`,
  ROUTE_LEGAL_TITLE: `Rechtliches`,
  ROUTE_SET_PASSWORD_SUBTITLE: `Passwort festlegen`,
  ROUTE_PASSWORD_FORGOT_HEADLINE: `Willkommen`,
  ROUTE_PASSWORD_FORGOT_TITLE: `Login`,
  ROUTE_PASSWORD_FORGOT_SUBTITLE: `Passwort vergessen`,
  ROUTE_TWO_FACTOR_HEADLINE: `Willkommen`,
  ROUTE_TWO_FACTOR_TITLE: `Login`,
  ROUTE_TWO_FACTOR_SUBTITLE: `Zwei-Faktor Authentifizierung`,
  ROUTE_CONFIRMED_EMAIL_HEADLINE: `Willkommen`,
  ROUTE_CONFIRMED_EMAIL_TITLE: `E-Mail bestätigen`,
  ROUTE_CONFIRMED_EMAIL_SUBTITLE: `E-Mail bestätigen`,
  ROUTE_UNSUBSCRIBE_NEWSLETTER_HEADLINE: `Willkommen`,
  ROUTE_UNSUBSCRIBE_NEWSLETTER_TITLE: `Login`,
  ROUTE_UNSUBSCRIBE_NEWSLETTER_SUBTITLE: `Newsletter abmelden`,
  ROUTE_TERMS_HEADLINE: `AGB`,
  ROUTE_TERMS_TITLE: `Rechtliches`,
  ROUTE_TERMS_SUBTITLE: `AGB`,
  ROUTE_PRIVACY_HEADLINE: `Datenschutz`,
  ROUTE_PRIVACY_TITLE: `Datenschutz`,
  ROUTE_PRIVACY_SUBTITLE: `Datenschutz`,
  ROUTE_IMPRINT_SUBTITLE: `Impressum`,
  ROUTE_SUPPORT_HEADLINE: `Support`,
  ROUTE_SUPPORT_TITLE: `Support`,
  ROUTE_SUPPORT_SUBTITLE: `Hilfezentrum`,
  ROUTE_NEWSLETTER_HEADLINE: `Support`,
  ROUTE_WIKI:
    'Unser Creator Wiki soll Dir bei der Bedienung von LiveCreator helfen',
  ROUTE_WIKI_HEADLINE: 'Support',
  ROUTE_WIKI_SUBTITLE: 'Wiki',
  ROUTE_CONTEST_SPECIAL: 'X-Mas Contest',
  ROUTE_WIKI_START: `Start`,
  ROUTE_WIKI_GENERAL: `Allgemein`,
  ROUTE_WIKI_MONEYMAKERS: `Moneymakers`,
  ROUTE_WIKI_XMAS: `Adventskalender`,
  ROUTE_NEWSLETTER_SUBTITLE: `Newsletter`,

  ROUTE_ACCOUNT_HEADLINE: `Mein Account`,
  ROUTE_ACCOUNT_TITLE: `Mein Account`,
  ROUTE_ACCOUNT_SUBTITLE: `Erste Schritte`,
  ROUTE_REVIEW_HEADLINE: `Mein Account`,
  ROUTE_REVIEW_TITLE: `Mein Account`,
  ROUTE_REVIEW_SUBTITLE: `Erste Schritte`,
  ROUTE_PENDING_HEADLINE: `Mein Account`,
  ROUTE_PENDING_TITLE: `Mein Account`,
  ROUTE_PENDING_SUBTITLE: `E-Mail Überprüfung`,
  ROUTE_CONFIRMED_HEADLINE: `Mein Account`,
  ROUTE_CONFIRMED_TITLE: `Mein Account`,
  ROUTE_CONFIRMED_SUBTITLE: `Mein Account`,
  ROUTE_CAM_MESSENGER_HEADLINE: `Multi Messenger`,
  ROUTE_CAM_MESSENGER_TITLE: `Multi Messenger`,
  ROUTE_LIVECAM_HEADLINE: `Livecam`,
  ROUTE_LIVECAM_TITLE: `Livecam`,
  ROUTE_LIVECAM_OPTIONS_SUBTITLE: `Einstellungen`,
  ROUTE_TOYCONTROL: 'Lovense Toy',
  ROUTE_DASHBOARD_TITTLE: `Dashboard`,
  ROUTE_MY_PERSONAL_DETAILS_TITLE: `Meine Daten`,
  ROUTE_ACCOUNT_DETAILS_TITLE: `Account Details`,
  ROUTE_PERSONAL_DETAILS_TITLE: `Persönliche Daten`,
  ROUTE_BANK_DETAILS_TITLE: `Bankdaten`,
  ROUTE_DOCUMENTS_TITLE: `Dokumente`,
  ROUTE_COMMISSION_TITLE: `Provision`,
  ROUTE_MY_PROFILE_TITLE: `Mein Profil`,
  ROUTE_VIRTUAL_GIFTS: 'Aufmerksamkeiten',
  ROUTE_MY_VIRTUAL_GIFTS: 'Meine Aufmerksamkeiten',
  ROUTE_ALL_VIRTUAL_GIFTS: 'Alle Aufmerksamkeiten',
  ROUTE_PROFILE_PICTURES: `Profilbilder`,
  ROUTE_PROFILE_VIDEO: `Profilvideo`,
  ROUTE_PROFILE_TEXT: `Profiltext`,
  ROUTE_PUBLIC_DETAILS: `Öffentliche Angaben`,
  ROUTE_PUBLIC_PROFILE: `Mein Profil anschauen`,
  ROUTE_SETTINGS_TITLE: `Einstellungen`,
  ROUTE_OPTIONS: `Optionen`,
  ROUTE_PRICES: `Preise`,
  ROUTE_ACCOUNT_SECURITY: `Account Sicherheit`,
  ROUTE_CONTENT_HEADLINE: `Mein Content`,
  ROUTE_CONTENT_TITLE: `Mein Content`,
  ROUTE_CONTENT_SUBTITLE: `Mein Content`,
  ROUTE_MY_PHOTOS: `Meine Fotos`,
  ROUTE_MY_VIDEOS: `Meine Videos`,
  ROUTE_MY_DIARY: `Mein Tagebuch`,
  ROUTE_NEW_DIARY: 'Neuen Tagebucheintrag',
  ROUTE_EDIT_DIARY: 'Eintrag bearbeiten',
  ROUTE_EDIT_GALLERY: `Galerie bearbeiten`,
  ROUTE_FMOTD: 'Free Movie of the Day',
  ROUTE_RELATIONSHIPS_TITLE: `Beziehungen`,
  ROUTE_RELATIONSHIPS_SUBTITLE: `Beziehungen`,
  ROUTE_RELATIONSHIPS_HEADLINE: `Beziehungen`,
  ROUTE_IGNORED: `Ignoriert`,
  ROUTE_FRIENDS: `Freunde`,
  ROUTE_FRIEND_REQUESTS: `Anfragen`,
  ROUTE_ACTIVE_FRIENDS: `Bestätigte Freunde`,
  ROUTE_MY_REQUESTS: `Meine Anfragen`,
  ROUTE_DENIED: `Abgelehnt`,
  ROUTE_FAVORITES: `Favoriten`,
  ROUTE_MONEYMAKERS_TITLE: 'Umsatzbringer',
  ROUTE_MONEYMAKERS_HEADLINE: 'Umsatzbringer',
  ROUTE_ATTACHMENTS_SUBTITLE: 'Dateianhänge',
  ROUTE_ICEBREAKER_SUBTITLE: 'Icebreaker',

  MENU_HOME: `Home`,
  MENU_SIGNUP: `Registrierung`,
  MENU_LOGIN: `Login`,
  MENU_FORGOT_PASSWORD: `Passwort vergessen`,
  MENU_RESET_PASSWORD: `Passwort zurücksetzen`,
  MENU_TWO_FACTOR: `Zwei-Faktor Authentifizierung`,
  MENU_VERIFY_EMAIL: `E-Mail Überprüfung`,
  MENU_UNSUBSCRIBE_NEWSLETTER: `Newsletter abmelden`,
  MENU_HEADER_ACTIVITIES: `Aktivitäten`,
  MENU_HEADER_MESSENGER: `Messenger`,
  MENU_HEADER_FEATURES: `Features`,
  MENU_ITEM_YOU: `Du`,
  MENU_ITEM_LOGOUT: `Logout`,
  MENU_ITEM_FIRST_STEPS: `Erste Schritte`,
  MENU_ITEM_DASHBOARD: `Dashboard`,
  MENU_ITEM_PERSONAL_DETAILS: `Meine Daten`,
  MENU_ITEM_DOCUMENTS: `Dokumente`,
  MENU_ITEM_COMMISSION: `Provision`,
  MENU_ITEM_MY_PROFILE: `Mein Profil`,
  MENU_ITEM_SETTINGS: `Einstellungen`,
  MENU_ITEM_SUPPORT: `Support`,
  MENU_ITEM_SUPPORT_CONTACT: `Hilfezentrum`,
  MENU_ITEM_NEWSLETTER: `Newsletter`,
  MENU_ITEM_WIKI: `Wiki`,
  MENU_ITEM_LEGAL: `Rechtliches`,
  MENU_ITEM_IMPRINT: `Impressum`,
  MENU_ITEM_TERMS: `AGB`,
  MENU_ITEM_PRIVACY_POLICY: `Datenschutz`,
  MENU_ITEM_CAM_MESSENGER: `Multi Messenger`,
  MENU_ITEM_LIVECAM_OPTIONS: `Einstellungen`,
  MENU_ITEM_LIVECAM: `Livecam`,
  MENU_ITEM_PHOTOS: `Fotos`,
  MENU_ITEM_VIDEOS: `Videos`,
  MENU_ITEM_DIARY: `Tagebuch`,
  MENU_ITEM_COMMENTS: `Kommentare`,
  MENU_ITEM_FMOTD: `Free Movie of the Day`,
  MENU_ITEM_ALL: `Alle`,
  MENU_ITEM_UNREAD: `Ungelesen`,
  MENU_ITEM_UNANSWERED: `Unbeantwortet`,
  MENU_ITEM_NEW_USER: `Neue User`,
  MENU_ITEM_FAVORITES: `Favoriten`,
  MENU_ITEM_FRIENDS: `Freunde`,
  MENU_ITEM_GAMES: `Games`,
  MENU_ITEM_IGNORED: `Ignoriert`,
  MENU_ITEM_MONEYMAKERS: 'Umsatzbringer',
  MENU_ITEM_MONEYMAKERS_ICEBREAKER: 'Icebreaker',
  MENU_ITEM_MONEYMAKERS_ATTACHMENT: 'Dateianhänge',
  MENU_ITEM_ACTIVITIES_PROFILE: 'Profilbesuche',
  MENU_ITEM_ACTIVITIES_ONLINE: 'Kunden Logins',
  MENU_ITEM_ACTIVITIES_ONLINE_FRIEND: 'Freunde Logins',
  MENU_ITEM_ACTIVITIES_FRIEND: 'Freundschaftsanfragen',
  MENU_ITEM_ACTIVITIES_CONTENT: 'Contentkäufe',
  MENU_ITEM_CUSTOMERS: 'Kunden',
  MENU_ITEM_SALES: 'Umsätze',
  MENU_ITEM_GAME4SCORE: 'Game 4Score',
  MENU_ITEM_COOKIES: 'Cookies',
};
