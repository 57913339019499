const monthNames = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const shortMonthNames = [
  'SALES_JAN',
  'SALES_FEB',
  'SALES_MAR',
  'SALES_APR',
  'SALES_MAY',
  'SALES_JUN',
  'SALES_JUL',
  'SALES_AUG',
  'SALES_SEP',
  'SALES_OCT',
  'SALES_NOV',
  'SALES_DEC',
];
const currentYear = new Date().getFullYear().toString();
const currentMonth = new Date().getMonth() + 1;
const currentDay = new Date().getDate();

export function getMonthOptions(yearFilter) {
  const returnArr = [
    { label: 'TABLE_LABEL_MONTH', value: false, disabled: false },
  ];

  for (let i = 0; i <= 11; i++) {
    returnArr.push({
      label: monthNames[i],
      value: i + 1,
    });
  }
  if (yearFilter === currentYear) {
    for (let i = currentMonth + 1; i < 13; i++) {
      returnArr[i].disabled = true;
    }
  }

  return returnArr;
}

export function getDaysOptions(year, month, intl) {
  const daysInMonth = new Date(year, month, 0).getDate(); // Get number of days in the month
  const returnArr = [
    { label: intl.formatMessage({ id: 'DAY' }), value: false, disabled: false },
  ];
  for (let i = 0; i <= daysInMonth - 1; i++) {
    returnArr.push({
      label: i + 1,
      value: i + 1,
    });
  }
  if (year === currentYear && month === currentMonth.toString()) {
    for (let i = currentDay + 1; i < daysInMonth + 1; i++) {
      if (returnArr[i]) {
        // Ensure that the index exists in returnArr
        returnArr[i].disabled = true;
      }
    }
  }

  return returnArr;
}

export function getYearOptions(intl) {
  const returnArr = [
    {
      label: intl.formatMessage({ id: 'TABLE_LABEL_YEAR' }),
      value: null,
      disabled: true,
    },
  ];

  const minDate = 2021;
  const maxDate = new Date().getFullYear();

  for (let i = maxDate; i > minDate; i--) {
    returnArr.push({
      label: i,
      value: i,
    });
  }

  return returnArr;
}

export function monthFilterToIntlId(monthFilter) {
  return {
    1: 'JANUARY',
    2: 'FEBRUARY',
    3: 'MARCH',
    4: 'APRIL',
    5: 'MAY',
    6: 'JUNE',
    7: 'JULY',
    8: 'AUGUST',
    9: 'SEPTEMBER',
    10: 'OCTOBER',
    11: 'NOVEMBER',
    12: 'DECEMBER',
  }[monthFilter];
}

