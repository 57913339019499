import React from 'react';
import { Redirect as RedirectRouter } from 'react-router';

class Redirect extends React.Component {
  componentDidUpdate() {
    if (this.props.redirectSettings) {
      this.props.redirectDone();
    }
  }

  render() {
    const { redirectSettings, location } = this.props;
    const pathSettings = '/my-account/settings/livecam';
    if (redirectSettings && location.pathname !== pathSettings) {
      return <RedirectRouter to={pathSettings} />;
    }
    return null;
  }
}

export default Redirect;
