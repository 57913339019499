import { connect } from 'react-redux';

import {
  chooseSmiley,
  management,
  selectAttachment,
  sendChatMessage,
  toggleCamView,
  toggleChatMenu,
  toggleSmileyOverlay,
  typingSender,
  camUser as userActions,
} from '../../../../actions';
import { TYPE_ADMIN } from '../../../../constants/user';
import { roomType, user as userUtils } from '../../../../utils';
import ChatControl from './Control';

const getText = (state, activeUser) => {
  const {
    sender: { roomTypes },
    chat: { text },
  } = state;
  if (roomType.isEvent(roomTypes)) {
    return text;
  }
  if (!activeUser) {
    return '';
  }
  return activeUser.text;
};

const mapDispatchToProps = (dispatch) => ({
  sendChat: (all, val, isMessageUser) => {
    if (!val || val === '') {
      return;
    }
    if (isMessageUser || all) {
      return dispatch(sendChatMessage(val, all));
    }
    dispatch(management.sendMessage(val));
  },
  typing: (userId, text) => dispatch(typingSender(userId, text)),
  toggleSmileyOverlay: () => dispatch(toggleSmileyOverlay()),
  toggleCamView: () => dispatch(toggleCamView()),
  toggleChatMenu: () => dispatch(toggleChatMenu()),
  clickSmiley: (code) => dispatch(chooseSmiley(code)),
  navLeft: () => dispatch(userActions.selectlast()),
  navRight: () => dispatch(userActions.selectnext()),
  unSelectAttachment: () =>
    dispatch(selectAttachment({ number: '', name: '' })),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    sendChat: (all, val) => {
      const { isAdmin, isDatingUser, isMessageUser } = stateProps;
      dispatchProps.sendChat(
        all,
        val,
        isMessageUser || (!isAdmin && !isDatingUser)
      );
    },
  };
};

export default connect(
  (state) => {
    const activeUser = userUtils.activeUser([
      ...state.camUser.camUsers,
      ...state.dialog.dialogs,
    ]);

    const {
      view: {
        isMobile,
        isPortrait,
        cam: camMenuOpen,
        usergame,
        userinfo,
        usernote,
        usergamestats,
      },
      smiley: { smileysTop, smileysBottom, overlay: SmileyOverlay },
      preferences: { chatlayout: layout },
      browser: { isMobile: mobileBrowser },
      user: {
        account: { scopes, voice_messages },
      },
      attachments: { selectedAttachmentName, selectedAttachment, files },
      voicemessage: { loading },
    } = state;

    let hasMessageScope = scopes?.includes('send_message');
    const showInputOnMobile = !usernote && !userinfo && !usergamestats;
    const isAppsUser = userUtils.isAppUser(activeUser);

    const attachment = files.find((f) => f.id && f.id === selectedAttachment);
    return {
      ...state.chat,
      text: getText(state, activeUser),
      isEvent: roomType.isEvent(state.sender.roomTypes),
      enable2All:
        state.sender.feature.powersender &&
        !userUtils.isVisibleMessageUser(activeUser),
      disabled:
        !activeUser ||
        !hasMessageScope ||
        userUtils.disableChat(state, activeUser) ||
        userUtils.checkDeleteOrIgnored(activeUser) ||
        activeUser.flags.includes('ignored'),
      SmileyOverlay,
      smileysTop,
      smileysBottom,
      showInputOnMobile,
      layout,
      isAdmin: activeUser?.type === TYPE_ADMIN,
      isMobile,
      isPortrait,
      camMenuOpen,
      isDatingUser: userUtils.isDatingUser(activeUser),
      isAppsUser,
      mobileBrowser,
      usergame,
      voicemessageLoading: loading,
      selectedAttachmentName,
      disallowAppsAttachments:
        isAppsUser && !activeUser?.portals?.allowAttachments,
      attachment,
      voicemessagesAllowed:
        voice_messages === 'enabled' &&
        activeUser?.profile?.active_features?.voice_messages === true,
    };
  },
  mapDispatchToProps,
  mergeProps
)(ChatControl);
