import './MyDiary.scss';

import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import placeholderImageDe from '../../../../assets/img/galleries_de.jpg';
import placeholderImageEn from '../../../../assets/img/galleries_en.jpg';
import placeholderImageDeMobile from '../../../../assets/img/galleries_mobile_de.jpg';
import placeholderImageEnMobile from '../../../../assets/img/galleries_mobile_en.jpg';
import sortNewOldIcon from '../../../../assets/img/svg/sort-new-old.svg';
import sortOldNewIcon from '../../../../assets/img/svg/sort-old-new.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import axiosInstance from '../../../../utils/api';
import Pagination from '../../../Pagination/Pagination';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import DiaryItem from '../DiaryItem';

function MyDiary({
  language,
  activeGalleryId,
  askDeleteDiaryEntryConfirmed,
  askDeleteDiaryEntryConfirmedAlert,
  askDeleteDiaryEntryId,
  deleteDiaryEntry,
  spinner,
  updateActiveGallery,
}) {
  const intl = useIntl();
  const history = useHistory();
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isLarge = useMediaQuery('(min-width: 1600px)');

  const [items, setItems] = useState([]);
  const [startDeleting, setStartDeleting] = useState(false);
  const [totalItems, setTotalItems] = useState(null);

  const [sortingDirection, setSortingDirection] = useState('desc');
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const initialItems = useRef(null);
  const editing = history.location.pathname.includes('edit');
  const doNotShow = editing && !isLarge;

  const [{ loading }, getDiaryData] = useAxios(
    {
      url: `/user/diary?l=${PAGINATION_PAGE_SIZE}&o=${offset}&sort=${sortingDirection}`,
    },
    { manual: true }
  );

  const [, totalPicturesReq] = useAxios(
    {
      url: `/user/diary/total`,
    },
    { manual: true }
  );

  const sortDiaryEntries = () => {
    if (sortingDirection === 'desc') {
      setSortingDirection('asc');
    } else {
      setSortingDirection('desc');
    }
  };

  const getDiary = useCallback(() => {
    getDiaryData()
      .then((response) => {
        initialItems.current = [...response.data];
        setItems(response.data);
      })
      .catch((error) => console.log(error));

    totalPicturesReq()
      .then((response) => {
        setTotalItems(response.data?.total);
      })
      .catch((error) => console.log(error));
  }, [getDiaryData, totalPicturesReq]);

  useEffect(() => {
    getDiary();
  }, [getDiary]);

  useEffect(() => {
    function deleteEntry(item) {
      spinner(true);
      axiosInstance
        .delete(`/user/diary/${item.id}`)
        .then((result) => {
          if (result?.status !== 204) {
            console.log(`Status not 204: ${result?.status}`); //can this happen that we get a status that would not raise an error?
          } else {
            updateActiveGallery({ ...item, delete: true });
            deleteDiaryEntry(item.id, true);
            editing &&
              activeGalleryId === item.id &&
              history.push('/my-content/diary/my-diary');
            getDiary();
          }
        })
        .catch((error) => {
          // should we display something here, in photos or videos we don't
          console.log({ deleteDiaryItemError: error });
        })
        .finally(() => spinner(false));
    }

    const item = items.find((i) => i.id === askDeleteDiaryEntryId);

    if (
      askDeleteDiaryEntryConfirmed &&
      askDeleteDiaryEntryConfirmedAlert &&
      askDeleteDiaryEntryId &&
      item &&
      startDeleting !== item.id
    ) {
      setStartDeleting(item.id);
      deleteEntry(item);
    }
  }, [
    askDeleteDiaryEntryConfirmed,
    askDeleteDiaryEntryConfirmedAlert,
    askDeleteDiaryEntryId,
    updateActiveGallery,
    history,
    editing,
    spinner,
    deleteDiaryEntry,
    activeGalleryId,
    items,
    startDeleting,
    getDiary,
  ]);

  if (doNotShow) return null;
  return loading ? (
    <div className="table-box">
      <SpinnerComponent />
    </div>
  ) : (
    <div className="diary-container">
      {!initialItems?.current?.length && (
        <div
          className="no-diary-entries"
          style={{
            backgroundImage: `url(${
              language === 'de'
                ? isMobileScreen
                  ? placeholderImageDeMobile
                  : placeholderImageDe
                : isMobileScreen
                ? placeholderImageEnMobile
                : placeholderImageEn
            })`,
          }}
        />
      )}

      {!!initialItems?.current?.length && (
        <div className="table-box">
          <table className="diary-table noHover">
            <thead>
              <tr>
                <th className="status" />
                <th className="date">
                  <div>
                    <span>{intl.formatMessage({ id: 'DIARY_DATE' })}</span>
                    <ReactSVG
                      onClick={sortDiaryEntries}
                      src={
                        sortingDirection === 'asc'
                          ? sortOldNewIcon
                          : sortNewOldIcon
                      }
                      className={'sort-icon'}
                      wrapper="span"
                    />
                  </div>
                </th>
                <th className="preview" />
                <th className="type">
                  {intl.formatMessage({ id: 'DIARY_TYPE' })}
                </th>
                <th className="title">
                  {intl.formatMessage({ id: 'DIARY_TITLE' })}
                </th>
                <th className="options" />
              </tr>
            </thead>
            <tbody>
              {!!items?.length &&
                items.map((item, index) => (
                  <DiaryItem
                    key={index}
                    {...item}
                    item={item}
                    editing={editing && activeGalleryId === item.id}
                  />
                ))}
            </tbody>
          </table>
          <Pagination
            offset={offset}
            totalItems={totalItems}
            pageNumber={pageNumber}
            setOffset={setOffset}
            setPageNumber={setPageNumber}
          />
        </div>
      )}
    </div>
  );
}

export default MyDiary;
