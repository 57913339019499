import _ from 'lodash';

import {
  PREFERENCES_ADD,
  SOUND_ACTIVATE,
  USER_LOGOUT,
} from '../actions/actions';
import { DEFAULT_CHAT_FONT_SIZE, POSSIBLE_CHAT_FONT_SIZES } from '../constants';
import { DEFAULT_SOUNDS, DEFAULT_VOLUME } from '../constants/sound';

const initialState = {
  fontsize: {
    possible: POSSIBLE_CHAT_FONT_SIZES,
    selected: DEFAULT_CHAT_FONT_SIZE,
  },
  loaded: false,
  sounds: DEFAULT_SOUNDS,
  volume: DEFAULT_VOLUME,
  functionkeys: ['', '', '', '', '', '', '', ''],
  chatlayout: 'single',
  showSendername: true,
  usercolor: true,
};

function preferences(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT:
      return initialState;
    case PREFERENCES_ADD:
      return _.merge({}, initialState, action.payload);
    case SOUND_ACTIVATE:
      if (state.sounds[action.payload.sound]) {
        const newState = { ...state };
        newState.sounds[action.payload.sound].active = action.payload.active;
        return newState;
      }
      return state;
    default:
      return state;
  }
}

export default preferences;
