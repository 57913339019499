export const USER_ADD_AUTH_TOKEN = 'USER_ADD_AUTH_TOKEN';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_REFRESH_AUTH_TOKEN = 'USER_REFRESH_AUTH_TOKEN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_FORCED = 'USER_LOGOUT_FORCED';
export const USER_UPDATE_DOCUMENTS = 'USER_UPDATE_DOCUMENTS';
export const USER_UPDATE_BANKDHOLDER_DOCUMENT =
  'USER_UPDATE_BANKDHOLDER_DOCUMENT';
export const USER_UPDATE_IMAGES = 'USER_UPDATE_IMAGES';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_UPDATE_TWO_FACTOR = 'USER_UPDATE_TWO_FACTOR';
export const SONUS_TOKEN_ADD = 'SONUS_TOKEN_ADD';

export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';
export const WEBCAM_SELECT = 'WEBCAM_SELECT';
export const SENDER_AUTH = 'SENDER_AUTH';
export const SENDER_LOGIN = 'SENDER_LOGIN';
export const SENDER_LOGIN_DATA = 'SENDER_LOGIN_DATA';
export const CHANGE_RESOLUTION_CAM = 'CHANGE_RESOLUTION_CAM';
export const ADD_CAM = 'ADD_CAM';
export const RESET_CAM = 'RESET_CAM';
export const CHANGE_CAM = 'CHANGE_CAM';
export const UPDATE_CAM = 'UPDATE_CAM';
export const UPDATE_MICROPHONE = 'UPDATE_MICROPHONE';
export const CHANGE_MICROPHONE = 'CHANGE_MICROPHONE';
export const RESET_MICROPHONE = 'RESET_MICROPHONE';
export const CHANGE_LABEL_CAM = 'CHANGE_LABEL_CAM';
export const CHANGE_LABEL_MICROPHONE = 'CHANGE_LABEL_MICROPHONE';
export const TEST_CAM = 'TEST_CAM';
export const CAM_RESIZING = 'CAM_RESIZING';
export const SEX_ICONS_ADD = 'SEX_ICONS_ADD';
export const REGARDS_ADD = 'REGARDS_ADD';
export const GAMES_INVITATIONS_ADD = 'GAMES_INVITATIONS_ADD';
export const GAMES_STATS_ADD = 'GAMES_STATS_ADD';
export const GAME_INVITATION = 'GAME_INVITATION';
export const GAME_INVITATION_ACCEPT = 'GAME_INVITATION_ACCEPT';
export const GAME_INVITATION_DECLINE = 'GAME_INVITATION_DECLINE';
export const GAME_INVITATION_STATUS = 'GAME_INVITATION_STATUS';
export const GAME_SESSION_STATUS = 'GAME_SESSION_STATUS';

export const MICROPHONE_MUTE = 'MICROPHONE_MUTE';
export const TOKEN_LOGIN_SENDER = 'TOKEN_LOGIN_SENDER';
export const SENDER_LOGOUT = 'SENDER_LOGOUT';
export const SENDER_TYPING = 'SENDER_TYPING';

export const SERVER_RESOLUTION_SAVE = 'SERVER_RESOLUTION_SAVE';

export const SELECT_USER = 'SELECT_USER';
export const MANAGEMENT_USER_KICK = 'MANAGEMENT_USER_KICK';
export const MANAGEMENT_USER_FAVORITE = 'MANAGEMENT_USER_FAVORITE';
export const FRIEND_FRIEND_REQUEST_SEND = 'FRIEND_FRIEND_REQUEST_SEND';
export const FRIEND_FRIEND_REQUEST_ANSWER = 'FRIEND_FRIEND_REQUEST_ANSWER';
export const FRIEND_DELETE = 'FRIEND_DELETE';

export const DIARY_ENTRY_DELETE = 'DIARY_ENTRY_DELETE';
export const DO_NOT_SHOW_IN = 'DO_NOT_SHOW_IN';
export const FRIEND_FRIEND_REQUEST_ALREADY_SENT =
  'FRIEND_FRIEND_REQUEST_ALREADY_SENT';
export const FRIEND_FRIEND_REQUEST_DENIED = 'FRIEND_FRIEND_REQUEST_DENIED';
export const FRIEND_FRIEND_REQUEST_REMOVE_DENIED =
  'FRIEND_FRIEND_REQUEST_REMOVE_DENIED';
export const IGNORE_USER = 'IGNORE_USER';
export const MANAGEMENT_LOST_INTERNET = 'MANAGEMENT_LOST_INTERNET';
export const MANAGEMENT_SENDER_TYPING = 'MANAGEMENT_SENDER_TYPING';
export const MANAGEMENT_PRIVATE_AUDIO_SESSION_REQUEST =
  'PRIVATE_AUDIO_SESSION_REQUEST';
export const MANAGEMENT_CONNECT = 'MANAGEMENT_CONNECT';
export const MANAGEMENT_CONNECT_START = 'MANAGEMENT_CONNECT_START';
export const MANAGEMENT_CONNECT_STOP = 'MANAGEMENT_CONNECT_STOP';
export const MANAGEMENT_CAM_LOGOUT = 'MANAGEMENT_CAM_LOGOUT';
export const MANAGEMENT_CAM_LOGIN = 'MANAGEMENT_CAM_LOGIN';
export const MANAGEMENT_EVENTS_RECONNECT = 'MANAGEMENT_EVENTS_RECONNECT';
export const MANAGEMENT_EVENTS_SOCKET_RECONNECT =
  'MANAGEMENT_EVENTS_SOCKET_RECONNECT';
export const MANAGEMENT_STREAM_SENDER_STARTED =
  'MANAGEMENT_STREAM_SENDER_STARTED';
export const MANAGEMENT_LOG_FPS = 'MANAGEMENT_LOG_FPS';
export const MANAGEMENT_STREAM_STARTED = 'MANAGEMENT_STREAM_STARTED';
export const MANAGEMENT_ORIGINS_SWITCH = 'MANAGEMENT_ORIGINS_SWITCH';
export const MANAGEMENT_EVENTS_STREAM_STOP = 'MANAGEMENT_EVENTS_STREAM_STOP';
export const MANAGEMENT_USER_AUDIO_STREAM = 'MANAGEMENT_USER_AUDIO_STREAM';
export const MANAGEMENT_EVENTS_CONNECTION_ERROR =
  'MANAGEMENT_EVENTS_CONNECTION_ERROR';
export const MANAGEMENT_EVENTS_CONNECTION_DISCONNECT =
  'MANAGEMENT_EVENTS_CONNECTION_DISCONNECT';
export const MANAGEMENT_EVENTS_CONNECTION_TRANSPORT_CLOSE =
  'MANAGEMENT_EVENTS_CONNECTION_TRANSPORT_CLOSE';
export const MANAGEMENT_EVENTS_CONNECTION_CONNECT =
  'MANAGEMENT_EVENTS_CONNECTION_CONNECT';
export const MANAGEMENT_EVENTS_STREAM_START = 'MANAGEMENT_EVENTS_STREAM_START';
export const MANAGEMENT_EVENTS_USER_ADD = 'MANAGEMENT_EVENTS_USER_ADD';
export const MANAGEMENT_EVENTS_USER_REMOVE = 'MANAGEMENT_EVENTS_USER_REMOVE';
export const MANAGEMENT_EVENTS_ADMIN_ADD = 'MANAGEMENT_EVENTS_ADMIN_ADD';
export const MANAGEMENT_EVENTS_ADMIN_REMOVE = 'MANAGEMENT_EVENTS_ADMIN_REMOVE';
export const MANAGEMENT_EVENTS_MESSAGE_USER_ADD =
  'MANAGEMENT_EVENTS_MESSAGE_USER_ADD';
export const MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD =
  'MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD';
export const MANAGEMENT_MESSAGE_SENDER_SEND = 'MANAGEMENT_MESSAGE_SENDER_SEND';
export const MANAGEMENT_MESSAGE_SENDER_SEND2ALL =
  'MANAGEMENT_MESSAGE_SENDER_SEND2ALL';
export const MANAGEMENT_EVENTS_USER_TYPING = 'MANAGEMENT_EVENTS_USER_TYPING';
export const MANAGEMENT_EVENTS_CAM2CAM_ENABLE =
  'MANAGEMENT_EVENTS_CAM2CAM_ENABLE';
export const MANAGEMENT_EVENTS_CAM2CAM_DISABLE =
  'MANAGEMENT_EVENTS_CAM2CAM_DISABLE';
export const MANAGEMENT_EVENTS_STREAMINFO_START =
  'MANAGEMENT_EVENTS_STREAMINFO_START';
export const MANAGEMENT_EVENTS_STREAMINFO_STOP =
  'MANAGEMENT_EVENTS_STREAMINFO_STOP';
export const MANAGEMENT_EVENTS_CLIENT_DIMENSIONS =
  'MANAGEMENT_EVENTS_CLIENT_DIMENSIONS';
export const MANAGEMENT_EVENTS_BROADCAST_MESSAGE =
  'MANAGEMENT_EVENTS_BROADCAST_MESSAGE';
export const MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE =
  'MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE';
export const MANAGEMENT_EVENTS_TIP = 'MANAGEMENT_EVENTS_TIP';

export const STREAM_PUBLISH = 'STREAM_PUBLISH';
export const STREAM_STOP = 'STREAM_STOP';
export const STREAM_STOP_TIMER_START = 'STREAM_STOP_TIMER_START';
export const STREAM_STOP_TIMER_STOP = 'STREAM_STOP_TIMER_STOP';
export const STREAM_RECONNECT = 'STREAM_RECONNECT';

export const STREAM_EVENTS_ERROR = 'STREAM_EVENTS_ERROR';
export const STREAM_EVENTS_STATS = 'STREAM_EVENTS_STATS';
export const STREAM_EVENTS_BANDWIDTH = 'STREAM_EVENTS_BANDWIDTH';
export const STREAM_EVENTS_PUBLISH = 'STREAM_EVENTS_PUBLISH';
export const STREAM_LOW_FPS = 'STREAM_LOW_FPS';

export const ORIGIN_TOGGLE = 'ORIGIN_TOGGLE';
export const ALERT_CLOSE = 'ALERT_CLOSE';
export const ALERT_OK = 'ALERT_OK';
export const ALERT_CANCEL = 'ALERT_CANCEL';

export const LOCALES_SET = 'LOCALES_SET';
export const CHAT_HEIGHT_CHANGE = 'CHAT_HEIGHT_CHANGE';
export const MESSAGE_CHAT_SYSTEM_SEND = 'MESSAGE_CHAT_SYSTEM_SEND';
export const MESSAGE_CHAT_REMOVE = 'MESSAGE_CHAT_REMOVE';

export const AUDIO_PLAY = 'AUDIO_PLAY';

export const SOUND_UPDATE = 'SOUND_UPDATE';
export const SOUND_ACTIVATE = 'SOUND_ACTIVATE';
export const SOUND_PLAY_CONFIRM = 'SOUND_PLAY_CONFIRM';
export const SOUND_QUEUED_REMOVE = 'SOUND_QUEUED_REMOVE';

export const ROOM_TYPES_CHANGE = 'ROOM_TYPE_CHANGE';
export const ROOM_TYPES_VOYEUR_ADD = 'ROOM_TYPES_VOYEUR_ADD';
export const ROOM_TYPES_VOYEUR_REMOVE = 'ROOM_TYPES_VOYEUR_REMOVE';
export const REDIRECTED_SETTINGS = 'REDIRECTED_SETTINGS';
export const CAMUSER_FILTER = 'CAMUSER_FILTER';
export const CAMUSER_MESSAGES_FILTER = 'CAMUSER_MESSAGES_FILTER';
export const UPGRADE_INIT = 'UPGRADE_INIT';
export const UPGRADE_START = 'UPGRADE_START';
export const EXCLUSIVE_ASK = 'EXCLUSIVE_ASK';
export const EXCLUSIVE_ASK_TICK = 'EXCLUSIVE_ASK_TICK';
export const SHOW_TYPE_EXPIRED = 'SHOW_TYPE_EXPIRED';
export const UPGRADETIMER_START = 'UPGRADETIMER_START';
export const UPGRADETIMER_STOP = 'UPGRADETIMER_STOP';
export const EXCLUSIVE_ASK_SENDER = 'EXCLUSIVE_ASK_SENDER';
export const EXCLUSIVE_ASK_SENDER_ABORT = 'EXCLUSIVE_ASK_SENDER_ABORT';
export const UPGRADE_SAVE = 'UPGRADE_SAVE';
export const SENDER_ASK_UPGRADE_DENIED = 'SENDER_ASK_UPGRADE_DENIED';
export const USERAUDIO_START = 'USERAUDIO_START';
export const USERAUDIO_EVENT_START = 'USERAUDIO_EVENT_START';
export const USERAUDIO_EVENT_STOP = 'USERAUDIO_EVENT_STOP';
export const BANDWIDTH_CHECK = 'BANDWIDTH_CHECK';
export const BANDWIDTH_CHECK_ABORT = 'BANDWIDTH_CHECK_ABORT';
export const CAM2CAM_START = 'CAM2CAM_START';
export const CAM2CAM_EVENT_START = 'CAM2CAM_EVENT_START';
export const CAM2CAM_EVENT_STOP = 'CAM2CAM_EVENT_STOP';
export const CAM2CAM_ROTATE = 'CAM2CAM_ROTATE';
export const CAM2CAM_ZOOM = 'CAM2CAM_ZOOM';
export const SMILEYOVERLAY_TOGGLE = 'SMILEYOVERLAY_TOGGLE';
export const CAM_OVERLAYHEIGHT_CHANGE = 'CAM_OVERLAYHEIGHT_CHANGE';
export const CAM_LOADING_START = 'CAM_LOADING_START';
export const CAM_LOADING_END = 'CAM_LOADING_END';
export const CAM_LOADING_ABORT = 'CAM_LOADING_ABORT';
export const CAM_STOP = 'CAM_STOP';
export const NOTIFICATION_SEND = 'NOTIFICATION_SEND';
export const NOTIFICATION_CLOSE = 'NOTIFICATION_CLOSE';
export const USER_MENU_TOGGLE = 'USER_MENU_TOGGLE';
export const USER_MENU_CLOSE = 'USER_MENU_CLOSE';
export const RECONNECT_ZEROBYTES_INCREMENT = 'RECONNECT_ZEROBYTES_INCREMENT';
export const CAM_PERMISSION_REQUEST_FAILED = 'CAM_PERMISSION_REQUEST_FAILED';

export const COUNTRIES_LIST_UPDATE = 'COUNTRIES_LIST_UPDATE';

export const GALLERY_ACTIVE_UPDATE = 'GALLERY_ACTIVE_UPDATE';

export const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES';
export const COOKIE = 'COOKIE';
export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPDATE_FILE_PERCENT = 'UPDATE_FILE_PERCENT';
export const SPINNER = 'SPINNER';
export const MENU_CHAT_TOGGLE = 'MENU_CHAT_TOGGLE';
export const MENU_USER_TOGGLE = 'MENU_USER_TOGGLE';
export const MENU_USERLIST_TOGGLE = 'MENU_USERLIST_TOGGLE';
export const MENU_TOYCONTROL_TOGGLE = 'MENU_TOYCONTROL_TOGGLE';
export const VIEW_CAM_TOGGLE = 'VIEW_CAM_TOGGLE';
export const VIEW_CAM_SET = 'VIEW_CAM_SET';
export const VIEW_USERINFO_TOGGLE = 'VIEW_USERINFO_TOGGLE';
export const VIEW_USERNOTE_TOGGLE = 'VIEW_USERNOTE_TOGGLE';
export const VIEW_USERGAME_TOGGLE = 'VIEW_USERGAME_TOGGLE';
export const VIEW_USERGAMESTAT_TOGGLE = 'VIEW_USERGAMESTAT_TOGGLE';
export const VIEW_USERLIST_TOGGLE = 'VIEW_USERLIST_TOGGLE';
export const RESIZE = 'RESIZE';
export const MENU_PAYTTACHMENTS_TOGGLE = 'MENU_PAYTTACHMENTS_TOGGLE';

export const BLOG_TARGETNEWSLETTER_CLEAR = 'BLOG_TARGETNEWSLETTER_CLEAR';
export const BLOG_TARGETNEWSLETTER_SET = 'BLOG_TARGETNEWSLETTER_SET';

export const DOCUMENT_ERROR = 'DOCUMENT_ERROR';
export const CONNECT_SOCKET_SERVICE = 'CONNECT_SOCKET_SERVICE';
export const ADD_DIALOGS = 'ADD_DIALOGS';
export const NEW_DIALOG = 'NEW_DIALOG';
export const DIALOG_REMOVE = 'DIALOG_REMOVE';
export const DIALOG_PERSIST = 'DIALOG_PERSIST';
export const DIALOGS_SORT = 'DIALOGS_SORT';
export const DIALOG_DUPLICATE_NAME = 'DIALOG_DUPLICATE_NAME';
export const DIALOG_READ = 'DIALOG_READ';
export const DIALOG_SENDER_KNOWN = 'DIALOG_SENDER_KNOWN';
export const DIALOG_ERROR_LOG = 'DIALOG_ERROR_LOG';
export const DIALOG_SELECT = 'DIALOG_SELECT';

export const FRIEND_FRIEND_REQUEST_UPDATE_LIST =
  'FRIEND_FRIEND_REQUEST_UPDATE_LIST';
export const FRIEND_OWN_REQUEST_UPDATE_LIST = 'FRIEND_OWN_REQUEST_UPDATE_LIST';

export const SALES_UPDATE = 'SALES_UPDATE';
export const SALES_ADD = 'SALES_ADD';
export const RESET_NEW_MESSAGES = 'RESET_NEW_MESSAGES';

export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const FETCH_ALL_MESSAGES = 'FETCH_ALL_MESSAGES';

export const DIALOG_USER_ONLINE = 'DIALOG_USER_ONLINE';
export const DIALOG_USER_OFFLINE = 'DIALOG_USER_OFFLINE';
export const USER_STATUS_DELETED = 'USER_STATUS_DELETED';
export const MESSAGE_CHAT_SEND = 'MESSAGE_CHAT_SEND';
export const MESSAGE_SENT = 'MESSAGE_SENT';

export const USER_PROFILE_GET = 'USER_PROFILE_GET';

export const USER_NOTES_GET = 'USER_NOTES_GET';
export const USER_NOTES_UPDATE = 'USER_NOTES_UPDATE';
export const BROWSER_NOT_ALLOWED = 'BROWSER_NOT_ALLOWED';

export const CHAT_ATTACHEMENT_ERROR = 'CHAT_ATTACHEMENT_ERROR';
export const ATTACHMENT_DELETE = 'ATTACHMENT_DELETE';
export const ATTACHMENT_DELETE_ASK = 'ATTACHMENT_DELETE_ASK';
export const ATTACHMENT_ADD = 'ATTACHMENT_ADD';
export const ATTACHMENT_SELECT = 'ATTACHMENT_SELECT';

export const PAYTTACHMENT_ADD = 'PAYTTACHMENT_ADD';

export const CHAT_PAGE_INCREMENT = 'CHAT_PAGE_INCREMENT';

export const VIEW_DIMENSIONS_LARGE_SCREEN_STREAMING_WINDOW_UPDATE =
  'VIEW_DIMENSIONS_LARGE_SCREEN_STREAMING_WINDOW_UPDATE';

export const ATTACHMENT_DELETE_NOT_ALLOWED = 'ATTACHMENT_DELETE_NOT_ALLOWED';

export const PREFERENCES_ADD = 'PREFERENCES_ADD';
export const OPTIONS_ADD = 'OPTIONS_ADD';
export const ADD_ONLINE_STATUS = 'ADD_ONLINE_STATUS';
export const START_TIMER_ONLINE_STATUS = 'START_TIMER_ONLINE_STATUS';
export const STOP_TIMER_ONLINE_STATUS = 'STOP_TIMER_ONLINE_STATUS';

export const FRIEND_ERROR = 'FRIEND_ERROR';

export const SAVE_FETCH_MESSAGES_EVENT = 'SAVE_FETCH_MESSAGES_EVENT';

export const TOKEN_LOGIN_ERROR = 'TOKEN_LOGIN_ERROR';
export const PROFILE_PICTURE_ERROR = 'PROFILE_PICTURE_ERROR';

export const USER_FINALISE_BUTTON_SHOWN = 'USER_FINALISE_BUTTON_SHOWN';

export const USER_SHOW_LOGOUT_ALERT = 'USER_SHOW_LOGOUT_ALERT';

export const KPI_UPDATE = 'UPDATE_KPI';
export const TOYCONTROL_CALL = 'TOYCONTROL_CALL';
export const TOYCONTROL_TOGGLE = 'TOYCONTROL_TOGGLE';
export const TOYCONTROLDATA_CHANGED = 'TOYCONTROLDATA_CHANGED';
export const FAVORITE_IDS_ADD = 'FAVORITES_IDS_ADD';
export const USER_IGNORED_IDS_ADD = 'USER_IGNORED_IDS_ADD';
export const USER_UNIGNORE_ID = 'USER_UNIGNORE_ID';
export const FRIEND_IDS_ADD = 'FRIEND_IDS_ADD';
export const TOY_SET_ACTIVE = 'TOY_SET_ACTIVE';
export const TOY_SET_INTENSITY = 'TOY_SET_INTENSITY';
export const TOY_COUNTDOWN = 'TOY_COUNTDOWN';
export const RUN_TOY_SUCCESS = 'RUN_TOY_SUCCESS';
export const TOYCONTROL_APP_OFFLINE = 'TOYCONTROL_APP_OFFLINE';

export const RATATOSKR_OPEN = 'RATATOSKR_OPEN';
export const OPEN_EVENT_RATATOSKR = 'OPEN_EVENT_RATATOSKR';
export const CLOSE_EVENT_RATATOSKR = 'CLOSE_RATATOSKR';
export const ERROR_EVENT_RATATOSKR = 'ERROR_EVENT_RATATOSKR';
export const ADD_MESSAGE_EVENT_RATATOSKR = 'ADD_MESSAGE_EVENT_RATATOSKR';
export const REMOVE_MESSAGE_EVENT_RATATOSKR = 'REMOVE_MESSAGE_EVENT_RATATOSKR';
export const CAPACITY_EXCEEDED = 'CAPACITY_EXCEEDED';
export const CAPACITY_AVAILABLE = 'CAPACITY_AVAILABLE';
export const UPDATE_CONTESTS = 'UPDATE_CONTESTS';

export const USER_CAPACITY_GET = 'USER_CAPACITY_GET';
export const ALERT_VERSION_UPDATE_MANDATORY = 'ALERT_VERSION_UPDATE_MANDATORY';
export const ALERT_VERSION_UPDATE_OPTIONAL = 'ALERT_VERSION_UPDATE_OPTIONAL';

export const MOTTO_CANCEL = 'MOTTO_CANCEL';
export const MOTTO_SET = 'MOTTO_SET';

export const SONUS_ERROR = 'SONUS_ERROR';
export const VERIFF_ERROR = 'VERIFF_ERROR';
export const MICROPHONE_DISABLED_ERROR = 'MICROPHONE_DISABLED_ERROR';

export const LOG_DIALOGLOAD_SEND = 'LOG_DIALOG_LOAD_SEND';
export const LOG_FIRST_DIALOG_DISPLAYED = 'LOG_FIRST_DIALOG_DISPLAYED';
export const LOG_NO_DIALOG = 'LOG_NO_DIALOG';
export const LOG_FETCHED_DIALOG = 'LOG_FETCHED_DIALOG';
export const VOICEMESSAGE_PLAY = 'VOICEMESSAGE_PLAY';
export const VOICEMESSAGE_STOP = 'VOICEMESSAGE_STOP';
export const VOICEMESSAGE_TEXT = 'VOICEMESSAGE_TEXT';
export const VOICEMESSAGE_LOADING = 'VOICEMESSAGE_LOADING';

export const VERIFF_FRAME_TOGGLE = 'VERIFF_FRAME_TOGGLE';
export const VERIFF_BAR_TOGGLE = 'VERIFF_BAR_TOGGLE';
