import { connect } from 'react-redux';

import {
  alreadySentFriendRequest,
  answerFriendRequest,
  deleteFriend,
  deniedFriendRequest,
  removeDeniedFriendRequest,
  sendFriendRequest,
} from '../../../../../../actions';
import { user as userUtils } from '../../../../../../utils';
import Friend from './Friend';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    user: {
      account: { scopes },
    },
  } = state;

  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const isAdmin =
    activeUser?.flags?.includes('is_support') || userUtils.isAdmin(activeUser);
  const isIgnored = activeUser?.flags?.includes('ignored');
  const userId = userUtils.getUserId(activeUser);

  let isFriend = false;
  if (activeUser?.flags?.includes('friend')) {
    isFriend = true;
  }
  let show = true;
  const hasFriendsScope = scopes?.includes('friends');

  if (
    !activeUser?.flags ||
    activeUser?.systemId === 901 ||
    userUtils.isDatingUser(activeUser)
  )
    show = false;

  return {
    isFriend,
    show,
    userId,
    name: activeUser?.name,
    hasFriendsScope,
    isActivated:
      !userUtils.disableChat(state, activeUser) &&
      hasFriendsScope &&
      !isAdmin &&
      !userUtils.checkDeleteOrIgnored(activeUser),
    isIgnored,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: () => dispatch(sendFriendRequest()),
  answerFriendRequest: (requestSent, userId, nickname) =>
    dispatch(answerFriendRequest(requestSent, userId, nickname)),
  alreadySentFriendRequest: () => dispatch(alreadySentFriendRequest()),
  deleteFriend: (id, requestSent, name) =>
    dispatch(deleteFriend(id, requestSent, name)),
  deniedFriendRequest: (name) => dispatch(deniedFriendRequest(name)),
  removeDeniedFriendRequest: (id, name) =>
    dispatch(removeDeniedFriendRequest(id, name, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Friend);

