import './Form.scss';

import { Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import signupIcon from '../../../../assets/img/svg/signup.svg';
import { lStorage } from '../../../../services/storage';
import { getCookieHintData } from '../../../../utils';
import api from '../../../../utils/api';
import Button from '../../../Button/Button';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import FormErrorMessage from '../../../Forms/FormErrorMessage/FormErrorMessage';
import Input from '../../../Forms/Input/Input';
import Password from '../../../Forms/Password/Password';
import Select from '../../../Forms/Select/Select';

const initialValues = {
  language: 'en',
  username: '',
  password: '',
};
const getValidationScheme = (intl) => {
  const t = (id) => intl.formatMessage({ id });
  return Yup.object().shape({
    language: Yup.string(),
    username: Yup.string()
      .email(t('VALIDATION_EMAIL'))
      .required(t('VALIDATION_REQUIRED_FIELD')),
    password: Yup.string()
      .min(6, t('VALIDATION_PASSWORD_TO_SHORT'))
      .required(t('VALIDATION_REQUIRED_FIELD')),
  });
};

const languageSelectOptions = [
  { label: 'LABEL_ENGLISH', value: 'en' },
  { label: 'LABEL_DEUTSCH', value: 'de' },
];

function Form({
  locale,
  addAuthToken = () => {},
  changeLocale = () => {},
  studioLink,
  cookies = true,
  cookie = () => {},
  aidLink,
}) {
  const formRef = useRef();
  const intl = useIntl();
  const validationSchema = getValidationScheme(intl);
  const [signUpError, setSignUpError] = useState(false);

  useEffect(() => {
    if (signUpError) {
      formRef.current.setSubmitting(false);
    }
  }, [signUpError]);

  const updateLocalStorage = () => {
    const studio = lStorage.getItem('studio') || studioLink;
    const aid = lStorage.getItem('aid') || aidLink;
    if (studio) {
      lStorage.removeItem('studio');
    }

    if (aid) {
      lStorage.removeItem('aid');
    }

    if (!lStorage.getItem('hasUsedDevice')) {
      lStorage.setItem('hasUsedDevice', true);
    }

    lStorage.removeItem('referer');
    lStorage.removeItem('userAgent');
    lStorage.removeItem('locale');
    lStorage.removeItem('getParams');
    lStorage.removeItem('parametersDataSet');

    lStorage.setItem('navigationTooltip', 1);
    lStorage.setItem('finaliseTooltip', 1);
  };

  function handleFormSubmission(values) {
    if (!navigator.cookieEnabled) {
      const cookieHintData = getCookieHintData(locale);
      return cookie(false, cookieHintData);
    }
    const payload = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '*',
      ...values,
      language: locale,
      password_confirmation: values.password,
      accept_terms: true,
      showFsk: !lStorage.getItem('mhp'),
      Referer: lStorage.getItem('referer'),
      'User-Agent': lStorage.getItem('userAgent'),
      'Accept-Language': lStorage.getItem('locale'),
    };

    const studio = lStorage.getItem('studio') || studioLink;
    const aid = lStorage.getItem('aid') || aidLink;

    if (studio) {
      payload.studio = studio;
    }

    if (aid) {
      payload.AID = aid;
    }

    let getParams = lStorage.getItem('getParams') || '';
    api
      .post(`/user${getParams}`, payload)
      .then((response) => {
        updateLocalStorage();

        addAuthToken(response.data);
        setSignUpError(false);
      })
      .catch((error) => {
        setSignUpError(true);
      });
  }

  return (
    <div className="signup-form-wrapper">
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmission}
      >
        {({ isSubmitting }) => {
          return (
            <FormikForm className="signup-form">
              <FormErrorMessage
                isShown={signUpError}
                title={intl.formatMessage({ id: 'ERROR' })}
                message={
                  signUpError
                    ? intl.formatMessage({ id: 'ERROR_REGISTER_GENERAL' })
                    : null
                }
              />

              <FieldWithErrors
                as={Select}
                label="FIELD_LANGUAGE"
                name="language"
                options={languageSelectOptions}
                value={locale}
                onChange={(e) => changeLocale(e.target.value)}
              />

              <FieldWithErrors
                as={Input}
                name="username"
                label="FIELD_EMAIL"
                placeholder="PLACEHOLDER_EMAIL"
              />

              <FieldWithErrors
                as={Password}
                name="password"
                label="FIELD_CHOOSE_PASSWORD"
                placeholder="PLACEHOLDER_PASSWORD"
              />

              <div className="form-group">
                <span className="column" />
                <Button
                  type="submit"
                  icon={signupIcon}
                  label={
                    isSubmitting && navigator.cookieEnabled
                      ? 'LOADING'
                      : 'BUTTON_SIGN_UP'
                  }
                  isLoading={isSubmitting && navigator.cookieEnabled}
                  id="submit-btn"
                  className="btn-form"
                  disabled={!cookies}
                />
              </div>

              <div className="form-group">
                <span className="column" />
                <div id="terms">
                  <FormattedMessage id="SIGNUP_TERMS_1" />{' '}
                  <Link to="/terms-and-conditions">
                    <FormattedMessage id="SIGNUP_TERMS_2" />
                  </Link>{' '}
                  <FormattedMessage id="SIGNUP_TERMS_3" />{' '}
                  <Link to="/privacy-policy">
                    <FormattedMessage id="SIGNUP_TERMS_4" />
                  </Link>
                  .
                </div>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default Form;
