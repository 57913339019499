import _ from 'lodash';

import {
  ALERT_CANCEL,
  ALERT_OK,
  CAPACITY_AVAILABLE,
  CAPACITY_EXCEEDED,
  SONUS_TOKEN_ADD,
  USER_ADD_AUTH_TOKEN,
  USER_CAPACITY_GET,
  USER_FINALISE_BUTTON_SHOWN,
  USER_LOGOUT,
  USER_REFRESH_AUTH_TOKEN,
  USER_SHOW_LOGOUT_ALERT,
  USER_UPDATE,
  USER_UPDATE_BANKDHOLDER_DOCUMENT,
  USER_UPDATE_DOCUMENTS,
  USER_UPDATE_IMAGES,
  USER_UPDATE_TWO_FACTOR,
} from '../actions/actions';

function userReducer(state = {}, action) {
  switch (action.type) {
    case USER_ADD_AUTH_TOKEN:
    case USER_REFRESH_AUTH_TOKEN: {
      const newPayload = _.cloneDeep(action.payload);
      if (action.payload?.private?.country_code) {
        newPayload.private.country_code =
          action.payload.private.country_code.toUpperCase();
      }
      if (action.payload?.public?.country_code) {
        newPayload.public.country_code =
          action.payload.public.country_code.toUpperCase();
      }

      return { ...state, ...newPayload };
    }
    case USER_UPDATE: {
      const newPayload = _.cloneDeep(action.payload);
      if (action.payload?.private?.country_code) {
        newPayload.private.country_code =
          action.payload.private.country_code.toUpperCase();
      }
      if (action.payload?.public?.country_code) {
        newPayload.public.country_code =
          action.payload.public.country_code.toUpperCase();
      }
      return {
        ...state,
        ...newPayload,
        isLoggedIn: true,
      };
    }
    case USER_LOGOUT:
      return {};

    case USER_UPDATE_DOCUMENTS:
      return {
        ...state,
        private: { ...state.private, documents: action.payload },
      };
    case USER_UPDATE_BANKDHOLDER_DOCUMENT:
      return {
        ...state,
        private: { ...state.private, bankholder_document: action.payload },
      };
    case USER_UPDATE_IMAGES:
      return {
        ...state,
        public: { ...state.public, images: action.payload },
      };
    case USER_FINALISE_BUTTON_SHOWN:
      return {
        ...state,
        account: { ...state.account, finaliseBtnShown: action.payload },
      };
    case USER_SHOW_LOGOUT_ALERT:
      return {
        ...state,
        account: { ...state.account, logoutAlert: true },
      };
    case ALERT_OK: {
      if (!state.account.logoutAlert) {
        return state;
      }
      return {
        ...state,
        account: { ...state.account, logoutUser: true, logoutAlert: false },
      };
    }
    case ALERT_CANCEL: {
      if (!state.account.logoutAlert) {
        return state;
      }
      return {
        ...state,
        account: { ...state.account, logoutAlert: false },
      };
    }
    case USER_CAPACITY_GET: {
      if (state?.account?.capacity && action?.payload) {
        return {
          ...state,
          account: {
            ...state.account,
            capacity: action.payload,
          },
        };
      }
      return state;
    }
    case CAPACITY_AVAILABLE: {
      if (state?.account?.capacity) {
        return {
          ...state,
          account: {
            ...state.account,
            capacity: {
              ...state.account.capacity,
              status: 'enabled',
            },
          },
        };
      }
      return state;
    }
    case CAPACITY_EXCEEDED: {
      if (state?.account?.capacity) {
        return {
          ...state,
          account: {
            ...state.account,
            capacity: {
              ...state.account.capacity,
              status: 'disabled',
            },
          },
        };
      }
      return state;
    }
    case SONUS_TOKEN_ADD: {
      return {
        ...state,
        sonusToken: { token: action.payload, timeStamp: action.timeStamp },
      };
    }
    case USER_UPDATE_TWO_FACTOR: {
      return {
        ...state,
        account: {
          ...state.account,
          two_factor: {
            ...state.account.two_factor,
            ...action.payload,
          },
        },
      };
    }
    default:
      return state;
  }
}

export default userReducer;

