import './Time.scss';

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const toHHMMSS = function (time) {
  if (time < 0) return '00:00';

  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time - hours * 3600) / 60);
  let seconds = time - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return (hours !== '00' ? hours + ':' : '') + minutes + ':' + seconds;
};

let timeout = null;

function Time({ noUser, turnExpires = 0, turnExpiresAt = null }) {
  const [timeleft, setTimeleft] = useState(0);
  const [timeend, setTimeend] = useState(0);

  useEffect(() => {
    if (!noUser) {
      const endtime = Math.floor(new Date(turnExpiresAt).getTime() / 1000);

      if (endtime !== timeend) {
        setTimeleft(0);
        setTimeend(endtime);
      } else {
        const now = Math.floor(Date.now() / 1000);
        const timeremains = endtime - now;

        if (timeleft === 0) {
          setTimeleft(timeremains);
        } else if (timeleft > 0) {
          clearTimeout(timeout);
          timeout = setTimeout(() => setTimeleft(timeleft - 1), 1000);
        }
      }

      return () => clearTimeout(timeout);
    }
  }, [timeleft, turnExpires, turnExpiresAt, noUser, timeend]);

  const barTimerStyles = {
    width: Math.floor((100 * timeleft) / turnExpires) + '%',
  };

  return !noUser ? (
    <div className="timeline">
      <div className="progress-bar">
        <div className="inner" style={barTimerStyles}></div>
      </div>
      <div>
        <FormattedMessage id="GAME_TIME_LEFT" />
        <span className="time">{toHHMMSS(timeleft)}</span>
      </div>
    </div>
  ) : null;
}

export default Time;
