import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SpinnerComponent from '../../../../Spinner/SpinnerComponent';
import GallerySelect from '../GallerySelect';
import AcceptGalleryRow from './Rows/AcceptGalleryRow/AcceptGalleryRow';
import GalleryRow from './Rows/GalleryRow/GalleryRow';
import NoGalleryRow from './Rows/NoGalleryRow/NoGalleryRow';

function FreeMovieOfTheDayTable({ language }) {
  const intl = useIntl();
  const isComponentMounted = useRef(false);
  const [gallerySelectOpened, setGallerySelectOpen] = useState(false);
  const [initialLoadFinished, setInitialLoadFinished] = useState(false);
  const [replaceGallery, setReplaceGallery] = useState(null);
  const [slots, setSlots] = useState([
    { slot: 1 },
    { slot: 2 },
    { slot: 3 },
    { slot: 4 },
    { slot: 5 },
  ]);

  const [, getFmotdData] = useAxios(`/fmd`, { manual: true });

  const loadFmotd = useCallback(async () => {
    try {
      const extractData = (data) => {
        const result = [];
        for (let i = 0; i < 5; i++) {
          if (data[i]?.id) {
            const item = data[i];
            //possible values of item.status: accepted, applied, declined
            let state = item?.status;
            if (state === 'accepted') {
              const fmotdStartDate = new Date(item?.date);
              const dateStringFormat =
                language === 'de' ? 'D. MMMM YYYY' : 'MMMM D, YYYY';
              item.dateString = dayjs(fmotdStartDate).format(dateStringFormat);
              const fmotdEndDate = new Date(
                new Date(item?.date).getTime() + 60 * 60 * 24 * 1000
              );
              const now = new Date();
              if (fmotdStartDate > now) {
                state = 'schedule';
              } else if (now >= fmotdStartDate && now < fmotdEndDate) {
                state = 'current';
              } else {
                state = 'archive';
              }
            }
            item.state = state;
            result[i] = { ...item, slot: i + 1 };
          } else {
            result[i] = { slot: i + 1 };
          }
        }
        return result;
      };
      const { data } = await getFmotdData();

      setSlots(extractData(data));
    } catch (error) {
      console.log(error);
    }
  }, [getFmotdData, language]);

  useEffect(() => {
    isComponentMounted.current = true;
    (async () => {
      await loadFmotd();
      if (isComponentMounted.current) {
        setInitialLoadFinished(true);
      }
    })();
    return () => {
      isComponentMounted.current = false;
    };
  }, [loadFmotd]);

  const deleteFmotd = () => {
    loadFmotd();
  };

  const openSelect = (gallery) => {
    setReplaceGallery(gallery);
    setGallerySelectOpen(true);
  };

  const onSelect = () => {
    setGallerySelectOpen(false);
    loadFmotd();
  };
  const closeSelect = () => {
    setGallerySelectOpen(false);
  };
  return (
    <div className="table-box">
      <h1 className="headline">
        {intl.formatMessage({ id: 'FMOTD_TABLE_HEADLINE' })}
      </h1>
      <p>
        <FormattedMessage id="FMOTD_TABLE_HEADLINE_SUB">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      {!initialLoadFinished ? (
        <SpinnerComponent />
      ) : (
        <div className="slots">
          {gallerySelectOpened ? (
            <GallerySelect
              onSelect={onSelect}
              onClose={closeSelect}
              replaceGallery={replaceGallery}
            />
          ) : null}

          {slots.map((item, index) =>
            item?.status === 'accepted' ? (
              <AcceptGalleryRow key={index} item={item} />
            ) : item?.id ? (
              <GalleryRow
                key={index}
                item={item}
                deleteFmotd={deleteFmotd}
                editFmotd={openSelect}
              />
            ) : (
              <NoGalleryRow key={index} item={item} addFmotd={openSelect} />
            )
          )}
          {}
        </div>
      )}
    </div>
  );
}

export default FreeMovieOfTheDayTable;
