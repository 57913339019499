import * as Yup from 'yup';

export const getValidationScheme = (intl) => {
  const t = (id) => intl.formatMessage({ id });
  const required = t('VALIDATION_REQUIRED_FIELD');
  const validationTextarea = Yup.string()
    .min(32, t('VALIDATION_PROFILE_DESCRIPTION_TO_SHORT'))
    .max(500, t('VALIDATION_PROFILE_DESCRIPTION_TO_LONG'))
    .test({
      test: function (value) {
        if (
          typeof value === 'string' &&
          value.match(/^.*(https?:\/\/|www\..+)+.*$/gim)
        ) {
          return this.createError({
            message: t('VALIDATION_NO_URLS'),
          });
        }
        if (
          typeof value === 'string' &&
          value.match(/<[/?a-zA-Z]+[/?0-9]*>/g)
        ) {
          return this.createError({
            message: t('VALIDATION_NO_TAGS'),
          });
        }

        return true;
      },
    });

  return Yup.object().shape({
    zip: Yup.string(),
    country_code: Yup.string(),
    native_language: Yup.string(),
    additional_languages: Yup.array(),
    age: Yup.string(),
    gender: Yup.string(),
    height: Yup.number()
      .min(120, t('VALUE_DOES_NOT_MAKE_SENSE'))
      .max(220, t('VALUE_DOES_NOT_MAKE_SENSE')),
    weight: Yup.number()
      .min(20, t('VALUE_DOES_NOT_MAKE_SENSE'))
      .max(200, t('VALUE_DOES_NOT_MAKE_SENSE')),
    ethnicity: Yup.string(),
    marital: Yup.string(),
    tattoos: Yup.string(),
    piercings: Yup.string(),
    glasses: Yup.string(),
    eye_color: Yup.string(),
    hair_color: Yup.string(),
    hair_length: Yup.string(),
    body: Yup.string(),
    body_hair: Yup.string(),
    pubic_hair: Yup.string(),
    bra_size: Yup.string(),
    smoking: Yup.string(),
    looking_for: Yup.array(),
    experience: Yup.array(),
    preference: Yup.array(),
    description: validationTextarea.required(required),
    about_myself: validationTextarea,
    said_about_me: validationTextarea,
    erotic_fantasy: validationTextarea,
  });
};

