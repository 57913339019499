export default {
  ROUTE_SETTINGS_HEADLINE: `Hier kannst Du alle notwendigen Einstellungen für Account, Profil und Preise vornehmen`,
  ROUTE_SETTINGS_TOYCONTROL_HEADLINE: `Verknüpfe hier Dein Lovense Toy mit Deinem Account`,
  OPTIONS_NOTIFICATION_HEADER: `Benachrichtigungen & Newsletter`,
  OPTIONS_NOTIFICATION_TEXT_1: `Möchtest Du benachrichtigt werden, wenn ein User Dir schreibt während Du offline bist?`,
  OPTIONS_NOTIFICATION_TEXT_2: `Wir informieren Dich in regelmäßigen Abständen über Neuigkeiten rund um LiveCreator.com und unsere Communities. Außerdem versorgen wir Dich mit nützlichen Tipps, wie Du noch erfolgreicher als Creator werden kannst.`,
  OPTIONS_NOTIFICATION_EMAIL: `E-Mail Benachrichtigungen`,
  OPTIONS_NOTIFICATION_NEWSLETTER: `Newsletter abonnieren`,
  OPTIONS_NOTIFICATION_RECOMMENDED: `(empfohlen)`,
  OPTIONS_CREATOR_TAGS_HEADER: `Creator Tags`,
  OPTIONS_CREATOR_TAGS_TEXT: `Wähle bis zu 5 Tags (Eigenschaften), die Dich am besten beschreiben. So machst Du es einfacher für User, Dich in den Communities zu finden.`,
  OPTIONS_MESSENGER_SETTINGS_HEADER: `Messenger`,
  OPTIONS_MESSENGER_SETTINGS_TEXT_1: `Einstellungen für den Chat`,
  OPTIONS_FEATURES_HEADER: `Features`,
  OPTIONS_FEATURES_TEXT_1: `Möchtest Du die folgenden Features verwenden?`,
  OPTIONS_FEATURES_APPS: `Dating Chat`,
  OPTIONS_FEATURES_DATING_CAMS: `Dating Cams`,
  OPTIONS_FEATURES_SAFE_MODE: `Safe Mode`,
  OPTIONS_FEATURES_VOYEUR: `Voyeur Cams`,
  OPTIONS_FEATURES_COMMUNITY_GAMES: `Community Games`,
  SETTINGS_OPTIONS_HEADLINE: `Community Funktionen im Detail`,
  SETTINGS_OPTIONS_1: `<span class="bold">Dating User - Livecam und Chat</span><br />Wir vermarkten unsere Livecams und den Messenger auch auf Datingseiten anderer Anbieter. Dort wird Dein Profil angezeigt, über welches die User Dich anschreiben oder Deine Cam besuchen können. Du profitierst hierdurch täglich von tausenden potentiellen Bezahl-Usern außerhalb unserer Communities!`,
  SETTINGS_OPTIONS_2: `<span class="bold">Lovense Toy</span><br />Verdiene noch mehr Geld während einer Cam-Session mit den interaktiven Sexspielzeugen von Lovense! Die Software lässt sich schnell und einfach auf Deinem Handy oder Computer installieren und einrichten. Deine Kunden bezahlen je nach Dauer und Vibrationsstufe für Dein Vergnügen mit dem Toy.`,
  SETTINGS_OPTIONS_3: `<span class="bold">Safe Mode</span><br />Mit dem Safe Mode hast Du die Möglichkeit alle anzüglichen Bilder, Dateianhänge und Aufmerksamkeiten im Messenger auszublenden.`,

  SETTINGS_OPTIONS_HEADLINE_DEFAULT: `Diese Community Funktionen sind immer verfügbar`,
  SETTINGS_OPTIONS_1_DEFAULT: `<span class="bold">Voyeur Cams</span><br />Mit der "Voyeur Cam" kannst Du zusätzlich Geld verdienen. Du bietest den Usern die Möglichkeit, anonym zu einem reduzierten Preis in Deine Livecam zu schauen. Der User ist aus seiner Sicht unsichtbar für Dich und kann Dich „heimlich“ beobachten. Als Voyeur kann er Dir aber keine Nachrichten senden oder Cam2Cam starten. Sobald mindestens ein Voyeur in Deiner Livecam ist, wird Dir das durch ein Symbol signalisiert. Ein Voyeur kann jederzeit mit einem einzigen Klick zu der normalen Show wechseln, er ist dann sofort mit Namen für Dich sichtbar.`,
  SETTINGS_OPTIONS_2_DEFAULT: `<span class="bold">Exklusiv Cam</span><br />In unseren Communities gibt es auch den besonderen Exclusive Cam Modus. Dieser ist ausschließlich Creator mit besonders hohem Livecam Ranking und einer überdurchschnittlichen Livecam Performance vorbehalten. Für weitere Details wende Dich bitte an unseren `,
  SETTINGS_OPTIONS_3_DEFAULT: `<span class="bold">Tagebuch</span><br />Als Creator kannst Du Dein eigenes Tagebuch führen und Deine User an Deinem intimen Leben teilhaben lassen. Die User bekommen so einen besseren Bezug zu Dir und Du kannst sie besser an Dich binden.`,

  PRICES_MESSAGE_HEADER: `Nachrichten`,
  PRICES_MESSAGE_TEXT: `Stelle hier ein, wie private Nachrichten an Dich vergütet werden sollen.`,
  PRICES_MESSAGE: `Nachricht:`,
  PRICES_APPS_MESSAGE: `Dating Nachricht:`,
  PRICES_LIVECAM_HEADER: `Livecams`,
  PRICES_LIVECAM_TEXT: `Wähle hier den gewünschten Preis <span class="bold">pro Minute</span>, den ein User innerhalb einer Livecam Show bei Dir zahlen muss.`,
  PRICES_NORMAL_SHOW: `Normale Show:`,
  PRICES_EXCLUSIVE_SHOW: `Exklusiv Show:`,
  PRICES_VOYEUR_SHOW: `Voyeur Show:`,
  PRICES_DATING_SHOW: `Dating Show:`,
  SETTINGS_PRICES_HEADLINE: `Preise für Community Funktionen`,
  SETTINGS_PRICES_1: `<span class="bold">Preis pro Minute</span><br />Das ist der Preis in Coins, den ein User pro Minute in Deiner Livecam Show zahlt. Es wird im 10 Sekunden Takt abgerechnet.`,
  SETTINGS_PRICES_2: `<span class="bold">Normale Show</span><br />Der Preis einer normalen Livecam Show kann bis zu 35 Coins pro Minute betragen. Es können unbegrenzt User in Deine Livecam Show.`,
  SETTINGS_PRICES_3: `<span class="bold">Exklusiv Show</span><br />Der Preis einer Exklusiv Show kann bis zu 90 Coins pro Minute betragen. Es kann nur ein User teilnehmen. Dieser Modus ist ausschließlich Creator mit besonders hohem Livecam Ranking und einer überdurchschnittlichen Livecam Performance vorbehalten. Für weitere Details wende Dich bitte an unseren `,
  SETTINGS_PRICES_4: `<span class="bold">Voyeur Show</span><br />Der Preis einer Voyeur Show beträgt 75% des Preises Deiner normalen Livecam Show. Es wird bei der Abrechnung immer aufgerundet.`,
  SETTINGS_PRICES_5: `<span class="bold">Dating Cam</span><br />Der Preis pro Minute für zusätzliche User, die über unsere externen Datingportal-Kooperationspartner kommen, ist auf 25 Coins pro Minute gedeckelt. Aufgrund externer Preismodelle können wir diesen Usern nicht mehr berechnen.`,
  SETTINGS_PRICES_6: `<span class="bold">Dating Nachricht</span><br />Der maximale Nachrichtenpreis für Dating Nachrichten beträgt 5 Coins.`,
  SETTINGS_PRICES_HEADLINE_2: `Was ist der Wert eines Coins?`,
  SETTINGS_PRICES_TEXT: `Für jeden ausgegebenen Coin generiert ein Community User 10 Cent Umsatz. Von diesem Umsatz erhälst Du eine Provision in Höhe Deines aktuellen Provisionssatzes.`,
};
