import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// import sortAZIcon from '../../../../assets/img/svg/sort-a-z.svg';
// import sortZAIcon from '../../../../assets/img/svg/sort-z-a.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { spinnerRequest } from '../../../../shared/spinnerRequest';
import { addPortals } from '../../../../utils/portals';
import Pagination from '../../../Pagination/Pagination';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import ThumbnailItem from '../../../ThumbnailItem';

function Ignored({ spinner }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const [items, setItems] = useState([]);
  // const [ignoredSearch, setIgnoredSearch] = useState('');
  // const [sortingDirection, setSortingDirection] = useState('desc');
  const initialItems = useRef(null);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [{ loading: ignoredDataLoading }, ignoredDataRequest] = useAxios(
    {
      url: `/user/ignored?o=${offset}&l=${PAGINATION_PAGE_SIZE}`,
    },
    { manual: true }
  );

  const getIgnoredData = useCallback(() => {
    spinnerRequest({
      request: ignoredDataRequest,
      spinner,
    })
      .then(async (response) => {
        setTotalItems(response.data?.total);
        const data = await addPortals([...response.data?.data]);
        initialItems.current = data;
        setItems(data);
      })
      .catch(() => {});
  }, [ignoredDataRequest, spinner]);

  useEffect(() => {
    getIgnoredData();
  }, [getIgnoredData]);

  const updateList = useCallback(
    (id) => {
      if (!id) return;
      //check if the last item on a page was deleted
      if (totalItems - offset === 1 && pageNumber > 1) {
        setOffset((prevState) => prevState - PAGINATION_PAGE_SIZE);
        setPageNumber((prevState) => prevState - 1);
        return;
      }
      if (offset >= totalItems) {
        setOffset(0);
        setPageNumber(1);
        return;
      }
      getIgnoredData();
    },
    [getIgnoredData, offset, pageNumber, totalItems]
  );

  // SEARCH TO BE ADDED BACK LATER

  // const searchIgnoredHandler = (value) => {
  //   const filteredItems = initialItems.current.filter((item) => {
  //     if (value === '') {
  //       return true;
  //     }
  //     return item.nickname.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setItems(filteredItems);
  //   setIgnoredSearch(value);
  // };

  // const clearSearchInput = () => {
  //   setIgnoredSearch('');
  //   searchIgnoredHandler('');
  // };

  // const sortIgnored = () => {
  //   const sortedItems = [...initialItems.current];
  //   if (sortingDirection === 'desc') {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname > b.nickname) {
  //         return -1;
  //       } else if (b.nickname < a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('asc');
  //   } else {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname < b.nickname) {
  //         return -1;
  //       } else if (b.nickname > a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('desc');
  //   }
  //   setItems(sortedItems);
  //   initialItems.current = [...sortedItems];
  // };

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="relationships-wrapper">
        <Scrollbar visible={isLargeScreen} isLargeScreen={isLargeScreen}>
          <div className="data-wrapper">
            <h1 className="headline">
              <FormattedMessage id="RELATIONSHIP_HEADLINE_IGNORE" />
            </h1>
            {!!initialItems?.current?.length ? (
              <>
                <div className="image-grid">
                  {items.map((item, index) => (
                    <ThumbnailItem
                      item={item}
                      type="relationship"
                      key={item.id}
                      relationshipType="ignored"
                      relationshipMenuType="ignored"
                      updateList={updateList}
                    />
                  ))}
                </div>
                <Pagination
                  offset={offset}
                  totalItems={totalItems}
                  pageNumber={pageNumber}
                  setOffset={setOffset}
                  setPageNumber={setPageNumber}
                />
              </>
            ) : (
              !ignoredDataLoading && (
                <div className="no-items">
                  {intl.formatMessage({
                    id: 'RELATIONSHIP_LABEL_NO_IGNORED_USERS',
                  })}
                </div>
              )
            )}
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="RELATIONSHIPS_INFO_HEADLINE" />
            </h2>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_1">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_2">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_2_1" />
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_3">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_4">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Ignored;

