import './Stepper.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { findFirstEnabledItemIndex } from './Stepper.functions';
import StepperContext from './StepperContext';

function Stepper({
  defaultPath = '/',
  items,
  setItems = () => {},
  isFinished,
  finishedComponent,
  isLoading = false,
  children,
  spinner = () => {},
}) {
  const location = useLocation();
  const history = useHistory();
  const [currentItemIndex, setCurrentItemIndex] = useState(
    findFirstEnabledItemIndex(
      items,
      location.pathname === '/onboarding'
        ? '/onboarding/profile-details'
        : location.pathname
    )
  );
  const [sendingToReview, setSendingToReview] = useState('none');
  const [nextItemIndex, setNextItemIndex] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if ((currentItemIndex || currentItemIndex === 0) && currentItemIndex >= 0) {
      history.replace(`${defaultPath}${items[currentItemIndex].path}`);
    }
  }, [currentItemIndex, defaultPath, history, items]);

  const contextValue = useMemo(() => {
    function markCurrentItemAsValid() {
      setItems((items) => {
        let newItems = [...items];

        newItems[currentItemIndex].isSuccess = true;
        newItems[currentItemIndex].isError = false;

        return newItems;
      });
    }

    function markCurrentItemAsInvalid() {
      setItems((items) => {
        let newItems = [...items];

        newItems[currentItemIndex].isError = true;
        newItems[currentItemIndex].isSuccess = false;

        return newItems;
      });
    }

    return {
      defaultPath,
      items,
      currentItemIndex,
      setCurrentItemIndex,
      nextItemIndex,
      setNextItemIndex,
      isDisabled,
      setIsDisabled,
      isFinished,
      finishedComponent,
      isLoading,
      markCurrentItemAsValid,
      markCurrentItemAsInvalid,
      setItems,
      sendingToReview,
      setSendingToReview,
    };
  }, [
    currentItemIndex,
    defaultPath,
    finishedComponent,
    isDisabled,
    isFinished,
    isLoading,
    items,
    nextItemIndex,
    sendingToReview,
    setItems,
  ]);

  useEffect(() => {
    spinner(isLoading);
  }, [isLoading, spinner]);

  useEffect(() => {
    setIsDisabled(isLoading);
  }, [isLoading]);

  if (finishedComponent?.isPending && !finishedComponent?.finishedComponent) {
    return new Error(
      `When isPending is true, a finishedComponent prop must be present as well in 'Stepper'.`
    );
  }

  return (
    <StepperContext.Provider value={contextValue}>
      {children}
    </StepperContext.Provider>
  );
}

export default Stepper;
