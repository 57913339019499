import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { sound } from '../../../../actions';
import { DEFAULT_CHAT_FONT_SIZE } from '../../../../constants';
import { DEFAULT_SOUNDS } from '../../../../constants/sound';
import { roomType as roomTypeUtils } from '../../../../utils';
import OptionsSoundMenu from './OptionsSoundMenu';

const mapStateToProps = (state) => ({
  isEvent: roomTypeUtils.isEvent(state.sender.roomTypes),
  fontSizeDefault:
    state.preferences.fontsize.selected === DEFAULT_CHAT_FONT_SIZE,
  newMessageSoundDefault:
    state.preferences.sounds.newMessage.type === DEFAULT_SOUNDS.newMessage.type,
  volumeDefault: state.preferences.volume === 1,
});

const mapDispatchToProps = (dispatch) => ({
  reset: (livecamReset, messengerReset) => {
    dispatch(sound.reset(livecamReset, messengerReset));
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OptionsSoundMenu)
);
