import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import menuIcon from '../../../../assets/img/svg/menu-dots.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Button from '../../../Button/Button';
import AudioVolume from '../../AudioVolume';
import FontSizeSelect from '../../FontSizeSelect';
import NewMessage from '../SoundSelectFields/NewMessage';

const OptionsSoundMenu = ({
  reset,
  isEvent,
  fontSizeDefault,
  newMessageSoundDefault,
  volumeDefault,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const menuIconClass = classNames('menu-icon', {
    active: menuVisible,
  });

  const disabled = fontSizeDefault && newMessageSoundDefault && volumeDefault;

  return (
    !isEvent && (
      <div className="messenger-options">
        <div className="headline-with-menu form-group">
          <h2 className="headline column">
            <FormattedMessage id="OPTIONS_MESSENGER_SETTINGS_HEADER" />
          </h2>
          <div className="settings-menu-wrapper">
            <Button
              variant="icon-only"
              onClick={() => {
                if (!menuVisible) {
                  setMenuVisible(true);
                }
              }}
              intlTranslate={false}
              icon={menuIcon}
              className={menuIconClass}
              disabled={disabled}
            />
            {menuVisible && (
              <div className="toggle-menu settings-menu" ref={menuRef}>
                <div
                  className="menu-item"
                  onClick={() => {
                    setMenuVisible(false);
                    reset(false, true);
                  }}
                >
                  <FormattedMessage id="reset" />
                </div>
              </div>
            )}
          </div>
        </div>
        <p>
          <FormattedMessage id="OPTIONS_MESSENGER_SETTINGS_TEXT_1" />
        </p>
        <FontSizeSelect />
        <AudioVolume />
        <NewMessage />
      </div>
    )
  );
};

export default OptionsSoundMenu;
