import { connect } from 'react-redux';

import { clearTargetNewsletter } from '../../../actions';
import Newsletter from './Newsletter';

const mapStateToProps = (state) => {
  return {
    nickname: state.user.account?.nickname,
    targetNewsletterId: state.newsletter.targetNewsletterId,
  };
};

const mapDispatchToProps = {
  clearTargetNewsletter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
