import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import menuIcon from '../../../assets/img/svg/menu-dots.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Button from '../../Button/Button';

const BandwidthCheck = ({
  percent,
  currentBandwidth,
  averageBandwidth,
  disabled,
  onClick,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const menuIconClass = classNames('menu-icon', {
    active: menuVisible,
  });

  return (
    <div className="bandwidth-check group-container">
      <div className="headline-with-menu form-group">
        <h2 className="headline column">
          <FormattedMessage id="bandwidthCheck.title" />
        </h2>
        <div className="settings-menu-wrapper">
          <Button
            variant="icon-only"
            onClick={() => {
              setMenuVisible(!menuVisible);
            }}
            disabled={disabled}
            intlTranslate={false}
            icon={menuIcon}
            className={menuIconClass}
          />
          {menuVisible && (
            <div className="toggle-menu settings-menu" ref={menuRef}>
              <div
                className="menu-item"
                values={{ suffix: '' }}
                onClick={() => {
                  setMenuVisible(false);
                  onClick();
                }}
              >
                <FormattedMessage id="check.bandwidth" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="bandwidthCheck.loading" />
        </label>
        <span>{percent}%</span>
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="bandwidthCheck.current" />
        </label>
        <span>{currentBandwidth} kbit/s</span>
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="bandwidthCheck.average" />
        </label>
        <span>{averageBandwidth} kbit/s</span>
      </div>
    </div>
  );
};

export default BandwidthCheck;
