import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import api from '../../../../utils/api';
import Button from '../../../Button/Button';
import PageSection from '../../../PageSection/PageSection';
import VirtualGiftsList from './VirtualGiftsList/VirtualGiftsList';

function AllVirtualGifts({ selectMsgUser }) {
  const [virtualGiftsCategories, setVirtualGiftsCategories] = useState();
  const [filteredGifts, setFilteredGifts] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const [loading, setLoading] = useState(false);
  const [, allVirtualGiftsCategoriesReq] = useAxios(
    {
      url: `data/virtual-gifts/categories`,
    },
    { manual: true }
  );

  const classes = classNames({
    active: true,
  });

  useEffect(() => {
    let isMounted = true;

    allVirtualGiftsCategoriesReq()
      .then((response) => {
        isMounted && setVirtualGiftsCategories(response?.data);
      })
      .catch(() => {});

    return () => (isMounted = false);
  }, [allVirtualGiftsCategoriesReq, setVirtualGiftsCategories]);

  useEffect(() => {
    let isMounted = true;
    if (isLargeScreen && virtualGiftsCategories) {
      async function fetchOnLoad() {
        try {
          setLoading(true);
          const response = await api.get(
            `data/virtual-gifts/categories/${virtualGiftsCategories[0]?.id}`
          );
          if (isMounted) {
            setLoading(false);
            setFilteredGifts(response?.data);
          }
        } catch {
          if (isMounted) {
            setLoading(false);
            console.log('error, virtual gifts categories cannot be fetched');
          }
        }
        setActiveButton(virtualGiftsCategories[0].name);
      }

      fetchOnLoad();
    }
    return () => (isMounted = false);
  }, [setFilteredGifts, virtualGiftsCategories, isLargeScreen]);

  const selectCategory = async (gift, id) => {
    try {
      setLoading(true);
      const response = await api.get(`data/virtual-gifts/categories/${id}`);
      setLoading(false);
      setFilteredGifts(response?.data);
    } catch {
      setLoading(false);
      console.log('error, virtual gifts categories cannot be fetched');
    }
    setActiveButton(gift);
  };

  const className = classNames('gifts-container', 'account-container', {});

  return (
    <>
      {isLargeScreen ? (
        <PageSection
          containsHeader={true}
          className={className}
          rightColumn={
            isLargeScreen && filteredGifts ? (
              <VirtualGiftsList
                items={filteredGifts}
                category={activeButton}
                loading={loading}
              />
            ) : null
          }
        >
          <div className="virtual-gifts-menu">
            <h2 className="headline">
              <FormattedMessage id="VIRTUAL_GIFTS_CATEGORY_LIST" />
            </h2>
            {virtualGiftsCategories?.map((gift, i) => (
              <Button
                key={gift.id}
                type="button"
                className={activeButton === gift.name ? classes : ''}
                intlTranslate={false}
                onClick={() => selectCategory(gift.name, gift.id)}
                label={gift.name}
                img={`${process.env.REACT_APP_IMAGE_URL_1}${gift.icon}`}
              />
            ))}
          </div>
        </PageSection>
      ) : (
        <PageSection className={className}>
          {!filteredGifts ? (
            <>
              <h2 className="headline">
                <FormattedMessage id="VIRTUAL_GIFTS_CATEGORY_LIST" />
              </h2>
              <div className="virtual-gifts-menu">
                {virtualGiftsCategories?.map((gift, i) => (
                  <Button
                    key={gift.id}
                    type="button"
                    className={activeButton === gift.name ? classes : ''}
                    intlTranslate={false}
                    onClick={() => selectCategory(gift.name, gift.id)}
                    label={gift.name}
                    img={`${process.env.REACT_APP_IMAGE_URL_1}${gift.icon}`}
                  />
                ))}
              </div>
            </>
          ) : (
            <VirtualGiftsList
              items={filteredGifts}
              setFilteredGifts={setFilteredGifts}
              svg={true}
              category={activeButton}
              loading={loading}
            />
          )}
        </PageSection>
      )}
    </>
  );
}

export default AllVirtualGifts;
