import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { sound } from '../../../../actions';
import { DEFAULT_SOUNDS } from '../../../../constants/sound';
import { roomType as roomTypeUtils } from '../../../../utils';
import LivecamSoundMenu from './LivecamSoundsMenu';

const mapStateToProps = (state) => ({
  isEvent: roomTypeUtils.isEvent(state.sender.roomTypes),
  lostConnectionSoundDefault:
    state.preferences.sounds.lostConnection.type ===
    DEFAULT_SOUNDS.lostConnection.type,
  leaveShowSoundDefault:
    state.preferences.sounds.leaveShow.type === DEFAULT_SOUNDS.leaveShow.type,
  newLivecamMessageSoundDefault:
    state.preferences.sounds.newLivecamMessage.type ===
    DEFAULT_SOUNDS.newLivecamMessage.type,
});

const mapDispatchToProps = (dispatch) => ({
  reset: (livecamReset, messengerReset) => {
    dispatch(sound.reset(livecamReset, messengerReset));
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LivecamSoundMenu)
);
