import './Chat.scss';

import classNames from 'classnames';
import React from 'react';

import scrollIcon from '../../../assets/img/svg/scroll.svg';
import {
  CHAT_LAYOUT_MULTI,
  DEFAULT_CHAT_FONT_SIZE,
  MESSAGE_TYPE_SENDER_2ALL,
  SPINNER_DEFAULT_DELAY,
} from '../../../constants';
import { user as userUtils } from '../../../utils/';
import { getFirstMessage, getLastMessage } from '../../../utils/chat';
import Alert from '../../Alerts/Alert';
import Button from '../../Button/Button';
import Scrollbar from '../../Scrollbar/Scrollbar';
import Spinner from '../../Spinner/Spinner';
import ChatControl from './Control';
import ChatControlSwipe from './Control/Swipe';
import GameInvitation from './GameInvitation';
import ChatHeader from './Header';
import ChatMessage from './Message';
import ChatMessageAudio from './Message/Audio';

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.createChatMessage = this.createChatMessage.bind(this);
    this.windowResize = this.windowResize.bind(this);
    this.chatElement = React.createRef();
    this.textarea = React.createRef();
    this.topRef = React.createRef(null);
    this._isMounted = false;
    this.state = {
      isObserving: false,
      scrollPlace: null,
      maxHeightScroll: null,
      sendNextRequest: true,
      isLoading: false,
    };
    this.observer = null;
  }

  scrollPlacement = (scrollPlace) => {
    if (!this._isMounted) return;
    this.setState({ scrollPlace });
  };
  maxHightScroll = (maxHeightScroll) => {
    if (!this._isMounted) return;
    this.setState({ maxHeightScroll });
  };

  createChatMessage(message, key) {
    const {
      selectedUser,
      usercolor,
      layout,
      smileyDictionary = {},
      globalPattern,
      showSendername,
      startTime,
      isAdmin,
      openOverlay,
      user,
      nickname,
      payttachments,
      attachments,
      safeMode,
      sexIcons,
      regards,
      spinner,
      sendChatMessage,
    } = this.props;
    const partner = user?.partner;
    if (message.gameInvitation) {
      const props = {
        layout,
        type: 'gameInvitation',
        message,
        showSendername,
        user,
        nickname,
        payttachments,
        safeMode,
        sexIcons,
        regards,
        spinner,
        ...message,
      };
      return <GameInvitation key={key} {...props} />;
    }
    if (
      selectedUser !== message.userId &&
      layout === CHAT_LAYOUT_MULTI &&
      message.type !== MESSAGE_TYPE_SENDER_2ALL
    ) {
      return null;
    }
    //In multichatlayout don't show old 2all messages
    if (
      layout === CHAT_LAYOUT_MULTI &&
      message.type === MESSAGE_TYPE_SENDER_2ALL &&
      (message.time < startTime || isAdmin)
    ) {
      return null;
    }

    let color = 'usercolor-default';
    if (usercolor && message.userColor) {
      color = message.userColor;
    }

    const highlight = message.highlight || false;

    if (message.payload?.type === 'voice') {
      const props = {
        ...message.payload,
        loading: message.payload?.loading,
        message,
        layout,
        type: message.type,
      };
      return <ChatMessageAudio key={key} {...props} />;
    }

    const props = {
      layout,
      color,
      type: message.type,
      message,
      highlight,
      smileyDictionary,
      globalPattern,
      showSendername,
      openOverlay,
      user,
      nickname,
      payttachments,
      attachments,
      safeMode,
      sexIcons,
      regards,
      spinner,
      partner,
      loading: message.payload?.loading,
      sendChatMessage,
    };

    return <ChatMessage key={key} {...props} />;
  }

  createSystemMessage(user, state) {
    const props = {
      selectedUser: user,
      state,
      livecam: this.props.livecam,
      deleteDialog: true,
      selectFirstDialog: this.props.selectFirstDialog,
      clearDialog: this.props.clearDialog,
      spinner: this.props.spinner,
    };
    return <ChatMessage {...props} />;
  }

  createUnignoreMessage(user) {
    const props = {
      selectedUser: user,
      livecam: this.props.livecam,
      selectFirstDialog: this.props.selectFirstDialog,
      clearDialog: this.props.clearDialog,
      spinner: this.props.spinner,
      isIgnored: this.props.isIgnored,
      unignoreIdUser: this.props.unignoreIdUser,
      unignoreLayout: this.props.layout,
    };
    return <ChatMessage {...props} />;
  }

  windowResize() {
    if (window.innerWidth < 1024 && this.props.layout === 'single') {
      this.props.changeLayoutChat(CHAT_LAYOUT_MULTI);
    }
  }

  scrollDown = (behavior = 'smooth', block = 'end') => {
    const htmlMessages = document.querySelectorAll('.chat-message');
    htmlMessages[htmlMessages?.length - 1]?.scrollIntoView({
      behavior,
      block,
    });
  };

  mobileScroll = () => {
    this.setState({
      scrollPlace: this.textarea.current?.scrollTop,
      maxHeightScroll:
        this.textarea.current?.scrollHeight -
        this.textarea.current?.offsetHeight,
    });
  };

  render() {
    const {
      messages,
      layout,
      selectedUser,
      isMessageUser,
      dialogmessages,
      fontsize = DEFAULT_CHAT_FONT_SIZE,
      isMobile,
      usergame,
      chatMenuOpen,
      navLeft,
      navRight,
      camOpen,
      initialized,
      alert,
      runToy,
      payttachmentsMenuOpen,
      user,
    } = this.props;

    const chatSize = `chat-size-${fontsize}`;
    const cssClasses = classNames(
      'chat',
      `chat-${layout}`,
      `user-selected-${selectedUser}`,
      {
        'chat-menu-open': chatMenuOpen,
        'cam-open': camOpen,
        [chatSize]: !isMobile,
        'toy-activated': runToy?.countDown > 0,
      }
    );
    const chatboxClasses = classNames('chatbox', {
      'payttachments-menu-open': payttachmentsMenuOpen,
      'mobile-game': usergame && isMobile,
    });

    return (
      <div className={cssClasses} ref={this.chatElement}>
        <div className="app-alerts height-auto">
          {alert.length > 0 && <Alert scope="messenger" />}
        </div>
        <ChatHeader isMobile={isMobile} />
        <ChatControlSwipe
          navLeft={navLeft}
          navRight={navRight}
          isMobile={isMobile}
        >
          <div
            onScroll={() => this.mobileScroll()}
            className={chatboxClasses}
            ref={this.textarea}
          >
            <Scrollbar
              visible={!isMobile}
              scrollDownOnModification={!this.state.isObserving}
              dontScrollOnNewMsg={
                this.state?.maxHeightScroll - 150 > this.state?.scrollPlace
              }
              scrollPlacement={this.scrollPlacement}
              maxHightScroll={this.maxHightScroll}
            >
              <div className="chat-spinner small" ref={this.topRef}>
                {this.state.isLoading ? (
                  <Spinner size={20} thickness={2} />
                ) : null}
              </div>
              {!isMessageUser ? (
                <>
                  {messages.map(this.createChatMessage)}
                  {this.props.isIgnored && this.createUnignoreMessage(user)}
                </>
              ) : initialized ? (
                Object.keys(user).length !== 0 && (
                  <>
                    {dialogmessages.map(this.createChatMessage)}
                    {userUtils.checkDeleteOrIgnored(user) &&
                      this.createSystemMessage(
                        user,
                        userUtils.checkDeleteOrIgnored(user)
                      )}
                    {this.props.isIgnored && this.createUnignoreMessage(user)}
                  </>
                )
              ) : (
                <div className="chat-spinner">
                  <Spinner delay={SPINNER_DEFAULT_DELAY} />
                </div>
              )}
            </Scrollbar>
          </div>
        </ChatControlSwipe>
        {this.state?.maxHeightScroll - 150 > this.state?.scrollPlace &&
          this.props.newMessage > 0 && (
            <div className="btn-scroll-wrapper">
              <Button
                variant="icon-only"
                onClick={() => {
                  this.scrollDown();
                }}
                intlTranslate={false}
                icon={scrollIcon}
              />
              <span className="additional-number">
                {this.props.newMessage ? this.props.newMessage : ''}
              </span>
            </div>
          )}
        <ChatControl />
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    this._isMounted = true;
    this.scrollDown('instant');
    this.mobileScroll();
    this.observer = new IntersectionObserver(
      (entries) => {
        if (!this._isMounted) return;

        if (
          this.props.dialogmessages?.length > 60 &&
          entries[0]?.isIntersecting &&
          this.state.sendNextRequest
        ) {
          this.setState({ isObserving: true });
          this.props.incrementPageChat();
          this.setState({
            isLoading: true,
          });
          this.props
            .fetchAllMessages(
              this.props?.user?.partner || this.props.selectedUser
            )
            .then((response) => {
              const firstChatMessage =
                document.querySelectorAll('.chat-message')[response?.length];
              if (firstChatMessage) {
                firstChatMessage.scrollIntoView({
                  block: 'nearest',
                  inline: 'start',
                });
              }
              if (response?.length <= 60) {
                this.setState({
                  isObserving: false,
                  sendNextRequest: false,
                });
              }
              this.setState({
                isLoading: false,
              });
            })
            .catch((error) => {
              this.setState({
                isLoading: false,
              });
              console.log(error);
            });
        } else if (this.props.dialogmessages?.length > 60) {
          this.setState({ isObserving: false });
        }
      },
      {
        threshold: 1,
      }
    );

    // Start observing the messages container
    if (this.topRef.current) {
      this.observer.observe(this.topRef.current);
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.isMobile !== prevProps.isMobile) {
      this.observer.unobserve(this.topRef.current);
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props?.user?.partner !== prevProps.user.partner) {
      this.setState({
        sendNextRequest: true,
      });
    }

    if (
      this.state?.maxHeightScroll - 150 < this.state?.scrollPlace &&
      this.props.newMessage &&
      this.props.newMessage > 0
    ) {
      this.props.resetNewMessages();
    }

    if (this.props.isMobile !== prevProps.isMobile) {
      this.observer.observe(this.topRef.current);
      this.scrollDown('instant');
      this.mobileScroll();
    }
    if (!this.props.isMobile) {
      return;
    }
    const messages = !this.props.isMessageUser
      ? this.props.messages
      : this.props.dialogmessages;

    const prevMessages = !this.props.isMessageUser
      ? prevProps.messages
      : prevProps.dialogmessages;

    const lastMessage = getLastMessage(messages);
    const prevLastMessage = getLastMessage(prevMessages);
    const firstMessage = getFirstMessage(messages);
    const prevFirstMessage = getFirstMessage(prevMessages);

    if (lastMessage !== prevLastMessage || firstMessage !== prevFirstMessage) {
      if (this.state?.maxHeightScroll - 150 > this.state?.scrollPlace) {
        return;
      }
      this.textarea.current.scrollTop = this.textarea.current.scrollHeight;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener('resize', this.windowResize);
  }
}

export default Chat;
