import { connect } from 'react-redux';

import { openOverlay } from '../../../actions';
import MyProfile from './MyProfile';

const defaultsObj = {
  zip: '',
  country_code: '',
  native_language: '',
  additional_languages: [],
  age: 0,
  gender: '',
  height: '',
  weight: '',
  ethnicity: '',
  marital: '',
  tattoos: '',
  piercings: '',
  glasses: '',
  eye_color: '',
  hair_color: '',
  hair_length: '',
  body: '',
  body_hair: '',
  pubic_hair: '',
  bra_size: '',
  smoking: '',
  looking_for: [],
  experience: [],
  preference: [],
  description: '',
  about_myself: '',
  said_about_me: '',
  erotic_fantasy: '',
};

function getInitialValues(reduxObj) {
  let returnObj = {};
  let reduxValues = { ...reduxObj };

  for (const k in defaultsObj) {
    returnObj[k] = reduxValues[k] || defaultsObj[k];
  }

  // Description
  returnObj.description = '';
  if (reduxObj?.description?.length > 0) {
    const descriptionObj = reduxObj.description.find(
      (d) => d.language === reduxObj.language
    );

    if (descriptionObj) {
      returnObj.description = descriptionObj.content;
    }
  }

  returnObj.about_myself = '';
  if (reduxObj?.about_myself?.length > 0) {
    const descriptionObj = reduxObj.about_myself.find(
      (d) => d.language === reduxObj.language
    );

    if (descriptionObj) {
      returnObj.about_myself = descriptionObj.content;
    }
  }

  returnObj.said_about_me = '';
  if (reduxObj?.said_about_me?.length > 0) {
    const descriptionObj = reduxObj.said_about_me.find(
      (d) => d.language === reduxObj.language
    );

    if (descriptionObj) {
      returnObj.said_about_me = descriptionObj.content;
    }
  }

  returnObj.erotic_fantasy = '';
  if (reduxObj?.erotic_fantasy?.length > 0) {
    const descriptionObj = reduxObj.erotic_fantasy.find(
      (d) => d.language === reduxObj.language
    );

    if (descriptionObj) {
      returnObj.erotic_fantasy = descriptionObj.content;
    }
  }

  // We can't use intl here because it is not a component,
  // and we can't pass a translatable string because this is the value of a field
  // and not the label that is translatable
  const yearsTranslation = reduxObj.language === 'en' ? ' years' : ' Jahre';
  returnObj.age = returnObj.age + yearsTranslation;

  return returnObj;
}

const mapStateToProps = (state) => ({
  initialValues: getInitialValues({
    ...state.user.private,
    ...state.user.public,
    ...state.user.account,
  }),
  user: state.user,
  language: state.user.account?.language,
});

const mapDispatchToProps = {
  openOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);

