export default {
  VIDEOS_INFO_1: `<span class="bold">Neue Galerie anlegen</span><br />Mit dem Button „Neue Galerie erstellen“ kannst Du direkt starten. Um ein Video hochzuladen kannst Du dieses einfach per „Drag and Drop“ in den weißen, blau umrandeten Bereich ziehen. Oder Du klickst das + an um eine Datei von Deinem Computer oder Smartphone auszuwählen.`,
  VIDEOS_INFO_2: `<span class="bold">Videos hinzufügen oder Löschen</span><br />Du kannst einer Galerie nur ein Video im Format mp4, avi, mpg, oder wmv hinzufügen. Ungeprüfte Videos kannst Du auch wieder löschen.`,

  VIDEO_GALLERY_FORMATS: `.mp4, .mov, .movie, .qt, .avi oder .wmv`,
  VIDEO_GALLERY_REQUIREMENTS_LIST: `<ul>
    <li>ein Video</li>
    <ul>
      <li>im Format {videoFormats}</li>
      <li>Mindestlaufzeit ist 1 Minute</li>
      <li>Maximale Dateigröße ist 5 GB</li>
    </ul>
    <li>der Galerie Titel</li>
    <li>eine kurze Beschreibung</li>
    <li>ein Vorschaubild</li>
    <li>mindestens eine Kategorie</li>
    <li>zugewiesene(s) Darsteller-Dokument(e)</li>
  </ul>`,

  VIDEO_PRICE_ADDITIONAL_LABEL_1: `50 Coins entsprechen 5,00 €`,
  VIDEO_PRICE_ADDITIONAL_LABEL_2: `Minimum: 1, Max: 500 Coins`,
  VIDEO_COINS_PER_VIDEO: `pro Video`,
  COINS_PER_VIDEO: `Coins pro video`,
  VIDEO_GALLERY_MENU_ADD_VIDEO: `Video hinzufügen`,
  VIDEO_DELETE_ERROR: `Beim Löschen Deines Videos gab es einen Fehler. Versuche es noch einmal.`,

  IS_CONVERTING: `Das Video wird konvertiert`,
  REMAINING_TIME_MINUTES: `{time} Minuten verbleibend`,
  REMAINING_TIME_SECONDS: ` {time} Sekunden verbleibend`,
  IS_UPLOADING: `Das Video wird hochgeladen`,
  ERROR_FETCHING_CONVERSION: `Es gab einen Fehler bei der Konvertierung des Videos.`,
  ERROR_CONVERTING_VIDEO: `Das Video konnte nicht konvertiert werden. Bitte lösche es und versuche es erneut.`,
};

