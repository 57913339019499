import './Blog.scss';

import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { setTargetNewsletter } from '../../../actions';
import { regionalFormat } from '../../../utils/date';
import Button from '../../Button/Button';
import SpinnerComponent from '../../Spinner/SpinnerComponent';

function Blog() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [messages, setMessages] = useState(null);
  const [{ loading: blogLoading }, blogtotalRequest] = useAxios(
    {
      url: `/user/blog`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const language = useSelector((state) => state.user.account.language);
  const goToLink = (link) => {
    history.push(link);
  };
  const goToNewsletter = (newsletterId) => {
    dispatch(setTargetNewsletter(newsletterId));
    history.push('/newsletter');
  };

  useEffect(() => {
    let isMounted = true;
    if (!messages) {
      blogtotalRequest()
        .then((response) => {
          isMounted && setMessages(response.data);
        })
        .catch((error) => {
          isMounted && console.error(error);
        });
      return () => (isMounted = false);
    }
  }, [blogtotalRequest, messages]);

  return blogLoading ? (
    <SpinnerComponent />
  ) : (
    <div className="blog-container">
      <h3 className="headline">
        {intl.formatMessage({ id: 'BLOG_HEADLINE' })}
      </h3>
      {messages?.length > 0 ? (
        messages.map((message, index) =>
          message.type === 'newsletter' ? (
            <div key={index} className="blog-item newsletter">
              <span className="title">
                {<span dangerouslySetInnerHTML={{ __html: message.subject }} />}
              </span>
              <span className="date">
                {regionalFormat(message.date, language)}
              </span>
              <p>
                <span dangerouslySetInnerHTML={{ __html: message.message }} />
              </p>
              <div className="btn-wrapper">
                <Button
                  label="READ_MORE"
                  className="btn-small"
                  variant={'primary'}
                  onClick={() => goToNewsletter(message.newsletterId)}
                />
              </div>
            </div>
          ) : (
            <div key={index} className="blog-item message">
              <span className="title">
                {<span dangerouslySetInnerHTML={{ __html: message.subject }} />}
              </span>
              <span className="date">
                {regionalFormat(message.date, language)}
              </span>
              <p>
                <span dangerouslySetInnerHTML={{ __html: message.message }} />
              </p>
              {message.link && (
                <div className="btn-wrapper">
                  <Button
                    label="READ_MORE"
                    className="btn-small"
                    variant={'primary'}
                    onClick={() => goToLink(message.link)}
                  />
                </div>
              )}
            </div>
          )
        )
      ) : (
        <p>{intl.formatMessage({ id: 'BLOG_PLACEHOLDER' })}</p>
      )}
    </div>
  );
}

export default Blog;
