import './MyComments.scss';

import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import sortNewOldIcon from '../../../../../assets/img/svg/sort-new-old.svg';
import sortOldNewIcon from '../../../../../assets/img/svg/sort-old-new.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../../constants';
import Pagination from '../../../../Pagination/Pagination';
import SpinnerComponent from '../../../../Spinner/SpinnerComponent';
import CommentsItem from '../CommentsItem';

function MyComments({ openDialog, user, isMobile }) {
  const intl = useIntl();

  const [sortingDirection, setSortingDirection] = useState('desc');
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(null);

  const [{ loading }, getCommentsData] = useAxios(
    `/comments?l=${PAGINATION_PAGE_SIZE}&o=${offset}&sort=${sortingDirection}`,
    { manual: true }
  );
  const [comments, setComments] = useState([]);

  const loadComments = useCallback(async () => {
    let isMounted = true;

    try {
      const res = await getCommentsData();
      if (isMounted) {
        setTotalItems(res.data.total);
        setComments(res.data.data);
      }
    } catch (error) {
      isMounted && console.log(error);
    }

    return () => (isMounted = false);
  }, [getCommentsData]);

  useEffect(() => {
    loadComments();
  }, [offset, sortingDirection, loadComments]);

  return (
    <div className="data-wrapper">
      <div className="comments-container">
        <div className="table-box">
          {loading ? (
            <SpinnerComponent />
          ) : comments.length === 0 ? (
            <p className="bold">
              {intl.formatMessage({ id: 'COMMENTS_NO_COMMENTS_AVAILABLE' })}
            </p>
          ) : (
            <>
              <h1 className="headline">
                {intl.formatMessage({ id: 'COMMENTS_TABLE_HEADLINE' })}
                {isMobile && (
                  <ReactSVG
                    onClick={() =>
                      setSortingDirection(
                        { asc: 'desc', desc: 'asc' }[sortingDirection]
                      )
                    }
                    src={
                      { asc: sortOldNewIcon, desc: sortNewOldIcon }[
                        sortingDirection
                      ]
                    }
                    className={'sort-icon'}
                    wrapper="span"
                  />
                )}
              </h1>

              {!isMobile && (
                <table className="comments-table noHover">
                  <thead>
                    <tr>
                      <th className="date">
                        <div>
                          <span>
                            {intl.formatMessage({ id: 'COMMENTS_DATE' })}
                          </span>
                          <ReactSVG
                            onClick={() =>
                              setSortingDirection(
                                { asc: 'desc', desc: 'asc' }[sortingDirection]
                              )
                            }
                            src={
                              { asc: sortOldNewIcon, desc: sortNewOldIcon }[
                                sortingDirection
                              ]
                            }
                            className={'sort-icon'}
                            wrapper="span"
                          />
                        </div>
                      </th>
                      <th className="user">
                        {intl.formatMessage({ id: 'COMMENTS_USER' })}
                      </th>
                      <th className="preview" />
                      <th className="comment">
                        {intl.formatMessage({ id: 'COMMENTS_TEXT' })}
                      </th>
                      <th className="options" />
                    </tr>
                  </thead>
                  <tbody>
                    {comments.map((comment, index) => (
                      <CommentsItem
                        key={index}
                        isOwnComment={user.account.id === comment.user.id}
                        openDialog={openDialog}
                        onCommentDelete={loadComments}
                        onCommentAnswer={loadComments}
                        isMobile={isMobile}
                        {...comment}
                      />
                    ))}
                  </tbody>
                </table>
              )}

              {isMobile && (
                <>
                  {comments.map((comment, index) => (
                    <CommentsItem
                      key={index}
                      isOwnComment={user.account.id === comment.user.id}
                      openDialog={openDialog}
                      onCommentDelete={loadComments}
                      onCommentAnswer={loadComments}
                      isMobile={isMobile}
                      {...comment}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
        <Pagination
          offset={offset}
          totalItems={totalItems}
          pageNumber={pageNumber}
          setOffset={setOffset}
          setPageNumber={setPageNumber}
        />
      </div>
    </div>
  );
}

export default MyComments;
