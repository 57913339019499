import TagManager from 'react-gtm-module';

export function initializeGtm() {
  const tagManagerArgs = {
    gtmId: 'GTM-MSZGLJF',
  };

  TagManager.initialize(tagManagerArgs);
}

