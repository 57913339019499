import './MobileHeader.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import logo from '../../../assets/img/logo-xs.svg';
import hamburgerIcon from '../../../assets/img/svg/hamburger-menu.svg';
import userlistIcon from '../../../assets/img/svg/userlist.svg';
import Button from '../../Button/Button';
import LanguageSelect from '../../Forms/LanguageSelect';
import User from '../../Livecam/Chat/Header/Item/User';
import CurrentSales from '../../Livecam/CurrentSales';
import Icebreaker from '../Icebreaker';

function MobileHeader({
  title,
  subtitle,
  showLanguageSelect,
  onMenuToggle,
  toggleUserlistView,
  userlist,
  sidebar,
  openedUserlist,
}) {
  const location = useLocation();
  const [isCamMessenger, setIsCamMessenger] = useState(false);
  useEffect(() => {
    setIsCamMessenger(location.pathname === '/multi-messenger');
  }, [setIsCamMessenger, location]);

  const hamburgerBtnClasses = classNames('hamburger-btn', {
    active: sidebar,
  });

  const userlistBtnClasses = classNames('userlist-btn', {
    active: userlist,
  });

  return (
    <div className="mobile-header">
      <div className="mobile-header-left">
        <Button
          type="button"
          className={hamburgerBtnClasses}
          onClick={(e) => {
            onMenuToggle();
            e.stopPropagation();
          }}
          icon={hamburgerIcon}
          intlTranslate={false}
          variant="icon-only"
        />

        <div className="logo-wrapper">
          <ReactSVG src={logo} wrapper="span" />
        </div>

        {!isCamMessenger || userlist ? (
          <span className="title">
            {title}
            {subtitle && <span className="subtitle">&nbsp;- {subtitle}</span>}
          </span>
        ) : (
          <User />
        )}
      </div>
      {isCamMessenger && (
        <div className="mobile-header-right">
          {userlist && (
            <>
              <Icebreaker />
              <CurrentSales classes="sales" label="SALES_TODAY_SHORT" />
            </>
          )}
          <Button
            type="button"
            className={userlistBtnClasses}
            onClick={(e) => {
              openedUserlist.current = true;
              toggleUserlistView();
              e.stopPropagation();
            }}
            icon={userlistIcon}
            intlTranslate={false}
            variant="icon-only"
          />
        </div>
      )}
      {showLanguageSelect && <LanguageSelect />}
    </div>
  );
}

export default MobileHeader;

