export default {
  AUTHENTICITY_HEADLINE: `Authenticity: The key to a stronger bond`,
  AUTHENTICITY_INTRO: `Today it's all about authenticity. But what exactly is authenticity and what does it mean for you as a creator? Why is it so important and how can you implement it effectively? We want to answer these questions and more here.`,
  AUTHENTICITY_INTRO_1: `In today's digital world, where almost everything is staged and edited, customers are increasingly looking for authenticity and insights into other people's lives, rather than just model measurements and eroticism. So this authenticity can be the key to building a closer and therefore longer bond with your customers.`,
  AUTHENTICITY_HEADLINE_2: `What does being authentic mean?`,
  AUTHENTICITY_TEXT_1: `Being authentic means staying true to yourself. It's about not retouching all flaws and using multiple filters to create a perfect image. Customers appreciate it more when they can recognize you as a real person. Everyone has flaws or areas that they don't like about themselves so much. But if you don't want to show or hide them, these can be cleverly concealed with poses or outfits instead of editing everything with filters.`,
  AUTHENTICITY_TEXT_2: `Authenticity also means that it doesn't always have to be about eroticism. No woman is always in an erotic mood, and it goes down well to talk about hobbies or travel destinations. Customers who enter into a long-term relationship often want to share their everyday life with you and not just consume erotic content. These real and personal interactions make you authentic.`,
  AUTHENTICITY_HEADLINE_3: `Why is authenticity important?`,
  AUTHENTICITY_STEP_1_HEADER: `Building trust`,
  AUTHENTICITY_STEP_1_CONTENT: `Customers want to feel like they really know you. By sharing personal moments and insights from your everyday life, you show that you are genuine and approachable. This creates trust and loyalty.`,
  AUTHENTICITY_STEP_2_HEADER: `Connection on a deeper level`,
  AUTHENTICITY_STEP_2_CONTENT: `Authentic insights make your customers feel like they are part of your life. They not only value your content, but also you as a person.`,
  AUTHENTICITY_STEP_3_HEADER: `Individuality`,
  AUTHENTICITY_STEP_3_CONTENT: `In an industry where a lot of content seems similar, authenticity can make you stand out. It is your individual everyday life and your personality that make you unique.`,
  AUTHENTICITY_HEADLINE_4: `How do I implement authenticity?`,
  AUTHENTICITY_STEP_4_HEADER: `Keep a diary`,
  AUTHENTICITY_STEP_4_CONTENT: `A diary entry in which you share personal information can be a wonderful way to give your customers a real insight. This could be a selfie with your morning coffee cup or a picture of your trip to the lake.`,
  AUTHENTICITY_STEP_5_HEADER: `Create a gallery`,
  AUTHENTICITY_STEP_5_CONTENT: `In addition to professional, staged photos, you could create a gallery with your favorite summer dresses, your latest shopping haul or other things from your everyday life. For example, if you are in your kitchen baking, why not take a few snapshots of yourself and let your customers share in them? That doesn't mean that you shouldn't pay attention to the quality of your photos.`,
  AUTHENTICITY_OUTRO: `In summary, authenticity is a great way to strengthen the relationship with your customers and stand out from the crowd. Your customers will not only be enthusiastic about what you do, but will also identify with you and build an emotional bond. So show yourself as you are, because it makes you distinctive and unique.`,
};
