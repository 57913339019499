import {
  BLOG_TARGETNEWSLETTER_CLEAR,
  BLOG_TARGETNEWSLETTER_SET,
} from '../actions/actions';

const initialState = {
  targetNewsletterId: null,
};

const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case BLOG_TARGETNEWSLETTER_SET:
      return {
        ...state,
        targetNewsletterId: action.payload,
      };
    case BLOG_TARGETNEWSLETTER_CLEAR:
      return {
        ...state,
        targetNewsletterId: null,
      };
    default:
      return state;
  }
};

export default newsletterReducer;
