import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import menuIcon from '../../../assets/img/svg/menu-dots.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Button from '../../Button/Button';
import Select from '../../Forms/Select/Select';

const StreamingOptions = ({
  resolutions,
  cams,
  microphones,
  selectedCam,
  selectedResolution,
  selectedMicrophone,
  disabled,
  reset,
  handleChange,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const menuIconClass = classNames('menu-icon', {
    active: menuVisible,
  });

  return (
    <div className="stream-settings group-container">
      <div className="headline-with-menu form-group">
        <h2 className="headline column">
          <FormattedMessage id="streamingOptions.title" />
        </h2>
        <div className="settings-menu-wrapper">
          <Button
            variant="icon-only"
            onClick={() => {
              if (!menuVisible) {
                setMenuVisible(true);
              }
            }}
            intlTranslate={false}
            icon={menuIcon}
            disabled={
              disabled ||
              (selectedCam.value === '0' &&
                !selectedResolution.value &&
                !selectedMicrophone.value)
            }
            className={menuIconClass}
          />
          {menuVisible && (
            <div className="toggle-menu settings-menu" ref={menuRef}>
              <div
                className="menu-item"
                onClick={() => {
                  reset();
                  setMenuVisible(false);
                }}
              >
                <FormattedMessage id="reset" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="streamingOptions.cam" />
        </label>
        <Select
          options={cams}
          intlTranslate={false}
          disabled={
            disabled ||
            cams.length === 1 ||
            (cams.length === 2 && selectedCam.value !== '0')
          }
          value={selectedCam.value}
          noIsSaved={true}
          onChange={handleChange('checkBandwidth')}
        />
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="streamingOptions.resolution" />
        </label>
        <Select
          options={resolutions}
          intlTranslate={false}
          disabled={disabled || !resolutions.length}
          value={selectedResolution.value}
          onChange={handleChange('changeCamResolution')}
        />
      </div>
      <div className="form-group">
        <label>
          <FormattedMessage id="streamingOptions.mic" />
        </label>
        <Select
          options={microphones}
          intlTranslate={false}
          disabled={disabled || microphones.length === 1}
          value={selectedMicrophone.value}
          onChange={handleChange('changeMicrophone')}
        />
      </div>
    </div>
  );
};

export default StreamingOptions;
