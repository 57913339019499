import classNames from 'classnames';
import { useEffect, useState } from 'react';

import camSVG from '../../../../../../assets/img/svg/video-call.svg';
import { CRITICAL_FPS, WARNING_FPS } from '../../../../../../constants';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { isLivecamCreator } from '../../../../../../utils';
import Button from '../../../../../Button/Button';

function Cams({
  cam,
  toggleCamView,
  hasCam,
  isMobile,
  fps,
  lowFps,
  streaming,
  camInitialized,
  noWebcamFound,
  user,
  cookiesAllowed,
}) {
  const disabled = isMobile && !hasCam && !cam;
  const isLivecam = isLivecamCreator(user) && cookiesAllowed;
  const [warningAlertSent, setWarningAlertSent] = useState(false);
  const [criticalAlertSent, setCriticalAlertSent] = useState(false);
  const isMediumOrMobileScreen = useMediaQuery('(max-width: 1599px)');

  const classes = classNames('control-item cams-icon', {
    'medium-fps-icon':
      isMediumOrMobileScreen && fps !== false && fps < 10 && fps > 5,
    'low-fps-icon': isMediumOrMobileScreen && fps !== false && fps < 5,
  });

  useEffect(() => {
    if (fps && streaming && isMediumOrMobileScreen) {
      if (fps < 10 && fps > 5) {
        if (!warningAlertSent) {
          lowFps(WARNING_FPS);
          setWarningAlertSent(true);
        }
      } else if (fps <= 5) {
        if (!criticalAlertSent) {
          lowFps(CRITICAL_FPS);
          setCriticalAlertSent(true);
        }
      }
    }
  }, [
    criticalAlertSent,
    fps,
    isMediumOrMobileScreen,
    lowFps,
    streaming,
    warningAlertSent,
  ]);

  useEffect(() => {
    if (warningAlertSent || criticalAlertSent) {
      if (warningAlertSent && !streaming) {
        setWarningAlertSent(false);
      }

      if (criticalAlertSent && !streaming) {
        setCriticalAlertSent(false);
      }
    }
  }, [criticalAlertSent, streaming, warningAlertSent]);

  return isLivecam && cookiesAllowed ? (
    <div className={classes}>
      <Button
        icon={camSVG}
        svgClassname={cam ? 'active' : ''}
        onClick={() => {
          camInitialized ? toggleCamView() : noWebcamFound();
        }}
        disabled={disabled}
        intlTranslate={false}
        variant="icon-only"
      />
    </div>
  ) : null;
}

export default Cams;
