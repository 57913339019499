import './Scrollbar.scss';

import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import useWindowResize from '../../hooks/useWindowResize';

function Scrollbar({
  children,
  visible,
  containsHeader,
  isLargeScreen,
  hasBoxShadow,
  isMobile,
  scrollDownOnModification,
  scrollPlacement,
  maxHightScroll,
  dontScrollOnNewMsg,
  scrollToTop = false,
}) {
  const windowResize = useWindowResize();
  const boxRef = useRef(null);
  const barRef = useRef(null);
  const sliderRef = useRef(null);

  const clientHeight = useRef(0);
  const scrollHeight = useRef(0);
  const scrollTopMax = useRef(0);
  const sliderRatio = useRef(0);
  const scrollTop = useRef(0);

  const timer = useRef(null);
  const interval = useRef(null);

  const movingSlider = useRef(null);

  const changeScroll = useCallback(
    (newScrollTop) => {
      scrollTop.current = newScrollTop;
      if (sliderRef.current) {
        sliderRef.current.style.transformOrigin =
          'center ' +
          (clientHeight.current * scrollTop.current) / scrollTopMax.current +
          'px';
      }
      if (scrollPlacement) {
        maxHightScroll(scrollTopMax.current);
        scrollPlacement(scrollTop.current);
      }
    },
    [maxHightScroll, scrollPlacement]
  );

  const changeDimensions = useCallback(
    (newScrollHeight, newClientHeight) => {
      clientHeight.current = newClientHeight;
      scrollHeight.current = newScrollHeight;
      scrollTopMax.current = Math.max(
        scrollHeight.current - clientHeight.current,
        0
      );
      sliderRatio.current = scrollHeight.current
        ? clientHeight.current / scrollHeight.current
        : 1;
      if (sliderRef.current) {
        if (sliderRatio.current === 1) {
          sliderRef.current.style.visibility = 'hidden';
        } else {
          sliderRef.current.style.visibility = 'visible';
          sliderRef.current.style.transform =
            'scaleY(' + sliderRatio.current + ')';
        }
      }
      changeScroll(scrollTop.current);
    },
    [changeScroll]
  );

  const scroll = (deltaY) => {
    if (boxRef?.current) {
      boxRef.current.scrollTop = scrollTop.current + deltaY;
    }
  };

  const clickHold = useCallback((deltaY, limitY) => {
    timer.current = setTimeout(function () {
      interval.current = setInterval(function () {
        if (deltaY > 0 && scrollTop + deltaY > limitY) {
          deltaY = limitY - scrollTop;
        } else if (deltaY < 0 && scrollTop + deltaY < limitY) {
          deltaY = limitY - scrollTop;
        } else if (!deltaY) {
        }
        scroll(deltaY);
      }, 50);
    }, 500);

    const clear = () => {
      clearTimeout(timer.current);
      clearInterval(interval.current);
      window.removeEventListener('mouseup', clear);
    };

    window.addEventListener('mouseup', clear);
  }, []);

  useEffect(() => {
    if (boxRef && boxRef.current && visible) {
      let resizeObserver = new ResizeObserver((entries) => {
        changeDimensions(
          entries[0].target.scrollHeight,
          entries[0].target.clientHeight
        );
      });
      resizeObserver.observe(boxRef.current);
      return () => {
        resizeObserver.disconnect();
        resizeObserver = null;
      };
    }
  }, [boxRef, changeDimensions, visible]);

  useEffect(() => {
    if (boxRef && boxRef.current && visible) {
      let mutationObserver = new MutationObserver((entries) => {
        if (boxRef?.current) {
          changeDimensions(
            boxRef.current.scrollHeight,
            boxRef.current.clientHeight
          );
          if (scrollDownOnModification) {
            if (dontScrollOnNewMsg) {
              return;
            }
            scroll(boxRef.current.scrollHeight);
          }
          if (scrollToTop) {
            boxRef.current.scrollTop = 0;
          }
        }
      });
      mutationObserver.observe(boxRef.current, {
        childList: true,
        subtree: true,
      });
      return () => {
        mutationObserver.disconnect();
        mutationObserver = null;
      };
    }
  }, [
    boxRef,
    changeDimensions,
    visible,
    scrollDownOnModification,
    dontScrollOnNewMsg,
    scrollToTop,
  ]);

  useEffect(() => {
    if (boxRef && boxRef.current && visible) {
      const scrollListener = () => {
        if (!boxRef || !boxRef.current || !visible) {
          return;
        }
        changeScroll(boxRef.current.scrollTop);
      };
      boxRef.current.addEventListener('scroll', scrollListener, {
        passive: true,
      });
      const cleanupBoxRef = boxRef.current;
      return () => {
        cleanupBoxRef?.removeEventListener('scroll', scrollListener, {
          passive: true,
        });
      };
    }
  }, [boxRef, visible, changeScroll]);

  useEffect(() => {
    if (barRef && barRef.current && visible) {
      barRef.current.addEventListener('onclick', (e) => {
        e.preventDefault();
      });
      barRef.current.addEventListener('mousedown', function (e) {
        if (!movingSlider.current) {
          var deltaY =
            clientHeight.current *
            (e.offsetY < scrollTop.current * sliderRatio.current ? -1 : 1);
          scroll(deltaY);
          clickHold(deltaY / 2, e.offsetY / sliderRatio.current);
        }
        return false;
      });
    }
  }, [clickHold, visible]);

  useEffect(() => {
    if (sliderRef && sliderRef.current && visible) {
      sliderRef.current.addEventListener('onclick', (e) => {
        e.preventDefault();
      });
      sliderRef.current.addEventListener('mousedown', function (e) {
        movingSlider.current = true;
        var startY = e.pageY;
        var startScrollPos = scrollTop.current;

        function onmousemove(e) {
          var scrollOffset = (e.pageY - startY) / sliderRatio.current;
          boxRef.current.scrollTop = startScrollPos + scrollOffset;
        }

        function onmouseup() {
          window.removeEventListener('mousemove', onmousemove);
          window.removeEventListener('mouseup', onmouseup);
          movingSlider.current = false;
          return false;
        }

        window.addEventListener('mousemove', onmousemove);
        window.addEventListener('mouseup', onmouseup);
        return false;
      });
    }
  }, [visible]);

  useEffect(() => {
    if (boxRef && boxRef.current && visible) {
      function onWindowResize() {
        changeDimensions(
          boxRef.current.scrollHeight,
          boxRef.current.clientHeight
        );
      }

      onWindowResize();
    }
  }, [changeDimensions, visible, windowResize]);

  const onsScrollClass = classNames('ons-scroll', {
    'has-box-shadow': hasBoxShadow,
    'contains-header': containsHeader && isLargeScreen,
  });

  return (
    <>
      {visible && !isMobile ? (
        <div className={onsScrollClass}>
          <div className="box" ref={boxRef}>
            {children}
          </div>
          <div className="scroll-overlay">
            <span className="bar" ref={barRef}>
              <span className="slider" ref={sliderRef} />
            </span>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default Scrollbar;
