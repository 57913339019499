export default {
  VIDEOS_INFO_1: `<span class="bold">Create a new gallery</span><br />You can start directly with the "Create new gallery" button. To upload a video, you can simply drag and drop it into the white area with a blue border. Or you click the + to select a file from your computer or smartphone.`,
  VIDEOS_INFO_2: `<span class="bold">Add or delete videos</span><br />You can add only one video in mp4, avi, mpg, or wmv format to a gallery. You can also delete unchecked videos.`,

  VIDEO_GALLERY_FORMATS: `.mp4, .mov, .movie, .qt, .avi or .wmv`,
  VIDEO_GALLERY_REQUIREMENTS_LIST: `<ul>
    <li>a video</li>
    <ul>
      <li>in {videoFormats}</li>
      <li>minimum duration is 1 minute </li>
      <li>maximum file size is 5 GB</li>
    </ul>
    <li>Gallery title</li>
    <li>a short description</li>
    <li>a preview image</li>
    <li>at least 1 category</li>
    <li>Actors' document(s)</li>
  </ul>`,

  VIDEO_PRICE_ADDITIONAL_LABEL_1: `50 Coins are €5.00`,
  VIDEO_PRICE_ADDITIONAL_LABEL_2: `Minimum: 1, Max: 500 Coins`,
  VIDEO_COINS_PER_VIDEO: `per video`,
  COINS_PER_VIDEO: `Coins per video`,
  VIDEO_GALLERY_MENU_ADD_VIDEO: `Add a video`,
  VIDEO_DELETE_ERROR: `There was an error while deleting your video. Please try again.`,

  IS_CONVERTING: `The video is converting`,

  REMAINING_TIME_MINUTES: `{time} minutes remaining`,
  REMAINING_TIME_SECONDS: `{time} seconds remaining`,
  IS_UPLOADING: `The video is uploading`,
  ERROR_FETCHING_CONVERSION: `There has been an error in fetching the conversion status.`,
  ERROR_CONVERTING_VIDEO: `There was an error converting the video. Please delete it and try again.`,
};

