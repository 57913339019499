import React from 'react';
import { FormattedMessage } from 'react-intl';

import BackButton from '../../../../BackButton';

function Authenticity({ onBackButtonClicked }) {
  return (
    <div className="wiki-content wiki-settings-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="AUTHENTICITY_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="AUTHENTICITY_INTRO" />
      </p>
      <p className="has-margin-bottom">
        <FormattedMessage id="AUTHENTICITY_INTRO_1" />
      </p>

      <h3 className="headline">
        <FormattedMessage id="AUTHENTICITY_HEADLINE_2" />
      </h3>
      <p>
        <FormattedMessage id="AUTHENTICITY_TEXT_1" />
      </p>
      <p className="has-margin-bottom">
        <FormattedMessage id="AUTHENTICITY_TEXT_2" />
      </p>

      <h3 className="headline">
        <FormattedMessage id="AUTHENTICITY_HEADLINE_3" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="AUTHENTICITY_STEP_1_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="AUTHENTICITY_STEP_1_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="AUTHENTICITY_STEP_2_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="AUTHENTICITY_STEP_2_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="AUTHENTICITY_STEP_3_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="AUTHENTICITY_STEP_3_CONTENT" />
          </p>
        </div>
      </div>

      <h3 className="headline">
        <FormattedMessage id="AUTHENTICITY_HEADLINE_4" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="AUTHENTICITY_STEP_4_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="AUTHENTICITY_STEP_4_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="AUTHENTICITY_STEP_5_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="AUTHENTICITY_STEP_5_CONTENT" />
          </p>
        </div>
      </div>

      <p>
        <FormattedMessage id="AUTHENTICITY_OUTRO" />
      </p>
    </div>
  );
}
export default Authenticity;
