import './Streaming.scss';

import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ResizableBox } from 'react-resizable';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import useWindowResize from '../../../../hooks/useWindowResize';
import Cam from '../../../Livecam/Cam';
import Cam2Cam from '../../../Livecam/Cam/Cam2Cam';
import ControlPanel from '../../../Livecam/ControlPanel';
import Useraudio from '../../../Livecam/Useraudio';
import MuteButton from './MuteButton';

function Streaming({
  cam,
  userinfo,
  running,
  mediumStreamingTabWidth,
  camInitialized,
  setCamView,
  resolutionValue,
  updateLargeScreenStreamingWindow,
  camResizing,
  sideBar,
  usergame,
}) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );
  const windowResize = useWindowResize();
  const [toggled, setToggled] = useState(false);
  const [streamingStyle, setStreamingStyle] = useState({});
  const streamingTabRef = useRef();
  const videoRef = useRef();
  const contentCamRef = useRef();
  const cam2CamRef = useRef();
  const resolutionsArray = resolutionValue.split('x');
  const resolutionHeight = parseInt(resolutionsArray[1], 10);
  const [minConstraint, setMinConstraint] = useState(
    resolutionHeight < 280 ? resolutionHeight : 280
  );
  const [maxConstraint, setMaxConstraint] = useState(
    resolutionHeight < 480 ? resolutionHeight : 480
  );
  const [initialCall, setInitialCall] = useState(false);
  const [height, setHeight] = useState(minConstraint);

  useEffect(() => {
    if (!toggled) {
      if (camInitialized && cam) {
        setToggled(true);
      } else if (!camInitialized && cam) {
        setCamView(false);
      } else if (camInitialized && !cam && !(isMediumScreen && usergame)) {
        setCamView(true);
        setToggled(true);
      }
    }
  }, [cam, camInitialized, toggled, isMediumScreen, usergame, setCamView]);

  useEffect(() => {
    if (
      isLargeScreen &&
      streamingTabRef.current?.offsetHeight &&
      videoRef.current?.offsetHeight
    ) {
      const timeout = setTimeout(() => {
        if (streamingTabRef?.current) {
          const resolution = resolutionValue.split('x');
          const is4x3 = parseInt(resolution[0]) / parseInt(resolution[1]) < 1.5;
          let height;
          if (is4x3) {
            height = parseInt(
              (streamingTabRef.current.offsetWidth * 3) / 4 + 60,
              10
            );
          } else {
            height = parseInt(
              (streamingTabRef.current.offsetWidth * 9) / 16 + 60,
              10
            );
          }
          setStreamingStyle({ height });
          updateLargeScreenStreamingWindow({ height, width: 0 });
        }
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [
    isLargeScreen,
    resolutionValue,
    updateLargeScreenStreamingWindow,
    videoRef.current?.offsetHeight,
    streamingTabRef.current?.offsetHeight,
    sideBar,
    initialCall,
  ]);
  const onResize = (event, { element, size }) => {
    const height = parseInt(size.height, 10);
    setHeight(height);
    setStreamingStyle({ height });
  };

  useEffect(() => {
    if (!running) {
      setMinConstraint(resolutionHeight < 280 ? resolutionHeight : 280);
      setMaxConstraint(resolutionHeight < 480 ? resolutionHeight : 480);
      if (contentCamRef?.current?.style) {
        contentCamRef.current.style.height = null;
        contentCamRef.current.style.maxWidth = null;
      }
    }
  }, [resolutionHeight, resolutionValue, running]);

  const measuredContentRef = useCallback(
    (node) => {
      if (!isMediumScreen || !node || !running) return;
      const resolution = resolutionValue.split('x');
      const is4x3 = parseInt(resolution[0]) / parseInt(resolution[1]) < 1.5;
      const maxWidth = (node.clientWidth - 20) * 0.7;
      const minWidth = (node.clientWidth - 20) * 0.5;
      let newMax;
      let newMin;
      if (is4x3) {
        newMax = (maxWidth * 3) / 4 + 20;
        newMin = (minWidth * 3) / 4 + 20;
      } else {
        newMax = (maxWidth * 9) / 16 + 20;
        newMin = (minWidth * 9) / 16 + 20;
      }
      setMaxConstraint(newMax);
      setMinConstraint(newMin);
      if (height > newMax) {
        setHeight(newMax);
        setStreamingStyle({ newMax });
      } else if (height < newMin) {
        setHeight(newMin);
        setStreamingStyle({ newMin });
      }
    },
    [height, isMediumScreen, resolutionValue, running]
  );

  useEffect(() => {
    const element = document.getElementById('content-resizable');
    measuredContentRef(element);
  }, [measuredContentRef, windowResize]);

  const onVideoLoaded = () => {
    setInitialCall(true);
  };
  if (!cam) return null;

  const contentClass = classNames('content', {
    'has-cam2cam': running && !userinfo && !isLargeScreen,
  });

  const contentCamClass = classNames('content-cam', {
    'has-cam2cam': running && !userinfo && !isLargeScreen,
  });

  const largeScreenContentClass = classNames('content', {
    'is-large-screen': isLargeScreen,
  });
  return (
    <div className="streaming" style={streamingStyle} ref={streamingTabRef}>
      {isLargeScreen ? (
        <>
          <ControlPanel />
          <div className={largeScreenContentClass}>
            <div className="content-inner">
              <Cam
                enableOverlay={true}
                videoRef={videoRef}
                onVideoLoaded={onVideoLoaded}
              />
              <Useraudio />
            </div>
          </div>
        </>
      ) : (
        <ResizableBox
          minConstraints={[Infinity, minConstraint]}
          maxConstraints={[Infinity, maxConstraint]}
          height={height}
          width={mediumStreamingTabWidth}
          axis="y"
          onResize={onResize}
          onResizeStart={() => camResizing(true)}
          onResizeStop={() =>
            setTimeout(() => {
              camResizing(false);
            }, 100)
          }
        >
          <div
            className={contentClass}
            ref={measuredContentRef}
            id="content-resizable"
          >
            <>
              <div className={contentCamClass} ref={contentCamRef}>
                <Cam enableOverlay={true} videoRef={videoRef}>
                  {!isMediumScreen && <MuteButton />}
                </Cam>
                <Useraudio />
              </div>
              {running && !userinfo && (
                <div className="cam2cam" ref={cam2CamRef}>
                  <Cam2Cam />
                </div>
              )}
            </>
          </div>
        </ResizableBox>
      )}
    </div>
  );
}

export default Streaming;
