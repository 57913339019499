import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// import sortAZIcon from '../../../../../assets/img/svg/sort-a-z.svg';
// import sortZAIcon from '../../../../../assets/img/svg/sort-z-a.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../../constants';
import { spinnerRequest } from '../../../../../shared/spinnerRequest';
import axiosInstance from '../../../../../utils/api';
import api from '../../../../../utils/api';
import { addPortals } from '../../../../../utils/portals';
import Button from '../../../../Button/Button';
import Pagination from '../../../../Pagination/Pagination';
import ThumbnailItem from '../../../../ThumbnailItem';

function FriendRequests({
  spinner,
  askAnswerFriendRequestConfirmed,
  askAnswerFriendRequestConfirmedAlert,
  askAnswerFriendRequestId,
  askAnswerFriendRequestType,
  askAnswerFriendRequestNickname,
  answerFriendRequest,
  friendsError,
  updateListFriendRequest,
}) {
  const [items, setItems] = useState([]);
  // const [friendRequestsSearch, setFriendRequestsSearch] = useState('');
  // const [sortingDirection, setSortingDirection] = useState('asc');
  const initialItems = useRef(null);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [
    { loading: friendsDataLoading, error: friendsDataError },
    friendsDataRequest,
  ] = useAxios(
    {
      url: `/user/friends/requested?o=${offset}&l=${PAGINATION_PAGE_SIZE}`,
    },
    { manual: true }
  );

  const getFriendsData = useCallback(() => {
    spinnerRequest({
      request: friendsDataRequest,
      spinner,
    })
      .then(async (response) => {
        updateListFriendRequest(response.data);
        setTotalItems(response.data?.total);
        const data = await addPortals([...response.data?.data]);
        const sortedItems = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        initialItems.current = [...sortedItems];
        setItems([...sortedItems]);
      })
      .catch(() => {});
  }, [friendsDataRequest, spinner, updateListFriendRequest]);

  useEffect(() => {
    getFriendsData();
  }, [getFriendsData, pageNumber, offset]);

  const updateList = useCallback(
    (id) => {
      if (!id) return;
      //check if the last item on a page was deleted
      if (totalItems - offset === 1 && pageNumber > 1) {
        setOffset((prevState) => prevState - PAGINATION_PAGE_SIZE);
        setPageNumber((prevState) => prevState - 1);
        return;
      }
      if (offset >= totalItems) {
        setOffset(0);
        setPageNumber(1);
        return;
      }
      getFriendsData();
    },
    [getFriendsData, offset, pageNumber, totalItems]
  );

  useEffect(() => {
    let isMounted = true;

    if (
      askAnswerFriendRequestConfirmed &&
      askAnswerFriendRequestConfirmedAlert
    ) {
      // Confirmation for accepting/denying a friend request

      if (askAnswerFriendRequestType === 'denied') {
        axiosInstance
          .delete(`/user/friends/${askAnswerFriendRequestId}`)
          .then((response) => {
            if (isMounted) {
              answerFriendRequest(
                true,
                askAnswerFriendRequestId,
                askAnswerFriendRequestNickname,
                askAnswerFriendRequestType
              );
              updateList(askAnswerFriendRequestId);
              console.log(response);
            }
          })
          .catch((error) => {
            if (isMounted) {
              answerFriendRequest(
                true,
                askAnswerFriendRequestId,
                askAnswerFriendRequestNickname
              );
              if (error.response.status === 409) {
                updateList(askAnswerFriendRequestId);
                friendsError();
              }
              console.log(error);
            }
          });
      } else {
        axiosInstance
          .put(`/user/friends/${askAnswerFriendRequestId}`)
          .then((response) => {
            if (isMounted) {
              answerFriendRequest(
                true,
                askAnswerFriendRequestId,
                askAnswerFriendRequestNickname,
                askAnswerFriendRequestType
              );
              updateList(askAnswerFriendRequestId);
              console.log(response);
            }
          })
          .catch((error) => {
            if (isMounted) {
              answerFriendRequest(
                true,
                askAnswerFriendRequestId,
                askAnswerFriendRequestNickname
              );
              if (error.response.status === 409) {
                updateList(askAnswerFriendRequestId);
                friendsError();
              }
              console.log(error);
            }
          });
      }
    }
  }, [
    answerFriendRequest,
    askAnswerFriendRequestConfirmed,
    askAnswerFriendRequestConfirmedAlert,
    askAnswerFriendRequestId,
    askAnswerFriendRequestNickname,
    askAnswerFriendRequestType,
    friendsError,
    updateList,
  ]);

  const acceptAllReq = async () => {
    if (items.length <= 0) {
      return;
    }
    const itemIds = items.map((i) => i.id);
    try {
      await api.put('/user/friends/multiple', itemIds);
      if (offset !== 0 && pageNumber !== 1) {
        setOffset((prevState) => prevState - PAGINATION_PAGE_SIZE);
        setPageNumber((prevState) => prevState - 1);
      }
      getFriendsData();
    } catch (err) {
      console.log({ err });
    }
  };
  const deleteAllReq = async () => {
    if (items.length <= 0) {
      return;
    }
    const itemIds = items.map((i) => i.id);
    try {
      await api.delete('/user/friends/multiple', { data: itemIds });
      if (offset !== 0 && pageNumber !== 1) {
        setOffset((prevState) => prevState - PAGINATION_PAGE_SIZE);
        setPageNumber((prevState) => prevState - 1);
      }
      getFriendsData();
    } catch (err) {
      console.log({ err });
    }
  };

  // SEARCH TO BE ADDED BACK LATER

  // const searchFriendsHandler = (value) => {
  //   const filteredItems = initialItems.current.filter((item) => {
  //     if (value === '') {
  //       return true;
  //     }
  //     return item.nickname.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setItems(filteredItems);
  //   setFriendRequestsSearch(value);
  // };

  // const clearSearchInput = () => {
  //   setFriendRequestsSearch('');
  //   searchFriendsHandler('');
  // };

  // const sortFriends = () => {
  //   const sortedItems = [...initialItems.current];
  //   if (sortingDirection === 'desc') {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname > b.nickname) {
  //         return -1;
  //       } else if (b.nickname > a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('asc');
  //   } else {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname < b.nickname) {
  //         return -1;
  //       } else if (b.nickname < a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('desc');
  //   }
  //   setItems(sortedItems);
  //   initialItems.current = [...sortedItems];
  // };

  return !!initialItems.current?.length ? (
    <>
      {/* <SearchInput
          id={'search_friends'}
          name={'search_friends'}
          placeholder={`${intl.formatMessage({
            id: 'SEARCH',
          })}`}
          value={friendRequestsSearch}
          className="search-friends-input"
          onChange={(event) => searchFriendsHandler(event.target.value)}
          clearInput={clearSearchInput}
        />
        <ReactSVG
          onClick={() => sortFriends()}
          src={sortingDirection === 'asc' ? sortAZIcon : sortZAIcon}
          className={'sort-icon'}
          wrapper="span"
        /> */}
      <div className="button-wrapper">
        <Button
          onClick={acceptAllReq}
          variant="primary"
          label="RELATIONSHIP_ITEM_ACCEPT_ALL"
        />
        <Button
          onClick={deleteAllReq}
          variant="dark"
          label="RELATIONSHIP_ITEM_DELETE_ALL"
        />
      </div>

      <div className="image-grid">
        {items.map((item, index) => (
          <ThumbnailItem
            item={item}
            type="relationship"
            key={`${item}-${index}`}
            relationshipType="friends"
            relationshipMenuType="friend-requests"
            updateList={updateList}
          />
        ))}
      </div>
      <Pagination
        offset={offset}
        totalItems={totalItems}
        pageNumber={pageNumber}
        setOffset={setOffset}
        setPageNumber={setPageNumber}
      />
    </>
  ) : !friendsDataLoading ? (
    !friendsDataError ? (
      <p>
        <FormattedMessage id="RELATIONSHIP_LABEL_NO_REQUESTS" />
      </p>
    ) : (
      <div className="no-items error-message">
        <FormattedMessage id="RELATIONSHIP_LABEL_ERROR_REQUESTS" />
      </div>
    )
  ) : null;
}

export default FriendRequests;
