import landingpage from './landingpage';

export default {
  ...landingpage,

  SIGNUP_TEXT_HEADLINE: `Verdiene bis zu 32% als CREATOR`,
  SIGNUP_TEXT_CONTENT_1: `Sei dabei! Werde als Creator ein fester Teil unserer Communities und verdiene bis zu 32% Provision! LiveCreator bietet die besten Verdienstmöglichkeiten und höchste Provisionen in den führenden Social Adult Communities.`,
  SIGNUP_TEXT_CONTENT_2: `Bring Dich ein, gewähre täglich tausend neuen Bezahl-User intime Einblicke in Dein Leben, chatte mit Ihnen, teile Gedanken, Fantasien, Bilder und Videos von Dir oder triff Dich live im Camchat.`,
  SIGNUP_TEXT_CONTENT_3: `Als Creator bieten sich Dir in unseren Communities viele Möglichkeiten und Chancen. Du entscheidest was Du bereit bist zu teilen und was Du damit verdienen möchtest. Ergänze und nutze Deine Social-Media Reichweite und gewähre als Creator bei uns noch intimere Einblicke als auf anderen Plattformen.`,
  SIGNUP_TEXT_CONTENT_4: `Profitiere von über 60000 Werbepartnern, die täglich unsere Communities bewerben und setze auf die volle Unterstützung durch unseren Support.`,
  SIGNUP_TEXT_CONTENT_5: `Starte noch heute als Creator und sichere Dir Deinen Platz in unseren Communities!`,

  SECTION_SIGNUP_TITLE: `Werde jetzt Creator`,
  SECTION_SIGNUP_HEADLINE: `Registriere dich als Creator in unseren Communities`,
  SECTION_SIGNUP_LOGIN_HINT: `Hast Du schon einen Account?`,
  SECTION_SIGNUP_LOGIN: `Login`,
  SIGNUP_TERMS_1: `Mit Absenden der Registrierung bestätigst und akzeptierst Du unsere `,
  SIGNUP_TERMS_2: `AGB`,
  SIGNUP_TERMS_3: `und`,
  SIGNUP_TERMS_4: `Datenschutzrichtlinien`,

  BUTTON_SIGN_UP: `Als Creator registrieren`,
  PLACEHOLDER_EMAIL: `Gebe hier Deine E-Mail-Adresse ein`,
  PLACEHOLDER_PASSWORD: `mindestens 6 Zeichen`,
  FIELD_CHOOSE_PASSWORD: `Wähle ein Passwort`,

  ACCOUNT_LOGIN: `LiveCreator Login`,
  ACCOUNT_LIVECREATOR_SET_PASSWORD: `LiveCreator Passwort festlegen`,
  LOGIN_HEADLINE: `Gib hier Deine Logindaten ein`,

  QUESTION_ACCOUNT: `Du hast noch keinen Account?`,
  ACCOUNT_SIGN_UP: `Jetzt registrieren`,
  BUTTON_LOGIN: `Login`,
  FIELD_STAY_LOGGED_IN: `eingeloggt bleiben`,
  ACCOUNT_FORGOT_PASSWORD: `Hast Du Dein Passwort vergessen?`,
  RESET_PASSWORD_LABEL: `Passwort zurücksetzen`,
  ACCOUNT_SET_PASSWORD: `Passwort festlegen`,
  SET_PASSWORD_LABEL: `Passwort festlegen`,

  SOCIAL_VISIT: `Besuche uns bei:`,

  CHANGE_NICKNAME_TITLE: 'Bitte ändere Deinen Benutzernamen',
  CHANGE_NICKNAME_TEXT:
    "Ein Benutzername darf nicht mit 'sender-' beginnen. Bitte wähle einen anderen Benutzernamen.",
  CHANGE_NICKNAME_NEW: 'Mein neuer Benutzernamen:',
  CHANGE_NICKNAME_CHANGE: 'Benutzernamen ändern',
  NICKNAME_CHANGER_ALTERNATIVE: 'Alternativen:',
};
