import { useEffect } from 'react';

import { lStorage } from '../services/storage';

let resizeTimer;

function useResize({ resize }) {
  useEffect(() => {
    const hasSidebar = lStorage.getItem('sidebar');
    if (hasSidebar) {
      resize(true, { sidebar: JSON.parse(hasSidebar) });
    } else {
      resize(true);
    }

    const resizeHandler = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(resize, 200);
    };

    window.removeEventListener('resize', resizeHandler); // make sure to avoid many handlers
    window.removeEventListener('orientationchange', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);
    window.addEventListener('resize', resizeHandler);

    return () => clearTimeout(resizeTimer);
  }, [resize]);
}

export default useResize;
