import './Documents.scss';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import { uuid } from '../../../utils';
import { getUpload } from '../../../utils/uploadUtils';
import StandaloneErrorWrapper from '../../Forms/FieldErrorWrapper/StandaloneErrorWrapper/StandaloneErrorWrapper';
import Scrollbar from '../../Scrollbar';
import DocumentsTable from '../../Tables/DocumentsTable';
import Uploader from '../../Uploader';
import MyAccountFileApi from './MyAccountFileApi';

function Documents({
  updateDocuments = () => {},
  isMobile,
  user = {},
  openOverlay = () => {},
  uploads = [],
  uploadFile = () => {},
  spinner = () => {},
}) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const [documentsError, setDocumentsError] = useState(null);
  const indicator = 'documents';

  const { uploadIndicator, uploadError, uploadResult } = getUpload(
    uploads,
    indicator
  );

  const [, privateDataRequest] = useAxios(
    {
      url: '/user/private',
    },
    { manual: true }
  );

  useEffect(() => {
    let isMounted = true;
    spinnerRequest({
      request: privateDataRequest,
      spinner,
    })
      .then((response) => {
        isMounted && updateDocuments(response.data.documents);
      })
      .catch(() => {});
    return () => (isMounted = false);
  }, [privateDataRequest, spinner, updateDocuments]);

  const openCam = () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        indicator,
        camstamp: stamp,
        uploadPath: '/user/documents',
        title: intl.formatMessage({ id: 'TAKE_SNAPSHOT' }),
        minScreenshotWidth: 1600,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        // Button inside the Cam is now the same as in the Onboarding, but the outsideFormik prop is still
        // needed since the regular one contains a wrapper connected to formik
        outsideFormik: true,
      },
    });
  };

  const openDocument = (title, fileSource) => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Document',
      props: {
        openDocumentStamp: stamp,
        title: title,
        fileSource: fileSource,
      },
    });
  };

  useEffect(() => {
    if (uploadIndicator === indicator) {
      if (uploadError) {
        setDocumentsError(uploadError);
        uploadFile('', null, indicator);
      } else if (uploadResult && uploadResult?.data) {
        setDocumentsError(null);
        updateDocuments(uploadResult.data);
        uploadFile('', null, indicator);
      }
    }
  }, [
    uploadError,
    uploadResult,
    uploadIndicator,
    uploadFile,
    updateDocuments,
    setDocumentsError,
  ]);

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-account-wrapper documents-data-wrapper">
        <Scrollbar visible={isLargeScreen}>
          <div className="data-wrapper">
            <h1 className="headline">
              <FormattedMessage id="UPLOAD_DOCUMENTS" />
            </h1>
            <div className="data-info-wrapper">
              <p>
                <FormattedMessage id="DOCUMENTS_TEXT_1" />
              </p>
              <p>
                <FormattedMessage id="DOCUMENTS_TEXT_2" />
              </p>
              <p>
                <FormattedMessage id="DOCUMENTS_TEXT_3" />
              </p>
              <p className="bold">
                <FormattedMessage id="DOCUMENTS_TEXT_4" />
              </p>
              <p>
                <FormattedMessage id="UPLOAD_DOCUMENTS_CONTENT_1">
                  {(message) => (
                    <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                  )}
                </FormattedMessage>
              </p>
            </div>
            <div className="uploader-wrapper">
              <StandaloneErrorWrapper
                name="documents"
                error={
                  documentsError
                    ? documentsError?.response?.status === 400
                      ? intl.formatMessage({
                          id: 'ERROR_UPLOAD_DOCUMENT_1',
                        })
                      : intl.formatMessage({
                          id: 'ERROR_UPLOAD_DOCUMENT_2',
                        })
                    : null
                }
                noGrid={true}
              >
                <Uploader
                  uploadPath="/user/documents"
                  indicator={indicator}
                  label="DOCUMENT_UPLOADER_LABEL"
                  acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png', '.pdf']}
                  openCam={openCam}
                  isMobile={isMobile}
                  handleError={setDocumentsError}
                  className="documents"
                >
                  {user.private.documents
                    .filter(
                      (document) =>
                        document.state === 'pending' &&
                        document.type === 'amateur'
                    )
                    .map((f, idx) => (
                      <MyAccountFileApi key={idx} file={f} />
                    ))}
                </Uploader>
              </StandaloneErrorWrapper>
            </div>
          </div>
        </Scrollbar>
        <Scrollbar visible={isLargeScreen} hasBoxShadow={true}>
          <div className="info-wrapper">
            <div className="table-box">
              <h2 className="headline">
                <FormattedMessage id="DOCUMENTS_EXISTS" />
              </h2>
              <div className="feedback-table">
                <DocumentsTable
                  openDocument={openDocument}
                  items={user.private.documents.filter(
                    (document) => document.state !== 'pending'
                  )}
                  spinner={spinner}
                />
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Documents;
