import {
  ALERT_CLOSE,
  SOUND_PLAY_CONFIRM,
  SOUND_QUEUED_REMOVE,
} from '../actions/actions';
import {
  BEAM,
  BEEP,
  BLUBBER,
  CAMERA,
  CARNEVAL,
  CHORD,
  CYMBAL,
  GLAS,
  JUNGLE,
  LOGIN,
  POLICEPASS,
  RING1,
  RING2,
  RING3,
  RING4,
  SCRATCH,
  TERMINAL,
  TOCK,
  TOCK2,
  TWEET,
  USER_ENTERS_SHOW_COMMUNITY,
  USER_ENTERS_SHOW_DATING,
} from '../constants/sound';

const initialState = {
  delayedSound: null,
  available: [
    BEEP,
    BEAM,
    BLUBBER,
    CAMERA,
    CARNEVAL,
    CHORD,
    CYMBAL,
    GLAS,
    JUNGLE,
    LOGIN,
    POLICEPASS,
    RING1,
    RING2,
    RING3,
    RING4,
    SCRATCH,
    TERMINAL,
    TOCK,
    TOCK2,
    TWEET,
  ],
  userEntersShow: {
    de: {
      ac: USER_ENTERS_SHOW_COMMUNITY,
      dating: USER_ENTERS_SHOW_DATING,
    },
    en: {
      ac: USER_ENTERS_SHOW_COMMUNITY,
      dating: USER_ENTERS_SHOW_DATING,
    },
  },
};

const locales = (state = initialState, action) => {
  switch (action.type) {
    case SOUND_PLAY_CONFIRM:
      return {
        ...state,
        delayedSound: {
          stamp: action.meta.stamp,
          sound: action.payload.sound,
          play: false,
        },
      };
    case ALERT_CLOSE:
      if (state.delayedSound) {
        return {
          ...state,
          delayedSound: { ...state.delayedSound, play: true },
        };
      }
      return state;
    case SOUND_QUEUED_REMOVE:
      return { ...state, delayedSound: null };
    default:
      return state;
  }
};

export default locales;
