import useAxios from 'axios-hooks';
import { useCallback } from 'react';

const useSendTwoFactorMail = () => {
  const [
    { loading: isDataLoading, error: dataRequestError },
    twoFactorSendMail,
  ] = useAxios(
    {
      url: '/user/two-factor/mail',
      method: 'PUT',
    },
    { manual: true }
  );

  const sendTwoFactorMail = useCallback(
    ({ userId, spinner = () => {} }) => {
      if (!userId) return false;

      let id = userId.replace(/^[^-@]*[-@]/, '');

      spinner(true);

      const payload = {
        userId: id,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      };

      return twoFactorSendMail({ data: payload })
        .then(() => true)
        .catch(() => false)
        .finally(() => spinner(false));
    },
    [twoFactorSendMail]
  );

  return {
    sendTwoFactorMail,
    isDataLoading,
    dataRequestError,
  };
};

export default useSendTwoFactorMail;

