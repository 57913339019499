export default {
  ROUTE_STATISTICS: 'Meine Statistik',
  ROUTE_MY_TOP_CUSTOMERS: 'Eine Übersicht Deiner Kunden',
  ROUTE_TOP_CUSTOMERS_SUBTITLE: 'Top Kunden',
  HEADLINE_MY_BEST_CUSTOMERS: 'Meine Top Kunden im ',

  ROUTE_SALES_SUBTITLE: 'Verkaufsstatistik',
  ROUTE_CUSTOMERS_SUBTITLE: 'Kunden',
  HEADLINE_SALES: 'Eine Übersicht Deiner Verkäufe',
  SALES_TABLE_HEADER: '{articleGroupLabel} im {monthLabel} {year}',

  ROUTE_REFERRED_CUSTOMERS_SUBTITLE: 'Geworbene Kunden',
  ROUTE_MY_REFERRED_CUSTOMERS: 'Eine Übersicht Deiner geworbenen Kunden',
  HEADLINE_MY_REFERRED_CUSTOMERS: 'Meine geworbenen Kunden',

  TABLE_TITLE_NO: 'Nr.',
  TABLE_TITLE_CUSTOMER: 'Kunde',
  TABLE_TITLE_PORTAL: 'Portal',
  TABLE_TITLE_COUNTRY: 'Land',
  TABLE_TITLE_LAST_ON: 'zuletzt am',
  TABLE_TITLE_TURNOVER: 'Umsatz',
  TABLE_LABEL_YEAR: 'Jahr',
  TABLE_LABEL_MONTH: 'Monat',
  TABLE_LABEL_ARTICLE_GROUPS: 'Artikel',
  TABLE_TITLE_TIMESTAMP: 'Zeitpunkt',
  TABLE_TITLE_ZIP: 'PLZ',

  ARTICLE_GROUP_ALL_ARTICLES: 'Alle Artikel',
  ARTICLE_GROUP_MESSAGES: 'Nachrichten',
  ARTICLE_GROUP_LIVECAMS: 'Livecams',
  ARTICLE_GROUP_MEDIA: 'Medien',
  ARTICLE_GROUP_OTHER: 'andere',

  CUSTOMERS_TOTAL_PERIOD: 'Gesamtzeitraum',

  STATS_INFO_HEADER: 'Info zu dieser Statistik',
  STATS_PERFORMANCE:
    'Aus Performancegründen wird die Anzeige dieser Rubrik für 5 Minuten zwischengespeichert.',
  STATS_SALES:
    'Dies ist eine Übersicht über Deine Verkäufe bei LiveCreator. Du kannst Dir einen bestimmten Monat oder ein ganzes Jahr anzeigen lassen. Um die stündliche Übersicht eines Tages anzuzeigen, klicke einfach auf den Balken dieses Tages oder wähle das Datum im Filter oben aus.',
  STATS_TOP_CUSTOMERS:
    'Dies ist eine Übersicht der Top-25-Kunden, die am meisten Coins für Dich ausgegeben haben. Du kannst Dir nur einen bestimmten Monat anzeigen lassen oder Deine gesamte Zeit bei LiveCreator.',

  STATS_GAME:
    'Du findest hier Deine letzten 30 Spiele. Schau Dir noch einmal an, wie Du ein Spiel gewonnen oder warum Du ein Match verloren hast.',
  STATS_GAME_1:
    'Natürlich siehst Du auch Deine verdienten Coins und kannst Deinen Spielpartner direkt kontaktieren.',

  STATS_REFERRED_CUSTOMERS:
    'Dies ist eine Übersicht der geworbenen Kunden. Es werden maximal die letzten 500 Kunden angezeigt.',
};

