import {
  NO_EXISTING_CONVERSATION,
  NO_EXISTING_CONVERSATION_GAME_TIMEOUT,
} from '../../../constants';
import { TYPE_ACNG, TYPE_AC_EU } from '../../../constants/user';
import store from '../../../store';
import { getUnixTimestamp, user as userUtils } from '../../../utils';
import api from '../../../utils/api';
import { errorLog, loadingVoicemessage, management } from '../../';
import { MESSAGE_CHAT_SEND } from '../../actions';
import logout from '../../user/logout';
import { toggleChatMenu, typingSender } from '../..';
export default (message, all, payttachmentId, sonusApiResult = null) =>
  async (dispatch, getState) => {
    if (typeof message !== 'string' && typeof message !== 'object') {
      return;
    }

    const resendId = typeof message === 'object' && message.id;

    const body = typeof message !== 'object' && message.trim();
    let headers = {};
    let loadPayload;
    if (
      body === '' &&
      !payttachmentId &&
      !sonusApiResult &&
      typeof message !== 'object'
    ) {
      return;
    }

    const {
      user: {
        account: { nickname: sendername },
      },
      dialog: { dialogs },
      camUser: { camUsers },
      chat: { selectedUser: selectedCamUserId, chatMenuOpen, dialogmessages },
      attachments: { selectedAttachment },
      payttachments: { files: allPayttachments },
    } = getState();

    if (chatMenuOpen) {
      dispatch(toggleChatMenu());
    }

    const call = async function (
      payload,
      user,
      sendername,
      selectedCamUserId,
      headers = {}
    ) {
      if (isLivecamUser && !user.color) {
        const camUser = camUsers.find((u) => u.userId === selectedCamUserId);
        if (!user.partner && userUtils.hasPartner(camUser)) {
          user.partner = userUtils.getPartner(camUser);
        }
        user.color = camUser?.color;
      }
      try {
        loadPayload = JSON.parse(JSON.stringify(payload));

        if (dialogmessages.length > 0) {
          loadPayload.id = dialogmessages[dialogmessages.length - 1].id + 0.01;
        } else {
          loadPayload.id = 0.01;
        }
        const now = new Date();
        loadPayload.time = getUnixTimestamp(now);
        loadPayload.payload.resendId = resendId;

        if (sonusApiResult) {
          loadPayload.payload.voiceData = sonusApiResult;
        }

        if (loadPayload.payload?.type === 'payttachment') {
          const payttachment = allPayttachments.find(
            (p) =>
              p.id === payttachmentId ||
              (!payttachmentId && p.id === message.payttachment_id)
          );

          loadPayload.payload = {
            ...loadPayload.payload,
            payttachment: {
              ...payttachment,
            },
          };
        }
        dispatch({
          type: MESSAGE_CHAT_SEND,
          payload: loadPayload,
          meta: {
            loading: true,
            user,
            senderName: sendername,
            selectedCamUserId,
          },
        });

        const response = await api.post('/message', payload, { headers });

        payload.id = response?.data[user.partner]?.id;
        payload.time = response?.data[user.partner]?.time;
        payload.sender = response?.data[user.partner]?.sender;

        if (response?.data[selectedUser]?.attachment) {
          payload.attachment = response?.data[selectedUser]?.attachment;
        }

        if (response?.data[selectedUser]?.payload?.type === 'payttachment') {
          const payttachment = allPayttachments.find(
            (p) =>
              p.id === payttachmentId ||
              (!payttachmentId && p.id === message.payttachment_id)
          );

          payload.payload = {
            ...payload.payload,
            payttachment: {
              ...payttachment,
            },
          };
        }

        if (sonusApiResult?.id) {
          payload.payload.voiceData = sonusApiResult;
        }

        payload.payload.tempId = loadPayload.id;

        dispatch({
          type: MESSAGE_CHAT_SEND,
          payload,
          meta: {
            user,
            senderName: sendername,
            selectedCamUserId,
          },
        });

        dispatch(loadingVoicemessage(false));

        selectedCamUserId && dispatch(typingSender(selectedCamUserId, ''));
      } catch (error) {
        selectedCamUserId && dispatch(typingSender(selectedCamUserId, ''));

        let errorPayload = error?.message || error;

        if (error?.response?.data?.xxx) {
          errorPayload = error.response.data.xxx[user.userId]?.error;
        }

        if (error?.response?.data[user.userId]?.error) {
          errorPayload = error.response.data[user.userId]?.error;
        }

        if (error?.response?.data?.errors && error?.response?.data?.errors[0]) {
          errorPayload = error?.response?.data?.errors[0];
        }

        if (errorPayload === NO_EXISTING_CONVERSATION) {
          const chatMessagesCopy = JSON.parse(
            JSON.stringify(
              isLivecamUser
                ? getState().chat.messages
                : getState().chat.dialogmessages
            )
          );

          const lastMessageCopy = chatMessagesCopy.pop();

          if (
            lastMessageCopy?.payload?.type === 'game.invitationTimeout' &&
            !chatMessagesCopy.find((m) => !m.payload.ibid)
          ) {
            errorPayload = NO_EXISTING_CONVERSATION_GAME_TIMEOUT;
          }
        }
        dispatch({
          type: MESSAGE_CHAT_SEND,
          error: true,
          id: loadPayload.id,
          message: loadPayload,
          payload: errorPayload,
          meta: {
            user,
            senderName: sendername,
          },
        });
        dispatch(loadingVoicemessage(false));
        if (error?.response?.status === 410) {
          store.dispatch(logout());
        }
        const errorObject = {
          location: 'chatMessage',
          error,
        };

        dispatch(errorLog(errorObject));

        console.log({ postMessageError: error });
      }
    };

    let selectedUser = selectedCamUserId;

    const isLivecamUser = !selectedCamUserId.match(/@/g);
    if (all && isLivecamUser) {
      const visibleUser = userUtils.visibleUser(camUsers);

      for (let i = 0; i < visibleUser.length; i++) {
        const user = visibleUser[i];
        if (
          [TYPE_AC_EU, TYPE_ACNG].includes(user.type) &&
          userUtils.hasPartner(user)
        ) {
          const partner = userUtils.getPartner(user);
          const payload = {
            recipients: [partner],
            body,
            payload: {
              type: 'message',
              lc_id: true,
            },
          };
          user.partner = partner;
          call(payload, user, sendername, user.userId);
        } else if (userUtils.isDatingUser(user)) {
          dispatch(management.sendMessage(body, user.userId));
        }
      }
      return;
    }
    if (isLivecamUser) {
      const activeCamUser = camUsers.find(
        (u) => u.userId === selectedCamUserId
      );
      if (!activeCamUser || !userUtils.hasPartner(activeCamUser)) {
        return; //@todo log this or stay silent?
      }
      selectedUser = userUtils.getPartner(activeCamUser);
    }

    const user = dialogs.find((d) => d.userId === selectedUser) || {};

    const payload = message?.recipients
      ? message
      : {
          recipients: [selectedUser],
          body,
          payload: {
            type: 'message',
          },
        };

    if (sonusApiResult?.id) {
      payload.payload.type = 'voice';
      payload.payload.id = sonusApiResult.id;
      const sonusToken = getState().user?.sonusToken?.token;
      if (sonusToken) {
        headers = { 'X-Token': sonusToken };
      }
    }

    if (isLivecamUser) {
      payload.payload.lc_id = true;
    }

    if (payttachmentId) {
      payload.payttachment_id = payttachmentId;
      payload.payload = {
        type: 'payttachment',
      };
    } else if (!isNaN(selectedAttachment) && typeof message !== 'object') {
      payload.attachment_id = parseInt(selectedAttachment, 10);
    }

    if (payload.time) {
      payload.time = null;
    }

    call(payload, user, sendername, selectedCamUserId, headers);
  };
