import {
  ALERT_CANCEL,
  ALERT_OK,
  FRIEND_DELETE,
  FRIEND_FRIEND_REQUEST_ANSWER,
  FRIEND_FRIEND_REQUEST_REMOVE_DENIED,
  FRIEND_FRIEND_REQUEST_SEND,
  FRIEND_FRIEND_REQUEST_UPDATE_LIST,
  FRIEND_IDS_ADD,
  FRIEND_OWN_REQUEST_UPDATE_LIST,
} from '../actions/actions';

const initialState = {
  ids: [],
  requests: [],
  ownRequests: [],
  askSendFriendRequestConfirmed: undefined,
  askSendFriendRequestConfirmedAlert: undefined,
  askSendFriendRequestId: undefined,
  askAnswerFriendRequestConfirmed: undefined,
  askAnswerFriendRequestConfirmedAlert: undefined,
  askAnswerFriendRequestId: undefined,
  askAnswerFriendRequestType: undefined,
  askAnswerFriendRequestNickname: undefined,
  askDeleteFriendConfirmed: undefined,
  askDeleteFriendConfirmedAlert: undefined,
  askDeleteFriendId: undefined,
  askRemoveDeniedFriendRequestConfirmed: undefined,
  askRemoveDeniedFriendRequestConfirmedAlert: undefined,
  askRemoveDeniedFriendRequestId: undefined,
  askRemoveDeniedFriendRequestNickname: undefined,
  total: 0,
};

function friendsReducer(state = initialState, action) {
  switch (action.type) {
    case FRIEND_IDS_ADD:
      return {
        ...state,
        ids: action.payload,
      };
    case FRIEND_FRIEND_REQUEST_UPDATE_LIST:
      return {
        ...state,
        requests: [...action.payload.data],
        total: action.payload.total ? action.payload.total : 0,
      };
    case FRIEND_OWN_REQUEST_UPDATE_LIST:
      return {
        ...state,
        ownRequests: [...action.payload],
      };

    case FRIEND_FRIEND_REQUEST_SEND:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          askSendFriendRequestConfirmedAlert: action.meta.stamp,
          askSendFriendRequestId: action.payload.clientProductId
            ? `${action.payload.clientProductId}@${action.payload.clientCustomerId}`
            : action.payload.userId,
        });
      } else {
        return Object.assign({}, state, {
          askSendFriendRequestConfirmed: undefined,
          askSendFriendRequestConfirmedAlert: undefined,
          askSendFriendRequestId: undefined,
        });
      }

    case FRIEND_FRIEND_REQUEST_ANSWER:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          askAnswerFriendRequestConfirmedAlert: action.meta.stamp,
          askAnswerFriendRequestId: action.payload.userId,
          askAnswerFriendRequestNickname: action.payload.nickname,
        });
      } else {
        return Object.assign({}, state, {
          askAnswerFriendRequestConfirmed: undefined,
          askAnswerFriendRequestConfirmedAlert: undefined,
          askAnswerFriendRequestId: undefined,
          askAnswerFriendRequestType: undefined,
          askAnswerFriendRequestNickname: undefined,
        });
      }

    case FRIEND_DELETE:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          askDeleteFriendConfirmedAlert: action.meta.stamp,
          askDeleteFriendId: action.payload.id,
        });
      } else {
        return Object.assign({}, state, {
          askDeleteFriendConfirmed: undefined,
          askDeleteFriendConfirmedAlert: undefined,
          askDeleteFriendId: undefined,
        });
      }

    case FRIEND_FRIEND_REQUEST_REMOVE_DENIED:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          askRemoveDeniedFriendRequestConfirmedAlert: action.meta.stamp,
          askRemoveDeniedFriendRequestId: action.payload.id,
          askRemoveDeniedFriendRequestNickname: action.payload.name,
        });
      } else {
        return Object.assign({}, state, {
          askRemoveDeniedFriendRequestConfirmed: undefined,
          askRemoveDeniedFriendRequestConfirmedAlert: undefined,
          askRemoveDeniedFriendRequestId: undefined,
          askRemoveDeniedFriendRequestNickname: undefined,
        });
      }

    case ALERT_OK: {
      switch (action.payload.stamp) {
        case state.askSendFriendRequestConfirmedAlert:
          return Object.assign({}, state, {
            askSendFriendRequestConfirmed: true,
          });
        case state.askAnswerFriendRequestConfirmedAlert:
          return Object.assign({}, state, {
            askAnswerFriendRequestConfirmed: true,
            askAnswerFriendRequestType: 'confirmed',
            total: state.total - 1 >= 0 ? state.total - 1 : 0,
          });
        case state.askDeleteFriendConfirmedAlert:
          return Object.assign({}, state, {
            askDeleteFriendConfirmed: true,
          });
        case state.askRemoveDeniedFriendRequestConfirmedAlert:
          return Object.assign({}, state, {
            askRemoveDeniedFriendRequestConfirmed: true,
          });
        default:
          return state;
      }
    }

    case ALERT_CANCEL: {
      switch (action.payload.stamp) {
        case state.askSendFriendRequestConfirmedAlert:
          return Object.assign({}, state, {
            askSendFriendRequestConfirmedAlert: undefined,
            askSendFriendRequestConfirmed: undefined,
          });
        case state.askAnswerFriendRequestConfirmedAlert:
          return Object.assign({}, state, {
            askAnswerFriendRequestConfirmed: true,
            askAnswerFriendRequestType: 'denied',
            total: state.total - 1 >= 0 ? state.total - 1 : 0,
          });
        case state.askDeleteFriendConfirmedAlert:
          return Object.assign({}, state, {
            askDeleteFriendConfirmed: undefined,
            askDeleteFriendConfirmedAlert: undefined,
            askDeleteFriendId: undefined,
          });
        case state.askRemoveDeniedFriendRequestConfirmedAlert:
          return Object.assign({}, state, {
            askRemoveDeniedFriendRequestConfirmed: undefined,
            askRemoveDeniedFriendRequestConfirmedAlert: undefined,
            askRemoveDeniedFriendRequestId: undefined,
            askRemoveDeniedFriendRequestNickname: undefined,
          });
        default:
          return state;
      }
    }
    default:
      return state;
  }
}

export default friendsReducer;

