import { connect } from 'react-redux';

import { closeOverlay, spinner } from '../../../actions';
import addAuthToken from '../../../actions/user/addAuthToken';
import TwoFactorLogin from './TwoFactorLogin';

const mapDispatchToProps = {
  addAuthToken,
  closeOverlay,
  spinner,
};

export default connect(null, mapDispatchToProps)(TwoFactorLogin);

