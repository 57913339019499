import './AccountSecurity.scss';

import useAxios from 'axios-hooks';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import imgApp from '../../../../assets/img/2fa/app.png';
import imgEmail from '../../../../assets/img/2fa/email.png';
import useSendTwoFactorMail from '../../../../utils/twoFactorUtils';
import Button from '../../../Button/Button';
import CopyInput from '../../../Forms/CopyInput/CopyInput';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../Forms/Input/Input';
import Select from '../../../Forms/Select/Select';
import TwoFactorInput from '../../TwoFactorInput/index';

function AccountSecurity({
  spinner = () => {},
  user,
  updateTwoFactor = () => {},
}) {
  const intl = useIntl();
  const twoFactorStatus = user?.account?.two_factor?.two_factor === 'true';
  const method = user?.account?.two_factor?.method;
  const verified = user?.account?.two_factor?.verified;
  const [value, setValue] = useState(verified && method);
  const [qrCode, setQrCode] = useState();
  const [backupCodes, setBackupCodes] = useState();
  const [error, setError] = useState();
  const [mailSend, setMailSend] = useState(false);
  const userId = user?.account?.id;
  const [useBackupCode, setUseBackupCode] = useState(false);
  const { sendTwoFactorMail } = useSendTwoFactorMail();

  const [isVisible, setIsVisible] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      spinner(false);
      isMounted.current = false;
    };
  }, [spinner]);

  const showMessage = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    let timer;
    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [isVisible]);

  const methods = [
    { value: 'default', label: 'OPTIONS_TWO_FACTOR_DEFAULT' },
    { value: 'totp', label: 'OPTIONS_TWO_FACTOR_APP' },
    { value: 'email', label: 'OPTIONS_TWO_FACTOR_EMAIL' },
  ];

  const [, twoFactorMethodRequest] = useAxios(
    {
      url: '/user/two-factor/setup-method',
      method: 'PUT',
    },
    { manual: true }
  );

  const [, twoFactorDeactivateRequest] = useAxios(
    {
      url: '/user/two-factor/deactivate',
      method: 'PUT',
    },
    { manual: true }
  );

  const [, twoFactorVerifyRequest] = useAxios(
    {
      url: '/user/two-factor/verify',
      method: 'PUT',
    },
    { manual: true }
  );

  const func = (two_factor) => {
    if (two_factor?.two_factor === 'false' && two_factor?.method) {
      return verifyHandler;
    } else if (two_factor?.two_factor === 'true' && two_factor?.verified) {
      return deactivate;
    }
  };

  function methodHandler(e) {
    if (e === 'default') {
      setQrCode(null);
      setMailSend(false);
      setValue(null);
      setUseBackupCode(false);
    }
    spinner(true);
    setValue(e);
    if (e === 'totp' || e === 'email') {
      const payload = {
        two_factor_method: e,
        code: '',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      };
      twoFactorMethodRequest({
        data: payload,
      })
        .then((response) => {
          if (isMounted.current) {
            setMailSend(false);
            setQrCode(null);
            if (e === 'totp') {
              setQrCode(response.data.qrCode);
            }
            const payload = {
              two_factor: 'false',
              method: e,
              verified: false,
            };
            updateTwoFactor(payload);
            setError(null);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            setError(error.response?.data?.errors);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            spinner(false);
          }
        });
    } else {
      spinner(false);
    }
  }

  function sendMail() {
    sendTwoFactorMail({ userId, spinner });
    setMailSend(true);
    showMessage();
  }

  function verifyHandler(code) {
    if (!code) return;

    const payload = {
      code: code,
      two_factor_method: value,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    spinner(true);
    twoFactorVerifyRequest({
      data: payload,
    })
      .then((response) => {
        if (isMounted.current) {
          setMailSend(false);
          setQrCode(null);
          setBackupCodes(response.data.backup_codes.join('\n'));
          const payload = {
            method: value,
            two_factor: 'true',
            verified: true,
          };
          updateTwoFactor(payload);
          setError(null);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setError(error.response.data?.errors);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          spinner(false);
        }
      });
  }

  function deactivate(code) {
    if (!code) return;

    const payload = {
      code: code,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    spinner(true);
    twoFactorDeactivateRequest({
      data: payload,
    })
      .then(() => {
        if (isMounted.current) {
          const payload = {
            method: null,
            two_factor: 'false',
            verified: null,
          };
          updateTwoFactor(payload);
          setValue('default');
          setBackupCodes(null);
          setError(null);
          setMailSend(false);
          setUseBackupCode(false);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setError(error.response.data?.errors);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          spinner(false);
        }
      });
  }
  return (
    <div className="security-wrapper data-columns">
      <div className="data-left">
        <h1 className="headline">
          <FormattedMessage id="TWO_FACTOR_HEADLINE" />
        </h1>
        <FieldWithErrors
          name="methods"
          as={Select}
          disabled={twoFactorStatus}
          options={methods}
          onChange={(e) => {
            methodHandler(e.target.value);
          }}
          value={value}
          label="OPTIONS_TWO_FACTOR_METHOD"
        />
        {value === 'email' && (
          <>
            <FieldWithErrors
              as={Input}
              name={'email'}
              label="FIELD_EMAIL"
              disabled={true}
            />

            {mailSend
              ? isVisible && (
                  <div className="form-group">
                    <span className="column"></span>
                    <div className="column2 success-wrapper small">
                      <FormattedMessage id="TWO_FACTOR_EMAIL_SEND" />
                    </div>
                  </div>
                )
              : !backupCodes && (
                  <div className="form-group">
                    <span className="column"></span>
                    <Button
                      className="btn-form"
                      type="button"
                      label={'TWO_FACTOR_SEND_MAIL'}
                      onClick={() => sendMail()}
                    />
                  </div>
                )}

            {mailSend && (
              <div className="form-group">
                <span className="column"></span>

                <p className="column2 text-small">
                  <FormattedMessage id="TWO_FACTOR_RESEND" />
                  <br />
                  <a onClick={() => sendMail()}>
                    <FormattedMessage id="TWO_FACTOR_RESEND_CLICK" />
                  </a>
                </p>
              </div>
            )}
          </>
        )}
      </div>

      <div className="data-right">
        {qrCode && (
          <>
            <h2 className="headline">
              <FormattedMessage id="TWO_FACTOR_HOW_TO" />
            </h2>
            <div className="list-container">
              <div className="list-item">
                <span className="number">1</span>
                <FormattedMessage id="TWO_FACTOR_QR_TEXT_1">
                  {(message) => (
                    <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                  )}
                </FormattedMessage>
              </div>
              <div className="list-item">
                <span className="number">2</span>
                <span>
                  <FormattedMessage id="TWO_FACTOR_QR_TEXT_2" />
                </span>
              </div>
              <img className="qr-code" alt="qr-code" src={qrCode} />
              <div className="list-item">
                <span className="number">3</span>
                <span>
                  <FormattedMessage id="TWO_FACTOR_QR_TEXT_3" />
                </span>
              </div>
              <div className="list-item">
                <span className="number">4</span>
                <span>
                  <FormattedMessage id="TWO_FACTOR_QR_TEXT_4" />
                </span>
              </div>
            </div>
          </>
        )}
        {backupCodes && (
          <div className="backup">
            <div className="success-wrapper">
              <div className="success-title">
                <FormattedMessage id="TWO_FACTOR_SUCCESS_TITLE" />
              </div>
              <hr />
              <div className="success-content">
                <FormattedMessage id="TWO_FACTOR_SUCCESS_CONTENT" />
              </div>
            </div>
            <h2 className="headline">
              <FormattedMessage id="TWO_FACTOR_BACKUP_HEADER" />
            </h2>
            <p>
              <FormattedMessage id="TWO_FACTOR_BACKUP_TEXT" />
            </p>
            <CopyInput list={true} value={backupCodes} />
          </div>
        )}
        {user.account.two_factor?.method !== null &&
        value &&
        value !== 'default' &&
        !backupCodes ? (
          <>
            <TwoFactorInput
              label={
                twoFactorStatus && verified
                  ? 'TWO_FACTOR_RESET_BUTTON'
                  : 'TWO_FACTOR_ACTIVATE'
              }
              buttonClick={func(user.account.two_factor)}
              error={error}
              setError={setError}
              noBackupCodeBelow={true}
              verified={verified}
              useBackupCode={useBackupCode}
              setUseBackupCode={setUseBackupCode}
            />
          </>
        ) : (
          !backupCodes && (
            <div className="methods">
              <h2 className="headline">
                <FormattedMessage id="TWO_FACTOR_METHODS" />
              </h2>
              <div className="item">
                <img className="img" src={imgEmail} alt="Img" />
                <div>
                  <p className="bold">
                    {intl.formatMessage({ id: 'TWO_FACTOR_METHOD_EMAIL' })}
                  </p>
                  <p>
                    {intl.formatMessage({ id: 'TWO_FACTOR_METHOD_EMAIL_TEXT' })}
                  </p>
                </div>
              </div>
              <div className="item">
                <img className="img" src={imgApp} alt="Img" />
                <div>
                  <p className="bold">
                    {intl.formatMessage({ id: 'TWO_FACTOR_METHOD_APP' })}
                  </p>
                  <p>
                    {intl.formatMessage({ id: 'TWO_FACTOR_METHOD_APP_TEXT' })}
                  </p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default AccountSecurity;

