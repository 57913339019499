import './MyAccount.scss';

import useAxios from 'axios-hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { getMyAccountRoutes, routeFunctionsMap } from '../../../routes';
import { isLivecamCreator } from '../../../utils';
import HeaderMenu from '../../HeaderMenu/HeaderMenu';
import Section from '../../Section/Section';
import StatusItem from '../../Tables/TableItems/StatusItem/StatusItem';
import MyAccountContext from './MyAccountContext';

function MyAccount({
  user,
  thirdPartyCookiesTested,
  thirdPartyCookiesAllowed,
}) {
  const intl = useIntl();
  const [items, setItems] = useState(null);
  const [pathname, setPathname] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('');
  const [tempUserState, setTempUserState] = useState({ documents: [] });
  const history = useHistory();
  const isLivecam = isLivecamCreator(user);

  const contextValue = useMemo(() => {
    return { tempUserState, setTempUserState };
  }, [tempUserState]);

  const [, parkingCreditsRequest] = useAxios(
    {
      url: '/user/parking-credits',
    },
    { manual: true }
  );

  const [, payoutsRequest] = useAxios(
    {
      url: '/user/bills/payouts',
    },
    { manual: true }
  );

  useEffect(() => {
    if (!user?.account?.slave) {
      const promise1 = parkingCreditsRequest();
      const promise2 = payoutsRequest();
      Promise.all([promise1, promise2])
        .then((response) => {
          if (response[1]?.data?.payouts?.length) {
            try {
              localStorage.setItem('payoutsSublink', 1);
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              localStorage.setItem('payoutsSublink', 0);
            } catch (error) {
              console.log(error);
            }
          }
          if (response[0]?.data?.bills?.length) {
            try {
              localStorage.setItem('parkingCreditsSublink', 1);
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              localStorage.setItem('parkingCreditsSublink', 0);
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [parkingCreditsRequest, payoutsRequest, user?.account?.slave]);

  useEffect(() => {
    if (history.location.pathname === '/my-account') {
      history.push('/my-account/dashboard');
    }
  }, [
    history,
    history.location.pathname,
    parkingCreditsRequest,
    payoutsRequest,
    user?.account?.slave,
  ]);

  useEffect(() => {
    const items = getMyAccountRoutes().filter((item) => !item.notInMenu);
    const pathname = `/${history.location.pathname.split('/')[2]}`;
    const routesFunction = routeFunctionsMap.get(pathname);
    const headerItems = routesFunction
      ? routesFunction(
          user?.account?.slave,
          parseInt(localStorage.getItem('parkingCreditsSublink')),
          parseInt(localStorage.getItem('payoutsSublink')),
          isLivecam && thirdPartyCookiesTested && thirdPartyCookiesAllowed,
          user?.account?.toy_control === 'enabled' &&
            user?.account?.livecam === 'true'
        )?.filter((item) => !item.notInMenu)
      : null;

    const routes = items
      ? items.map((route, i) => {
          if (history.location.pathname.includes(route.path)) {
            if (
              history.location.pathname.includes('toycontrol') &&
              route.path === '/settings'
            ) {
              setHeaderTitle(
                intl.formatMessage({ id: 'ROUTE_SETTINGS_TOYCONTROL_HEADLINE' })
              );
            } else {
              setHeaderTitle(intl.formatMessage({ id: route.headerTitle }));
            }
          }
          const Component = route.component;
          const componentRoutesFunction = routeFunctionsMap.get(route.path);

          return (
            <Route
              key={`/my-account${route.path}`}
              path={`/my-account${route.path}`}
              component={() => (
                <Component
                  items={
                    componentRoutesFunction
                      ? componentRoutesFunction(
                          user?.account?.slave,
                          parseInt(
                            localStorage.getItem('parkingCreditsSublink')
                          ),
                          parseInt(localStorage.getItem('payoutsSublink')),
                          isLivecam &&
                            thirdPartyCookiesTested &&
                            thirdPartyCookiesAllowed
                        )
                      : null
                  }
                />
              )}
            />
          );
        })
      : null;

    routes.push(
      <Redirect key="redirect-to/dashboard" to="/my-account/dashboard" />
    );
    setItems(headerItems);
    setPathname(pathname);
    setRoutes(routes);
  }, [
    history.location.pathname,
    intl,
    user?.account?.slave,
    isLivecam,
    thirdPartyCookiesAllowed,
    thirdPartyCookiesTested,
    user?.account?.toy_control,
    user?.account?.livecam,
  ]);

  return (
    <div className="page-wrapper">
      <Section
        title={headerTitle}
        rightTitle={
          history.location.pathname === '/my-account/dashboard' ? (
            <StatusItem
              data={
                user?.account?.state === 'unconfirmed'
                  ? intl.formatMessage({ id: 'LABEL_UNCONFIRMED' })
                  : intl.formatMessage({ id: 'LABEL_CONFIRMED' })
              }
              status={user?.account?.state}
            />
          ) : null
        }
        className="component-section"
      >
        <>
          {items ? (
            <HeaderMenu
              className="header-menu-wrapper"
              defaultPath={`/my-account${pathname}`}
              items={items}
            />
          ) : null}
          <div
            className={
              items ? 'component-wrapper' : 'component-wrapper-no-header-menu'
            }
          >
            <MyAccountContext.Provider value={contextValue}>
              <Switch>{routes}</Switch>
            </MyAccountContext.Provider>
          </div>
        </>
      </Section>
    </div>
  );
}

export default MyAccount;
