import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { ReactSVG } from 'react-svg';

import arrowIcon from '../../../assets/img/svg/arrow-down.svg';
import sendIcon from '../../../assets/img/svg/send.svg';
import BackButton from '../../BackButton';
import Button from '../../Button/Button';
import Input from '../../Forms/Input/Input';
import StandaloneFieldWithErrors from '../../Forms/StandaloneFieldWithErrors/StandaloneFieldWithErrors';

function Support({
  setSupportForm,
  supportForm,
  isMobile,
  user,
  lang,
  spinner,
  setHideZoom,
}) {
  const intl = useIntl();
  const { category, subject, subSubject } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(category || '');
  const [currentSubject, setCurrentSubject] = useState(subject || '');
  const [currentSubSubject, setCurrentSubSubject] = useState(subSubject || '');
  const [solution, setSolution] = useState(null);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [support, setSupport] = useState(null);

  const [textArea, setTextArea] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const hasSupportRun = useRef(false);

  const getCategoryClassName = (category) => {
    return classNames('category', {
      active: currentCategory?.id === category.id,
    });
  };

  useEffect(() => {
    setCurrentCategory(category || '');
    setCurrentSubject(subject || '');
    setCurrentSubSubject(subSubject || '');
    setSupportForm(null);
  }, [category, subject, subSubject, setSupportForm]);

  const [, supportReq] = useAxios(
    {
      url: `/support/get`,
      method: 'POST',
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        lang: lang,
        status: status,
      },
    },
    {
      manual: true,
    }
  );

  const [, supportFormSubmit] = useAxios(
    {
      url: `/support/submit/`,
      data: {
        userId: user?.account?.id.replace(/^\d+[@-]/, ''),
        lang: lang,
        category: currentCategory?.id,
        subject: currentSubject?.id,
        subSubject: currentSubSubject?.id,
        name: `${user?.private?.forename} ${user.private?.surname}`,
        phone: user?.private?.phone,
        email: user?.account?.email,
        text: textArea,
      },

      method: 'POST',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (user?.isLoggedIn) {
      setStatus(null);
      if (user?.account?.slave) {
        setStatus('studio');
      }
    } else {
      setStatus('guest');
    }
  }, [setStatus, user?.account?.slave, user?.isLoggedIn]);

  useEffect(() => {
    let isMounted = true;
    spinner(true);

    supportReq()
      .then((response) => {
        isMounted && setSupport(response.data);
      })
      .catch((error) => {
        isMounted && console.log(error);
      })
      .finally(() => {
        isMounted && spinner(false);
      });

    return () => (isMounted = false);
  }, [supportReq, spinner]);

  useEffect(() => {
    if (support && !hasSupportRun.current) {
      const filterSupport = (type, id, setFn, setSolutionFn) => {
        if (id !== '' && typeof id !== 'object') {
          const item = support[type].find((item) => item.id === Number(id));
          setFn(item);
          if (item?.solution !== null) {
            setSolutionFn(item);
          } else {
            setSolutionFn('');
          }
        } else if (type === 'categories' && typeof id !== 'object') {
          setCurrentCategory(support.categories[0]);
        }
      };

      filterSupport(
        'categories',
        currentCategory,
        setCurrentCategory,
        () => {}
      );
      filterSupport('subjects', currentSubject, setCurrentSubject, setSolution);
      filterSupport(
        'subSubjects',
        currentSubSubject,
        setCurrentSubSubject,
        setSolution
      );
      hasSupportRun.current = false;
    }
  }, [support, currentCategory, currentSubject, currentSubSubject]);

  function createSupportPath(category, subject, subSubject) {
    const basePath = '/support';

    if (!category) {
      history.push(basePath);
      return;
    }

    let path = `${basePath}/${category.id}`;

    if (subject) {
      path += `/${subject.id}`;
      if (subSubject) {
        path += `/${subSubject.id}`;
      }
    }

    history.push(path);
  }

  function ClickCategory(category) {
    setCurrentCategory(category.id === currentCategory?.id ? null : category);
    setCurrentSubject(null);
    setCurrentSubSubject(null);
    setSubmitResponse(null);
    createSupportPath(category);
  }

  function ClickSubject(subject) {
    if (subject.solution === null) {
      setCurrentSubject(subject.id === currentSubject?.id ? null : subject);
      setCurrentSubSubject(null);
    } else {
      setCurrentSubject(subject);
      setSolution(subject);
      setHideZoom(true);
    }
    createSupportPath(currentCategory, subject);
  }

  function clickSubSubject(subSubject) {
    setCurrentSubSubject(subSubject);
    setSolution(subSubject);
    setHideZoom(true);
    createSupportPath(currentCategory, currentSubject, subSubject);
  }

  function clear(data) {
    setSolution(null);
    setHideZoom(null);
    setSupportForm(null);
    setTextArea('');
    if (data === 'full') {
      setCurrentCategory(support['categories'][0]);
      setCurrentSubject(null);
      setCurrentSubSubject(null);
      createSupportPath();
    } else {
      createSupportPath(currentCategory, currentSubject);
    }
  }

  function TextAreaHandler(value) {
    setTextArea(value);
    setErrorMessage(null);
  }

  function Submit() {
    if (textArea !== '') {
      spinner(true);
      supportFormSubmit()
        .then(() => {
          setSubmitResponse(true);
        })
        .catch((error) => {
          console.log(error);
          setSubmitResponse(false);
        })
        .finally(() => {
          clear('full');
          spinner(false);
        });
    } else {
      setErrorMessage(intl.formatMessage({ id: 'SUPPORT_ERROR_MESSAGE' }));
    }
  }

  return (
    <div className="info-wrapper">
      {submitResponse !== null && (
        <>
          <div
            className={
              'support-form-message ' + (submitResponse ? 'success' : 'error')
            }
          >
            <span>
              {submitResponse
                ? intl.formatMessage({ id: 'SUPPORT_SUCCESS' })
                : intl.formatMessage({ id: 'SUPPORT_ERROR' })}
            </span>
          </div>
        </>
      )}
      {!supportForm && (
        <h2 className="headline">
          <FormattedMessage id={'SUPPORT_INTRO'} />
        </h2>
      )}

      {support && !solution ? (
        <div className="categories-box">
          {support['categories'].map((category, index) => (
            <React.Fragment key={index}>
              <div
                className={getCategoryClassName(category)}
                onClick={() => ClickCategory(category)}
              >
                {category.lang}
                <ReactSVG
                  src={arrowIcon}
                  wrapper="span"
                  className={
                    'icon-arrow ' +
                    (currentCategory?.id === category.id && 'active')
                  }
                />
              </div>

              <div
                className={
                  'subjects-container ' +
                  (currentCategory?.id === category.id && 'active')
                }
              >
                {support['subjects']
                  .filter((item) => item.category_id === category.id)
                  .map((subject, index) => (
                    <React.Fragment key={index}>
                      <div
                        className="subject"
                        onClick={() => ClickSubject(subject)}
                      >
                        {subject.lang}
                        {support['subSubjects'].some(
                          (item) => item.parent_subject_id === subject.id
                        ) && (
                          <ReactSVG
                            src={arrowIcon}
                            wrapper="span"
                            className={
                              'icon-arrow ' +
                              (currentSubject?.id === subject.id
                                ? 'active'
                                : '')
                            }
                          />
                        )}
                      </div>

                      <div
                        className={
                          'sub-subjects-container ' +
                          (subject.id === currentSubject?.id ? 'active' : '')
                        }
                      >
                        {support['subSubjects']
                          .filter(
                            (item) => item.parent_subject_id === subject.id
                          )
                          .map((subSubject, index) => (
                            <div
                              className="sub-subject"
                              onClick={() => clickSubSubject(subSubject)}
                              key={index}
                            >
                              {subSubject.lang}
                            </div>
                          ))}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className="solution-container">
          {!solution || (isMobile && supportForm) ? null : (
            <div className="solution-box">
              <div className="question">
                {
                  <BackButton
                    onClick={() => {
                      clear();
                    }}
                  />
                }
                {solution?.lang}
              </div>
              <div className="answer">
                <div dangerouslySetInnerHTML={{ __html: solution?.solution }} />
              </div>
            </div>
          )}

          {solution && !supportForm && (
            <div className="button-wrapper">
              <h3 className="headline">
                <FormattedMessage id="TUTORIAL_SUBJECT_TEXT" />
              </h3>
              <Button
                label="TUTORIAL_YES_BUTTON"
                onClick={() => {
                  clear('full');
                }}
              />
              {user?.isLoggedIn && (
                <Button
                  label="TUTORIAL_NO_BUTTON"
                  onClick={() => setSupportForm(true)}
                />
              )}
            </div>
          )}
          {supportForm && (
            <>
              <h2 className="headline form">
                {isMobile && supportForm && (
                  <BackButton
                    onClick={() => {
                      clear();
                    }}
                  />
                )}
                <FormattedMessage id="SUPPORT_FORM_HEADLINE" />
              </h2>

              <form className="support-contact-form">
                <StandaloneFieldWithErrors
                  name={'contact_support_category'}
                  label="CONTACT_SUPPORT_CATEGORY"
                >
                  <Input
                    id={'contact_support_category'}
                    name={'contact_support_category'}
                    value={currentCategory?.lang}
                    readOnly="readOnly"
                  />
                </StandaloneFieldWithErrors>
                <StandaloneFieldWithErrors
                  name={'contact_support_subject'}
                  label="CONTACT_SUPPORT_SUBJECT"
                >
                  <Input
                    id={'contact_support_subject'}
                    name={'contact_support_subject'}
                    value={solution?.lang}
                    readOnly="readOnly"
                  />
                </StandaloneFieldWithErrors>
                <StandaloneFieldWithErrors
                  name={'contact_support_message'}
                  label={'CONTACT_SUPPORT_MESSAGE'}
                  error={errorMessage ? errorMessage : null}
                >
                  <textarea
                    id={'contact_support_message'}
                    name={'contact_support_message'}
                    maxLength={500}
                    value={textArea}
                    onChange={(event) => TextAreaHandler(event.target.value)}
                  />
                </StandaloneFieldWithErrors>
                <div className="form-group">
                  <span className="column"></span>
                  <Button
                    icon={sendIcon}
                    label={'CONTACT_SUPPORT_BUTTON'}
                    id="submit-btn"
                    className="btn-form"
                    onClick={() => Submit()}
                  />
                </div>
              </form>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Support;
