import beam from '../assets/audio/beam.mp3';
import beep from '../assets/audio/beep.mp3';
import blubber from '../assets/audio/blubber.mp3';
import buzz from '../assets/audio/buzz.mp3';
import camera from '../assets/audio/camera.mp3';
import carneval from '../assets/audio/carneval.mp3';
import chord from '../assets/audio/chord.mp3';
import cymbal from '../assets/audio/cymbal.mp3';
import glas from '../assets/audio/glas.mp3';
import jungle from '../assets/audio/jungle.mp3';
import login from '../assets/audio/login.mp3';
import policepass from '../assets/audio/policepass.mp3';
import ring1 from '../assets/audio/ring1.mp3';
import ring2 from '../assets/audio/ring2.mp3';
import ring3 from '../assets/audio/ring3.mp3';
import ring4 from '../assets/audio/ring4.mp3';
import scratch from '../assets/audio/scratch.mp3';
import terminal from '../assets/audio/terminal.mp3';
import tock from '../assets/audio/tock.mp3';
import tock2 from '../assets/audio/tock2.mp3';
import tweet from '../assets/audio/tweet.mp3';
import community from '../assets/audio/userEntersShow/community.mp3';
import dating from '../assets/audio/userEntersShow/dating.mp3';
import wake from '../assets/audio/wake.mp3';

export const BEAM = 'beam';
export const BEEP = 'beep';
export const BUZZ = 'buzz';
export const BLUBBER = 'blubber';
export const CAMERA = 'camera';
export const CARNEVAL = 'carneval';
export const CHORD = 'chord';
export const CYMBAL = 'cymbal';
export const GLAS = 'glas';
export const JUNGLE = 'jungle';
export const LOGIN = 'login';
export const POLICEPASS = 'policepass';
export const RING1 = 'ring1';
export const RING2 = 'ring2';
export const RING3 = 'ring3';
export const RING4 = 'ring4';
export const SCRATCH = 'scratch';
export const TERMINAL = 'terminal';
export const TOCK = 'tock';
export const TOCK2 = 'tock2';
export const TWEET = 'tweet';
export const WAKE = 'wake';

export const USER_ENTERS_SHOW_COMMUNITY = 'userEntersShowCommunity';
export const USER_ENTERS_SHOW_DATING = 'userEntersShowDating';

export const AUDIO_FILES = {
  [BEAM]: beam,
  [BEEP]: beep,
  [BUZZ]: buzz,
  [BLUBBER]: blubber,
  [CAMERA]: camera,
  [CARNEVAL]: carneval,
  [CHORD]: chord,
  [CYMBAL]: cymbal,
  [GLAS]: glas,
  [JUNGLE]: jungle,
  [LOGIN]: login,
  [POLICEPASS]: policepass,
  [RING1]: ring1,
  [RING2]: ring2,
  [RING3]: ring3,
  [RING4]: ring4,
  [SCRATCH]: scratch,
  [TERMINAL]: terminal,
  [TOCK]: tock,
  [TOCK2]: tock2,
  [TWEET]: tweet,
  [WAKE]: wake,
  [USER_ENTERS_SHOW_COMMUNITY]: community,
  [USER_ENTERS_SHOW_DATING]: dating,
};
export const DEFAULT_VOLUME = 1.0;
export const VOLUME_LEVELS = [0.5, 0.75, 1, 1.5, 2, 2.5, 3];
export const DEFAULT_SOUNDS = {
  lostConnection: {
    type: TERMINAL,
    active: true,
  },
  leaveShow: {
    type: TOCK2,
    active: true,
  },
  newMessage: {
    type: BEEP,
    active: true,
  },
  newLivecamMessage: {
    type: GLAS,
    active: true,
  },
};

export const DEFAULT_LIVE_CAM_SOUNDS = {
  lostConnection: {
    type: TERMINAL,
    active: true,
  },
  leaveShow: {
    type: TOCK2,
    active: true,
  },
  newLivecamMessage: {
    type: GLAS,
    active: true,
  },
};

export const DEFAULT_MESSAGE_SOUND = {
  newMessage: {
    type: BEEP,
    active: true,
  },
};
