import {
  audio,
  management,
  newDialog,
  revertOrigin,
  sendSystemChatMessage,
  stopUpgradetimer,
  stream,
  toggleChatMenu,
  camUser as userActions,
  useraudio,
} from '../../../../actions';
import {
  roomType as roomTypeUtils,
  user as userUtils,
} from '../../../../utils';
import { MANAGEMENT_EVENTS_USER_REMOVE } from '../../../actions';

export default (userInfo) => async (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    streaming: { permanentCam2CamStream },
    camUserFilter,
    sender: {
      roomTypes,
      feature: { powersender },
    },
  } = getState();

  const userFound = userUtils.get(camUsers, userInfo.userId);

  dispatch(stopUpgradetimer({ userId: userInfo.userId }));
  const { action, nextUserHasCam2Cam } = userUtils.createStopEventAction(
    camUsers,
    dialogs,
    MANAGEMENT_EVENTS_USER_REMOVE,
    userInfo,
    powersender,
    camUserFilter
  );

  if (
    userFound &&
    userFound.visible &&
    !userUtils.isVoyeur(userFound) &&
    !roomTypeUtils.isEvent(roomTypes)
  ) {
    userFound.hasPrivateAudioSession &&
      dispatch(useraudio.stopEvent(userInfo.userId));
    userFound.cam2camActive && dispatch(management.stopCam2Cam(userFound.id));
    nextUserHasCam2Cam &&
      !permanentCam2CamStream &&
      dispatch(management.startCam2Cam(action.meta.nextActiveUser.id));
    dispatch(
      sendSystemChatMessage({
        id: 'systemMessage.userLeft',
        values: { user: userFound.name },
      })
    );
    dispatch(audio.leaveShow());
    dispatch(revertOrigin());
  }

  const nextActiveUser = action?.meta?.nextActiveUser;

  if (
    (nextActiveUser &&
      nextActiveUser?.id &&
      nextActiveUser?.id.includes('@') &&
      nextActiveUser?.flags &&
      nextActiveUser?.flags.includes('noRemoteDialog') &&
      nextActiveUser?.flags.includes('answered')) ||
    camUsers.find((u) => u.partner === nextActiveUser?.id && u.hasText)
  ) {
    await dispatch(newDialog({ id: nextActiveUser.id }));
  } else if (!nextActiveUser && userFound?.name) {
    const foundUserWithText = camUsers.find(
      (u) =>
        u.userId === userInfo.userId &&
        (u.hasText || u.flags?.includes('answered'))
    );

    if (foundUserWithText && foundUserWithText.partner) {
      const foundDialog = dialogs.find(
        (u) => u.partner && u.partner === foundUserWithText.partner
      );
      const flags = foundDialog?.flags || [];
      const createNew = !foundDialog || flags.includes('noRemoteDialog');

      if (createNew) {
        await dispatch(newDialog({ id: foundUserWithText.partner }));
      }
    }
  }

  if (
    action?.meta?.nextActiveUser?.userId &&
    action.meta.nextActiveUser.userId !== 'system'
  ) {
    dispatch(userActions.select(action.meta.nextActiveUser.userId, false));
  }
  dispatch(action);
  dispatch(stream.checkStop());

  const chatMenuOpen = getState().chat.chatMenuOpen;
  const combinedUserList = [
    ...getState().camUser.camUsers,
    ...getState().dialog.dialogs,
  ];
  const hasVisibleUsers = userUtils.visibleUser(combinedUserList).length > 0;
  if (!hasVisibleUsers && chatMenuOpen) {
    toggleChatMenu();
  }
};
