import {
  MENU_TOYCONTROL_TOGGLE,
  MENU_USERLIST_TOGGLE,
  MENU_USER_TOGGLE,
  RESIZE,
  SELECT_USER,
  SIDEBAR_TOGGLE,
  VIEW_CAM_SET,
  VIEW_CAM_TOGGLE,
  VIEW_USERGAMESTAT_TOGGLE,
  VIEW_USERGAME_TOGGLE,
  VIEW_USERINFO_TOGGLE,
  VIEW_USERLIST_TOGGLE,
  VIEW_USERNOTE_TOGGLE,
} from '../actions/actions';
import { lStorage } from '../services/storage';

const isMobileDefaults = {
  userinfo: false,
  usernote: false,
  usergame: false,
  cam: false,
  sidebar: false,
  userlist: true,
  usergamestats: false,
};

const isMediumDefaults = {
  userinfo: false,
  usernote: false,
  usergame: false,
  cam: true,
  sidebar: true,
  userlist: true,
  usergamestats: false,
};

const isMediumLargeDefaults = {
  userinfo: false,
  usernote: false,
  usergame: false,
  cam: true,
  sidebar: true,
  userlist: true,
  usergamestats: false,
};

const isLargeDefaults = {
  userinfo: true,
  usernote: false,
  usergame: false,
  cam: true,
  sidebar: true,
  userlist: true,
  usergamestats: false,
};

const setViewDefaults = (newState, initial, storageSidebar, defaults) => {
  if (initial && storageSidebar) {
    return { ...newState, ...defaults, ...storageSidebar };
  }
  lStorage.setItem('sidebar', defaults.sidebar);
  return { ...newState, ...defaults };
};

const makeStateDefaults = (
  state,
  { initial, storageSidebar },
  { isMobile, isMedium, isMediumLarge, isLarge, isPortrait }
) => {
  const newState = {
    ...state,
    isMobile,
    isMedium,
    isMediumLarge,
    isLarge,
    isPortrait,
  };
  if (typeof state.resize === 'number') {
    newState.resize++;
  } else {
    newState.resize = 0;
  }
  if (newState.isMobile) {
    newState.sidebar = false;
  }
  if (newState.isMobile && !state.isMobile) {
    //on mobile, the sidebar should never appear after reload.
    lStorage.setItem('sidebar', isMobileDefaults.sidebar);
    return { ...newState, ...isMobileDefaults };
  }

  if (newState.isMedium && !state.isMedium) {
    return setViewDefaults(newState, initial, storageSidebar, isMediumDefaults);
  }

  if (newState.isMediumLarge && !state.isMediumLarge) {
    return setViewDefaults(
      newState,
      initial,
      storageSidebar,
      isMediumLargeDefaults
    );
  }

  if (newState.isLarge && !state.isLarge) {
    return setViewDefaults(newState, initial, storageSidebar, isLargeDefaults);
  }

  if (
    newState.isLarge &&
    !newState.isMediumLarge &&
    !newState.userinfo &&
    !newState.usernote &&
    !newState.usergame
  ) {
    return { ...newState, userinfo: true };
  }

  return newState;
};

const initialState = {
  userinfo: false,
  usernote: false,
  usergame: false,
  usergamestats: false,
  cam: false,
  sidebar: false,
  userlist: true,
  isMobile: false,
  isMedium: false,
  isMediumLarge: false,
  isLarge: false,
  isPortrait: false,
  userMenuOpen: false,
  toycontrolMenuOpen: false,
  userListMenuOpen: false,
};

const tab = (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_TOGGLE:
      if (state.isMobile) {
        const newState = {
          ...state,
          sidebar: !state.sidebar,
        };
        if (newState.sidebar === true) {
          newState.userlist = false;
        }
        return newState;
      }
      if (state.isMedium) {
        return {
          ...state,
          ...isMediumDefaults,
          sidebar: !state.sidebar,
          cam: state.cam,
        };
      }
      return { ...state, sidebar: !state.sidebar };
    case RESIZE:
      return makeStateDefaults(
        state,
        { ...action.payload },
        { ...action.meta }
      );
    case SELECT_USER:
      if (state.isMedium || state.isMediumLarge) {
        if (state.usergame && !action?.payload?.flags?.includes('game')) {
          return {
            ...state,
            usergame: false,
          };
        }
        if (!state.usergame && action?.payload?.flags?.includes('game')) {
          return {
            ...state,
            cam: false,
            usergame: true,
          };
        }
      }

      if (state.usergame && !action?.payload?.flags?.includes('game')) {
        if (state.isLarge) {
          return {
            ...state,
            userinfo: true,
            usergame: false,
          };
        } else {
          return {
            ...state,
            usergame: false,
          };
        }
      }
      if (!state.usergame && action?.payload?.flags?.includes('game')) {
        return {
          ...state,
          userinfo: false,
          usernote: false,
          usergame: true,
        };
      }

      return state;

    case VIEW_CAM_SET: {
      if (state.isMedium || state.isMediumLarge) {
        return {
          ...state,
          cam: action.payload,
          usergame: false,
        };
      }
      if (state.isLarge) {
        return {
          ...state,
          cam: action.payload,
        };
      }
      return {
        ...state,
        sidebar: state.isMedium ? state.sidebar : false,
        userlist: false,
        usergame: false,
        cam: !state.cam,
        usernote: !state.cam ? false : state.usernote,
        userinfo: !state.cam ? false : state.userinfo,
      };
    }

    case VIEW_CAM_TOGGLE: {
      if (state.isMedium || state.isMediumLarge) {
        return {
          ...state,
          cam: !state.cam,
          usergame: false,
        };
      }
      if (state.isLarge) {
        return {
          ...state,
          cam: !state.cam,
        };
      }
      return {
        ...state,
        sidebar: state.isMedium ? state.sidebar : false,
        userlist: false,
        usergame: false,
        cam: !state.cam,
        usernote: !state.cam ? false : state.usernote,
        userinfo: !state.cam ? false : state.userinfo,
      };
    }

    case VIEW_USERGAME_TOGGLE: {
      const usergame = !state.usergame;
      if (state.isMedium || state.isMediumLarge) {
        return {
          ...state,
          usergame,
          cam: false,
        };
      }
      if (state.isLarge) {
        return {
          ...state,
          usergame,
          usernote: false,
          userinfo: !usergame,
        };
      }
      return {
        ...state,
        usergame,
        cam: usergame ? false : state.cam,
        usernote: usergame ? false : state.usernote,
        userinfo: usergame ? false : state.userinfo,
        usergamestats: false,
      };
    }
    case VIEW_USERINFO_TOGGLE: {
      const userinfo = !state.userinfo;
      if (state.isMediumLarge) {
        return {
          ...state,
          userinfo: !state.userinfo,
          usernote: false,
        };
      }
      if (state.isLarge && !userinfo) return state; // should not happen as button should be disabled
      if (state.isLarge) {
        return {
          ...state,
          userinfo,
          usernote: !userinfo,
          usergame: false,
        };
      }
      return {
        ...state,
        userinfo,
        cam: state.isMedium ? state.cam : userinfo ? false : state.cam,
        usernote: userinfo ? false : state.usernote,
        usergame: state.isMedium
          ? state.usergame
          : userinfo
          ? false
          : state.usergame,
        sidebar: false,
        usergamestats: false,
      };
    }
    case VIEW_USERNOTE_TOGGLE: {
      const usernote = !state.usernote;
      if (state.isMediumLarge) {
        return {
          ...state,
          userinfo: false,
          usernote: !state.usernote,
        };
      }
      if (state.isLarge && !usernote) return state; // should not happen as button should be disabled
      if (state.isLarge) {
        return {
          ...state,
          usernote,
          userinfo: !usernote,
          usergame: false,
        };
      }
      return {
        ...state,
        usernote,
        cam: state.isMedium ? state.cam : usernote ? false : state.cam,
        userinfo: usernote ? false : state.userinfo,
        usergame: state.isMedium
          ? state.usergame
          : usernote
          ? false
          : state.usergame,
        sidebar: false,
        usergamestats: false,
      };
    }
    case VIEW_USERLIST_TOGGLE:
      const newState = {
        ...state,
        userlist: !state.userlist,
      };
      if (state.isMobile && newState.sidebar) {
        newState.sidebar = false;
      }
      return newState;
    case VIEW_USERGAMESTAT_TOGGLE: {
      const newState = {
        ...state,
        usergamestats: !state.usergamestats,
      };

      if (newState.usergamestats) {
        newState.usernote = false;
        newState.userinfo = false;
      }

      if (!state.isMobile) {
        newState.usergamestats = false; // make sure this can only be called in mobile view
      }

      return newState;
    }
    case MENU_TOYCONTROL_TOGGLE:
      return { ...state, toycontrolMenuOpen: !state.toycontrolMenuOpen };
    case MENU_USERLIST_TOGGLE:
      return { ...state, userListMenuOpen: !state.userListMenuOpen };
    case MENU_USER_TOGGLE:
      return { ...state, userMenuOpen: !state.userMenuOpen };
    default:
      return state;
  }
};

export default tab;
