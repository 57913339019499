import './Contest.scss';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Contest({ locale, contest }) {
  const intl = useIntl();

  if (!contest) {
    return null;
  }

  const state = contest?.state;
  const livecams = contest?.show?.livecams;
  const messages = contest?.show?.messages;
  const content = contest?.show?.content;

  if (!livecams && !messages && !content) return null;

  const imageClasses = classNames('contest-image', {
    [`locale-${locale}`]: !!locale,
    [`contest-${state}`]: !!state,
  });

  return (
    <div className="contest-counter-wrapper">
      <div className={imageClasses}>
        <div className="counter-box">
          {livecams && (
            <div className="contest-box">
              <div className="box">
                <div className="box-title">
                  {intl.formatMessage({ id: 'CONTEST_LIVECAM_SHOWS' })}
                </div>
                <div className="coins">
                  <span className="coin-number">{livecams.coins}</span>
                  <span className="subtitle">
                    {intl.formatMessage({ id: 'CONTEST_COINS' })}
                  </span>
                </div>
              </div>
              <div className="rank">
                <span className="subtitle">
                  {intl.formatMessage({ id: 'CONTEST_RANK' })}
                </span>

                {state === 'active' ? (
                  livecams.rank === 0 ? (
                    <span className="subtitle no-rank">
                      {intl.formatMessage({ id: 'CONTEST_NO_RANK' })}
                    </span>
                  ) : (
                    <span className="rank-number">{livecams.rank}</span>
                  )
                ) : livecams.rank === 0 || livecams.rank > 100 ? (
                  <span className="subtitle no-rank">
                    {intl.formatMessage({ id: 'CONTEST_END_NO_RANK' })}
                  </span>
                ) : (
                  <span className="rank-number">{livecams.rank}</span>
                )}
              </div>
            </div>
          )}

          {messages && (
            <div className="contest-box">
              <div className="box">
                <div className="box-title">
                  {intl.formatMessage({ id: 'CONTEST_MESSAGES' })}
                </div>
                <div className="coins">
                  <span className="coin-number">{messages.coins}</span>
                  <span className="subtitle">
                    {intl.formatMessage({ id: 'CONTEST_COINS' })}
                  </span>
                </div>
              </div>
              <div className="rank">
                <span className="subtitle">
                  {intl.formatMessage({ id: 'CONTEST_RANK' })}
                </span>

                {state === 'active' ? (
                  messages.rank === 0 ? (
                    <span className="subtitle no-rank">
                      {intl.formatMessage({ id: 'CONTEST_NO_RANK' })}
                    </span>
                  ) : (
                    <span className="rank-number">{messages.rank}</span>
                  )
                ) : messages.rank === 0 || messages.rank > 100 ? (
                  <span className="subtitle no-rank">
                    {intl.formatMessage({ id: 'CONTEST_END_NO_RANK' })}
                  </span>
                ) : (
                  <span className="rank-number">{messages.rank}</span>
                )}
              </div>
            </div>
          )}

          {content && (
            <div className="contest-box">
              <div className="box">
                <div className="box-title">
                  {intl.formatMessage({ id: 'CONTEST_ITEMS_SOLD' })}
                </div>
                <div className="coins">
                  <span className="coin-number">{content.coins}</span>
                  <span className="subtitle">
                    {intl.formatMessage({ id: 'CONTEST_COINS' })}
                  </span>
                </div>
              </div>
              <div className="rank">
                <span className="subtitle">
                  {intl.formatMessage({ id: 'CONTEST_RANK' })}
                </span>

                {state === 'active' ? (
                  content.rank === 0 ? (
                    <span className="subtitle no-rank">
                      {intl.formatMessage({ id: 'CONTEST_NO_RANK' })}
                    </span>
                  ) : (
                    <span className="rank-number">{content.rank}</span>
                  )
                ) : content.rank === 0 || content.rank > 100 ? (
                  <span className="subtitle no-rank">
                    {intl.formatMessage({ id: 'CONTEST_END_NO_RANK' })}
                  </span>
                ) : (
                  <span className="rank-number">{content.rank}</span>
                )}
              </div>
            </div>
          )}
        </div>
        <p className="contest-info">
          <Link to="/wiki/contest">
            {intl.formatMessage({ id: 'CONTEST_INFO_LINK_TEXT' })}
          </Link>
          {state === 'active' ? (
            <span>{intl.formatMessage({ id: 'CONTEST_UPDATED_TEXT' })}</span>
          ) : null}
        </p>
      </div>
    </div>
  );
}

export default Contest;
