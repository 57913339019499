export default {
  ROUTE_SIGNUP_HEADLINE: `Welcome`,
  ROUTE_SIGNUP_TITLE: `Sign up`,
  ROUTE_SIGNUP_SUBTITLE: `Become a Creator now`,
  ROUTE_LOGIN_HEADLINE: `Welcome`,
  ROUTE_LOGIN_TITLE: `Login`,
  ROUTE_LOGIN_SUBTITLE: `LiveCreator Login`,
  ROUTE_HOMEPAGE_HEADLINE: `Welcome`,
  ROUTE_HOMEPAGE_TITLE: `Home`,
  ROUTE_HOMEPAGE_SUBTITLE: `LiveCreator`,
  ROUTE_PASSWORD_RESET_HEADLINE: `Password reset`,
  ROUTE_SET_PASSWORD_HEADLINE: `Set Password`,
  ROUTE_PASSWORD_RESET_TITLE: `Login`,
  ROUTE_PASSWORD_RESET_SUBTITLE: `Reset your password`,
  ROUTE_SET_PASSWORD_SUBTITLE: `Set password`,
  ROUTE_LEGAL_TITLE: `Legal`,
  ROUTE_PASSWORD_FORGOT_HEADLINE: `Welcome`,
  ROUTE_PASSWORD_FORGOT_TITLE: `Login`,
  ROUTE_PASSWORD_FORGOT_SUBTITLE: `Forgot password`,
  ROUTE_TWO_FACTOR_HEADLINE: `Welcome`,
  ROUTE_TWO_FACTOR_TITLE: `Login`,
  ROUTE_TWO_FACTOR_SUBTITLE: `Two-Factor authentication`,
  ROUTE_CONFIRMED_EMAIL_HEADLINE: `Welcome`,
  ROUTE_CONFIRMED_EMAIL_TITLE: `Email confirmed`,
  ROUTE_CONFIRMED_EMAIL_SUBTITLE: `Email confirmed`,
  ROUTE_UNSUBSCRIBE_NEWSLETTER_HEADLINE: `Welcome`,
  ROUTE_UNSUBSCRIBE_NEWSLETTER_TITLE: `Login`,
  ROUTE_UNSUBSCRIBE_NEWSLETTER_SUBTITLE: `Unsubscribe Newsletter`,
  ROUTE_TERMS_HEADLINE: `Terms`,
  ROUTE_TERMS_TITLE: `Legal`,
  ROUTE_TERMS_SUBTITLE: `Terms and Conditions`,
  ROUTE_PRIVACY_HEADLINE: `Privacy Policy`,
  ROUTE_PRIVACY_TITLE: `Privacy Policy`,
  ROUTE_PRIVACY_SUBTITLE: `Privacy Policy`,
  ROUTE_IMPRINT_SUBTITLE: `Imprint`,
  ROUTE_SUPPORT_HEADLINE: `Support`,
  ROUTE_SUPPORT_TITLE: `Support`,
  ROUTE_SUPPORT_SUBTITLE: `Helpcenter`,
  ROUTE_NEWSLETTER_HEADLINE: `Support`,
  ROUTE_WIKI:
    'Our Creator Wiki has been designed to help you with the navigation and functions at LiveCreator',
  ROUTE_WIKI_HEADLINE: 'Support',
  ROUTE_WIKI_SUBTITLE: 'Wiki',
  ROUTE_CONTEST_SPECIAL: 'X-Mas Contest',
  ROUTE_WIKI_START: `Start`,
  ROUTE_WIKI_GENERAL: `General`,
  ROUTE_WIKI_MONEYMAKERS: `Moneymakers`,
  ROUTE_WIKI_XMAS: `Xmas Calendar`,
  ROUTE_NEWSLETTER_SUBTITLE: `Newsletter`,
  ROUTE_ACCOUNT_HEADLINE: `My account`,
  ROUTE_ACCOUNT_TITLE: `My Account`,
  ROUTE_ACCOUNT_SUBTITLE: `First steps`,
  ROUTE_REVIEW_HEADLINE: `My account`,
  ROUTE_REVIEW_TITLE: `My account`,
  ROUTE_REVIEW_SUBTITLE: `First steps`,
  ROUTE_PENDING_HEADLINE: `My account`,
  ROUTE_PENDING_TITLE: `My account`,
  ROUTE_PENDING_SUBTITLE: `Confirm email`,
  ROUTE_CONFIRMED_HEADLINE: `My account`,
  ROUTE_CONFIRMED_TITLE: `My account`,
  ROUTE_CONFIRMED_SUBTITLE: `My account`,
  ROUTE_CAM_MESSENGER_HEADLINE: `Multi Messenger`,
  ROUTE_CAM_MESSENGER_TITLE: `Multi Messenger`,
  ROUTE_LIVECAM_HEADLINE: `Livecam`,
  ROUTE_LIVECAM_TITLE: `Livecam`,
  ROUTE_LIVECAM_OPTIONS_SUBTITLE: 'Cam Options',
  ROUTE_TOYCONTROL: 'Lovense Toy',
  ROUTE_DASHBOARD_TITTLE: `Dashboard`,
  ROUTE_MY_PERSONAL_DETAILS_TITLE: `Private data`,
  ROUTE_ACCOUNT_DETAILS_TITLE: `Account Details`,
  ROUTE_PERSONAL_DETAILS_TITLE: `Personal Details`,
  ROUTE_BANK_DETAILS_TITLE: `Bank Details`,
  ROUTE_DOCUMENTS_TITLE: `Documents`,
  ROUTE_COMMISSION_TITLE: `Commission`,
  ROUTE_MY_PROFILE_TITLE: `My Profile`,
  ROUTE_VIRTUAL_GIFTS: 'Virtual Gifts',
  ROUTE_MY_VIRTUAL_GIFTS: 'My virtual gifts',
  ROUTE_ALL_VIRTUAL_GIFTS: 'All virtual gifts',
  ROUTE_PROFILE_PICTURES: `Profile Pictures`,
  ROUTE_PROFILE_VIDEO: `Profile Video`,
  ROUTE_PROFILE_TEXT: `Profile Text`,
  ROUTE_PUBLIC_DETAILS: `Public Details`,
  ROUTE_PUBLIC_PROFILE: `View my public profile`,
  ROUTE_SETTINGS_TITLE: `Settings`,
  ROUTE_OPTIONS: `Options`,
  ROUTE_PRICES: `Prices`,
  ROUTE_ACCOUNT_SECURITY: `Account Security`,
  ROUTE_CONTENT_HEADLINE: `My content`,
  ROUTE_CONTENT_TITLE: `My content`,
  ROUTE_CONTENT_SUBTITLE: `My content`,
  ROUTE_MY_PHOTOS: `My photos`,
  ROUTE_MY_VIDEOS: `My videos`,
  ROUTE_MY_DIARY: `My diary`,
  ROUTE_NEW_DIARY: 'New diary entry',
  ROUTE_EDIT_DIARY: 'Edit entry',
  ROUTE_EDIT_GALLERY: `Edit gallery`,
  ROUTE_FMOTD: 'Free Movie of the Day',
  ROUTE_RELATIONSHIPS_TITLE: `Relationships`,
  ROUTE_RELATIONSHIPS_SUBTITLE: `Relationships`,
  ROUTE_RELATIONSHIPS_HEADLINE: `Relationships`,
  ROUTE_IGNORED: `Ignored`,
  ROUTE_FAVORITES: `Favorites`,
  ROUTE_FRIENDS: `Friends`,
  ROUTE_FRIEND_REQUESTS: `Requests`,
  ROUTE_ACTIVE_FRIENDS: `Active friends`,
  ROUTE_MY_REQUESTS: `My requests`,
  ROUTE_DENIED: `Denied`,
  ROUTE_MONEYMAKERS_TITLE: 'Money Makers',
  ROUTE_MONEYMAKERS_HEADLINE: 'Money Makers',
  ROUTE_ATTACHMENTS_SUBTITLE: 'Attachments',
  ROUTE_ICEBREAKER_SUBTITLE: 'Icebreaker',

  MENU_HOME: `Home`,
  MENU_SIGNUP: `Sign up`,
  MENU_LOGIN: `Login`,
  MENU_FORGOT_PASSWORD: `Forgot password`,
  MENU_RESET_PASSWORD: `Reset password`,
  MENU_TWO_FACTOR: `Two-Factor authentication`,
  MENU_VERIFY_EMAIL: `Confirm email`,
  MENU_UNSUBSCRIBE_NEWSLETTER: `Unsubscribe newsletter`,
  MENU_HEADER_ACTIVITIES: `Activities`,
  MENU_HEADER_MESSENGER: `Messenger`,
  MENU_HEADER_FEATURES: `Features`,
  MENU_ITEM_YOU: `You`,
  MENU_ITEM_LOGOUT: `Logout`,
  MENU_ITEM_FIRST_STEPS: `First Steps`,
  MENU_ITEM_DASHBOARD: `Dashboard`,
  MENU_ITEM_PERSONAL_DETAILS: `Private data`,
  MENU_ITEM_DOCUMENTS: `Documents`,
  MENU_ITEM_COMMISSION: `Commission`,
  MENU_ITEM_MY_PROFILE: `My profile`,
  MENU_ITEM_SETTINGS: `Settings`,
  MENU_ITEM_SUPPORT: `Support`,
  MENU_ITEM_SUPPORT_CONTACT: `Helpcenter`,
  MENU_ITEM_WIKI: `Wiki`,
  MENU_ITEM_NEWSLETTER: `Newsletter`,
  MENU_ITEM_LEGAL: `Legal`,
  MENU_ITEM_IMPRINT: `Imprint`,
  MENU_ITEM_TERMS: `Terms`,
  MENU_ITEM_PRIVACY_POLICY: `Privacy Policy`,
  MENU_ITEM_CAM_MESSENGER: `Multi Messenger`,
  MENU_ITEM_LIVECAM_OPTIONS: 'Settings',
  MENU_ITEM_LIVECAM: `Livecam`,
  MENU_ITEM_PHOTOS: `Photos`,
  MENU_ITEM_VIDEOS: `Videos`,
  MENU_ITEM_DIARY: `Diary`,
  MENU_ITEM_COMMENTS: `Comments`,
  MENU_ITEM_FMOTD: `Free Movie of the Day`,
  MENU_ITEM_ALL: `All`,
  MENU_ITEM_UNREAD: `Unread`,
  MENU_ITEM_UNANSWERED: `Unanswered`,
  MENU_ITEM_NEW_USER: `New user`,
  MENU_ITEM_FAVORITES: `Favorites`,
  MENU_ITEM_FRIENDS: `Friends`,
  MENU_ITEM_IGNORED: `Ignored`,
  MENU_ITEM_GAMES: `Games`,
  MENU_ITEM_MONEYMAKERS: 'Money Makers',
  MENU_ITEM_MONEYMAKERS_ICEBREAKER: 'Icebreaker',
  MENU_ITEM_MONEYMAKERS_ATTACHMENT: 'Attachments',
  MENU_ITEM_ACTIVITIES_PROFILE: 'Profile visitors',
  MENU_ITEM_ACTIVITIES_ONLINE: 'Customer logins',
  MENU_ITEM_ACTIVITIES_ONLINE_FRIEND: 'Friend logins',
  MENU_ITEM_ACTIVITIES_FRIEND: 'Friend requests',
  MENU_ITEM_ACTIVITIES_CONTENT: 'Content purchases',
  MENU_ITEM_CUSTOMERS: 'Customers',
  MENU_ITEM_SALES: 'Sales',
  MENU_ITEM_GAME4SCORE: 'Game 4Score',
  MENU_ITEM_COOKIES: 'Cookies',
};
