import { connect } from 'react-redux';

import Sales from './Sales';

const mapStateToProps = (state) => ({
  language: state.user.account?.language,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
