import './HeaderMenu.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import addIcon from '../../assets/img/svg/add-circle.svg';
import chevronLeft from '../../assets/img/svg/chevron-single-left.svg';
import chevronRight from '../../assets/img/svg/chevron-single-right.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import useWindowResize from '../../hooks/useWindowResize';
import Button from '../Button/Button';
import HeaderMenuButton from './HeaderMenuButton/HeaderMenuButton';
import HeaderMenuItem from './HeaderMenuItem/HeaderMenuItem';

function HeaderMenu({
  defaultPath = '/my-account',
  items = [],
  showButton,
  additionalContent,
}) {
  const history = useHistory();
  const windowResize = useWindowResize();
  const visibleItemsRef = useRef();

  const areScrollingButtonsDisplayed = useMediaQuery('(max-width: 610px)');
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const [, createGalleryRequest] = useAxios(
    {
      url: '/galleries',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right - 5 <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  useEffect(() => {
    if (areScrollingButtonsDisplayed) {
      const activeItemIndex = items.findIndex((item) =>
        history.location.pathname.includes(`${defaultPath}${item.path}`)
      );
      const element = document.getElementById(
        `header-menu-item.${activeItemIndex}`
      );

      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });

      if (items.length >= 3) {
        const firstItem =
          document.getElementsByClassName('header-menu-item')[0];
        const lastItem =
          document.getElementsByClassName('header-menu-item')[items.length - 1];
        if (isInViewport(firstItem)) {
          setShowPreviousButton(false);
        } else {
          setShowPreviousButton(true);
        }
        if (isInViewport(lastItem)) {
          setShowNextButton(false);
        } else {
          setShowNextButton(true);
        }
      }
    }
  }, [
    areScrollingButtonsDisplayed,
    defaultPath,
    history.location.pathname,
    items,
    windowResize,
  ]);

  function changeRoute(route) {
    history.push(`${defaultPath}${route}`);
  }

  const scrollItems = debounce((direction) => {
    const activeElement = document.getElementsByClassName(
      'header-menu-item active'
    )[0];

    if (!visibleItemsRef?.current) {
      return;
    }

    visibleItemsRef.current.scrollBy({
      top: 0,
      left:
        direction === 'previous'
          ? -activeElement.offsetWidth
          : activeElement.offsetWidth,
      behavior: 'smooth',
    });
  }, 200);

  useEffect(() => {
    if (visibleItemsRef?.current) {
      const visibleItem = visibleItemsRef.current;

      const scrollListener = debounce(() => {
        const firstItem =
          document.getElementsByClassName('header-menu-item')[0];
        const lastItem =
          document.getElementsByClassName('header-menu-item')[items.length - 1];

        if (isInViewport(firstItem)) {
          setShowPreviousButton(false);
        } else {
          setShowPreviousButton(true);
        }
        if (isInViewport(lastItem)) {
          setShowNextButton(false);
        } else {
          setShowNextButton(true);
        }
      }, 200);

      visibleItem.addEventListener('scroll', scrollListener);

      return () => {
        visibleItem.removeEventListener('scroll', scrollListener);
      };
    }
  }, [items.length]);

  function createGallery() {
    const type = history.location.pathname.includes('my-photos')
      ? 'pictures'
      : history.location.pathname.includes('my-videos')
      ? 'movies'
      : 'diary';

    const getResponse = (response) => {
      const id = response.data.id;
      if (id) {
        history.push(`${defaultPath}/edit-gallery?type=${type}&id=${id}`);
      }
    };

    const logError = (error) => {
      console.log('error', error);
    };

    if (type === 'diary') {
      return history.push(`${defaultPath}/edit/`);
    } else {
      createGalleryRequest({ data: { type } })
        .then(getResponse)
        .catch(logError);
    }
  }

  const visibleItemsClass = classNames('visible-items', {
    'one-button': showPreviousButton ^ showNextButton,
    'two-buttons': showPreviousButton && showNextButton,
  });

  const createButtonLabel = history.location.pathname.includes('diary')
    ? 'CREATE_NEW_DIARY_ENTRY'
    : 'CREATE_NEW_GALLERY';

  return (
    <div className="header-menu">
      {areScrollingButtonsDisplayed && showPreviousButton ? (
        <span
          onClick={() => scrollItems('previous')}
          className="previous-button"
        >
          <HeaderMenuButton icon={chevronLeft} />
        </span>
      ) : null}
      <div className={visibleItemsClass} ref={visibleItemsRef}>
        {items.map((item, i) => (
          <HeaderMenuItem
            id={`header-menu-item.${i}`}
            key={i}
            click={() => changeRoute(item.path)}
            title={item.title}
            active={
              history.location.pathname.includes(
                `${defaultPath}${item.path}`
              ) ||
              item.childPaths?.some((childPath) => {
                return history.location.pathname.includes(
                  `${defaultPath}${childPath}`
                );
              })
            }
            borderLeft={i !== 0}
            additionalContent={
              item.path === '/friend-requests' && additionalContent
            }
          />
        ))}
        {showButton && (
          <Button
            label={createButtonLabel}
            className="btn-create-gallery"
            icon={addIcon}
            onClick={() => createGallery()}
          />
        )}
      </div>
      {areScrollingButtonsDisplayed && showNextButton ? (
        <span onClick={() => scrollItems('next')} className="next-button">
          <HeaderMenuButton icon={chevronRight} />
        </span>
      ) : null}
    </div>
  );
}

export default HeaderMenu;
