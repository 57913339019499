import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import menuIcon from '../../../../assets/img/svg/menu-dots.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Button from '../../../Button/Button';
import LeaveShow from '../SoundSelectFields/LeaveShow';
import LostConnection from '../SoundSelectFields/LostConnection';
import NewLivecamMessage from '../SoundSelectFields/NewLivecamMessage';

const LivecamSoundMenu = ({
  reset,
  isEvent,
  lostConnectionSoundDefault,
  leaveShowSoundDefault,
  newLivecamMessageSoundDefault,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const menuIconClass = classNames('menu-icon', {
    active: menuVisible,
  });

  const disabled =
    lostConnectionSoundDefault &&
    leaveShowSoundDefault &&
    newLivecamMessageSoundDefault;

  return (
    !isEvent && (
      <div className="group-container">
        <div className="headline-with-menu form-group">
          <h2 className="headline column">
            <FormattedMessage id="audio.title" />
          </h2>
          <div className="settings-menu-wrapper">
            <Button
              variant="icon-only"
              onClick={() => {
                if (!menuVisible) {
                  setMenuVisible(true);
                }
              }}
              intlTranslate={false}
              icon={menuIcon}
              className={menuIconClass}
              disabled={disabled}
            />
            {menuVisible && (
              <div className="toggle-menu settings-menu" ref={menuRef}>
                <div
                  className="menu-item"
                  onClick={() => {
                    setMenuVisible(false);
                    reset(true, false);
                  }}
                >
                  <FormattedMessage id="reset" />
                </div>
              </div>
            )}
          </div>
        </div>
        <LostConnection />
        <LeaveShow />
        <NewLivecamMessage />
      </div>
    )
  );
};

export default LivecamSoundMenu;
