export default {
  ROUTE_MY_RELATIONSHIPS_HEADLINE: `Überprüfe und ändere den Status der Beziehung mit Usern`,

  RELATIONSHIP_HEADLINE_IGNORE: `Ignorierte User`,
  RELATIONSHIP_ITEM_MENU_UNIGNORE: `Nicht mehr ignorieren`,
  RELATIONSHIP_LABEL_NO_IGNORED_USERS: `Kein User wird ignoriert.`,

  RELATIONSHIP_ITEM_MENU_END_FRIENDSHIP: `Freundschaft beenden`,
  RELATIONSHIP_ITEM_MENU_CONFIRM_DENY: `Annehmen / Ablehnen`,

  RELATIONSHIP_LABEL_NO_FRIENDS: `Du hast noch keine Freunde.`,
  RELATIONSHIP_LABEL_NO_REQUESTS: `Es gibt derzeit keine Anfragen.`,
  RELATIONSHIP_LABEL_ERROR_REQUESTS: `Es gab ein Problem beim Laden der Anfragen.`,
  RELATIONSHIP_LABEL_NO_DENIED_REQUESTS: `Du hast noch keine Anfrage abgelehnt.`,
  RELATIONSHIP_HEADLINE_FAVORITES: `Meine Favoriten`,
  RELATIONSHIP_ITEM_MENU_REMOVE_FAVORITE: `Favorit entfernen`,
  RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE: `Eine Nachricht schreiben`,
  RELATIONSHIP_ITEM_MENU_CANCEL_REQUEST: `Zurücknehmen`,
  RELATIONSHIP_ITEM_MENU_REMOVE_REQUEST: `Löschen`,
  RELATIONSHIP_ITEM_ACCEPT_ALL: `Diese Anfragen annehmen`,
  RELATIONSHIP_ITEM_DELETE_ALL: `Diese Anfragen ablehnen`,

  RELATIONSHIP_LABEL_NO_FAVORITES: `Du hast noch keinen Favoriten.`,

  RELATIONSHIPS_INFO_HEADLINE: `Die Bedeutung der einzelnen Beziehungen`,
  RELATIONSHIPS_INFO_1: `<span class='bold'>Freunde</span><br />Deine Freunde sind in den Communities für alle sichtbar.`,
  RELATIONSHIPS_INFO_2: `<span class='bold'>Anfragen</span><br />Bevor Du eine Anfrage beantwortest solltest Du schauen, ob Du mit dem User schon Kontakt hattest. Falls nicht, frage ihn direkt, warum er Dein Freund werden möchte.`,
  RELATIONSHIPS_INFO_2_1: `Hast Du sehr viele Anfragen, kannst Du alle sichtbaren Anfragen auf der aktuellen Seite mit den Buttons annehmen oder ablehnen.`,
  RELATIONSHIPS_INFO_3: `<span class='bold'>Favoriten</span><br />Merke Dir User, die Dir viele Aufmerksamkeiten senden oder auch sonst viele Coins bei Dir ausgeben. Damit hast Du Deine Top-Kunden immer im Blick und vergisst nicht, Dich regelmäßig bei ihnen zu melden. Favoriten kannst nur Du selbst sehen, sie bleiben in der Community verborgen.`,
  RELATIONSHIPS_INFO_4: `<span class='bold'>Ignoriert</span><br />Von Dir ignorierte User können Dich nicht mehr kontaktieren. Bitte gehe vorsichtig mit dieser Funktion um.`,

  PAGE: `Seite`,
  PAGE_OF: `von`,

  RELATIONSHIP_ITEM_MENU_SHOW_IMAGE: 'Profilbild anzeigen',
  RELATIONSHIP_ITEM_MENU_HIDE_IMAGE: 'Profilbild ausblenden',
};

