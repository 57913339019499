import _ from 'lodash';

import { DEFAULT_CHAT_FONT_SIZE } from '../../constants';
import {
  DEFAULT_LIVE_CAM_SOUNDS,
  DEFAULT_MESSAGE_SOUND,
  DEFAULT_SOUNDS,
  DEFAULT_VOLUME,
} from '../../constants/sound';
import { uuid } from '../../utils';
import api from '../../utils/api';
import { PREFERENCES_ADD } from '../actions';

export default (livecamReset = false, messengerReset = false) =>
  async (dispatch, getState) => {
    const { preferences } = getState();
    const { fontsize: fontPreference, sounds: soundPreferences } = preferences;

    async function saveToDB(data) {
      try {
        await api.put('/user/preferences', { data });
        dispatch({
          type: PREFERENCES_ADD,
          payload: data,
        });
      } catch (error) {
        dispatch({
          type: PREFERENCES_ADD,
          payload: preferences,
          error: true,
          meta: { stamp: uuid() },
        });
        console.log({ error });
      }
    }

    // reset livecam Settings
    if (livecamReset) {
      const defaultLivecamSounds = _.merge(
        {},
        soundPreferences,
        DEFAULT_LIVE_CAM_SOUNDS
      );

      const data = {
        ...preferences,
        sounds: defaultLivecamSounds,
      };
      await saveToDB(data);

      return;
    }

    //reset messenger settings
    if (messengerReset) {
      const defaultMessageSound = _.merge(
        {},
        soundPreferences,
        DEFAULT_MESSAGE_SOUND
      );
      const defaultFontSize = _.merge({}, fontPreference, {
        selected: DEFAULT_CHAT_FONT_SIZE,
      });
      const data = {
        ...preferences,
        sounds: defaultMessageSound,
        volume: DEFAULT_VOLUME,
        fontsize: defaultFontSize,
      };
      await saveToDB(data);
      return;
    }

    //reset everything
    const data = {
      ...preferences,
      sounds: DEFAULT_SOUNDS,
      volume: DEFAULT_VOLUME,
    };
    await saveToDB(data);
  };
