export default {
  ROUTE_PERSONAL_DETAILS_HEADLINE: `Here you can change or update your personal and account information`,
  ACCOUNT_DETAILS_HEADLINE: `You are registered with these details`,
  FIELD_CURRENT_PASSWORD: `Current password:`,
  FIELD_NEW_PASSWORD: `New password:`,
  FIELD_REPEAT_PASSWORD: `Repeat password:`,

  PERSONAL_DATA: `Private personal data`,
  FIELD_STREET_ADDRESS: `Street address:`,
  FIELD_STREET_ADDRESS2: `Street continue:`,
  FIELD_ZIP_AND_CITY: `Zip & City:`,
  FIELD_LIVINGCOUNTRY: `Home country:`,
  FIELD_ZIP_CODE: `Zip Code`,
  FIELD_CONTACT: `Contact`,
  FIELD_PHONE: `Phone:`,
  FIELD_SKYPE: `Skype:`,
  PERSONAL_DETAILS_FOR_BUSINESS: `For businesses <span class="subtext">(optional)</span>`,
  FIELD_COMPANY: `Company name:`,
  FIELD_VAT: `VAT number:`,

  CHANGE_ADDRESS: 'Change address',
  CHANGE_ADDRESS_TEXT: `If your address has changed, you can enter your new address details here.
After you have confirmed your entry with 'Change address', it will be checked by the support.
The address details you provided during registration will apply until the check has been completed successfully.`,
  CHANGE_ADDRESS_BACK: 'Back',
  ADDRESS_DECLINED: 'Address declined',
  ADDRESS_PENDING_TITLE: `Address pending`,
  ADDRESS_PENDING_1: `Our support team is currently reviewing the new information you provided to ensure everything is accurate.
To view the details of your new address, please click`,
  ADDRESS_PENDING_2: 'here.',
  CHANGE_ADDRESS_ZIP: 'Zip:',
  CHANGE_ADDRESS_CITY: 'City:',

  ADDRESS_ERROR: `There has been an error submitting the request. Please try again.`,

  CHANGE_ADDRESS_STREET_TOO_LONG: `The street name must be at most 64 characters long`,
  CHANGE_ADDRESS_STREET2_TOO_LONG: `The Street continue text must be at most 64 characters long`,
  CHANGE_ADDRESS_ZIP_TOO_LONG: `The zip address must be at most 12 characters long`,
  CHANGE_ADDRESS_CITY_TOO_LONG: `The city name must be at most 64 characters long`,
  CHANGE_ADDRESS_VALIDATION_ERROR: 'These special characters are not supported',

  PERSONAL_DATA_ADDRESS_HINT: `If you would like to change your address information, {link}.`,
  PERSONAL_DATA_ADDRESS_HINT_LINK: `please contact support`,

  PERSONAL_DATA_INFO_HEADLINE: `Why and what do we need your data for?`,
  PERSONAL_DATA_INFO_CONTENT_1: `So that we can ensure that you are of legal age and that you have actually registered as a creator, we compare your details with the authentication documents you have uploaded.`,
  PERSONAL_DATA_INFO_CONTENT_2: `Of course, we also need this data for the payment of your commissions. In accordance with the Money Laundering Act, the recipient must also be clearly legitimized.`,
  PERSONAL_DATA_INFO_CONTENT_3: `All data you provide is of course subject to privacy policy.`,

  PERSONAL_DATA_TWO_FACTOR_INFO_HEADLINE: `How 2FA keeps your data safe`,
  PERSONAL_DATA_TWO_FACTOR_INFO_CONTENT_1: `Proident Lorem quis commodo deserunt aliquip.`,
  PERSONAL_DATA_TWO_FACTOR_INFO_CONTENT_2: `Incididunt labore ex irure aute officia irure anim in consectetur adipisicing esse reprehenderit aliqua.`,
  PERSONAL_DATA_TWO_FACTOR_INFO_CONTENT_3: `Duis sit duis aliqua sint sunt nulla commodo.`,

  BANK_DETAILS_HEADLINE: `Payout recepient`,
  BANK_DETAILS_TEXT_1: `If the payout recipient differs from the owner of the Creator account, the account holder's personal details must be provided here. In addition, a copy of the valid identity card or passport with registration certificate must be uploaded.`,
  BANK_DETAILS_TEXT_2: `If this data is not available, no payment can be made.`,
  BANK_DETAILS_ADDRESS_ERROR: `The address details are still missing!`,
  BANK_DETAILS_HEADLINE_2: `Bank details payout recipient`,
  BANK_DETAILS_MINIMUM_PAYOUT: `The minimum payout amount is {minimum_payout} EUR.`,
  BANK_DETAILS_PAYOUT_NO_COST: `There are no additional costs for the transaction.`,
  BANK_DETAILS_PAYOUT_COST: `We will pay via TransferWise in your local currency, which will incur additional transaction costs depending on your country of origin. You can see the potential costs at TransferWise. If you want to avoid these costs, please deposit a SEPA account with us.`,
  BANK_DETAILS_PAYOUT_NONE: `The minimum payout amount depends on the account type.`,
  BANK_ERROR: `There was an issue processing your name. Please contact support, as this may require manual review.`,
  SEPA_ACCOUNT: `SEPA Account`,
  NONE_SEPA: `Non-SEPA Account`,
  PAXUM: `Paxum`,
  ACCOUNT_DETAILS_LATER: `I will submit my bank details later`,
  FIELD_ACCOUNT_HOLDER: `Account holder:`,
  FIELD_IBAN: `IBAN:`,
  FIELD_BIC: `BIC/SWIFT:`,
  FIELD_BANK_PAX_EMAIL: `Email:`,
  FIELD_BANK_NAME: `Name o/t Bank:`,
  FIELD_BANK_ADDRESS: `Bank address:`,
  FIELD_BANK_CITY: `City o/t bank:`,
  FIELD_BANK_COUNTRY: `Country o/t bank:`,

  BANKHOLDER_DOCUMENT_MISSING: `Please upload the ID or passport of the payout recipient!`,
  BANKHOLDER_DOCUMENT_PENDING: `Your documents are currently being examined. Please be patient, thank you very much.`,
  BANKHOLDER_DOCUMENT_REJECTED: `The uploaded ID was rejected. If you have any questions, please contact the support.`,
  BANKHOLDER_DOCUMENT_CONFIRMED: `The ID of the payout recipient has been verified! Thank you.`,

  TWO_FACTOR_HEADLINE: `Two-factor authentication`,
  OPTIONS_TWO_FACTOR_METHOD: 'Two-factor method:',
  OPTIONS_TWO_FACTOR_DEFAULT: `Choose a method`,
  OPTIONS_TWO_FACTOR_APP: `Authenticator app`,
  OPTIONS_TWO_FACTOR_EMAIL: `Email`,
  TWO_FACTOR_EMAIL: `Email address:`,

  TWO_FACTOR_METHODS: `Two-factor methods`,
  TWO_FACTOR_METHOD_EMAIL: `Email`,
  TWO_FACTOR_METHOD_EMAIL_TEXT: `We will send you a one-time password via email. It is valid for 10 minutes.`,
  TWO_FACTOR_METHOD_APP: 'Authenticator app',
  TWO_FACTOR_METHOD_APP_TEXT:
    'An authenticator app (software) generates a one-time password. The validity is limited in time and is shown in the app.',

  TWO_FACTOR_HOW_TO: `How to`,
  TWO_FACTOR_QR_TEXT_1: `Download and install an authenticator app such as <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">Google Authenticator</a> or <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app">Microsoft Authenticator</a> on your smartphone`,
  TWO_FACTOR_QR_TEXT_2: `Open the app and scan the QR code below`,
  TWO_FACTOR_QR_TEXT_3: `Enter the code from the app in the field below`,
  TWO_FACTOR_QR_TEXT_4: `Click "Activate 2FA"`,

  TWO_FACTOR_LOGIN: `Add the code for verification`,
  TWO_FACTOR_LOGIN_TEXT: `To confirm your request please enter the code `,
  TWO_FACTOR_LOGIN_EMAIL: `that was sent to you by email.`,
  TWO_FACTOR_LOGIN_TOTP: `displayed in your authenticator app.`,

  TWO_FACTOR_RESET: `Enter the code to deactivate`,
  TWO_FACTOR_RESET_BUTTON: `Reset`,
  TWO_FACTOR_BACKUP_CODE_TEXT: `If you use a backup code, two-factor authentication will be reset. You can then set up a new 2FA method.`,
  TWO_FACTOR_LABEL: `Enter the code:`,

  TWO_FACTOR_USE_BACKUP: `If you have lost access to your smartphone or your emails, use a {link} to reset your 2FA.`,
  TWO_FACTOR_USE_BACKUP_LINK: `backup code`,
  TWO_FACTOR_USE_CODE: `I want to use the {link} {method} to reset 2FA.`,
  TWO_FACTOR_USE_CODE_LINK: `6-digit code`,
  TWO_FACTOR_USE_APP: `from my authenticator app`,
  TWO_FACTOR_USE_EMAIL: `from an email`,

  TWO_FACTOR_BACKUP_HEADER: `Backup codes`,
  TWO_FACTOR_BACKUP_TEXT: `Copy these codes and keep them safe. They guarantee that you can log into your LiveCreator account even if you no longer have access to your emails or smartphone.`,

  TWO_FACTOR_SEND_MAIL: `Send email with code`,
  TWO_FACTOR_ACTIVATE: `Activate 2FA`,
  TWO_FACTOR_VERIFY: `Verify`,
  TWO_FACTOR_LOADING: 'Verify...',
  TWO_FACTOR_RESEND: `Didn't receive the code or it has expired? `,
  TWO_FACTOR_RESEND_CLICK: `Send email again`,

  TWO_FACTOR_EMAIL_SEND: `An email was sent to you`,
  TWO_FACTOR_SUCCESS_TITLE: `Your account is secured`,
  TWO_FACTOR_SUCCESS_CONTENT: `You have successfully activated two-factor authentication!`,
  TWO_FACTOR_ERROR_CODE: `The code you entered is incorrect. Please try again.`,
  TWO_FACTOR_ERROR_EXPIRED: `The code you entered has expired. Please request a new code.`,
  TWO_FACTOR_ERROR: `An error occurred during the two-factor authentication process. Please try again later.`,

  TWO_FACTOR_INFO_HEADLINE: `Secure your data with two-factor authentication (2FA)`,
  TWO_FACTOR_INFO_CONTENT_1: `Two-factor authentication, or 2FA for short, is an additional security measure that can significantly improve the protection of your LiveCreator account. While traditional login only takes place with a password, this access can quickly be misused if the password is compromised. With 2FA, you need a second factor in addition to your password to log in. This makes it much more difficult for potential attackers to break into your account.`,
  TWO_FACTOR_INFO_CONTENT_2: `After entering your password, an additional security check takes place: you must enter a code that you receive either by email or via an authenticator app. This step ensures that it is actually you who wants to access the account.`,
  TWO_FACTOR_INFO_CONTENT_3: `You can only access LiveCreator content once your identity has been confirmed by both factors (password + code).`,
};

