import { MANAGEMENT_EVENTS_USER_ADD } from '../actions/actions';
import {
  ACTIVITY_TYPE_CONTENT_PURCHASE,
  ACTIVITY_TYPE_CUSTOMER_LOGIN,
  ACTIVITY_TYPE_FRIEND_LOGIN,
  ACTIVITY_TYPE_FRIEND_REQUEST,
  ACTIVITY_TYPE_PROFILE_VISIT,
  SHOW_TYPE_EXCLUSIVE,
  SHOW_TYPE_FREE,
  SHOW_TYPE_NORMAL,
  SHOW_TYPE_VOYEUR,
} from '../constants';
import * as userConstants from '../constants/user';
import { arrayUtils, gameUtils, objectUtils } from '.';

const defaultType = userConstants.TYPE_AC_EU;

const visibleAdmins = (userList) =>
  userList.filter((user) => user.visible && isAdmin(user));
const visibleExclusiveUser = (userList) =>
  userList.filter(
    (user) => user.visible && user.showType === SHOW_TYPE_EXCLUSIVE
  );
const visibleNormalUser = (userList) =>
  userList.filter((user) => user.visible && user.showType === SHOW_TYPE_NORMAL);
const visibleFreeUser = (userList) =>
  userList.filter((user) => user.visible && user.showType === SHOW_TYPE_FREE);
const visibleMessageUser = (userList) => userList.filter(isVisibleMessageUser);
const voyeurCount = (userList) =>
  userList.reduce(
    (count, user) =>
      user.visible && user.showType === SHOW_TYPE_VOYEUR ? count + 1 : count,
    0
  );

const livecamCount = (userList) =>
  userList.reduce(
    (count, user) =>
      user.visible &&
      (isAdmin(user) ||
        user.showType === SHOW_TYPE_NORMAL ||
        user.showType === SHOW_TYPE_EXCLUSIVE)
        ? count + 1
        : count,
    0
  );
const visibleUser = (userList) => [
  ...visibleAdmins(userList),
  ...visibleExclusiveUser(userList),
  ...visibleNormalUser(userList),
  ...visibleFreeUser(userList),
  ...visibleMessageUser(userList),
];
const visibleLivecamUser = (userList) => [
  ...visibleExclusiveUser(userList),
  ...visibleNormalUser(userList),
  ...visibleFreeUser(userList),
];
const firstVisibleUser = (userList) =>
  visibleUser(userList).length ? visibleUser(userList)[0] : null;
const isVisibleMessageUser = (u) =>
  u && u.type === 'dialog.fetched' && u.visible;
const isVisibleLivecamUser = (user) =>
  user.visible &&
  [SHOW_TYPE_EXCLUSIVE, SHOW_TYPE_FREE, SHOW_TYPE_NORMAL].includes(
    user.showType
  );
const activeUser = (userList) => userList.find((user) => user.active === true);

const checkDeleteOrIgnored = (dialog) =>
  dialog?.profile?.info?.status === 'deleted' ||
  dialog?.profile?.info?.status === 'blocked'
    ? 'deleted'
    : dialog?.profile?.ignored_by === true
    ? 'ignored'
    : null;

const hasVisibleUserOrVoyeur = (userList) =>
  visibleUser(userList).length + voyeurCount(userList) > 0;
const show = (userList, id, type, data, dialog = []) => {
  const foundUser = dialog.find((user) => {
    return user.userId === `${data.clientProductId}@${data.clientCustomerId}`;
  });
  const newData = objectUtils.update(data, {
    visible: true,
    type: type,
    notifiedTyping: false,
    text: '',
    message: '',
    hasCam2Cam: false,
    audioStream: data.audioStream === 1 || data.audioStream === true,
    flags: foundUser ? [...foundUser.flags] : ['unanswered'],
    active: false,
  });
  return arrayUtils.updateItem(userList, id, (user) =>
    objectUtils.update(user, newData)
  );
};

const hide = (userList, id) => {
  const newUserList = arrayUtils.updateItem(userList, id, (user) => {
    return objectUtils.update(user, { visible: false, active: false });
  });
  return newUserList;
};
const getType = (origin, systemId) => {
  switch (origin) {
    case 'EU':
      switch (true) {
        case systemId === 1:
          return userConstants.TYPE_AC_EU;
        case systemId > 1 && systemId <= 10000:
          return userConstants.TYPE_DATING;
        case systemId > 10000:
          return userConstants.TYPE_ACNG;
        default:
          return defaultType;
      }
    default:
      return defaultType;
  }
};

const add = (userList, id, item) => {
  const data = {
    id: id,
    visible: false,
    active: false,
    isDialogUser: false,
  };
  if (item.type === 'admin') {
    item.type = userConstants.TYPE_ADMIN;
  }
  return [objectUtils.update(item, data), ...userList];
};
const remove = (userList, id) => {
  const newUserList = userList.filter((user) => {
    if (user.id !== id) {
      return true;
    }
    return false;
  });
  return newUserList;
};
const selectUser = (userList, id, text) => {
  let result = userList.map((user) => {
    if (user.id !== id) {
      return objectUtils.update(user, { active: false });
    }
    const updates = {
      active: true,
      newMessage: false,
      newInList: false,
    };
    if (typeof text === 'string') {
      updates.text = text;
    }
    return objectUtils.update(user, updates);
  });
  return result;
};

const createStopEventAction = (
  userList,
  dialogList,
  type,
  payload,
  powersender,
  { filter: iFilter, messagesFilter, activities }
) => {
  // make sure we have a userId even if it is an admin call
  payload.userId = payload.userId || payload.adminSocketId;
  payload.powersender = powersender;

  const foundUser = userList.find((user) => user.userId === payload.userId);
  if (foundUser && foundUser.color) {
    payload.color = foundUser.color;
  }
  const action = {
    type,
    payload,
  };

  if (
    payload.showType !== SHOW_TYPE_VOYEUR &&
    payload.type !== userConstants.TYPE_ADMIN
  ) {
    action.meta = {
      userList,
    };
  }

  if (foundUser && foundUser.active) {
    const copyUserList = hide(userList, foundUser.userId);
    const partner = getPartner(foundUser);
    const foundVisibleUser = firstVisibleUser(copyUserList);
    let nextActiveUser = { userId: 'system' };

    if (foundVisibleUser) {
      nextActiveUser = foundVisibleUser;
    } else {
      const messageUser = dialogList.find((d) => d.partner === partner);
      if (messageUser) {
        nextActiveUser = messageUser;
      } else {
        let list = dialogList;
        if (messagesFilter) {
          list = filter(
            iFilter,
            messagesFilter,
            visibleUser(dialogList),
            activities
          );
        }

        const firstDialogUser = firstVisibleUser(list);
        if (firstDialogUser) {
          nextActiveUser = firstDialogUser;
        }
      }
    }
    action.meta = { ...action.meta, nextActiveUser, partner };
  }

  return {
    action,
    nextUserHasCam2Cam: action?.meta?.nextActiveUser?.hasCam2Cam,
  };
};

const isPrivateAudioSessionAllowed = (user, cam) => {
  if (
    !user ||
    !cam.selectedMicrophone ||
    cam.selectedMicrophone.value === false ||
    isAdmin(user) ||
    user.privateAudioSessionRequested
  ) {
    return false;
  }
  return true;
};
const createAddEventAction = ({
  state: {
    camUser: { camUsers },
    dialog: { dialogs },
    usercolor,
  },
  type,
  payload,
}) => {
  // make sure we have a userId even if it is an admin call
  if (payload.adminSocketId) {
    payload.type = userConstants.TYPE_ADMIN;
    payload.userId = payload.adminSocketId;
  } else {
    payload.type = getType(payload.origin, payload.systemId);
    const color = arrayUtils.findLowestCount(usercolor);
    payload.color = color && color.className ? color.className : null;
  }

  const action = {
    type,
    payload,
  };

  if (type === MANAGEMENT_EVENTS_USER_ADD) {
    action.camUsers = camUsers;
  }

  if (
    ((activeUser(dialogs) || {}).partner === getPartner(payload) ||
      visibleUser([...camUsers, ...dialogs]).length === 0 ||
      !activeUser([...camUsers, ...dialogs])) &&
    payload.showType !== SHOW_TYPE_VOYEUR
  ) {
    action.meta = { nextActiveUser: payload };
  } else if (payload.showType !== SHOW_TYPE_VOYEUR) {
    payload.newInList = true;
  }

  action.meta = { ...action?.meta, dialog: dialogs };

  return action;
};

const isAdmin = (user) => user?.type && user.type === userConstants.TYPE_ADMIN;

const getDisplayInfo = (
  type,
  mobile,
  multipleOrigins,
  portalName,
  hasFlag = false
) => {
  const acDefaultInfo = {
    text: userConstants.TEXT_AC,
    icon: mobile ? userConstants.ICON_AC_MOBILE : userConstants.ICON_AC,
  };
  switch (type) {
    case userConstants.TYPE_AC_EU:
      if (!multipleOrigins || hasFlag) return acDefaultInfo;
      return {
        text: userConstants.TEXT_AC_EU,
        icon: mobile
          ? userConstants.ICON_AC_EU_MOBILE
          : userConstants.ICON_AC_EU,
      };
    case userConstants.TYPE_DATING:
      return {
        text: userConstants.TEXT_DATING,
        icon: mobile
          ? userConstants.ICON_DATING_USER_MOBILE
          : userConstants.ICON_DATING_USER,
      };
    case userConstants.TYPE_ADMIN:
      return {
        text: userConstants.TEXT_ADMIN,
        icon: userConstants.ICON_ADMIN,
      };
    case userConstants.TYPE_ACNG:
      return {
        text: portalName
          ? userConstants.TEXT_ACNG + ' ' + portalName
          : userConstants.TEXT_ACNG,
        icon: mobile
          ? userConstants.ICON_ACNG_MOBILE
          : hasFlag
          ? userConstants.ICON_ACNG_CLEAN
          : userConstants.ICON_ACNG,
      };
    default:
      return acDefaultInfo;
  }
};
const enablePrivateAudioSession = (userList, userId) => {
  return userList.map((user) => {
    if (user.userId === userId) {
      user.privateAudioSessionRequested = false;
      user.hasPrivateAudioSession = true;
    } else {
      user.privateAudioSessionRequested = false;
      user.hasPrivateAudioSession = false;
    }
    return user;
  });
};
const disablePrivateAudioSession = (userList, userId) => {
  return userList.map((user) => {
    if (user.userId === userId) {
      user.privateAudioSessionRequested = false;
      user.hasPrivateAudioSession = false;
    }
    return user;
  });
};
const requestPrivateAudioSession = (userList, userData) => {
  if (typeof userData !== 'object' || typeof userData.userId !== 'string') {
    return userList;
  }
  return userList.map((user) => {
    if (user.userId === userData.userId) {
      user.privateAudioSessionRequested = true;
    }
    return user;
  });
};

const privateAudioSessionUser = (userList) => {
  const pasUserList = userList.filter((user) => {
    return user.privateAudioSessionRequested || user.hasPrivateAudioSession;
  });
  if (pasUserList.length === 0) {
    return null;
  }
  return pasUserList.shift();
};
const typing = (userList, userData) => {
  if (typeof userData !== 'object' || typeof userData.userId !== 'string') {
    return userList;
  }
  return userList.map((user) => {
    if (user.userId === userData.userId) {
      user.typing = userData.status;
    }
    return user;
  });
};

const notifyTyping = (userList, userData) => {
  if (typeof userData !== 'object' || typeof userData.userId !== 'string') {
    return userList;
  }
  return userList.map((user) => {
    if (user.userId === userData.userId) {
      user.notifiedTyping = userData.status;
    }
    return user;
  });
};
const senderTyping = (userList, userData) => {
  if (typeof userData !== 'object' || typeof userData.userId !== 'string') {
    return userList;
  }
  return userList.map((user) => {
    if (user.userId === userData.userId) {
      user.text = userData.text;
    }
    return user;
  });
};
const addMessage = (userList, messageData) => {
  return userList.map((user) => {
    if (user.userId === messageData.userId) {
      user.message = messageData.message;
    }
    return user;
  });
};
const get = (userList, userId) =>
  userList.find((user) => user.userId === userId);

const startUpgrade = (
  userList,
  {
    payload: { showType, upgrades, userId, duplicateName, showStartTime },
    meta: { nextActiveUser, dialog },
  }
) => {
  const user = Object.assign({}, get(userList, userId), {
    upgrades,
    showType,
    showStartTime,
  });
  let list = show(userList, user.userId, user.type, user, dialog);

  switch (showType) {
    case SHOW_TYPE_EXCLUSIVE:
      if (!nextActiveUser.isDialogUser) {
        list = selectUser(list, user.userId);
      }
      break;
    case SHOW_TYPE_NORMAL: {
      const normalUser = get(list, userId);
      list = remove(userList, userId);
      const partner = getPartner(normalUser);
      if (nextActiveUser?.partner && nextActiveUser?.partner !== partner) {
        normalUser.active = false;
        normalUser.newInList = true;
      }
      normalUser.duplicateName = duplicateName;
      list.push(normalUser);
      break;
    }
    default:
  }

  return list;
};

const findExclusiveUpgrade = ({ upgrades = [] }) => {
  return upgrades.find((u) => u.showType === SHOW_TYPE_EXCLUSIVE);
};

const enableCam2Cam = (userList, userId) => {
  return userList.map((user) => {
    if (user.userId === userId) {
      user.hasCam2Cam = true;
    }
    return user;
  });
};

const disableCam2Cam = (userList, userId) => {
  return userList.map((user) => {
    if (user.userId === userId) {
      user.hasCam2Cam = false;
    }
    return user;
  });
};

const startCam2Cam = (userList, userId) => {
  return userList.map((user) => {
    if (user.userId === userId) {
      user.cam2camActive = true;
    }
    return user;
  });
};

const stopCam2Cam = (userList, userId) => {
  return userList.map((user) => {
    if (user.userId === userId) {
      user.cam2camActive = false;
    }
    return user;
  });
};

const cam2camUser = (userList) => {
  const cam2camUserList = userList.filter((user) => {
    return user.cam2camActive;
  });
  if (cam2camUserList.length === 0) {
    return [];
  }
  return cam2camUserList;
};
const isKickable = (user) =>
  !isAdmin(user) &&
  user.userId !== 'system' &&
  user.showType !== SHOW_TYPE_VOYEUR;

const activeUserIndex = (userList) =>
  userList.findIndex((user) => user.active === true);

const getUserAfterOrBefore = (userListState, after = true) => {
  const userList = objectUtils.clone(userListState).filter((u) => u.visible);
  if (userList.length < 2) {
    return null;
  }

  // sort admins to the bottom of the list, as list gets reverted in view
  userList.sort((userA, userB) => {
    if (isAdmin(userA) && !isAdmin(userB)) {
      return -1;
    }
    if (isAdmin(userB) && !isAdmin(userA)) {
      return 1;
    }
    return 0;
  });

  const indexActiveUser = activeUserIndex(userList);

  // should not happen
  if (indexActiveUser < 0) {
    return null;
  }

  const searchUserIndex = after ? indexActiveUser + 1 : indexActiveUser - 1;

  if (!userList[searchUserIndex]) {
    return null;
  }

  if (!userList[searchUserIndex].visible) {
    return null;
  }
  return userList[searchUserIndex];
};

const getNextUser = (userList) => getUserAfterOrBefore(userList);
const getLastUser = (userList) => getUserAfterOrBefore(userList, false);
const isVoyeur = (user) =>
  !!(user && user.showType && user.showType === SHOW_TYPE_VOYEUR);

const filter = (filter, messagesFilter, userList, activities) => {
  const isActivityFilter = messagesFilter.startsWith('activities');
  let filteredList = [...userList];

  if (isActivityFilter) {
    const activityFilter = messagesFilter.slice(11);
    filteredList = userList.filter((user) => {
      return (
        !user.isDialogUser ||
        !!activities[activityFilter].find((a) => a.userId === user.partner)
      );
    });
  } else if (messagesFilter === 'all') {
    filteredList = userList.filter(
      (user) => !(user.isDialogUser && user.flags?.includes('noRemoteDialog'))
    );
  } else if (messagesFilter !== '') {
    filteredList = userList.filter((user) => {
      if (!user.isDialogUser) return true;
      if (user.flags?.includes('noRemoteDialog')) {
        return false;
      }
      return messagesFilter === 'unread'
        ? !user.read
        : messagesFilter === 'new'
        ? user.newInList
        : messagesFilter === 'games'
        ? user.flags?.includes('game') || user.flags?.includes('gameInvitation')
        : user.flags?.includes(messagesFilter);
    });
  }
  if (filter !== '') {
    filteredList = filteredList.filter((user) => {
      return (
        user.name?.toLowerCase().includes(filter.toLowerCase()) ||
        isVisibleLivecamUser(user)
      );
    });
  }
  return filteredList;
};
const hasPartner = (user) => user?.clientProductId && user?.clientCustomerId;
const getPartner = (user) =>
  hasPartner(user) ? `${user.clientProductId}@${user.clientCustomerId}` : '';
const getUserId = (user) =>
  hasPartner(user) ? getPartner(user) : user?.userId;

const hasDatingPartner = (user) => user?.datingCustomerId && user?.systemId;
const getDatingProductId = (user) => user.systemId + 50000;
const getDatingPartner = (user) =>
  hasDatingPartner(user)
    ? `${getDatingProductId(user)}@${user.datingCustomerId}`
    : '';

const isDatingUser = (user) => user?.type === userConstants.TYPE_DATING;

const adaptUserId = (userId) => userId?.replace('-', '@');

const hasActiveUser = (userList) => !!activeUser(userList);

const isDialogUser = (userType) =>
  [userConstants.TYPE_AC_EU, userConstants.TYPE_ACNG].indexOf(userType) >= 0;

const checkFlags = (flagsValue, key, value) => {
  if (!flagsValue.length) {
    return flagsValue;
  }
  const flags = [...flagsValue];
  const includesFlag = flags.includes(key);
  if (includesFlag && !value) {
    return flags.filter((el) => el !== key);
  }
  if (!includesFlag && value) {
    flags.push(key);
  }
  return flags;
};

const checkBankholderAddress = (userPrivate) =>
  userPrivate?.bankholder_document === 'same_name' ||
  (userPrivate?.bank_address1 &&
    userPrivate?.bank_zip &&
    userPrivate?.bank_city &&
    userPrivate?.bank_country);

const userLoggedInAndConfirmed = (state) =>
  state.user?.isLoggedIn && state.user?.account?.state === 'confirmed';

const gamesEnabled = (state) =>
  userLoggedInAndConfirmed(state) && state.user?.account.game === 'enabled';

const gamesBlocked = (state) =>
  userLoggedInAndConfirmed(state) && state.user?.account.game === 'blocked';

const hasGamesScope = (state) => state.user?.account?.scopes.includes('games');
const hasLivecamUser = (id, camUser) => {
  const clientIds = id.split('@');
  const clientCustomerId = clientIds[1];
  const clientProductId = parseInt(clientIds[0], 10);
  return !!camUser.find(
    (u) =>
      u.clientCustomerId === clientCustomerId &&
      u.clientProductId === clientProductId
  );
};

const checkActivity = (type, state, activeUser) =>
  state.camUserFilter.activities[type].findIndex(
    (d) => d.userId === activeUser?.userId
  ) >= 0;
const isOnlyProfileVisitUser = (state, activeUser) =>
  activeUser?.flags?.includes('noRemoteDialog') &&
  checkActivity('profile', state, activeUser) &&
  !checkActivity('content', state, activeUser) &&
  !checkActivity('friend', state, activeUser) &&
  !checkActivity('friendRequest', state, activeUser) &&
  !checkActivity('online', state, activeUser);

const hasRevenueOrIsFriend = (user) =>
  user?.flags?.includes('friend') || user?.profile?.turnover?.total > 0;

const disableChat = (state, activeUser) =>
  isOnlyProfileVisitUser(state, activeUser) &&
  !hasRevenueOrIsFriend(activeUser) &&
  !gameUtils.isActive(gameUtils.getGame(state, activeUser.partner)) &&
  !state.chat.containsNonIcebreakerMessages;

const getHighestActivityState = (state, activeUser) => {
  if (!activeUser?.flags?.includes('noRemoteDialog')) {
    return '';
  }
  if (checkActivity('friend', state, activeUser))
    return ACTIVITY_TYPE_FRIEND_LOGIN;
  if (checkActivity('online', state, activeUser))
    return ACTIVITY_TYPE_CUSTOMER_LOGIN;
  if (checkActivity('friendRequest', state, activeUser))
    return ACTIVITY_TYPE_FRIEND_REQUEST;
  if (checkActivity('content', state, activeUser))
    return ACTIVITY_TYPE_CONTENT_PURCHASE;
  if (
    checkActivity('profile', state, activeUser) &&
    !hasRevenueOrIsFriend(activeUser) &&
    !gameUtils.isActive(gameUtils.getGame(state, activeUser.partner))
  )
    return ACTIVITY_TYPE_PROFILE_VISIT;

  return '';
};

const showGame = (user) =>
  user?.flags?.includes('game') || user?.flags?.includes('gameDecided');

const isIcebreakerAllowed = (user) =>
  ['true', 'false'].indexOf(user?.account?.icebreaker) >= 0;

const isIcebreakerEnabled = (user) =>
  user?.account?.capacity?.status === 'enabled' &&
  user?.account?.icebreaker === 'true';

const filterPersonalData = (personalResponse) => {
  if (!personalResponse?.data) {
    return null;
  }
  const personalData = personalResponse.data;
  const result = {};
  if (personalData?.personal) {
    const personal = objectUtils.filter(personalData.personal, (el) => !el);
    if (Object.keys(personal).length > 0) {
      result.personal = personal;
    }
  }
  if (personalData?.my_preferences) {
    const my_preferences = objectUtils.filter(
      personalData.my_preferences,
      (el) => Array.isArray(el) && Object.keys(el).length === 0
    );
    if (Object.keys(my_preferences).length > 0) {
      result.my_preferences = my_preferences;
    }
  }
  if (personalData?.more_info) {
    const more_info = objectUtils.filter(
      personalData.more_info,
      (el) => !el || el?.length === 0
    );
    if (Object.keys(more_info).length > 0) {
      result.more_info = more_info;
    }
  }
  if (!Object.keys(result).length) {
    return null;
  }

  return result;
};

const isExclusiveShow = (user) => user?.showType === SHOW_TYPE_EXCLUSIVE;
const isAppUser = (user) => user?.productId >= 1000;
const isLivecamUserId = (userId) => !userId?.match(/@/g);
const getShowVeriff = (user) => {
  const veriff = user?.account?.veriff || {};
  if (veriff.access === 'enabled' && veriff.status === 'mandatory') return true;
  return false;
};
const getAdminName = (name) => `Admin ${name}`;

const createName = (name, flags) =>
  flags.includes('is_support') ? getAdminName(name) : name;

export default {
  add,
  filter,
  remove,
  show,
  hide,
  getType,
  selectUser,
  visibleUser,
  voyeurCount,
  livecamCount,
  activeUser,
  createStopEventAction,
  createAddEventAction,
  isAdmin,
  getDisplayInfo,
  typing,
  notifyTyping,
  senderTyping,
  addMessage,
  get,
  startUpgrade,
  findExclusiveUpgrade,
  isPrivateAudioSessionAllowed,
  requestPrivateAudioSession,
  enablePrivateAudioSession,
  disablePrivateAudioSession,
  privateAudioSessionUser,
  enableCam2Cam,
  disableCam2Cam,
  cam2camUser,
  stopCam2Cam,
  startCam2Cam,
  isKickable,
  getNextUser,
  getLastUser,
  hasVisibleUserOrVoyeur,
  isVoyeur,
  visibleLivecamUser,
  isVisibleLivecamUser,
  hasPartner,
  getPartner,
  hasActiveUser,
  firstVisibleUser,
  isVisibleMessageUser,
  adaptUserId,
  isDialogUser,
  checkFlags,
  checkBankholderAddress,
  userLoggedInAndConfirmed,
  gamesEnabled,
  gamesBlocked,
  hasGamesScope,
  hasLivecamUser,
  isOnlyProfileVisitUser,
  getHighestActivityState,
  hasRevenueOrIsFriend,
  disableChat,
  showGame,
  isIcebreakerAllowed,
  isIcebreakerEnabled,
  filterPersonalData,
  isAppUser,
  isLivecamUserId,
  getDatingPartner,
  hasDatingPartner,
  isDatingUser,
  isExclusiveShow,
  checkDeleteOrIgnored,
  getShowVeriff,
  getAdminName,
  createName,
  getUserId,
};
