import './MyProfile.scss';

import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import logoAMF from '../../../assets/img/logoAMF.png';
import logoAMX from '../../../assets/img/logoAMX.png';
import logoFUN from '../../../assets/img/logoFUN.png';
import posingImage1 from '../../../assets/img/profile-pictures/posing1.jpg';
import posingImage2 from '../../../assets/img/profile-pictures/posing2.jpg';
import posingImage3 from '../../../assets/img/profile-pictures/posing3.jpg';
import posingImage4 from '../../../assets/img/profile-pictures/posing4.jpg';
import posingImage5 from '../../../assets/img/profile-pictures/posing5.jpg';
import posingImage6 from '../../../assets/img/profile-pictures/posing6.jpg';
import videoPosterImgDe from '../../../assets/img/videoPoster/profile_image_de.jpg';
import videoPosterImgEn from '../../../assets/img/videoPoster/profile_image_en.jpg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar';
import VideoWithOverlay from '../../VideoWithOverlay';
import { getValidationScheme } from './MyProfile.functions';

function MyProfile({ initialValues, items, language }) {
  const intl = useIntl();
  const history = useHistory();
  const isLarge = useMediaQuery('(min-width: 1600px)');
  const [isLargeScreen, setIsLargeScreen] = useState(isLarge);
  const isFullscreen = useMediaQuery('(display-mode: fullscreen)');

  const video =
    language === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/profile_image2_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/profile_image2_de.mp4`;
  const videoPosterImg =
    language === 'en' ? videoPosterImgEn : videoPosterImgDe;

  useEffect(() => {
    if (!isFullscreen) {
      setIsLargeScreen(isLarge);
    }
  }, [isLarge, isFullscreen]);

  const routes = items.map((route, i) => {
    return (
      <Route
        key={`/my-account/my-profile${route.path}`}
        path={`/my-account/my-profile${route.path}`}
        component={route.component}
      />
    );
  });

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-account-wrapper my-profile-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={getValidationScheme(intl)}
            >
              <Switch>
                {routes}
                <Redirect to="/" />
              </Switch>
            </Formik>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper profile-pictures">
            {history.location.pathname ===
              '/my-account/my-profile/profile-pictures' && (
              <>
                <h2 className="headline">
                  <FormattedMessage id="PROFILE_PICTURES_VIDEO_HEADLINE" />
                </h2>
                <VideoWithOverlay
                  video={video}
                  poster={videoPosterImg}
                  wikiLink="/wiki/start#profileImage"
                />

                <div className="profile-picture-grid">
                  <div className="profile-picture-grid-col">
                    <h3 className="headline">
                      <FormattedMessage id="PROFILE_PICTURE_POSING_HEADLINE" />
                    </h3>
                    <p className="has-margin-bottom">
                      <FormattedMessage id="PROFILE_PICTURE_POSING" />
                    </p>
                    <div className="posing">
                      <img src={posingImage1} alt="Posing" />
                      <img src={posingImage2} alt="Posing" />
                      <img src={posingImage3} alt="Posing" />
                    </div>
                  </div>
                  <div className="profile-picture-grid-col">
                    <h3 className="headline">
                      <FormattedMessage id="PROFILE_PICTURE_POSING_NOFACE_HEADLINE" />
                    </h3>
                    <p className="has-margin-bottom">
                      <FormattedMessage id="PROFILE_PICTURE_NOFACE_POSING" />
                    </p>
                    <div className="posing">
                      <img src={posingImage4} alt="Posing" />
                      <img src={posingImage5} alt="Posing" />
                      <img src={posingImage6} alt="Posing" />
                    </div>
                  </div>
                </div>

                <p className="profile-pictures-text">
                  <FormattedMessage id="PROFILE_PICTURES_INFO_TEXT">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
              </>
            )}
            {history.location.pathname ===
              '/my-account/my-profile/profile-video' && (
              <div className="profile-video-info">
                <h2 className="headline">
                  <FormattedMessage id="PROFILE_VIDEO_INFO_HEADLINE" />
                </h2>
                <p>
                  <FormattedMessage id="PROFILE_VIDEO_INFO_TEXT_1" />
                </p>
                <p>
                  <FormattedMessage id="PROFILE_VIDEO_INFO_TEXT_2" />
                </p>
                <p>
                  <FormattedMessage id="PROFILE_VIDEO_INFO_TEXT_3">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
              </div>
            )}
            {history.location.pathname ===
              '/my-account/my-profile/profile-text' && (
              <div className="profile-text-info">
                <h2 className="headline">
                  <FormattedMessage id="PROFILE_TEXT_INFO_HEADLINE" />
                </h2>
                <p>
                  <FormattedMessage id="PROFILE_TEXT_INFO_TEXT_1" />
                </p>
              </div>
            )}
            {history.location.pathname ===
              '/my-account/my-profile/public-details' && (
              <div className="public-details-info">
                <h2 className="headline">
                  <FormattedMessage id="PUBLIC_DETAILS_INFO_HEADLINE" />
                </h2>
                <p>
                  <FormattedMessage id="PUBLIC_DETAILS_INFO_TEXT_1" />
                </p>
                <p>
                  <FormattedMessage id="PUBLIC_DETAILS_INFO_TEXT_2" />
                </p>
              </div>
            )}

            {history.location.pathname ===
              '/my-account/my-profile/my-public-profile' && (
              <div className="my-public-profile-info">
                <h2 className="headline">
                  <FormattedMessage id="MY_PUBLIC_DETAILS_INFO_HEADLINE" />
                </h2>
                <p>
                  <FormattedMessage id={'MY_PUBLIC_DETAILS_INFO_TEXT_1'}>
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <div className="logos">
                  <img src={logoAMX} alt="AmateurX" />
                  <img src={logoFUN} alt="FunCommunity" />
                  <img src={logoAMF} alt="AmateurFans" />
                </div>
              </div>
            )}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default MyProfile;
