import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import { default as locales } from '../locale';
import logger from '../middleware/logger';
import sidebarMiddleware from '../middleware/sidebar';
import reducers from '../reducers';
import { lStorage, sStorage } from '../services/storage';
import { storage } from '../utils';

let locale = 'en'; // set a default
// try retrieve the browser language
const browserLang = String(
  window.navigator.language || window.navigator.userLanguage
)
  .split('-')
  .shift();

// only use our allowed languages
if (['de', 'en'].includes(browserLang)) {
  locale = browserLang;
}

// clear storage on admin login
if (window.location?.pathname?.startsWith('/login-with-token')) {
  storage.clear();
}

// try to get/save language from/to localStorage
locale = lStorage.getItem('lang') || locale;

let user = {};

const userFromStorage = lStorage.getItem('user') || sStorage.getItem('user');
if (userFromStorage) {
  try {
    const { access_token, expires_in, refresh_token, token_type } =
      JSON.parse(userFromStorage);
    user = { access_token, expires_in, refresh_token, token_type };
  } catch (err) {
    console.log({ err });
  }
}

document.documentElement.lang = locale;

lStorage.setItem('lang', locale);

const intl = {
  locale,
  messages: locales[locale],
};

const preloadedState = {
  intl,
  user,
};

export default createStore(
  reducers,
  preloadedState,
  composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })(applyMiddleware(thunk, sidebarMiddleware, logger))
);
