export default {
  ROUTE_PERSONAL_DETAILS_HEADLINE: `Hier kannst Du Deine persönlichen Daten und Accountdaten ändern`,
  ACCOUNT_DETAILS_HEADLINE: `Deine bei uns hinterlegten Daten`,
  FIELD_CURRENT_PASSWORD: `Aktuelles Passwort:`,
  FIELD_NEW_PASSWORD: `Neues Passwort:`,
  FIELD_REPEAT_PASSWORD: `Passwort wiederholen:`,

  PERSONAL_DATA: `Private Daten`,
  FIELD_STREET_ADDRESS: `Straße:`,
  FIELD_STREET_ADDRESS2: `Adresszusatz:`,
  FIELD_ZIP_AND_CITY: `PLZ & Ort:`,
  FIELD_LIVINGCOUNTRY: `Land:`,
  FIELD_ZIP_CODE: `Postleitzahl`,
  FIELD_CONTACT: `Kontakt`,
  FIELD_PHONE: `Telefon:`,
  FIELD_SKYPE: `Skype:`,
  PERSONAL_DETAILS_FOR_BUSINESS: `Für Unternehmen <span class="subtext">(optional)</span>`,
  FIELD_COMPANY: `Name des Unternehmens:`,
  FIELD_VAT: `Steuernummer:`,

  CHANGE_ADDRESS: 'Adresse ändern',
  CHANGE_ADDRESS_TEXT: `Sollte sich Deine Anschrift geändert haben, kannst Du hier Deine neuen Adressdaten eingeben.
Nachdem Du Deine Eingabe mit 'Adresse ändern' bestätigt hast, wird diese durch den Support geprüft.
Bis die Prüfung positiv abgeschlossen wurde, gelten die Adressdaten, die Du bei der Registrierung angegeben hast.`,
  CHANGE_ADDRESS_BACK: 'Zurück',
  ADDRESS_DECLINED: 'Adresse abgelehnt',
  ADDRESS_PENDING_TITLE: `Adresse ausstehend`,
  ADDRESS_PENDING_1: `Unser Support-Team überprüft derzeit die von Dir bereitgestellten neuen Informationen, um sicherzustellen, dass alles korrekt ist.
Um die Details Deiner neuen Adresse anzusehen, klicke`,
  ADDRESS_PENDING_2: 'hier.',
  CHANGE_ADDRESS_ZIP: 'PLZ:',
  CHANGE_ADDRESS_CITY: 'Ort:',

  ADDRESS_ERROR: `Es ist ein Fehler beim Versenden aufgetreten. Bitte versuche es noch einmal.`,

  CHANGE_ADDRESS_STREET_TOO_LONG:
    'Der Straßenname darf höchstens 64 Zeichen lang sein',
  CHANGE_ADDRESS_STREET2_TOO_LONG:
    'Der zusätzliche Adresszusatz darf höchstens 64 Zeichen lang sein',
  CHANGE_ADDRESS_ZIP_TOO_LONG:
    'Die Postleitzahl darf höchstens 12 Zeichen lang sein',
  CHANGE_ADDRESS_CITY_TOO_LONG:
    'Der Stadtname darf höchstens 64 Zeichen lang sein',
  CHANGE_ADDRESS_VALIDATION_ERROR: 'Diese Sonderzeichen sind nicht erlaubt',

  PERSONAL_DATA_ADDRESS_HINT: `Falls Du Deine Adressdaten ändern möchtest, {link}.`,
  PERSONAL_DATA_ADDRESS_HINT_LINK: `kontaktiere bitte den Support`,

  PERSONAL_DATA_INFO_HEADLINE: `Warum und wofür benötigen wir Deine Daten?`,
  PERSONAL_DATA_INFO_CONTENT_1: `Damit wir sicherstellen können, dass Du volljährig bist und Deine Anmeldung als Creator auch wirklich von Dir erfolgt ist, gleichen wir Deine Angaben mit den von Dir hochgeladenen Legitimationsdokumenten ab.`,
  PERSONAL_DATA_INFO_CONTENT_2: `Außerdem benötigen wir diese Daten natürlich auch für die Auszahlung Deiner Provisionen. Hierbei muss gemäß Geldwäschegesetz ebenfalls eine eindeutige Legitimation des Empfängers erfolgen.`,
  PERSONAL_DATA_INFO_CONTENT_3: `Alle Deine Angaben unterliegen selbstverständlich dem Datenschutzgesetz.`,

  BANK_DETAILS_HEADLINE: `Auszahlungsempfänger`,
  BANK_DETAILS_TEXT_1: `Sollte der Inhaber des Auszahlungskontos vom Inhaber des Creator-Accounts abweichen, müssen hier die entsprechenden persönlichen Daten des Kontoinhabers angegeben werden. Außerdem muss eine Kopie des gültigen Personalausweises bzw. Reisepasses mit Meldebescheinigung hochgeladen werden.`,
  BANK_DETAILS_TEXT_2: `Liegen diese Daten nicht vor, kann keine Auszahlung erfolgen.`,
  BANK_DETAILS_ADDRESS_ERROR: `Es fehlen noch Adressdaten!`,
  BANK_DETAILS_HEADLINE_2: `Bankdaten des Empfängers`,
  BANK_DETAILS_MINIMUM_PAYOUT: `Der Mindestauszahlungbetrag beträgt {minimum_payout} EUR`,
  BANK_DETAILS_PAYOUT_NO_COST: `Es fallen keine zusätzlichen Transaktionskosten an.`,
  BANK_DETAILS_PAYOUT_COST: `Wir zahlen via TransferWise in Deiner Landeswährung aus, worüber Dir abhängig Deines Heimatlandes zusätzliche Transaktionskosten entstehen. Du kannst die potentiell anfallenden Kosten bei TransferWise einsehen. Möchtest Du diese Kosten vermeiden, hinterlege bei uns bitte ein SEPA Konto.`,
  BANK_DETAILS_PAYOUT_NONE: `Der Mindestauszahlungsbetrag ist abhängig von der Art der Auszahlung.`,
  BANK_ERROR: `Beim Verarbeiten Deines Namens ist ein Problem aufgetreten. Bitte wende Dich an den Support`,
  SEPA_ACCOUNT: `SEPA Konto`,
  NONE_SEPA: `Kein SEPA Konto`,
  PAXUM: `Paxum`,
  ACCOUNT_DETAILS_LATER: `Ich gebe meine Bankdaten später an`,
  FIELD_ACCOUNT_HOLDER: `Kontoinhaber:`,
  FIELD_IBAN: `IBAN:`,
  FIELD_BIC: `BIC/SWIFT:`,
  FIELD_BANK_PAX_EMAIL: `E-Mail:`,
  FIELD_BANK_NAME: `Name der Bank:`,
  FIELD_BANK_ADDRESS: `Adresse der Bank:`,
  FIELD_BANK_CITY: `Stadt der Bank:`,
  FIELD_BANK_COUNTRY: `Land der Bank:`,

  BANKHOLDER_DOCUMENT_MISSING: `Bitte lade den Ausweis oder Reisepass des Auszahlungsempfängers hoch!`,
  BANKHOLDER_DOCUMENT_PENDING: `Es befinden sich entsprechende Dokumente in der Prüfung. Bitte habe etwas Geduld, vielen Dank.`,
  BANKHOLDER_DOCUMENT_REJECTED: `Der hochgeladene Ausweis wurde abgelehnt. Bei Fragen kontaktiere bitte den Support.`,
  BANKHOLDER_DOCUMENT_CONFIRMED: `Der Ausweis des Auszahlungsempfängers wurde verifiziert! Vielen Dank.`,

  TWO_FACTOR_HEADLINE: `Zwei-Faktor-Authentisierung`,
  OPTIONS_TWO_FACTOR_METHOD: 'Zwei-Faktor Methode:',
  OPTIONS_TWO_FACTOR_DEFAULT: `Wähle eine Methode`,
  OPTIONS_TWO_FACTOR_APP: `Authenticator App`,
  OPTIONS_TWO_FACTOR_EMAIL: `E-Mail`,
  TWO_FACTOR_EMAIL: `E-Mail Adresse:`,

  TWO_FACTOR_METHODS: `Zwei-Faktor Methoden`,
  TWO_FACTOR_METHOD_EMAIL: `E-Mail`,
  TWO_FACTOR_METHOD_EMAIL_TEXT: `Wir senden Dir per E-Mail ein Einmalkennwort. Die Gültigkeit ist auf 10 Minuten begrenzt.`,
  TWO_FACTOR_METHOD_APP: 'Authenticator App',
  TWO_FACTOR_METHOD_APP_TEXT:
    'Eine Authenticator App (Software) generiert ein Einmalkennwort. Die Gültigkeit ist zeitlich begrenzt und wird Dir in der App angezeigt.',

  TWO_FACTOR_HOW_TO: `So geht's`,
  TWO_FACTOR_QR_TEXT_1: `Lade Dir eine Authenticator-App wie <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=de">Google Authenticator</a> oder <a target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/de-de/security/mobile-authenticator-app">Microsoft Authenticator</a> auf Dein Smartphone herunter und installiere sie`,
  TWO_FACTOR_QR_TEXT_2: `Öffne die App und scanne den nachfolgenden QR-Code`,
  TWO_FACTOR_QR_TEXT_3: `Gebe den Code aus der App in das Feld unten ein`,
  TWO_FACTOR_QR_TEXT_4: `Klicke auf "2FA aktivieren"`,

  TWO_FACTOR_LOGIN: `Gib den Code zur Überprüfung ein`,
  TWO_FACTOR_LOGIN_TEXT: `Um Deine Anfrage zu bestätigen, gib bitte den Code ein, `,
  TWO_FACTOR_LOGIN_EMAIL: `der Dir per E-Mail gesendet wurde.`,
  TWO_FACTOR_LOGIN_TOTP: `der in Deiner Authenticator-App angezeigt wird.`,

  TWO_FACTOR_RESET: `Gib den Code zum Deaktivieren ein`,
  TWO_FACTOR_RESET_BUTTON: `Zurücksetzen`,
  TWO_FACTOR_BACKUP_CODE_TEXT: `Wenn Du einen Backup-Code verwendest, wird die Zwei-Faktor-Authentifizierung zurückgesetzt. Du kannst danach eine neue 2FA-Methode einrichten.`,
  TWO_FACTOR_LABEL: `Code eingeben:`,

  TWO_FACTOR_USE_BACKUP: `Wenn Du den Zugriff auf Dein Smartphone oder Deine E-Mails verloren hast, nutze einen {link}, um Deine 2FA zurückzusetzen.`,
  TWO_FACTOR_USE_BACKUP_LINK: `Backup-Code`,
  TWO_FACTOR_USE_CODE: `Ich möchte einen {link} {method} nutzen, um 2FA zurückzusetzen.`,
  TWO_FACTOR_USE_CODE_LINK: `6-stelligen Code`,
  TWO_FACTOR_USE_APP: `aus meiner Authenticator App`,
  TWO_FACTOR_USE_EMAIL: `aus einer E-Mail`,

  TWO_FACTOR_BACKUP_HEADER: `Backup-Codes`,
  TWO_FACTOR_BACKUP_TEXT: `Kopiere diese Codes und bewahre sie sicher auf. Sie garantieren Dir den Login in Deinen Account bei LiveCreator, auch wenn Du keinen Zugriff mehr auf Deine E-Mails oder Dein Smartphone hast.`,

  TWO_FACTOR_SEND_MAIL: `E-Mail mit Code zusenden`,
  TWO_FACTOR_ACTIVATE: `2FA aktivieren`,
  TWO_FACTOR_VERIFY: `Verifizieren`,
  TWO_FACTOR_LOADING: 'Wird geprüft...',
  TWO_FACTOR_RESEND: `Code nicht erhalten oder abgelaufen? `,
  TWO_FACTOR_RESEND_CLICK: `E-Mail erneut senden`,

  TWO_FACTOR_EMAIL_SEND: `Eine E-Mail wurde an Dich versendet`,
  TWO_FACTOR_SUCCESS_TITLE: `Dein Account ist abgesichert`,
  TWO_FACTOR_SUCCESS_CONTENT: `Du hast die Zwei-Faktor-Authentisierung erfolgreich aktiviert!`,
  TWO_FACTOR_ERROR_CODE: `Der eingegebene Code ist falsch. Bitte versuche es noch einmal.`,
  TWO_FACTOR_ERROR_EXPIRED: `Der eingegebene Code ist abgelaufen. Bitte fordere einen neuen Code an.`,
  TWO_FACTOR_ERROR: `Ein Fehler ist während des Zwei-Faktor-Authentifizierungsprozesses aufgetreten. Bitte versuche es später erneut.`,

  TWO_FACTOR_INFO_HEADLINE: `Sichere Deine Daten mit Zwei-Faktor-Authentisierung (2FA)`,
  TWO_FACTOR_INFO_CONTENT_1: `Die Zwei-Faktor-Authentisierung, kurz 2FA, ist eine zusätzliche Sicherheitsmaßnahme, die den Schutz Deines Accounts bei LiveCreator erheblich verbessern kann. Während die herkömmliche Anmeldung nur durch ein Passwort erfolgt, kann dieser Zugang bei einem kompromittierten Passwort schnell missbraucht werden. Mit 2FA benötigst Du neben Deinem Passwort einen zweiten Faktor, um Dich anzumelden. So wird es für potenzielle Angreifer deutlich schwerer, in Deinen Account einzudringen.`,
  TWO_FACTOR_INFO_CONTENT_2: `Nach der Eingabe Deines Passworts erfolgt ein zusätzlicher Sicherheitscheck: Du musst einen Code eingeben, den Du entweder per E-Mail oder über eine Authenticator-App erhältst. Dieser Schritt stellt sicher, dass tatsächlich Du es bist, der auf den Account zugreifen möchte.`,
  TWO_FACTOR_INFO_CONTENT_3: `Erst wenn Deine Identität durch beide Faktoren (Passwort + Code) bestätigt wurde, kannst Du auf die Inhalte von LiveCreator zugreifen.`,
};

