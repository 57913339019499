import { connect } from 'react-redux';

import { spinner, updateTwoFactor, userLogout } from '../../../../actions';
import AccountSecurity from './AccountSecurity';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  spinner: (payload) => dispatch(spinner(payload)),
  userLogout: () => dispatch(userLogout()),

  updateTwoFactor: (payload) => dispatch(updateTwoFactor(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSecurity);
