import 'react-calendar/dist/Calendar.css';

import './EditGallery.scss';

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import useAxios from 'axios-hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Calendar from 'react-calendar';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import eyeIcon from '../../../assets/img/svg/eye.svg';
import scheduleIcon from '../../../assets/img/svg/schedule.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import { uuid } from '../../../utils';
import api from '../../../utils/api';
import { getUpload } from '../../../utils/uploadUtils';
import Button from '../../Button/Button';
import FormErrorMessage from '../../Forms/FormErrorMessage/FormErrorMessage';
import Input from '../../Forms/Input/Input';
import Pagination from '../../Pagination/Pagination';
import ThumbnailItem from '../../ThumbnailItem/ThumbnailItem';
import Uploader from '../../Uploader';
import SortableItem from '../../Utils/SortableItem/SortableItem';
import {
  checkGalleryErrors,
  mobileScrollIntoMap,
} from './EditGallery.functions';

function EditGallery({
  language,
  isMobile,
  activeGallery,
  updateActiveGallery,
  uploads = [],
  uploadFile,
  openOverlay,
  spinner,
  user,
}) {
  const intl = useIntl();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('id');
  const target = new URLSearchParams(search).get('target');
  const indicator = `gallery-${id}`;
  const { uploadIndicator, uploadError, uploadPercent, uploadResult } =
    getUpload(uploads, indicator);

  const videoFormatText = intl.formatMessage({
    id: 'VIDEO_GALLERY_FORMATS',
  });

  const [imageError, setImageError] = useState('');
  const [imageSuccess, setImageSuccess] = useState('');
  const [schedulerVisible, setSchedulerVisible] = useState(false);
  const [galleryError, setGalleryError] = useState(false);
  const [thumbnail, setThumbnail] = useState({ url: null, comment: null });
  const history = useHistory();
  const isMediumScreen = useMediaQuery('(max-width: 1023px)');
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const mediumScreenFivePicturesGrid = useMediaQuery(
    '(min-width: 1280px) and (max-width: 1599px)'
  );
  const largeScreenFivePicturesGrid = useMediaQuery('(min-width: 1920px)');
  const intervalRef = useRef(null);
  const [dateValue, setDateValue] = useState(new Date());
  const calendarRef = useRef();
  const [offset, setOffset] = useState(0);
  const [lastOffset, setLastOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const isVideoGallery = history.location.pathname.includes('videos');

  const canCancelGallery =
    activeGallery?.status === 'pending' &&
    activeGallery?.schedule &&
    user?.account?.state &&
    user.account.state !== 'unconfirmed';

  const paginationSize =
    mediumScreenFivePicturesGrid || largeScreenFivePicturesGrid ? 25 : 24;
  const editGalleryClass = classNames({
    published: activeGallery?.status === 'published',
    archived: activeGallery?.status === 'archived',
    blocked: activeGallery?.status === 'blocked',
    'not-published': activeGallery?.status === 'unpublished',
  });

  const scheduleMenuClass = classNames('calendar-wrapper', {
    mobile: isMobileScreen,
  });

  const buttonsWrapperClass = classNames('buttons-wrapper', {
    archived: activeGallery?.status === 'archived',
  });

  const [
    { data: galleryRequestData, loading: galleryRequestLoading },
    galleryRequest,
  ] = useAxios(
    {
      url: `/galleries/${id}?l=${paginationSize}&o=${offset}`,
    },
    { manual: true }
  );

  const [, sortPicturesRequest] = useAxios(
    {
      url: `/galleries/${id}/pictures`,
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const [, unscheduleGalleryRequest] = useAxios(
    {
      url: `/galleries/${id}/unschedule`,
      method: 'PUT',
    },
    { manual: true }
  );

  const [, deleteVideoRequest] = useAxios(
    {
      url: `/galleries/${id}/movie`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const [items, setItems] = useState([]);
  const [itemMenuVisible, setItemMenuVisible] = useState([]);
  const [scheduledDay, setScheduledDay] = useState(dayjs().date());
  const [scheduledMonth, setScheduledMonth] = useState(dayjs().month());
  const [scheduledYear, setScheduledYear] = useState(dayjs().year());
  const [isScheduled, setIsScheduled] = useState(false);
  const [publishError, setPublishError] = useState(false);
  const [videoUrls, setVideoUrls] = useState(null);
  const [videoMessage, setVideoMessage] = useState(null);
  const [deletionError, setDeletionError] = useState(null);
  const [conversionDetails, setConversionDetails] = useState({});
  const [conversionError, setConversionError] = useState(null);

  const [videoGalleryDeleteDisabled, setVideoGalleryDeleteDisabled] =
    useState(false);
  const [photoGalleryDeleteDisabled, setPhotoGalleryDeleteDisabled] =
    useState(false);

  const [, publishGalleryRequest] = useAxios(
    {
      url: `/galleries/${id}/publish`,
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const [{ loading: movieStatusRequestLoading }, movieStatusRequest] = useAxios(
    {
      url: `/galleries/${id}/movie/status`,
    },
    { manual: true }
  );

  const sortableGridConfirmed = classNames('image-grid', {
    empty: items.every((item) => item.status !== 'confirmed'),
  });

  const sortableGridUnconfirmed = classNames('image-grid', {
    empty: items.every((item) => item.status === 'confirmed'),
  });

  function updateMenuVisibility(id, value) {
    setItemMenuVisible((prevState) => {
      const index = items?.findIndex((itemMenu) => itemMenu.id === id);
      // The prev index needs to be found as well in case the items have been reordered since the last menu opening
      const prevIndex = prevState?.findIndex((itemMenu) => itemMenu.id === id);
      const newState = items.map((item) => {
        return { id: item.id, menu: false };
      });
      newState[index].menu =
        value === undefined ? !prevState[prevIndex].menu : value;
      return newState;
    });
  }

  const getMovieUrls = useCallback(
    (gallery) => {
      if (!gallery.movies?.length) {
        return;
      }

      if (gallery.movies[0].status === 'failed') {
        setConversionError('failed');
        return;
      }
      setVideoMessage({ loading: true });

      const getMovieData = async () => {
        const movieUrls = await api.get(
          '/galleries/' + gallery.id + '/movie/' + gallery.movies[0].id + '/vod'
        );
        setVideoUrls(movieUrls.data);
        setVideoMessage(null);
      };

      const checkPercentage = () => {
        if (intervalRef?.current) {
          return;
        }
        intervalRef.current = setInterval(async () => {
          try {
            if (!user?.isLoggedIn) {
              clearInterval(intervalRef.current);
              setConversionError(null);
              intervalRef.current = null;
              return;
            }
            if (movieStatusRequestLoading) {
              return;
            }
            const response = await movieStatusRequest();
            if (
              response.data.status === 'in_progress' &&
              response.data.percentage
            ) {
              setConversionDetails({
                percent: response.data.percentage,
                remainingTime: response.data.finished_in,
              });
            }
            if (response.data.status === 'finished') {
              setConversionDetails({
                percent: 100,
                remainingTime: 0,
              });
              clearInterval(intervalRef.current);
              intervalRef.current = null;
              setTimeout(async () => {
                try {
                  if (!user?.isLoggedIn) return;
                  await getMovieData();
                } catch (error) {
                  setConversionError(true);
                  clearInterval(intervalRef.current);
                }
              }, 1000);
            }
            if (response.data.status === 'failed') {
              setConversionError('failed');
              clearInterval(intervalRef.current);
              intervalRef.current = null;
              return;
            }
            setConversionError(null);
          } catch (error) {
            setConversionError(true);
            clearInterval(intervalRef.current);
          }
        }, 5000);
      };

      const checkMovies = async () => {
        try {
          await getMovieData();
        } catch (error) {
          if (
            error.response?.data?.errors?.includes(
              'movies_converted_video_not_found'
            )
          ) {
            setVideoMessage({ conversion: true });
            checkPercentage();
          }
        }
      };
      checkMovies();
    },
    [movieStatusRequest, movieStatusRequestLoading, user?.isLoggedIn]
  );

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const myInterval = useRef(null);
  useEffect(() => {
    if (!isVideoGallery) {
      const isGallery =
        galleryRequestData?.pictures &&
        galleryRequestData?.pictures.filter(
          (pic) => pic.status === 'converting' || pic.status === 'uploaded'
        );
      const intervalCheckStateChange = () => {
        if (isGallery?.length !== 0 && !galleryRequestLoading && id) {
          galleryRequest()
            .then((response) => {
              const items = response?.data?.pictures;
              if (Array.isArray(items)) {
                setItems(items);
              }
            })
            .catch(() => {});
        }
      };
      if (myInterval.current) {
        clearInterval(myInterval.current);
      }
      myInterval.current = setInterval(intervalCheckStateChange, 5000);
    }
    return () => clearInterval(myInterval.current);
  }, [
    galleryRequest,
    galleryRequestData,
    isVideoGallery,
    galleryRequestLoading,
    id,
  ]);

  const getGallery = useCallback(() => {
    if (!id) {
      history.push('/my-account/dashboard');
    }
    spinnerRequest({
      request: galleryRequest,
      spinner,
    })
      .then((response) => {
        getMovieUrls(response.data);
        setGalleryError(false);
        // set condition for videos
        const items = isVideoGallery
          ? response?.data?.movies
          : response?.data?.pictures;
        if (Array.isArray(items)) {
          setItems(items);
          const itemMenus = items.map((item) => {
            return { id: item.id, menu: false };
          });
          setItemMenuVisible(itemMenus);
          updateActiveGallery(response.data);
          if (
            response.data.thumbnail?.denied &&
            !response.data.thumbnail?.thumbnail
          ) {
            setThumbnail({
              url: response.data.thumbnail?.denied.file,
              comment:
                response.data.thumbnail?.denied.comment ||
                intl.formatMessage({ id: 'GALLERY_ERROR_THUMBNAIL' }),
            });
          } else {
            setThumbnail({
              url: response.data.thumbnail.thumbnail,
              comment: null,
            });
          }
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors[0] === 'gallery_invalid_user'
        ) {
          history.push('/my-account/dashboard');
        } else if (error?.response) {
          setGalleryError(intl.formatMessage({ id: 'GALLERY_ERROR_LOADING' }));
        }
      });
  }, [
    galleryRequest,
    getMovieUrls,
    history,
    id,
    intl,
    isVideoGallery,
    spinner,
    updateActiveGallery,
  ]);

  useEffect(() => {
    if (offset !== lastOffset) {
      setLastOffset(offset);
      getGallery();
    }
  }, [offset, lastOffset, getGallery]);

  useEffect(() => {
    if (!galleryRequestLoading && !galleryRequestData && !galleryError) {
      getGallery();
    }
  }, [galleryError, galleryRequestData, galleryRequestLoading, getGallery]);

  const todayDate = new Date();
  const minDate = todayDate.setDate(todayDate.getDate() + 3);
  const maxDate = todayDate.setDate(todayDate.getDate() + 365);

  const publishGallery = (schedule) => {
    const errors = checkGalleryErrors(activeGallery, isVideoGallery);

    if (errors) {
      setPublishError(errors);
      return;
    }

    const payload = {
      publish: schedule
        ? dayjs(dateValue).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    };

    spinnerRequest({
      request: publishGalleryRequest,
      spinner,
      payload: {
        data: payload,
      },
    })
      .then((response) => {
        updateActiveGallery(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.errors?.pictures[0]) {
          if (!errors) {
            setPublishError(['GALLERY_ERROR_MISSING', 'GALLERY_ERROR_PHOTOS']);
          }
        }
      });
  };

  const uploadFormData = useMemo(() => {
    new FormData();
  }, []);

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const newArray = arrayMove(items, oldIndex, newIndex);
      setItems([...newArray]);

      const filteredNewArray = newArray
        .filter((item) => item.status === 'confirmed')
        .map((item) => item.id);

      const payload = {
        pictures: filteredNewArray,
      };

      spinnerRequest({
        request: sortPicturesRequest,
        spinner,
        payload: { data: payload },
      }).catch(() => {});
    }
  }

  function moveElement(itemId, direction) {
    const confirmedItems = items.filter((item) => item.status === 'confirmed');
    const oldIndex = confirmedItems.findIndex((item) => item.id === itemId);
    let newIndex = oldIndex === confirmedItems.length - 1 ? 0 : oldIndex + 1;
    if (direction === 'backward') {
      newIndex = oldIndex === 0 ? confirmedItems.length - 1 : oldIndex - 1;
    }
    const newArray = arrayMove(confirmedItems, oldIndex, newIndex);
    const payload = {
      pictures: newArray.map((item) => item.id),
    };

    const unconfirmedItems = items.filter(
      (item) => item.status !== 'confirmed'
    );

    spinnerRequest({
      request: sortPicturesRequest,
      spinner,
      payload: { data: payload },
    })
      .then(() => {
        updateActiveGallery({
          ...activeGallery,
          pictures: [...newArray, ...unconfirmedItems],
        });
        setItems([...newArray, ...unconfirmedItems]);
        const element = document.getElementById(`thumbnail-item-${itemId}`);
        element.scrollIntoView({ behavior: 'smooth' });
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (uploadIndicator === indicator) {
      if (uploadError) {
        getGallery();
        const errorMsg = uploadError?.response?.data?.errors?.files
          ? uploadError?.response?.data?.errors?.files[0]
          : uploadError?.response?.data?.errors?.file
          ? uploadError?.response?.data?.errors?.file[0]
          : null;

        if (errorMsg === 'unable_to_open_zip' || errorMsg === 'mimes|zip') {
          setImageError(intl.formatMessage({ id: 'ZIP_FILE_ERROR' }));
        } else if (errorMsg === 'video_duration_min|60') {
          setImageError(intl.formatMessage({ id: 'VIDEO_DURATION_60_ERROR' }));
        } else {
          setImageError(intl.formatMessage({ id: 'ERROR_NETWORK_ERROR' }));
        }

        setImageSuccess('');
        uploadFile('', false, indicator);
      } else if (uploadResult && uploadResult?.data) {
        setImageSuccess('');
        if (
          uploadResult?.config?.url.includes('thumbnail') &&
          activeGallery?.status === 'published'
        ) {
          setImageSuccess(
            intl.formatMessage({ id: 'GALLERY_THUMBNAIL_MESSAGE' })
          );
        }
        getGallery();
        setImageError('');
        setDeletionError(null);
        if (!isVideoGallery) {
          let lastOffset =
            Math.ceil(uploadResult.data.pictures_total / paginationSize) - 1;
          setPageNumber(
            Math.ceil(uploadResult.data.pictures_total / paginationSize)
          );
          setOffset(paginationSize * lastOffset);
        }

        uploadFile('', false, indicator);
      }
    }
  }, [
    activeGallery?.status,
    getGallery,
    intl,
    uploadError,
    uploadFile,
    uploadIndicator,
    uploadResult,
    setOffset,
    galleryRequestData?.pictures_total,
    isVideoGallery,
    paginationSize,
    indicator,
  ]);

  useEffect(() => {
    if (activeGallery) {
      if (activeGallery?.schedule && !isScheduled) {
        setIsScheduled(true);
        setScheduledDay(dayjs(activeGallery.schedule).date());
        setScheduledMonth(dayjs(activeGallery.schedule).month());
        setScheduledYear(dayjs(activeGallery.schedule).year());
      } else if (!activeGallery?.schedule && isScheduled) {
        setIsScheduled(false);
        setScheduledDay(dayjs().date());
        setScheduledMonth(dayjs().month());
        setScheduledYear(dayjs().year());
      }
      setPublishError(false);
    }
  }, [activeGallery, isScheduled]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const openPictureCropper = useCallback(
    (acceptedfiles, source) => {
      const stamp = uuid();
      openOverlay({
        stamp,
        Component: 'PictureCropper',
        props: {
          imageSource: source,
          cropperstamp: stamp,
          uploadFormData,
          uploadPath: `/galleries/${id}/thumbnail`,
          indicator,
          file: acceptedfiles,
          title: intl.formatMessage({ id: 'GALLERY_PREVIEW_IMAGE' }),
          minDimensions: { width: 1024, height: 576 },
          widescreen: true,
        },
      });
    },
    [id, intl, openOverlay, uploadFormData, indicator]
  );

  useEffect(() => {
    setTimeout(() => {
      if (isMediumScreen && target) {
        const element = document.getElementsByClassName(
          mobileScrollIntoMap.get(target)
        )[0];
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 500);
  }, [isMediumScreen, target]);

  function itemDeleted(id) {
    if (isVideoGallery) {
      setItems([]);
      setItemMenuVisible([]);
      return;
    }
    const newItems = items.filter((item) => item.id !== id);
    setItems([...newItems]);
    const itemMenus = newItems.map((item) => {
      return { id: item.id, menu: false };
    });
    if (newItems.length === 0 && pageNumber !== 1 && offset !== 0) {
      setPageNumber((prevState) => prevState - 1);
      setOffset((prevState) => prevState - paginationSize);
    }
    getGallery();
    setItemMenuVisible(itemMenus);
  }

  function toggleScheduler() {
    if (!schedulerVisible) {
      setSchedulerVisible(true);
      return;
    }

    setSchedulerVisible(false);
    const errors = checkGalleryErrors(activeGallery, isVideoGallery);
    if (errors) {
      setPublishError(errors);
      return;
    }

    if (!dayjs(dateValue).isAfter(dayjs())) {
      setPublishError(['INVALID_DATE_MESSAGE']);
      return;
    }

    setPublishError(null);
    setIsScheduled(true);
    publishGallery(true);
  }

  function cancelSchedule() {
    spinnerRequest({
      request: unscheduleGalleryRequest,
      spinner,
    })
      .then(() => {
        setIsScheduled(false);
        updateActiveGallery({
          ...activeGallery,
          status: 'unpublished',
          schedule: null,
          published: false,
        });
      })
      .catch(() => {});
  }

  function deleteVideo() {
    spinnerRequest({
      request: deleteVideoRequest,
      spinner,
    })
      .then(() => {
        setDeletionError(null);
        itemDeleted();
        setConversionError(null);
      })
      .catch(() => {
        setDeletionError(true);
      });
  }

  useOutsideClick(calendarRef, () => {
    if (schedulerVisible) {
      setSchedulerVisible(false);
    }
  });

  useEffect(() => {
    if (isVideoGallery) {
      if (
        items?.length &&
        (activeGallery?.status === 'published' ||
          activeGallery?.status === 'pending')
      ) {
        setVideoGalleryDeleteDisabled(true);
      } else {
        setVideoGalleryDeleteDisabled(false);
      }
    } else {
      if (
        items?.length <= 3 &&
        (activeGallery?.status === 'published' ||
          activeGallery?.status === 'pending')
      ) {
        setPhotoGalleryDeleteDisabled(true);
      } else {
        setPhotoGalleryDeleteDisabled(false);
      }
    }
  }, [items.length, activeGallery?.status, isVideoGallery]);

  return galleryError ? (
    <div className="error-message">{galleryError}</div>
  ) : (
    <div className={editGalleryClass}>
      {activeGallery?.reason_for_reject && (
        <FormErrorMessage
          isShown={activeGallery?.reason_for_reject}
          title={intl.formatMessage({ id: 'GALLERY_REJECT_REASONS' })}
          message={`${intl.formatMessage({
            id: `reject_${activeGallery?.reason_for_reject}`,
          })}`}
        />
      )}
      <Uploader
        indicator={indicator}
        uploadPath={
          isVideoGallery ? `/galleries/${id}/movie` : `/galleries/${id}/picture`
        }
        uploadZip={`/galleries/${id}/zip`}
        uploadThumbnail={`/galleries/${id}/thumbnail`}
        uploadFormData={uploadFormData}
        minDimensions={{ width: 1024, height: 576 }}
        minGalleryDimensions={{ width: 800, height: 600 }}
        label="UNNAMED_GALLERY"
        acceptedFileTypes={
          isVideoGallery
            ? [
                '.mov',
                '.avi',
                'video/mp4',
                'video/quicktime',
                'video/x-msvideo',
                'video/x-ms-wmv',
              ]
            : ['.jpg', '.jpeg', '.jpe', '.png']
        }
        additionalAcceptedFileTypes={['.zip']}
        title={activeGallery?.title?.content}
        handleError={(error) => {
          setImageError(error);
        }}
        imageError={imageError}
        thumbnailComment={thumbnail.comment}
        imageSuccess={imageSuccess}
        hasChildrenWrapper={true}
        dropzoneProps={{ multiple: true }}
        isMobile={isMobile}
        className="profile-picture"
        type={isVideoGallery ? 'gallery-video' : 'gallery-photo'}
        thumbnailUrl={thumbnail.url}
        activeGallery={activeGallery}
        containsVideo={!!items.length}
        widescreen={true}
      />

      <div className="publishing-wrapper">
        {(activeGallery?.status === 'unpublished' ||
          activeGallery?.schedule ||
          activeGallery?.status === 'archived') && (
          <>
            {activeGallery?.status !== 'published' && (
              <div className={buttonsWrapperClass}>
                {isScheduled ? (
                  <div className="publish-date-info">
                    <span>
                      {intl.formatMessage({
                        id: 'GALLERY_WILL_BE_PUBLISHED_LABEL',
                      })}
                    </span>
                    <Input
                      value={dayjs(
                        `${scheduledYear}-${
                          parseInt(scheduledMonth) + 1
                        }-${scheduledDay}`
                      ).format(
                        language === 'de' ? 'DD.MMMM YYYY' : 'MMMM DD, YYYY'
                      )}
                      disabled={true}
                    />
                  </div>
                ) : (
                  activeGallery?.status !== 'archived' && (
                    <div ref={calendarRef} className="schedule-wrapper">
                      <Button
                        type="button"
                        icon={scheduleIcon}
                        onClick={() => toggleScheduler()}
                        label={
                          schedulerVisible ? 'SAVE_SCHEDULE' : 'SET_SCHEDULE'
                        }
                        className="schedule-button"
                        clicked={schedulerVisible}
                      />
                      {schedulerVisible && (
                        <div
                          className={scheduleMenuClass}
                          //stop propagation is used so the event doesnt bubble up when clicking month/year, (problem with outside click closing)
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Calendar
                            onChange={setDateValue}
                            value={dateValue}
                            minDate={new Date(minDate)}
                            maxDate={new Date(maxDate)}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
                {activeGallery?.status === 'unpublished' ||
                activeGallery?.status === 'archived' ? (
                  <Button
                    type="button"
                    icon={eyeIcon}
                    onClick={() => publishGallery()}
                    label={'PUBLISH_NOW'}
                    disabled={schedulerVisible}
                    className="publish-now-button"
                  />
                ) : (
                  canCancelGallery && (
                    <Button
                      type="button"
                      icon={scheduleIcon}
                      onClick={() => cancelSchedule()}
                      label={'CANCEL_SCHEDULE'}
                      className="cancel-schedule-button"
                    />
                  )
                )}
              </div>
            )}
            {publishError && (
              <div className="error-message">
                {<FormattedMessage id={publishError[0]} />}
                <ul>
                  {publishError.map((error, index) => {
                    return (
                      index !== 0 && (
                        <li key={`publish-error-${index}`}>
                          <FormattedMessage id={error} />
                        </li>
                      )
                    );
                  })}
                </ul>
              </div>
            )}
          </>
        )}
        {canCancelGallery && (
          <div className="success-message">
            {<FormattedMessage id="GALLERY_SCHEDULED" />}
          </div>
        )}
        {activeGallery?.status === 'pending' &&
          !!activeGallery?.published &&
          !!activeGallery?.schedule === false && (
            <div className="success-message">
              {<FormattedMessage id="GALLERY_PUBLISHED" />}
            </div>
          )}
      </div>

      {!activeGallery?.pictures?.length &&
        !activeGallery?.movies?.length &&
        !galleryRequestLoading &&
        !uploadPercent && (
          <div className="gallery-requirements">
            <p className="bold">
              <FormattedMessage id="GALLERY_REQUIREMENTS" />
            </p>
            {isVideoGallery ? (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl
                      .formatMessage({ id: 'VIDEO_GALLERY_REQUIREMENTS_LIST' })
                      .replace('{videoFormats}', videoFormatText),
                  }}
                />
              </>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'PHOTO_GALLERY_REQUIREMENTS_LIST',
                  }),
                }}
              />
            )}
          </div>
        )}
      {deletionError && (
        <div className="error-message video-error-message">
          <FormattedMessage id="VIDEO_DELETE_ERROR" />
        </div>
      )}
      {!!items?.length && (
        <>
          {!isVideoGallery && (
            <div className={sortableGridConfirmed}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                  {items
                    .filter((item) =>
                      isMobile ? false : item.status === 'confirmed'
                    )
                    .map((item, index) => (
                      <SortableItem
                        key={item.id}
                        id={item.id}
                        tickOrText={item.fsk === 'fsk12' ? 'tick' : 'text'}
                        item={item}
                        type="photo"
                        galleryStatus={activeGallery?.status}
                        openPictureCropper={openPictureCropper}
                        itemDeleted={itemDeleted}
                        itemMenuVisible={itemMenuVisible}
                        updateMenuVisibility={updateMenuVisibility}
                        setImageError={setImageError}
                      />
                    ))}
                </SortableContext>
              </DndContext>
            </div>
          )}
          {/* The confirmed and unconfirmed items are split into 2 separate grids for 2 reasons:
      1. The unconfirmed items can't be sorted, so they can't be inside the DndContext and SortableContext
      2. If we don't separate the divs, they will show up next to each other, which will break the menu structure
      as the menus are designed to show the last menu in a row sideways (so that it is visible) based on the index.
      To keep the indices calculation working, we need a different grid for each of them */}
          {!isVideoGallery && (
            <>
              <div
                className={
                  isMobile ? sortableGridConfirmed : sortableGridUnconfirmed
                }
              >
                {items
                  .filter((item, i) => {
                    return isMobile
                      ? item.status === 'confirmed'
                      : item.status !== 'confirmed';
                  })
                  .map((item, index) => (
                    <ThumbnailItem
                      key={item.id}
                      id={item.id}
                      item={item}
                      type={
                        item.status !== 'pending' &&
                        item.status !== 'failed' &&
                        item.status !== 'confirmed'
                          ? 'spinner'
                          : 'photo'
                      }
                      tickOrText={item.fsk === 'fsk12' ? 'tick' : 'text'}
                      galleryStatus={activeGallery?.status}
                      openPictureCropper={openPictureCropper}
                      itemDeleted={itemDeleted}
                      itemMenuVisible={itemMenuVisible}
                      updateMenuVisibility={updateMenuVisibility}
                      spinner={spinner}
                      activeGallery={activeGallery}
                      setImageError={setImageError}
                      changeItemPosition={moveElement}
                      component={'photoGallery'}
                      photoGalleryDeleteDisabled={photoGalleryDeleteDisabled}
                    />
                  ))}
              </div>
              {isMobile && (
                <div className={sortableGridUnconfirmed}>
                  {items
                    .filter((item, i) => {
                      return item.status !== 'confirmed';
                    })
                    .map((item, index) => (
                      <ThumbnailItem
                        key={item.id}
                        id={item.id}
                        item={item}
                        type={
                          item.status !== 'pending' && item.status !== 'failed'
                            ? 'spinner'
                            : 'photo'
                        }
                        galleryStatus={activeGallery?.status}
                        openPictureCropper={openPictureCropper}
                        itemDeleted={itemDeleted}
                        itemMenuVisible={itemMenuVisible}
                        updateMenuVisibility={updateMenuVisibility}
                        spinner={spinner}
                        activeGallery={activeGallery}
                        setImageError={setImageError}
                        component={'photoGallery'}
                        photoGalleryDeleteDisabled={photoGalleryDeleteDisabled}
                      />
                    ))}
                </div>
              )}
            </>
          )}
          {isVideoGallery && items && !videoMessage && !conversionError && (
            <div className={'image-grid'}>
              <ThumbnailItem
                key={items[0].id}
                id={items[0].id}
                item={items[0]}
                type={'video'}
                galleryStatus={activeGallery?.status}
                openPictureCropper={openPictureCropper}
                itemDeleted={itemDeleted}
                itemMenuVisible={itemMenuVisible}
                updateMenuVisibility={updateMenuVisibility}
                spinner={spinner}
                activeGallery={activeGallery}
                setImageError={setImageError}
                data={videoUrls}
                changeItemPosition={moveElement}
                setDeletionError={setDeletionError}
                videoGalleryDeleteDisabled={videoGalleryDeleteDisabled}
              />
            </div>
          )}
          {isVideoGallery &&
            videoMessage?.conversion &&
            conversionError !== 'failed' && (
              <div className={'image-grid'}>
                <ThumbnailItem
                  item={items[0]}
                  type={'spinner'}
                  conversionDetails={conversionDetails}
                  conversionError={conversionError}
                />
              </div>
            )}
          {isVideoGallery && conversionError === 'failed' && (
            <div className="error-wrapper error-conversion">
              <div className="error-title">
                {intl.formatMessage({ id: 'ERROR_TITLE' })}
              </div>
              <hr />
              <div className="error-content">
                {intl.formatMessage({ id: 'ERROR_CONVERTING_VIDEO' })}
                <Button
                  type="button"
                  onClick={() => deleteVideo()}
                  label={'GALLERY_MENU_DELETE'}
                  className="delete"
                />
              </div>
            </div>
          )}
        </>
      )}
      {!!uploadPercent &&
        isVideoGallery &&
        items.length === 0 &&
        !galleryRequestLoading && (
          <div className={'image-grid'}>
            <ThumbnailItem
              item={{}}
              type={'spinner'}
              uploadPercent={uploadPercent}
            />
          </div>
        )}
      {!isVideoGallery && items?.length ? (
        <Pagination
          offset={offset}
          totalItems={galleryRequestData?.pictures_total}
          pageNumber={pageNumber}
          setOffset={setOffset}
          setPageNumber={setPageNumber}
          paginationSize={paginationSize}
        />
      ) : null}
    </div>
  );
}

export default EditGallery;
