import Commission from './components/Content/Commission';
import BillArticles from './components/Content/Commission/BillArticles';
import BillDetails from './components/Content/Commission/BillDetails';
import BillingPeriod from './components/Content/Commission/BillingPeriod';
import LivecamOverview from './components/Content/Commission/LivecamOverview';
import ParkingCredits from './components/Content/Commission/ParkingCredits';
import Payouts from './components/Content/Commission/Payouts';
import ConfirmEmail from './components/Content/ConfirmEmail';
import Dashboard from './components/Content/Dashboard';
import Diary from './components/Content/Diary';
import MyDiary from './components/Content/Diary/MyDiary';
import Documents from './components/Content/Documents';
import Doi from './components/Content/Doi';
import EditGallery from './components/Content/EditGallery';
import ForgotPassword from './components/Content/ForgotPassword/ForgotPassword';
import LandingPageModel from './components/Content/LandingPages/LandingPageModel';
import Imprint from './components/Content/Legal/Imprint/Imprint';
import PrivacyPolicy from './components/Content/Legal/PrivacyPolicy';
import TermsAndConditions from './components/Content/Legal/TermsAndConditions';
import Livecam from './components/Content/Livecam';
import LivecamRedirect from './components/Content/Livecam/LivecamRedirect';
import Login from './components/Content/Login/Login';
import Attachments from './components/Content/MoneyMakers/Attachments';
import Icebreaker from './components/Content/MoneyMakers/Icebreaker';
import MyAccount from './components/Content/MyAccount';
import MyContent from './components/Content/MyContent';
import Comments from './components/Content/MyContent/Comments';
import MyComments from './components/Content/MyContent/Comments/MyComments';
import FreeMovieOfTheDay from './components/Content/MyContent/FreeMovieOfTheDay';
import MyPersonalDetails from './components/Content/MyPersonalDetails';
import AccountDetails from './components/Content/MyPersonalDetails/AccountDetails';
import AccountSecurity from './components/Content/MyPersonalDetails/AccountSecurity';
import BankDetails from './components/Content/MyPersonalDetails/BankDetails';
import PersonalDetails from './components/Content/MyPersonalDetails/PersonalDetails';
import MyProfile from './components/Content/MyProfile';
import MyPublicProfile from './components/Content/MyProfile/MyPublicProfile';
import ProfilePictures from './components/Content/MyProfile/ProfilePictures';
import ProfileText from './components/Content/MyProfile/ProfileText';
import ProfileVideo from './components/Content/MyProfile/ProfileVideo';
import PublicDetails from './components/Content/MyProfile/PublicDetails';
import MyRelationships from './components/Content/MyRelationships';
import Favorites from './components/Content/MyRelationships/Favorites';
import ActiveFriends from './components/Content/MyRelationships/Friends/ActiveFriends';
import Denied from './components/Content/MyRelationships/Friends/Denied';
import FriendRequests from './components/Content/MyRelationships/Friends/FriendRequests';
import Friends from './components/Content/MyRelationships/Friends/Friends';
import MyRequests from './components/Content/MyRelationships/Friends/MyRequests';
import Ignored from './components/Content/MyRelationships/Ignored';
import Newsletter from './components/Content/Newsletter';
import Onboarding from './components/Content/Onboarding';
import PasswordReset from './components/Content/PasswordReset/PasswordReset';
import Photos from './components/Content/Photos';
import MyPhotos from './components/Content/Photos/MyPhotos';
import Review from './components/Content/Review/Review';
import SetPassword from './components/Content/SetPassword/SetPassword';
import Settings from './components/Content/Settings';
import LivecamSettings from './components/Content/Settings/LivecamSettings';
import Options from './components/Content/Settings/Options';
import Prices from './components/Content/Settings/Prices';
import ToycontrolSettings from './components/Content/Settings/ToycontrolSettings';
import Signup from './components/Content/Signup';
import CustomerStats from './components/Content/Stats';
import Customers from './components/Content/Stats/Customers';
import Game4Score from './components/Content/Stats/Game4Score';
import ReferredCustomers from './components/Content/Stats/ReferredCustomers';
import Sales from './components/Content/Stats/Sales';
import Contact from './components/Content/Support';
import AdminLogin from './components/Content/TokenLogin/AdminLoginContainer';
import ClientLogin from './components/Content/TokenLogin/ClientLoginContainer';
import UnsubscribeNewsletter from './components/Content/UnsubscribeNewsletter';
import Videos from './components/Content/Videos';
import MyVideos from './components/Content/Videos/MyVideos';
import VirtualGifts from './components/Content/VirtualGifts';
import AllVirtualGifts from './components/Content/VirtualGifts/AllVirtualGifts/AllVirtualGifts';
import MyVirtualGifts from './components/Content/VirtualGifts/MyVirtualGifts';
import Wiki from './components/Content/Wiki';
import ContestPage from './components/Content/Wiki/Contest';
import WikiAccountPage from './components/Content/Wiki/HowTo';
import WikiXmasPage from './components/Content/Wiki/Xmas';
import HomeRoute from './components/Routing/HomeRoute';

export const routes = [
  {
    title: 'ROUTE_LOGIN_TITLE',
    subtitle: 'ROUTE_LOGIN_SUBTITLE',
    headline: 'ROUTE_LOGIN_HEADLINE',
    path: '/',
    exact: true,
    component: HomeRoute,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_LOGIN_TITLE',
    subtitle: 'ROUTE_LOGIN_SUBTITLE',
    headline: 'ROUTE_LOGIN_HEADLINE',
    path: '/management-login',
    component: AdminLogin,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_LOGIN_TITLE',
    subtitle: 'ROUTE_LOGIN_SUBTITLE',
    headline: 'ROUTE_LOGIN_HEADLINE',
    path: '/client-login',
    component: ClientLogin,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_HOMEPAGE_TITLE',
    subtitle: 'ROUTE_HOMEPAGE_SUBTITLE',
    headline: 'ROUTE_HOMEPAGE_HEADLINE',
    path: '/model',
    component: LandingPageModel,
    states: ['unauthenticated'],
  },
  {
    title: 'ROUTE_LOGIN_TITLE',
    subtitle: 'ROUTE_LOGIN_SUBTITLE',
    headline: 'ROUTE_LOGIN_HEADLINE',
    path: '/login',
    component: Login,
    states: ['unauthenticated'],
  },
  {
    title: 'ROUTE_SIGNUP_TITLE',
    subtitle: 'ROUTE_SIGNUP_SUBTITLE',
    headline: 'ROUTE_SIGNUP_HEADLINE',
    path: '/signup',
    component: Signup,
    states: ['unauthenticated'],
  },
  {
    title: 'ROUTE_PASSWORD_RESET_TITLE',
    subtitle: 'ROUTE_PASSWORD_RESET_SUBTITLE',
    headline: 'ROUTE_PASSWORD_RESET_HEADLINE',
    path: '/reset-password',
    component: PasswordReset,
    states: ['unauthenticated'],
  },
  {
    title: 'ROUTE_PASSWORD_RESET_TITLE',
    subtitle: 'ROUTE_SET_PASSWORD_SUBTITLE',
    headline: 'ROUTE_SET_PASSWORD_HEADLINE',
    path: '/set-password',
    component: SetPassword,
    states: ['unauthenticated'],
  },
  {
    title: 'ROUTE_PASSWORD_FORGOT_TITLE',
    subtitle: 'ROUTE_PASSWORD_FORGOT_SUBTITLE',
    headline: 'ROUTE_PASSWORD_FORGOT_HEADLINE',
    path: '/forgot-password',
    component: ForgotPassword,
    states: ['unauthenticated'],
  },
  {
    title: 'ROUTE_TERMS_TITLE',
    subtitle: 'ROUTE_TERMS_SUBTITLE',
    headline: 'ROUTE_LEGAL_TITLE',
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_LEGAL_TITLE',
    subtitle: 'ROUTE_PRIVACY_SUBTITLE',
    headline: 'ROUTE_LEGAL_TITLE',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_LEGAL_TITLE',
    subtitle: 'ROUTE_IMPRINT_SUBTITLE',
    headline: 'ROUTE_LEGAL_TITLE',
    path: '/imprint',
    component: Imprint,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_SUPPORT_TITLE',
    subtitle: 'ROUTE_SUPPORT_SUBTITLE',
    headline: 'ROUTE_SUPPORT_HEADLINE',
    path: '/support/:category?/:subject?/:subSubject?',
    component: Contact,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_NEWSLETTER_HEADLINE',
    subtitle: 'ROUTE_NEWSLETTER_SUBTITLE',
    headline: 'ROUTE_SUPPORT_TITLE',
    path: '/newsletter',
    component: Newsletter,
    states: ['confirmed', 'unconfirmed'],
  },
  {
    title: 'ROUTE_STATISTICS',
    headerTitle: 'ROUTE_MY_TOP_CUSTOMERS',
    subtitle: 'ROUTE_TOP_CUSTOMERS_SUBTITLE',
    headline: 'ROUTE_STATISTICS',
    path: '/statistics',
    component: CustomerStats,
    states: ['confirmed'],
  },
  {
    title: 'ROUTE_WIKI_HEADLINE',
    subtitle: 'ROUTE_WIKI_SUBTITLE',
    headline: 'ROUTE_SUPPORT_TITLE',
    path: '/wiki',
    component: Wiki,
    states: ['confirmed', 'unconfirmed'],
  },
  {
    title: 'ROUTE_ACCOUNT_TITLE',
    subtitle: 'ROUTE_ACCOUNT_SUBTITLE',
    headline: 'ROUTE_ACCOUNT_HEADLINE',
    path: '/onboarding',
    component: Onboarding,
    states: ['onboarding', 'pending'],
  },
  {
    title: 'ROUTE_REVIEW_TITLE',
    subtitle: 'ROUTE_REVIEW_SUBTITLE',
    headline: 'ROUTE_REVIEW_HEADLINE',
    path: '/review',
    component: Review,
    states: ['review_needed'],
  },
  {
    title: 'ROUTE_PENDING_TITLE',
    subtitle: 'ROUTE_PENDING_SUBTITLE',
    headline: 'ROUTE_PENDING_HEADLINE',
    path: '/onboarding',
    component: Onboarding,
    states: ['pending'],
  },
  {
    title: 'ROUTE_PENDING_TITLE',
    subtitle: 'ROUTE_PENDING_SUBTITLE',
    headline: 'ROUTE_PENDING_HEADLINE',
    path: '/doi',
    component: Doi,
    states: ['double_optin_needed'],
  },
  {
    title: 'ROUTE_CONFIRMED_TITLE',
    subtitle: 'ROUTE_CONFIRMED_SUBTITLE',
    headline: 'ROUTE_CONFIRMED_HEADLINE',
    showCreatorName: true,
    path: '/my-account',
    component: MyAccount,
    states: ['confirmed', 'unconfirmed'],
  },
  {
    title: 'ROUTE_CONTENT_TITLE',
    subtitle: 'ROUTE_CONTENT_SUBTITLE',
    headline: 'ROUTE_CONTENT_HEADLINE',
    path: '/my-content',
    component: MyContent,
    states: ['confirmed'],
  },
  {
    title: 'ROUTE_CONFIRMED_EMAIL_TITLE',
    subtitle: 'ROUTE_CONFIRMED_EMAIL_SUBTITLE',
    headline: 'ROUTE_CONFIRMED_EMAIL_HEADLINE',
    path: '/email-verification',
    component: ConfirmEmail,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_UNSUBSCRIBE_NEWSLETTER_TITLE',
    subtitle: 'ROUTE_UNSUBSCRIBE_NEWSLETTER_SUBTITLE',
    headline: 'ROUTE_UNSUBSCRIBE_NEWSLETTER_HEADLINE',
    path: '/unsubscribe-newsletter',
    component: UnsubscribeNewsletter,
    states: [
      'unauthenticated',
      'onboarding',
      'pending',
      'review_needed',
      'confirmed',
      'unconfirmed',
      'double_optin_needed',
    ],
  },
  {
    title: 'ROUTE_CAM_MESSENGER_TITLE',
    headline: 'ROUTE_CAM_MESSENGER_HEADLINE',
    path: '/multi-messenger',
    component: Livecam,
    states: ['confirmed'],
    livecam: true,
  },
  {
    title: 'ROUTE_CAM_MESSENGER_TITLE',
    headline: 'ROUTE_CAM_MESSENGER_HEADLINE',
    path: '/cam-messenger',
    component: LivecamRedirect,
    states: ['confirmed'],
    livecam: true,
  },
  {
    title: 'ROUTE_RELATIONSHIPS_TITLE',
    subtitle: 'ROUTE_RELATIONSHIPS_SUBTITLE',
    headline: 'ROUTE_RELATIONSHIPS_HEADLINE',
    path: '/my-relationships',
    component: MyRelationships,
    states: ['confirmed'],
  },
  {
    title: 'ROUTE_MONEYMAKERS_TITLE',
    headline: 'ROUTE_MONEYMAKERS_HEADLINE',
    subtitle: 'ROUTE_ATTACHMENTS_SUBTITLE',
    path: '/moneymakers/attachments',
    component: Attachments,
    states: ['confirmed'],
  },
  {
    title: 'ROUTE_MONEYMAKERS_TITLE',
    headline: 'ROUTE_MONEYMAKERS_HEADLINE',
    subtitle: 'ROUTE_ICEBREAKER_SUBTITLE',
    path: '/moneymakers/icebreaker',
    component: Icebreaker,
    states: ['confirmed'],
    icebreaker: true,
  },
  {
    title: 'ROUTE_GAME4SCORE_TITLE',
    headline: 'ROUTE_GAME4SCORE_TITLE',
    subtitle: 'ROUTE_GAME4SCORE_TITLE',
    path: '/statistics/game4score',
    component: Game4Score,
    states: ['confirmed'],
  },
];

export function getMyAccountRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_DASHBOARD_TITTLE',
      headerTitle: 'ROUTE_DASHBOARD_HEADLINE',
      path: '/dashboard',
      component: Dashboard,
    },
    {
      title: 'ROUTE_MY_PERSONAL_DETAILS_TITLE',
      headerTitle: 'ROUTE_PERSONAL_DETAILS_HEADLINE',
      path: '/my-personal-details',
      component: MyPersonalDetails,
    },
    {
      title: 'ROUTE_DOCUMENTS_TITLE',
      headerTitle: 'ROUTE_DOCUMENTS_HEADLINE',
      path: '/documents',
      component: Documents,
    },
    {
      title: 'ROUTE_COMMISSION_TITLE',
      headerTitle: 'ROUTE_COMMISSION_HEADLINE',
      path: '/commission',
      component: Commission,
    },
    {
      title: 'ROUTE_MY_PROFILE_TITLE',
      headerTitle: 'ROUTE_MY_PROFILE_HEADLINE',
      path: '/my-profile',
      component: MyProfile,
    },
    {
      title: 'ROUTE_VIRTUAL_GIFTS',
      headerTitle: 'ROUTE_VIRTUAL_GIFTS_HEADLINE',
      path: '/virtual-gifts',
      component: VirtualGifts,
    },
    {
      title: 'ROUTE_SETTINGS_TITLE',
      headerTitle: 'ROUTE_SETTINGS_HEADLINE',
      path: '/settings',
      component: Settings,
    },
  ];

  return routes;
}

export function getPersonalDetailsRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_ACCOUNT_DETAILS_TITLE',
      path: '/account-details',
      component: AccountDetails,
    },
    {
      title: 'ROUTE_ACCOUNT_SECURITY',
      path: '/account-security',
      component: AccountSecurity,
    },
    {
      title: 'ROUTE_PERSONAL_DETAILS_TITLE',
      path: '/personal-details',
      component: PersonalDetails,
    },
  ];

  const bankDetailsItem = {
    title: 'ROUTE_BANK_DETAILS_TITLE',
    path: '/bank-details',
    component: BankDetails,
  };

  if (!slave) {
    routes.push(bankDetailsItem);
  }

  return routes;
}

export function getCommissionRoutes(slave, parkingCreditsRef, payoutsRef) {
  if (slave && slave.camstatsVisible) {
    const livecamOverviewItems = [
      {
        title: 'ROUTE_LIVECAM_OVERVIEW_TITLE',
        path: '/livecam-overview',
        component: LivecamOverview,
      },
    ];
    return livecamOverviewItems;
  }

  const routes = [
    {
      title: 'ROUTE_BILLING_PERIOD_TITLE',
      path: '/billing-period',
      childPaths: ['/bill-articles'],
      component: BillingPeriod,
    },
    {
      path: '/bill-articles',
      component: BillArticles,
      notInMenu: true,
    },
    { path: '/payout-details', component: BillDetails, notInMenu: true },
    {
      path: '/parking-credits-detail',
      component: BillDetails,
      notInMenu: true,
    },
  ];

  const parkingCreditsItem = {
    title: 'ROUTE_PARKING_CREDITS_TITLE',
    path: '/parking-credits',
    childPaths: ['/parking-credits-detail'],
    component: ParkingCredits,
  };

  const payoutsItem = {
    title: 'ROUTE_PAYOUT_TITLE',
    path: '/payout',
    childPaths: ['/payout-details'],
    component: Payouts,
  };

  if (parkingCreditsRef) {
    routes.push(parkingCreditsItem);
  }

  if (payoutsRef) {
    routes.push(payoutsItem);
  }

  return routes;
}

export function getSettingsRoutes(
  slave,
  arg2,
  arg3,
  isLivecam,
  toycontrolEnabled
) {
  let routes = [
    {
      title: 'ROUTE_OPTIONS',
      path: '/options',
      component: Options,
    },
    {
      title: 'ROUTE_LIVECAM_TITLE',
      path: '/livecam',
      component: LivecamSettings,
    },
    {
      title: 'ROUTE_TOYCONTROL',
      path: '/toycontrol',
      component: ToycontrolSettings,
    },
    {
      title: 'ROUTE_PRICES',
      path: '/prices',
      component: Prices,
    },
  ];

  if (isLivecam === false) {
    routes = routes.filter((r) => r.path !== '/livecam');
  }

  if (toycontrolEnabled === false || isLivecam === false) {
    routes = routes.filter((r) => r.path !== '/toycontrol');
  }

  return routes;
}

export function getVirtualGiftsRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_MY_VIRTUAL_GIFTS',
      headerTitle: 'ROUTE_VIRTUAL_GIFTS_HEADLINE',
      path: '/my-virtual-gifts',
      component: MyVirtualGifts,
    },
    {
      title: 'ROUTE_ALL_VIRTUAL_GIFTS',
      headerTitle: 'ROUTE_VIRTUAL_GIFTS_HEADLINE',
      path: '/all-virtual-gifts',
      component: AllVirtualGifts,
    },
  ];

  return routes;
}

export function getStatsRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_SALES_SUBTITLE',
      headerTitle: 'HEADLINE_SALES',
      subtitle: 'ROUTE_SALES_SUBTITLE',
      path: '/sales',
      component: Sales,
    },
    {
      title: 'ROUTE_TOP_CUSTOMERS_SUBTITLE',
      headerTitle: 'ROUTE_MY_TOP_CUSTOMERS',
      subtitle: 'ROUTE_TOP_CUSTOMERS_SUBTITLE',
      headline: 'ROUTE_STATISTICS',
      path: '/top-customers',
      component: Customers,
      customerRoute: true,
    },
    {
      title: 'ROUTE_REFERRED_CUSTOMERS_SUBTITLE',
      headerTitle: 'ROUTE_MY_REFERRED_CUSTOMERS',
      subtitle: 'ROUTE_REFERRED_CUSTOMERS_SUBTITLE',
      headline: 'ROUTE_STATISTICS',
      path: '/referred-customers',
      component: ReferredCustomers,
      customerRoute: true,
      referredCustomer: true,
    },
    {
      title: 'ROUTE_GAME4SCORE_TITEL',
      headerTitle: 'ROUTE_GAME4SCORE_HEADLINE',
      path: '/game4score',
      component: Game4Score,
    },
  ];

  return routes;
}

export function getMyProfileRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_PROFILE_PICTURES',
      path: '/profile-pictures',
      component: ProfilePictures,
    },
    {
      title: 'ROUTE_PROFILE_VIDEO',
      path: '/profile-video',
      component: ProfileVideo,
    },
    {
      title: 'ROUTE_PROFILE_TEXT',
      path: '/profile-text',
      component: ProfileText,
    },
    {
      title: 'ROUTE_PUBLIC_DETAILS',
      path: '/public-details',
      component: PublicDetails,
    },
    {
      title: 'ROUTE_PUBLIC_PROFILE',
      path: '/my-public-profile',
      component: MyPublicProfile,
    },
  ];

  return routes;
}

export const routeFunctionsMap = new Map([
  ['/my-personal-details', getPersonalDetailsRoutes],
  ['/commission', getCommissionRoutes],
  ['/settings', getSettingsRoutes],
  ['/my-profile', getMyProfileRoutes],
  ['/photos', getPhotosRoutes],
  ['/friends', getFriendsRoutes],
  ['/videos', getVideosRoutes],
  ['/free-movie-of-the-day', getFreeMovieOfTheDayRoutes],
  ['/comments', getCommentsRoutes],
  ['/diary', getDiaryRoutes],
  ['/wiki', getWikiRoutes],
  ['/virtual-gifts', getVirtualGiftsRoutes],
  ['/statistics', getStatsRoutes],
]);

export function getMyContentRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_MY_PHOTOS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/photos',
      component: Photos,
    },
    {
      title: 'ROUTE_MY_VIDEOS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/videos',
      component: Videos,
    },
    {
      title: 'ROUTE_MY_COMMENTS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/comments',
      component: Comments,
    },
    {
      title: 'ROUTE_FMOTD',
      headerTitle: 'ROUTE_FMOTD_HEADLINE',
      path: '/free-movie-of-the-day',
      component: FreeMovieOfTheDay,
    },
    {
      title: 'ROUTE_MY_DIARY',
      headerTitle: 'ROUTE_MY_DIARY_HEADLINE',
      path: '/diary',
      component: Diary,
    },
  ];

  return routes;
}

export function getPhotosRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_MY_PHOTOS',
      path: '/my-photos',
      component: MyPhotos,
    },
    {
      title: 'ROUTE_EDIT_GALLERY',
      path: '/edit-gallery',
      component: EditGallery,
      notInMenu: 'current-path',
    },
  ];

  return routes;
}

export function getWikiRoutes(hasContest, hasXmas = false) {
  const routes = [
    {
      title: 'ROUTE_WIKI_START',
      headerTitle: 'ROUTE_WIKI',
      path: '/start',
      component: WikiAccountPage,
    },
    {
      title: 'ROUTE_WIKI_GENERAL',
      headerTitle: 'ROUTE_WIKI',
      path: '/general',
      component: WikiAccountPage,
    },
    {
      title: 'ROUTE_WIKI_MONEYMAKERS',
      headerTitle: 'ROUTE_WIKI',
      path: '/moneymakers',
      component: WikiAccountPage,
    },
  ];
  if (hasXmas) {
    routes.unshift({
      title: 'ROUTE_WIKI_XMAS',
      headerTitle: 'ROUTE_WIKI',
      path: '/xmas',
      component: WikiXmasPage,
    });
  }
  if (hasContest) {
    routes.unshift({
      title: 'ROUTE_CONTEST_SPECIAL',
      headerTitle: 'ROUTE_WIKI',
      path: '/contest',
      component: ContestPage,
    });
  }
  return routes;
}

export function getCommentsRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_MY_COMMENTS',
      path: '/my-comments',
      component: MyComments,
    },
  ];

  return routes;
}

export function getFreeMovieOfTheDayRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_FMOTD',
      path: '/my-movies',
      component: FreeMovieOfTheDay,
    },
  ];

  return routes;
}

export function getDiaryRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_MY_DIARY',
      path: '/my-diary',
      component: MyDiary,
    },
    {
      title: 'ROUTE_EDIT_DIARY',
      path: '/edit',
      component: MyDiary,
      notInMenu: 'current-path',
    },
  ];

  return routes;
}

export function getVideosRoutes(slave, pathname) {
  const routes = [
    {
      title: 'ROUTE_MY_VIDEOS',
      path: '/my-videos',
      component: MyVideos,
    },
    {
      title: 'ROUTE_EDIT_GALLERY',
      path: '/edit-gallery',
      component: EditGallery,
      notInMenu: 'current-path',
    },
  ];

  return routes;
}

// Used for the headlines and titles only
export const landingPageRoute = {
  title: 'ROUTE_HOMEPAGE_TITLE',
  subtitle: 'ROUTE_HOMEPAGE_SUBTITLE',
  headline: 'ROUTE_HOMEPAGE_HEADLINE',
  states: ['unauthenticated'],
};

export function getNestedRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_DASHBOARD_TITTLE',
      headerTitle: 'ROUTE_DASHBOARD_HEADLINE',
      path: '/dashboard',
      component: Dashboard,
    },
    {
      title: 'ROUTE_MY_PERSONAL_DETAILS_TITLE',
      headerTitle: 'ROUTE_PERSONAL_DETAILS_HEADLINE',
      path: '/my-personal-details',
      component: MyPersonalDetails,
    },
    {
      title: 'ROUTE_DOCUMENTS_TITLE',
      headerTitle: 'ROUTE_DOCUMENTS_HEADLINE',
      path: '/documents',
      component: Documents,
    },
    {
      title: 'ROUTE_COMMISSION_TITLE',
      headerTitle: 'ROUTE_COMMISSION_HEADLINE',
      path: '/commission',
      component: Commission,
    },
    {
      title: 'ROUTE_MY_PROFILE_TITLE',
      headerTitle: 'ROUTE_MY_PROFILE_HEADLINE',
      path: '/my-profile',
      component: MyProfile,
    },
    {
      title: 'ROUTE_VIRTUAL_GIFTS',
      headerTitle: 'ROUTE_MY_VIRTUAL_GIFTS_HEADLINE',
      path: '/virtual-gifts',
      component: VirtualGifts,
    },
    {
      title: 'ROUTE_GAME4SCORE_TITEL',
      headerTitle: 'ROUTE_GAME4SCORE_TITEL',
      path: '/statistics/game4score',
      component: Game4Score,
    },
    {
      title: 'ROUTE_CUSTOMERS_SUBTITLE',
      headerTitle: 'ROUTE_CUSTOMERS_SUBTITLE',
      path: '/statistics',
      component: CustomerStats,
    },
    {
      title: 'ROUTE_SETTINGS_TITLE',
      headerTitle: 'ROUTE_SETTINGS_HEADLINE',
      path: '/settings',
      component: Settings,
    },
    {
      title: 'ROUTE_MY_PHOTOS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/photos',
      component: Photos,
    },
    {
      title: 'ROUTE_MY_VIDEOS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/videos',
      component: Videos,
    },
    {
      title: 'ROUTE_MY_COMMENTS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/comments',
      component: Comments,
    },
    {
      title: 'ROUTE_FMOTD',
      headerTitle: 'ROUTE_FMOTD_HEADLINE',
      path: '/free-movie-of-the-day',
      component: FreeMovieOfTheDay,
    },
    {
      title: 'ROUTE_MY_DIARY',
      headerTitle: 'ROUTE_MY_DIARY_HEADLINE',
      path: '/diary',
      component: FreeMovieOfTheDay,
    },
    {
      title: 'ROUTE_IGNORED',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/ignored',
      component: Ignored,
    },
    {
      title: 'ROUTE_FAVORITES',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/favorites',
      component: Favorites,
    },
    {
      title: 'ROUTE_FRIENDS',
      headerTitle: 'ROUTE_MY_CONTENT_HEADLINE',
      path: '/friends',
      component: Friends,
    },
  ];

  return routes;
}

export function getMyRelationshipsRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_IGNORED',
      headerTitle: 'ROUTE_MY_RELATIONSHIPS_HEADLINE',
      path: '/ignored',
      component: Ignored,
    },
    {
      title: 'ROUTE_FAVORITES',
      headerTitle: 'ROUTE_MY_RELATIONSHIPS_HEADLINE',
      path: '/favorites',
      component: Favorites,
    },
    {
      title: 'ROUTE_FRIENDS',
      headerTitle: 'ROUTE_MY_RELATIONSHIPS_HEADLINE',
      path: '/friends',
      component: Friends,
    },
  ];

  return routes;
}

export function getFriendsRoutes(slave) {
  const routes = [
    {
      title: 'ROUTE_ACTIVE_FRIENDS',
      path: '/active-friends',
      component: ActiveFriends,
    },
    {
      title: 'ROUTE_FRIEND_REQUESTS',
      path: '/friend-requests',
      component: FriendRequests,
    },
    {
      title: 'ROUTE_MY_REQUESTS',
      path: '/my-requests',
      component: MyRequests,
    },
    {
      title: 'ROUTE_DENIED',
      path: '/denied',
      component: Denied,
    },
  ];

  return routes;
}

