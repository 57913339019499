import { useEffect, useRef } from 'react';

function ChatControlSwipe({ navRight, navLeft, children, isMobile }) {
  const swipeHandlerRef = useRef();

  const xDown = useRef(null);
  const yDown = useRef(null);
  const timer = useRef(null);

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  useEffect(() => {
    if (isMobile) {
      function debounce(func) {
        clearTimeout(timer);
        timer.current = setTimeout(() => {
          func();
        }, 300);
      }

      function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown.current = firstTouch.clientX;
        yDown.current = firstTouch.clientY;
      }

      function handleTouchMove(evt) {
        if (xDown.current && yDown.current) {
          let xUp = evt.touches[0].clientX;
          let yUp = evt.touches[0].clientY;

          let xDiff = xDown.current - xUp;
          let yDiff = yDown.current - yUp;

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
              debounce(navLeft);
            } else {
              debounce(navRight);
            }
          } else {
            if (yDiff > 0) {
              /* down swipe */
            } else {
              /* up swipe */
            }
          }
          /* reset values */
          xDown.current = null;
          yDown.current = null;
        }
      }

      const handler = swipeHandlerRef.current;
      handler.addEventListener('touchstart', handleTouchStart, {
        passive: true,
      });
      handler.addEventListener('touchmove', handleTouchMove, { passive: true });
      return () => {
        handler.removeEventListener('touchstart', handleTouchStart);
        handler.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [swipeHandlerRef, navLeft, navRight, isMobile]);

  return isMobile ? (
    <div className="swipe-handler" ref={swipeHandlerRef}>
      {children}
    </div>
  ) : (
    children
  );
}

export default ChatControlSwipe;
