import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// import sortAZIcon from '../../../../../assets/img/svg/sort-a-z.svg';
// import sortZAIcon from '../../../../../assets/img/svg/sort-z-a.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../../constants';
import { spinnerRequest } from '../../../../../shared/spinnerRequest';
import axiosInstance from '../../../../../utils/api';
import { addPortals } from '../../../../../utils/portals';
import Pagination from '../../../../Pagination/Pagination';
import ThumbnailItem from '../../../../ThumbnailItem';

function ActiveFriends({
  spinner,
  askDeleteFriendConfirmed,
  askDeleteFriendConfirmedAlert,
  askDeleteFriendId,
  deleteFriend,
  friendsError,
}) {
  const [items, setItems] = useState([]);
  // const [friendsSearch, setFriendsSearch] = useState('');
  // const [sortingDirection, setSortingDirection] = useState('asc');
  const [totalItems, setTotalItems] = useState(0);
  const initialItems = useRef(null);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [{ loading: friendsDataLoading }, friendsDataRequest] = useAxios(
    {
      url: `/user/friends?o=${offset}&l=${PAGINATION_PAGE_SIZE}`,
    },
    { manual: true }
  );

  const getFriendsData = useCallback(() => {
    spinnerRequest({
      request: friendsDataRequest,
      spinner,
    })
      .then(async (response) => {
        setTotalItems(response.data?.total);
        const data = await addPortals([...response.data?.data]);
        initialItems.current = data;
        setItems(data);
      })
      .catch(() => {});
  }, [friendsDataRequest, spinner]);

  const updateList = useCallback(
    (id) => {
      if (!id) return;
      //check if the last item on a page was deleted
      if (totalItems - offset === 1 && pageNumber > 1) {
        setOffset((prevState) => prevState - PAGINATION_PAGE_SIZE);
        setPageNumber((prevState) => prevState - 1);
        return;
      }
      if (offset >= totalItems) {
        setOffset(0);
        setPageNumber(1);
        return;
      }
      getFriendsData();
    },
    [getFriendsData, offset, pageNumber, totalItems]
  );

  useEffect(() => {
    getFriendsData();
  }, [getFriendsData]);

  useEffect(() => {
    let isMounted = true;
    if (askDeleteFriendConfirmed && askDeleteFriendConfirmedAlert) {
      // Confirmation for deleting a friend
      axiosInstance
        .delete(`/user/friends/${askDeleteFriendId}`)
        .then((response) => {
          if (isMounted) {
            deleteFriend(askDeleteFriendId, true);
            updateList(askDeleteFriendId);
            console.log(response);
          }
        })
        .catch((error) => {
          if (isMounted) {
            deleteFriend(askDeleteFriendId, true);
            if (error.response?.status === 409) {
              updateList(askDeleteFriendId);
              friendsError();
            }
            console.log(error);
          }
        });
    }
    return () => (isMounted = false);
  }, [
    askDeleteFriendConfirmed,
    askDeleteFriendConfirmedAlert,
    askDeleteFriendId,
    deleteFriend,
    friendsError,
    updateList,
  ]);

  // SEARCH TO BE ADDED BACK LATER

  // const searchFriendsHandler = (value) => {
  //   const filteredItems = initialItems.current.filter((item) => {
  //     if (value === '') {
  //       return true;
  //     }
  //     return item.nickname.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setItems(filteredItems);
  //   setFriendsSearch(value);
  // };

  // const clearSearchInput = () => {
  //   setFriendsSearch('');
  //   searchFriendsHandler('');
  // };

  // const sortFriends = () => {
  //   const sortedItems = [...initialItems.current];
  //   if (sortingDirection === 'desc') {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname > b.nickname) {
  //         return -1;
  //       } else if (b.nickname > a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('asc');
  //   } else {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname < b.nickname) {
  //         return -1;
  //       } else if (b.nickname < a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('desc');
  //   }
  //   setItems(sortedItems);
  //   initialItems.current = [...sortedItems];
  // };

  return !!initialItems.current?.length ? (
    <>
      {/*<div className="searchbar">
         <SearchInput
          id={'search_friends'}
          name={'search_friends'}
          placeholder={`${intl.formatMessage({
            id: 'SEARCH',
          })}`}
          value={friendsSearch}
          className="search-friends-input"
          onChange={(event) => searchFriendsHandler(event.target.value)}
          clearInput={clearSearchInput}
        />
        <ReactSVG
          onClick={() => sortFriends()}
          src={sortingDirection === 'asc' ? sortAZIcon : sortZAIcon}
          className={'sort-icon'}
          wrapper="span"
        />
      </div>*/}
      <div className="image-grid">
        {items.map((item, index) => (
          <ThumbnailItem
            item={item}
            type="relationship"
            key={`${item}-${index}`}
            relationshipType="friends"
            relationshipMenuType="active-friends"
            updateList={updateList}
          />
        ))}
      </div>
      <Pagination
        offset={offset}
        totalItems={totalItems}
        pageNumber={pageNumber}
        setOffset={setOffset}
        setPageNumber={setPageNumber}
      />
    </>
  ) : (
    !friendsDataLoading && (
      <div className="no-items">
        <FormattedMessage id="RELATIONSHIP_LABEL_NO_FRIENDS" />
      </div>
    )
  );
}

export default ActiveFriends;
