import './IcebreakerAttachments.scss';

import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import close from '../../../../assets/img/svg/close.svg';
import movieIcon from '../../../../assets/img/svg/movie.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { uuid } from '../../../../utils';
import Button from '../../../Button/Button';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import AttachmentItem from '../../../ThumbnailItem/AttachmentItem/AttachmentItem';

function IcebreakerAttachments({
  attachments,
  icebreakerData,
  updateData,
  updateOptions,
  clickHandler,
  menuOpen,
  type,
  standard,
  icebreakersIds,
  scrollIntoView,
  index,
  item,
  openOverlay,
  spinner,
}) {
  const ref = useRef(null);
  const outsideClickRef = useRef(null);
  const intl = useIntl();
  const realData = updateData ? updateData : icebreakerData;
  const attachmentFiltered = attachments.filter(
    (attachment) => attachment.value === realData?.attachment_id
  );
  const [selectedAttachment, setSelectedAttachment] = useState(
    ...attachmentFiltered
  );
  const { change, isDataLoading } = updateOptions;

  useOutsideClick(outsideClickRef, () => {
    if (menuOpen) {
      clickHandler();
    }
  });
  const selectAttachmentHandler = (value) => {
    if (!isDataLoading) {
      const newValue = selectedAttachment?.value === value ? null : value;
      const attachmentFiltered = attachments.filter(
        (attachment) => attachment.value === newValue
      );
      setSelectedAttachment(...attachmentFiltered);
      change({
        type: type,
        standard: standard,
        attachment_id: newValue,
      });
      clickHandler();
    }
  };
  const unSelectAttachmentHandler = () => {
    change({
      type: type,
      standard: standard,
      attachment_id: null,
    });

    setSelectedAttachment(null);
  };

  const openThumbnail = async (title, fileSource, type) => {
    spinner(true);
    if (type === 'image/jpeg') {
      const stamp = uuid();
      openOverlay({
        stamp,
        Component: 'Document',
        props: {
          openDocumentStamp: stamp,
          title,
          fileSource,
        },
      });
    } else {
      window.open(fileSource, '_blank');
    }

    spinner(false);
  };

  const clickNow = () => {
    clickHandler(type);
    setTimeout(() => scrollIntoView(ref), 100);
  };
  return (
    <div ref={ref} key={index} className="icebreaker-box">
      <p className="icebreaker-title">
        {intl.formatMessage({ id: item.headline })}
      </p>
      <div className="inner">
        <p className="text bold">{intl.formatMessage({ id: item.text1 })}</p>
        <div ref={outsideClickRef}>
          {item.text2 && (
            <p className="text text2">
              {intl.formatMessage({ id: item.text2 })}
            </p>
          )}
          {item.text3 && (
            <p className="text text2">
              {intl.formatMessage({ id: item.text3 })}
            </p>
          )}
          <div className="icebreaker-attachments">
            <div className="form-group">
              <label>
                <FormattedMessage id="ATTACHMENT_LABEL" />
              </label>
              {!selectedAttachment && (
                <Button
                  className="btn-default"
                  label="ICEBREAKER_ADD_ATTACHMENT"
                  onClick={(event) => {
                    clickNow(type, ref);
                    event.stopPropagation();
                  }}
                />
              )}

              {selectedAttachment && (
                <div className="selected-attachment">
                  <div
                    className="attachment-thumbnail-wrapper"
                    onClick={() =>
                      openThumbnail(
                        selectedAttachment?.label,
                        `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${selectedAttachment?.file}`,
                        selectedAttachment?.type
                      )
                    }
                  >
                    {selectedAttachment?.type.includes('video') ? (
                      <div className="attachment-thumbnail movie">
                        <ReactSVG
                          src={movieIcon}
                          wrapper="span"
                          className="icon"
                        />
                      </div>
                    ) : (
                      <div
                        className="attachment-thumbnail"
                        style={{
                          backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${selectedAttachment?.file}')`,
                        }}
                      />
                    )}
                  </div>
                  <span className="attachment-name">
                    {selectedAttachment?.label}
                  </span>
                  <Button
                    intlTranslate={false}
                    icon={close}
                    variant="icon-only"
                    className="delete"
                    onClick={() => unSelectAttachmentHandler()}
                  />
                </div>
              )}
            </div>

            {menuOpen && (
              <div className="attachment-wrapper">
                <Scrollbar visible={true}>
                  <div className="attachments-preview-container">
                    {attachments.map((attachment, index) => {
                      return (
                        <AttachmentItem
                          key={`${attachment.value}${index}`}
                          selectAttachment={selectAttachmentHandler}
                          value={attachment.value}
                          label={attachment.label}
                          file={attachment.file}
                          thumbnail={attachment.thumbnail}
                          selectedAttachment={selectedAttachment}
                          type={attachment.type}
                          disabled={icebreakersIds.includes(attachment.value)}
                        />
                      );
                    })}
                  </div>
                </Scrollbar>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default IcebreakerAttachments;

