import { SIDEBAR_TOGGLE } from '../actions/actions';
import { lStorage } from '../services/storage';

const sidebarMiddleware = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case SIDEBAR_TOGGLE:
      lStorage.setItem(
        'sidebar',
        JSON.stringify(store.getState().view.sidebar)
      );
      break;

    default:
      return;
  }
};

export default sidebarMiddleware;
