export default {
  ROUTE_SETTINGS_HEADLINE: `Manage your account, profile and prices here.`,
  ROUTE_SETTINGS_TOYCONTROL_HEADLINE: `Connect your Lovense toy here`,
  OPTIONS_NOTIFICATION_HEADER: `Notifications & Newsletter`,
  OPTIONS_NOTIFICATION_TEXT_1: `Would you like to be informed if you receive a message while you are offline?`,
  OPTIONS_NOTIFICATION_TEXT_2: `We will inform you at regular intervals about news about LiveCreator.com and our communities. We also provide you with useful tips on how you can become even more successful as a Creator.`,
  OPTIONS_NOTIFICATION_EMAIL: `Email notifications`,
  OPTIONS_NOTIFICATION_NEWSLETTER: `Subscribe newsletter`,
  OPTIONS_NOTIFICATION_RECOMMENDED: `(recommended)`,
  OPTIONS_CREATOR_TAGS_HEADER: `Creator tags`,
  OPTIONS_CREATOR_TAGS_TEXT: `Choose up to 5 different tags (properties), that describe you best. This will make it a lot easier for users to find you in our communities.`,
  OPTIONS_MESSENGER_SETTINGS_HEADER: `Messenger`,
  OPTIONS_MESSENGER_SETTINGS_TEXT_1: `Settings for the chat`,
  OPTIONS_FEATURES_HEADER: `Features`,
  OPTIONS_FEATURES_TEXT_1: `Would you like to use the following features?`,
  OPTIONS_FEATURES_APPS: `Dating Chat`,
  OPTIONS_FEATURES_DATING_CAMS: `Dating Cams`,
  OPTIONS_FEATURES_SAFE_MODE: `Safe Mode`,
  OPTIONS_FEATURES_VOYEUR: `Voyeur Cams`,
  OPTIONS_FEATURES_COMMUNITY_GAMES: `Community Games`,
  SETTINGS_OPTIONS_HEADLINE: `Community features in detail`,
  SETTINGS_OPTIONS_1: `<span class="bold">Dating User - Livecam and Chat</span><br />We also market our Livecams and Messenger on dating sites from other providers. Your profile is displayed there and users can message you or visit your Livecam. You benefit from thousands of potential paying customers outside our communities every day!`,
  SETTINGS_OPTIONS_2: `<span class="bold">Lovense Toy</span><br />Earn even more money during a cam session with Lovense's interactive sex toys! The software can be installed and set up quickly and easily on your mobile phone or computer. Your customers pay for your pleasure with the toy depending on the duration and vibration level.`,
  SETTINGS_OPTIONS_3: `<span class="bold">Safe Mode</span><br />With Safe Mode you have the option to hide all lewd pictures, file attachments and regards in the messenger.`,

  SETTINGS_OPTIONS_HEADLINE_DEFAULT: `These community features are always available`,
  SETTINGS_OPTIONS_1_DEFAULT: `<span class="bold">Voyeur Cams</span><br />With the "Voyeur Cam" you can earn additional money. You offer users the opportunity to watch your livecam anonymously at a reduced price. The user is invisible to you from his point of view and can "secretly" observe you. As a voyeur, however, he cannot send you messages or start Cam2Cam. As soon as there is at least one voyeur in your live cam, this is signaled to you by a symbol. A voyeur can switch to the normal show at any time with a single click, he is then immediately visible to you by name.`,
  SETTINGS_OPTIONS_2_DEFAULT: `<span class="bold">Exklusive Cam</span><br />In our communities we also offer a special Exclusive Cam mode. This is reserved exclusively for Creators with a particularly high livecam ranking and an above-average livecam performance. For further details please contact our `,
  SETTINGS_OPTIONS_3_DEFAULT: `<span class="bold">Weblog</span><br />As a Creator, you can write diary entries and let your users participate in your intimate life. This greatly helps to establish a better relationship with your regular customers and attract new users.`,

  PRICES_MESSAGE_HEADER: `Messages`,
  PRICES_MESSAGE_TEXT: `Manage and set the pricing for incoming messages for customers.`,
  PRICES_MESSAGE: `Message:`,
  PRICES_APPS_MESSAGE: `Dating Message:`,
  PRICES_LIVECAM_HEADER: `Livecams`,
  PRICES_LIVECAM_TEXT: `Please set the price you would like to charge for a livecam session <span class="bold">per minute</span>`,
  PRICES_NORMAL_SHOW: `Regular show:`,
  PRICES_EXCLUSIVE_SHOW: `Exclusive show:`,
  PRICES_VOYEUR_SHOW: `Voyeur show:`,
  PRICES_DATING_SHOW: `Dating show:`,
  SETTINGS_PRICES_HEADLINE: `Prices for Community features`,
  SETTINGS_PRICES_1: `<span class="bold">Price per minute</span><br />The price in coins that a user pays per minute in your livecam show. It is charged every 10 seconds.`,
  SETTINGS_PRICES_2: `<span class="bold">Regular Show</span><br />The price of a regular livecam show can be up to 35 Coins per minute. There is no limit to the number of users in your livecam Show.`,
  SETTINGS_PRICES_3: `<span class="bold">Exklusive Show</span><br />The price of an exclusive cam can be up to 90 Coins per minute. Only one user can view your exclusive cam show at the same time. This mode is reserved exclusively for Creators with a particularly high livecam ranking and above-average livecam performance. For further details please contact our `,
  SETTINGS_PRICES_4: `<span class="bold">Voyeur Show</span><br />The price of a voyeur cam show is 75% of the price of your normal live cam show. It is always rounded up during billing.`,
  SETTINGS_PRICES_5: `<span class="bold">Dating Cam</span><br />The price per minute for users who come through our external dating portal cooperation partners is capped at 25 Coins per minute. Due to external pricing models, the price cannot exceed the cap of 25 Coins per Minute.`,
  SETTINGS_PRICES_6: `<span class="bold">Dating Message</span><br />The maximum message price for dating messages is 5 Coins per message.`,
  SETTINGS_PRICES_HEADLINE_2: `What's the value of a Coin?`,
  SETTINGS_PRICES_TEXT: `For every coin spent, a user generates 10 cents in sales. From this turnover you will receive a commission in the amount of your current commission rate.`,
};
